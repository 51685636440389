import { ProdutoTipoInterface, UnidadeInterface, EstruturaArmazenagemInterface, EstruturaArmazenagemDivisaoInterface, ObjetoValorNumeroInterface } from "app/modules/gestao-unidades/operacional/shared/interfaces";
import { LinksResponseInterface, MetaResponseInterface } from "app/shared/interfaces";

export interface ResponseInterfaceAeracaoAutomatica<T> {
    totalizador: TotalizadorAeracaoAutomatica
    data: T[];
    meta?: MetaResponseInterface;
    links?: LinksResponseInterface;
    dataInicio?: string;
    dataFim?: string;
}

export interface TotalizadorAeracaoAutomatica {
    quantidade: number,
    receita: number
}

export interface DadosAeracaoAutomaticaInterface {
    id: string;
    tempo_armazenagem: number;
    umidade_grao: number;
    estrutura_armazenagem: EstruturaArmazenagemInterface;
    estrutura_armazenagem_divisao: EstruturaArmazenagemDivisaoInterface;
    unidade: UnidadeInterface;
    produto_tipo: ProdutoTipoInterface;
    tempo_aeracao: TempoAeracaoInterface;
    estoque: EstoqueInterface;
    ganho_aeracao_automatica: GanhoAeracaoAutomaticaInterface;
}

export interface EstoqueInterface {
    estoque_informado_maximo: number;
    estoque_sem_aeracao_automatica: number;
    estoque_capacidade_maximo: number;
}

export interface GanhoAeracaoAutomaticaInterface {
    quantidade: number;
    receita: number;
}

export interface TempoAeracaoInterface {
    tempo_decorrido_equilibrio_menor_igual_14: ObjetoValorNumeroInterface;
    tempo_decorrido_equilibrio_maior_14: ObjetoValorNumeroInterface;
    tempo_decorrido_manual: ObjetoValorNumeroInterface;
    tempo_decorrido_automatico: ObjetoValorNumeroInterface;
    tempo_decorrido_total: ObjetoValorNumeroInterface;
}
