export class SincronizadorModel {
    coletor_comunicacao: {
        id: string;
        instante: string;
        status: boolean;
    };
    comunicacao_local_com_nuvem: {
        id: string;
        instante: string;
        status: boolean;
    };
}

export class HistoricoSincronizadorModel {
    id: string;
    instante: string;
    status: boolean;
}
