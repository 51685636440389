export enum SecadorUltimaLeituraFornalhaKwEnum {
    status = 'status',
    modo_operacao = 'modo_operacao',
    temperatura_controle_rampa = 'temperatura_controle_rampa',
    posicao_valvula = 'posicao_valvula',
    velocidade_ventilador = 'velocidade_ventilador',
    velocidade_rosca = 'velocidade_rosca',
    velocidade_grelha = 'velocidade_grelha',
    queimador_ligado = 'queimador_ligado',
    queimador_temperatura_baixa = 'queimador_temperatura_baixa',
    queimador_temperatura_alta = 'queimador_temperatura_alta',
    queimador_alarme = 'queimador_alarme',
    queimador_falha = 'queimador_falha',
    queimador_status_operacao = 'queimador_status_operacao',
}

export enum SecadorUltimaLeituraFornalhaKwTranducaoEnum {
    STATUS_OPERACAO_FORNALHA = 'SECADOR_VISAO_SCREEN.STATUS_OPERACAO_FORNALHA',
    MODO_OPERACAO_FORNALHA = 'SECADOR_VISAO_SCREEN.MODO_OPERACAO_FORNALHA',
    TEMPERATURA_CONTROLE_RAMPA = 'SECADOR_VISAO_SCREEN.TEMPERATURA_CONTROLE_RAMPA',
    POSICAO_VALVULA = 'SECADOR_VISAO_SCREEN.POSICAO_VALVULA',
    VELOCIDADE_VENTILADOR = 'SECADOR_VISAO_SCREEN.VELOCIDADE_VENTILADOR',
    VELOCIDADE_ROSCA = 'SECADOR_VISAO_SCREEN.VELOCIDADE_ROSCA',
    VELOCIDADE_GRELHA = 'SECADOR_VISAO_SCREEN.VELOCIDADE_GRELHA',
    QUEIMADOR_LIGADO = 'SECADOR_VISAO_SCREEN.QUEIMADOR_LIGADO',
    QUEIMADOR_TEMPERATURA_BAIXA = 'SECADOR_VISAO_SCREEN.QUEIMADOR_TEMPERATURA_BAIXA',
    QUEIMADOR_TEMPERATURA_ALTA = 'SECADOR_VISAO_SCREEN.QUEIMADOR_TEMPERATURA_ALTA',
    QUEIMADOR_ALARME = 'SECADOR_VISAO_SCREEN.QUEIMADOR_ALARME',
    QUEIMADOR_FALHA = 'SECADOR_VISAO_SCREEN.QUEIMADOR_FALHA',
    QUEIMADOR_STATUS_OPERACAO = 'SECADOR_VISAO_SCREEN.QUEIMADOR_STATUS_OPERACAO',
}
