export interface elementosTabelaModalAeracaoAutomatica {
    Silo_Armazem: string;
    Grao: string;
    Acao: number | string;
}

export interface ModalAeracaoConfiguracao {
    dataPointIndex: number;
    selectedDataPoints: number[];
    seriesIndex: number;
    w: any[];
}
