import { NomeTipoSensorResumoEnum, UnidadeMedidaSensoresEnum } from 'app/shared/enums';

export class UnidadesMedidasSensoresResumoHelper {
    public static formataUnidadasMeidasSensoresResumo(tipoSensor: string): string {
        switch (tipoSensor) {
            case NomeTipoSensorResumoEnum.TEMPERATURA_DIGITAL:
                return UnidadeMedidaSensoresEnum.DIGITAL;
            case NomeTipoSensorResumoEnum.PRESSAO:
                return UnidadeMedidaSensoresEnum.PRESSAO;
            case NomeTipoSensorResumoEnum.TEMPERATURA_TERMOPAR:
                return UnidadeMedidaSensoresEnum.TERMOPAR;
            case NomeTipoSensorResumoEnum.TEMPERATURA_GRAO:
                return UnidadeMedidaSensoresEnum.TERMORESISTENCIA;
            case NomeTipoSensorResumoEnum.UMIDADE_GRAO:
                return UnidadeMedidaSensoresEnum.UMIDADE_GRAO;
            case NomeTipoSensorResumoEnum.UMIDADE_RELATIVA:
                return UnidadeMedidaSensoresEnum.UMIDADE_RELATIVA;
            default:
                return '';
        }
    }
}
