export interface UnidadeInterface {
    id: number;
    unidade_parametrizacao: UnidadeParametrizacaoInterface;
    unidade_produto_interno: UnidadeProdutoInternoInterface[];
}

interface UnidadeParametrizacaoInterface {
    id: number;
    unidade_id: string;
    potencia_limite: number;
    prioridade_acionamento: string;
    aguardar_tempo_entre_estruturas: boolean;
    tempo_espera_entre_estruturas: number;
    aguardar_tempo_histerese: boolean;
    tempo_espera_histerese: number;
    horario_pico_inicio: string;
    horario_pico_fim: string;
    intervalo_ligar_antes_horario_pico: number;
    intervalo_desligar_antes_horario_pico: number;
    unidade_estoque_padrao: string;
    tempo_expiracao_comando: number;
    tempo_expiracao_comando_secador: number;
    aquecimento_ar_1: number;
    aquecimento_ar_2: number;
    aquecimento_ar_3: number;
    instalado_local: boolean;
    instalado_nuvem: boolean;
    ceres_cpu_modelo: string;
}

export interface UnidadeProdutoInternoInterface {
    id: string;
    arquivado: boolean;
    unidade_id: string;
    produto: string;
}
