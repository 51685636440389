import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { TipoAmbiente } from 'app/shared/enums';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class BloqueiaFuncionalidadeAmbienteLocalGuard implements CanActivate {
    constructor(private router: Router) {}

    canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (environment.level.toUpperCase() === TipoAmbiente.LOCAL) {
            this.router.navigate(['/armazenagem/visualizacao/painel-geral']);
            return of(false);
        }
        return of(true);
    }
}
