export enum CoresAeracaoGraosEnum {
    COR_AUTOMATICA = '#39CA6A',
    COR_MANUAL = '#FFBA05',
    COR_SEM_COMUNICACAO = '#D6505A',
    COR_RESFRIAMENTO = '#019DE8',
    COR_CONSERVACAO ='#3CBCBC',
    COR_SECAGEM = '#E46262',
    COR_RESFRIAMENTO_E_CONSERVACAO = '#49CA27',
    COR_CORRETIVA = '#9747FF',
    COR_CONTROLE_MANUAL = '#FFBA05',
    SEM_COR_FUNDO = '#FFF',
    COR_MARKER_LEGENDA_BORDER ='#535767'
}
