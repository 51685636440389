import { Injectable } from '@angular/core';
import { IApiTransportInterface } from 'app/core/interfaces';
import { ApiTransportService } from 'app/core/services/transport';
import {
    ResponseInterface,
    EstacaoMeteorologicaInterface,
    EstacaoMetereologicaTipoInterface,
} from 'app/shared/interfaces';
import { Observable } from 'rxjs';

export abstract class IEstacaoMeteorologicaController {
    abstract buscaEstacoesMeteorologicas(): Observable<ResponseInterface<EstacaoMeteorologicaInterface>>;
    abstract buscaTiposEstacoesMeteorologicas(
        data?: string[]
    ): Observable<ResponseInterface<EstacaoMetereologicaTipoInterface>>;
}

@Injectable({
    providedIn: 'root',
})
export class EstacaoMeteorologicaController {
    private transport: IApiTransportInterface;

    constructor(public readonly apiTransportService: ApiTransportService) {
        this.transport = this.apiTransportService.execute('/estacao-meteorologica');
    }

    buscaEstacoesMeteorologicas(): Observable<ResponseInterface<EstacaoMeteorologicaInterface>> {
        return this.transport.http.get<ResponseInterface<EstacaoMeteorologicaInterface>>(
            `${this.transport.path}/listagem/estacao`
        );
    }

    buscaTiposEstacoesMeteorologicas(
        data?: string[]
    ): Observable<ResponseInterface<EstacaoMetereologicaTipoInterface>> {
        const params = data ? { tipos: data } : {};

        return this.transport.http.get<ResponseInterface<EstacaoMetereologicaTipoInterface>>(
            `${this.transport.path}/estacao`,
            { params }
        );
    }
}
