export enum SucessoApiEnum {    
    PROGRAMA_MENSAGEM_SALVAR_SUCESSO = 'MODAL_SUCESSO_API_COMPONENT.PROGRAMA_MENSAGEM_SALVAR_SUCESSO',
    PROGRAMA_MENSAGEM_DESARQUIVARDO_SUCESSO = 'MODAL_SUCESSO_API_COMPONENT.PROGRAMA_MENSAGEM_DESARQUIVARDO_SUCESSO',
    PROGRAMA_MENSAGEM_ARQUIVARDO_SUCESSO = 'MODAL_SUCESSO_API_COMPONENT.PROGRAMA_MENSAGEM_ARQUIVARDO_SUCESSO',
    PROGRAMA_MENSAGEM_DUPLICAR_SUCESSO = 'MODAL_SUCESSO_API_COMPONENT.PROGRAMA_MENSAGEM_DUPLICAR_SUCESSO',
    PROGRAMA_MENSAGEM_EXPORTAR_SUCESSO = 'MODAL_SUCESSO_API_COMPONENT.PROGRAMA_MENSAGEM_EXPORTAR_SUCESSO',
    GRAO_MENSAGEM_ARQUIVARDO_SUCESSO = 'MODAL_SUCESSO_API_COMPONENT.GRAO_MENSAGEM_ARQUIVARDO_SUCESSO',
    GRAO_MENSAGEM_DESARQUIVARDO_SUCESSO = 'MODAL_SUCESSO_API_COMPONENT.GRAO_MENSAGEM_DESARQUIVARDO_SUCESSO',
    GRAO_MENSAGEM_SALVAR_SUCESSO = 'MODAL_SUCESSO_API_COMPONENT.GRAO_MENSAGEM_SALVAR_SUCESSO',
    COMANDO_ENVIADO_SUCESSO = 'MODAL_SUCESSO_API_COMPONENT.COMANDO_ENVIADO_SUCESSO',
    CONTROLE_ALTERADO_SUCESSO = 'MODAL_SUCESSO_API_COMPONENT.CONTROLE_ALTERADO_SUCESSO',
    PROGRAMA_ALTERADO_SUCESSO = 'MODAL_SUCESSO_API_COMPONENT.PROGRAMA_ALTERADO_SUCESSO',
    VARIAVEL_ALTERADA_SUCESSO = 'MODAL_SUCESSO_API_COMPONENT.VARIAVEL_ALTERADA_SUCESSO',
    LIMPEZA_SECADOR_SUCESSO = 'MODAL_SUCESSO_API_COMPONENT.LIMPEZA_SECADOR_SUCESSO',
    SECADOR_ALERTA_MENSAGEM_SALVAR_SUCESSO = 'MODAL_SUCESSO_API_COMPONENT.SECADOR_ALERTA_MENSAGEM_SALVAR_SUCESSO',
    SECADOR_ALERTA_DESTINATARIO_MENSAGEM_ARQUIVARDO_SUCESSO = 'MODAL_SUCESSO_API_COMPONENT.SECADOR_ALERTA_DESTINATARIO_MENSAGEM_ARQUIVARDO_SUCESSO',
    SECADOR_ALERTA_DESTINATARIO_MENSAGEM_DESARQUIVARDO_SUCESSO = 'MODAL_SUCESSO_API_COMPONENT.SECADOR_ALERTA_DESTINATARIO_MENSAGEM_DESARQUIVARDO_SUCESSO',
    SECADOR_ALERTA_DESTINATARIO_MENSAGEM_SALVAR_SUCESSO = 'MODAL_SUCESSO_API_COMPONENT.SECADOR_ALERTA_DESTINATARIO_MENSAGEM_SALVAR_SUCESSO',
    SECADOR_ALTERACAO_CONTROLE_SUCESSO = 'MODAL_SUCESSO_API_COMPONENT.SECADOR_ALTERACAO_CONTROLE_SUCESSO',
}
