export const configuracaoTamanhoModal = {
    fullScreen: {
        width: '100%',
        adaptacaoHeight: 0.89,        
    },
    informativo: {
        desktop: {
            width: '450px',
            height: '350px',
        },
        mobile: {
            width: '100%',
            height: 'auto',
        },
    },
    comTabela: {
        desktop: {
            width: '640px',
            height: '400px',
        },
        mobile: {
            width: '100%',
            height: 'auto',
        },
    },
    confirmacao: {
        desktop: {
            width: '450px',
            height: '350px',
        },
        mobile: {
            width: '100%',
            height: 'auto',
        },
    },
};
