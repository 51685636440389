export const ordemSecadorQualidade = {
    'entradaTemperatura:desc': 'secador_leitura_historico_consolidado.temperatura_atual_entrada:DESC',
    'entradaTemperatura:asc': 'secador_leitura_historico_consolidado.temperatura_atual_entrada:ASC',
    'saidaTemperatura:desc': 'secador_leitura_historico_consolidado.temperatura_atual_saida:DESC',
    'saidaTemperatura:asc': 'secador_leitura_historico_consolidado.temperatura_atual_saida:ASC',
    'massaTemperatura:desc': 'secador_leitura_historico_consolidado.temperatura_atual_massa:DESC',
    'massaTemperatura:asc': 'secador_leitura_historico_consolidado.temperatura_atual_massa:ASC',
    'entradaMaiorTemperatura:desc': 'secador_leitura_historico_consolidado.maior_valor_lido_entrada:DESC',
    'entradaMaiorTemperatura:asc': 'secador_leitura_historico_consolidado.maior_valor_lido_entrada:ASC',
    'massaMaiorTemperatura:desc': 'secador_leitura_historico_consolidado.maior_valor_lido_massa:DESC',
    'massaMaiorTemperatura:asc': 'secador_leitura_historico_consolidado.maior_valor_lido_massa:ASC',
    'saidaMaiorTemperatura:desc': 'secador_leitura_historico_consolidado.maior_valor_lido_saida:DESC',
    'saidaMaiorTemperatura:asc': 'secador_leitura_historico_consolidado.maior_valor_lido_saida:ASC',
    'entradaAlerta:desc': 'secador_leitura_historico_consolidado.numero_alerta_temperatura_maxima_entrada:DESC',
    'entradaAlerta:asc': 'secador_leitura_historico_consolidado.numero_alerta_temperatura_maxima_entrada:ASC',
    'massaAlerta:desc': 'secador_leitura_historico_consolidado.numero_alerta_temperatura_maxima_massa:DESC',
    'massaAlerta:asc': 'secador_leitura_historico_consolidado.numero_alerta_temperatura_maxima_massa:ASC',
    'saidaAlerta:desc': 'secador_leitura_historico_consolidado.numero_alerta_temperatura_maxima_saida:DESC',
    'saidaAlerta:asc': 'secador_leitura_historico_consolidado.numero_alerta_temperatura_maxima_saida:ASC',
    'cargaUmidade:desc': 'secador_leitura_historico_consolidado.umidade_grao_carga:DESC',
    'cargaUmidade:asc': 'secador_leitura_historico_consolidado.umidade_grao_carga:ASC',
    'descargaUmidade:desc': 'secador_leitura_historico_consolidado.umidade_grao_descarga:DESC',
    'descargaUmidade:asc': 'secador_leitura_historico_consolidado.umidade_grao_descarga:ASC',
    'minimoUmidadeCarga:desc': 'secador_leitura_historico_consolidado.ug_carga_minima:DESC',
    'minimoUmidadeCarga:asc': 'secador_leitura_historico_consolidado.ug_carga_minima:ASC',
    'mediaUmidadeCarga:desc': 'secador_leitura_historico_consolidado.ug_carga_media:DESC',
    'mediaUmidadeCarga:asc': 'secador_leitura_historico_consolidado.ug_carga_media:ASC',
    'maximaUmidadeCarga:desc': 'secador_leitura_historico_consolidado.ug_carga_maxima:DESC',
    'maximaUmidadeCarga:asc': 'secador_leitura_historico_consolidado.ug_carga_maxima:ASC',
    'minimoUmidadeDescarga:desc': 'secador_leitura_historico_consolidado.ug_descarga_minima:DESC',
    'minimoUmidadeDescarga:asc': 'secador_leitura_historico_consolidado.ug_descarga_minima:ASC',
    'mediaUmidadeDescarga:desc': 'secador_leitura_historico_consolidado.ug_descarga_media:DESC',
    'mediaUmidadeDescarga:asc': 'secador_leitura_historico_consolidado.ug_descarga_media:ASC',
    'maximaUmidadeDescarga:desc': 'secador_leitura_historico_consolidado.ug_descarga_maxima:DESC',
    'maximaUmidadeDescarga:asc': 'secador_leitura_historico_consolidado.ug_descarga_maxima:ASC',
}
