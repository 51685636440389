import { PaletaDeCoresInterface } from "app/shared/interfaces";

export const paletaCoresMassaTemperaturaGraoConstant: { [key in string]: PaletaDeCoresInterface[] } = {
    arroz: [
        { min: '', max: '', fundo: '#EBEEF2', cor: '#16171B' },
        { min: 0, max: 19, fundo: '#313696', cor: '#FFFFFF' },
        { min: 20, max: 29, fundo: '#74aed2', cor: '#16171B' },
        { min: 30, max: 38, fundo: '#3DAC79', cor: '#FFFFFF' },
        { min: 39, max: 49, fundo: '#E75135', cor: '#FFFFFF' },
        { min: 50, max: 100, fundo: '#D83027', cor: '#FFFFFF' },
    ],
    milho: [
        { min: '', max: '', fundo: '#EBEEF2', cor: '#16171B' },
        { min: 0, max: 19, fundo: '#313696', cor: '#FFFFFF' },
        { min: 20, max: 29, fundo: '#74aed2', cor: '#16171B' },
        { min: 30, max: 48, fundo: '#3DAC79', cor: '#FFFFFF' },
        { min: 49, max: 59, fundo: '#E75135', cor: '#FFFFFF' },
        { min: 60, max: 100, fundo: '#D83027', cor: '#FFFFFF' },
    ],
    soja: [
        { min: '', max: '', fundo: '#EBEEF2', cor: '#16171B' },
        { min: 0, max: 19, fundo: '#313696', cor: '#FFFFFF' },
        { min: 20, max: 29, fundo: '#74aed2', cor: '#16171B' },
        { min: 30, max: 35, fundo: '#3DAC79', cor: '#FFFFFF' },
        { min: 36, max: 49, fundo: '#E75135', cor: '#FFFFFF' },
        { min: 50, max: 100, fundo: '#D83027', cor: '#FFFFFF' },
    ],
    trigo: [
        { min: '', max: '', fundo: '#EBEEF2', cor: '#16171B' },
        { min: 0, max: 19, fundo: '#313696', cor: '#FFFFFF' },
        { min: 20, max: 29, fundo: '#74aed2', cor: '#16171B' },
        { min: 30, max: 48, fundo: '#ffffc0', cor: '#16171B' },
        { min: 49, max: 59, fundo: '#E75135', cor: '#FFFFFF' },
        { min: 60, max: 100, fundo: '#D83027', cor: '#FFFFFF' },
    ],
};
