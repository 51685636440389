import { PaletaDeCoresInterface } from "app/shared/interfaces";

export const paletaCoresCargaUmidadeGraoConstant: { [key in string]: PaletaDeCoresInterface[] } = {
    arroz: [
        { min: '', max: '', fundo: '#EBEEF2', cor: '#16171B' },
        { min: 0, max: 13.5, fundo: '#d83027', cor: '#FFFFFF' },
        { min: 13.6, max: 16.5, fundo: '#e75135', cor: '#FFFFFF' },
        { min: 16.6, max: 21.99, fundo: '#3DAC79', cor: '#FFFFFF' },
        { min: 22, max: 25.99, fundo: '#25719E', cor: '#FFFFFF' },
        { min: 26, max: 100, fundo: '#185C84', cor: '#FFFFFF' },
    ],
    milho: [
        { min: '', max: '', fundo: '#EBEEF2', cor: '#16171B' },
        { min: 0, max: 14.5, fundo: '#d83027', cor: '#FFFFFF' },
        { min: 14.6, max: 16.5, fundo: '#e75135', cor: '#FFFFFF' },
        { min: 16.6, max: 21.99, fundo: '#3DAC79', cor: '#FFFFFF' },
        { min: 22, max: 25.99, fundo: '#25719E', cor: '#FFFFFF' },
        { min: 26, max: 100, fundo: '#185C84', cor: '#FFFFFF' },
    ],
    soja: [
        { min: '', max: '', fundo: '#EBEEF2', cor: '#16171B' },
        { min: 0, max: 14.5, fundo: '#d83027', cor: '#FFFFFF' },
        { min: 14.6, max: 16.5, fundo: '#e75135', cor: '#FFFFFF' },
        { min: 16.6, max: 21.99, fundo: '#3DAC79', cor: '#FFFFFF' },
        { min: 22, max: 25.99, fundo: '#25719E', cor: '#FFFFFF' },
        { min: 26, max: 100, fundo: '#185C84', cor: '#FFFFFF' },
    ],
    trigo: [
        { min: '', max: '', fundo: '#EBEEF2', cor: '#16171B' },
        { min: 0, max: 14.5, fundo: '#d83027', cor: '#FFFFFF' },
        { min: 14.6, max: 16.5, fundo: '#e75135', cor: '#FFFFFF' },
        { min: 16.6, max: 21.99, fundo: '#3DAC79', cor: '#FFFFFF' },
        { min: 22, max: 25.99, fundo: '#25719E', cor: '#FFFFFF' },
        { min: 26, max: 100, fundo: '#185C84', cor: '#FFFFFF' },
    ],
};
