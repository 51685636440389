import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import * as BABYLON from '@babylonjs/core';
import * as GUI from '@babylonjs/gui/2D';
import { PenduloSilo3DInterface } from 'app/shared/interfaces';
import { IMostrarTemperaturaPenduloSilo3DService } from 'app/shared/services';
import { TipoSensoresTecnologiaEnum } from 'app/shared/enums';
import { TranslocoService } from '@ngneat/transloco';

export abstract class ICriaBotoesSilo3DService {
    abstract execute(
        thad,
        penduloData: PenduloSilo3DInterface[],
        texturaAvancada: GUI.AdvancedDynamicTexture,
        pendulosControle: UntypedFormControl,
        linhasPendulos: { [key: number]: BABYLON.LinesMesh },
        vetorSensores: BABYLON.Mesh[][],
        cena: BABYLON.Scene
    ): void;
}

@Injectable({ providedIn: 'root' })
export class CriaBotoesSilo3DService implements ICriaBotoesSilo3DService {
    constructor(
        private mostrarTemperaturaPenduloSilo3DService: IMostrarTemperaturaPenduloSilo3DService,
        private translocoService: TranslocoService
    ) {}
    async execute(
        thad,
        penduloData: PenduloSilo3DInterface[],
        texturaAvancada: GUI.AdvancedDynamicTexture,
        pendulosControle: UntypedFormControl,
        linhasPendulos: { [key: number]: BABYLON.LinesMesh },
        vetorSensores: BABYLON.Mesh[][],
        cena: BABYLON.Scene
    ): Promise<void> {
        for (let i = 1; i <= penduloData.length; i++) {
            let qtdSensoresPendulo = penduloData[i - 1].qtdsensores;
            let qtdText = 0;

            const esferaBotao = cena.getMeshByName(`esfera_${i}_Botao`);
            const botao = texturaAvancada.getControlByName(`botao_${i}`);
            const legendaBotao = texturaAvancada.getControlByName(`legenda_${i}`);
            const targetBotao = texturaAvancada.getControlByName(`target_${i}`);
            const linhaBotao = texturaAvancada.getControlByName(`linha_${i}`);
            const line = cena.getMeshByName(`linha${i - 1}`);

            for (let j = 0; j < qtdSensoresPendulo; j++) {
                qtdText++;

                let sensor = cena.getMeshByName(`sensor_${qtdText}_botao_${i}`);

                if (sensor) {
                    sensor.dispose();
                }
            }

            if (esferaBotao) {
                esferaBotao.dispose();
            }
            if (botao) {
                botao.onPointerUpObservable.clear();
                botao.dispose();
            }
            if (legendaBotao) {
                legendaBotao.dispose();
            }
            if (targetBotao) {
                targetBotao.dispose();
            }
            if (linhaBotao) {
                linhaBotao.dispose();
            }
            if (line) {
                line.dispose();
            }
        }

        let esferas = {};
        let textos = {};
        let temperaturas = {};
        let retangulosTexto = {};
        let retangulosTemperatura = {};
        let vetoresInfoBotoes = [esferas, textos, temperaturas, retangulosTexto, retangulosTemperatura];

        for (let i = 1; i <= penduloData.length; i++) {
            let posX = penduloData[i - 1].posX;
            let posZ = penduloData[i - 1].posZ;
            let posY1 = penduloData[i - 1].posY1;
            let posY2 = penduloData[i - 1].posY2;

            let esferaBotao = BABYLON.MeshBuilder.CreateSphere(`esfera_${i}_Botao`, { diameter: 0.001 }, cena);
            esferaBotao.position.x = posX;
            esferaBotao.position.z = posZ;
            esferaBotao.position.y = posY2;

            let botao = new GUI.Button(`botao_${i}`);
            botao?.onPointerUpObservable.clear();
            botao.width = '30px';
            botao.height = '30px';
            botao.cornerRadius = 20;
            botao.thickness = 2;
            texturaAvancada.addControl(botao);
            botao.linkWithMesh(esferaBotao);
            botao.linkOffsetY = -25;
            botao.isPointerBlocker = true;

            let legendaBotao = new GUI.TextBlock(`legenda_${i}`, penduloData[i - 1].numeroPendulo);
            legendaBotao.fontSize = '14px';
            legendaBotao.fontWeight = 'bold';

            botao.addControl(legendaBotao);

            let targetBotao = new GUI.Ellipse(`target_${i}`);
            targetBotao.width = '10px';
            targetBotao.height = '10px';
            targetBotao.thickness = 2;
            texturaAvancada.addControl(targetBotao);
            targetBotao.linkWithMesh(esferaBotao);

            let linhaBotao = new GUI.Line(`linha_${i}`);
            linhaBotao.lineWidth = 0;
            linhaBotao.color = 'white';
            linhaBotao.y2 = 20;
            linhaBotao.linkOffsetY = -10;
            texturaAvancada.addControl(linhaBotao);
            linhaBotao.linkWithMesh(esferaBotao);
            linhaBotao.connectedControl = botao;

            const materialLinha = new BABYLON.StandardMaterial('materialLinha', cena);
            const tipoPendulo = penduloData[i - 1].tipoPendulo;
            if (tipoPendulo === TipoSensoresTecnologiaEnum.TERMOPAR) {
                materialLinha.diffuseColor = new BABYLON.Color3(0.9, 0, 0);
            } else {
                materialLinha.diffuseColor = new BABYLON.Color3(0, 0, 0);
            }

            if (tipoPendulo === TipoSensoresTecnologiaEnum.TERMOPAR) {
                botao.color = 'red';
                targetBotao.color = 'red';
                if (this.retornarEscalaCor(penduloData[i - 1].mediaTemperatura) == '#de737b') {
                    legendaBotao.color = 'white';
                }
            } else {
                botao.color = 'black';
                targetBotao.color = 'black';
            }

            targetBotao.background = 'white';
            botao.background = 'white';

            linhasPendulos[i - 1] = BABYLON.MeshBuilder.CreateLines(
                `linha${i - 1}`,
                {
                    points: [new BABYLON.Vector3(posX, posY1, posZ), new BABYLON.Vector3(posX, posY2, posZ)],
                    updatable: true,
                },
                cena
            );
            linhasPendulos[i - 1].color = materialLinha.diffuseColor;

            if (penduloData[i - 1].destaque) {
                const carregamentoInicial = true;
                penduloData[i - 1].ativo = true;
                this.mostrarTemperaturaPenduloSilo3DService.execute(
                    i - 1,
                    thad,
                    retangulosTemperatura,
                    retangulosTexto,
                    vetoresInfoBotoes,
                    temperaturas,
                    vetorSensores[i - 1],
                    botao,
                    targetBotao,
                    penduloData,
                    cena,
                    carregamentoInicial
                );
            }

            let contadorToggle = 0;

            pendulosControle.valueChanges.subscribe((valor) => {
                contadorToggle++;

                if (contadorToggle == 1) {
                    if (!penduloData[i - 1].ativo) {
                        penduloData[i - 1].ativo = true;
                        penduloData[i - 1].destaque = false;
                        this.mostrarTemperaturaPenduloSilo3DService.execute(
                            i - 1,
                            thad,
                            retangulosTemperatura,
                            retangulosTexto,
                            vetoresInfoBotoes,
                            temperaturas,
                            vetorSensores[i - 1],
                            botao,
                            targetBotao,
                            penduloData,
                            cena
                        );
                    } else {
                        penduloData[i - 1].ativo = false;
                        penduloData[i - 1].destaque = false;
                        this.mostrarTemperaturaPenduloSilo3DService.execute(
                            i - 1,
                            thad,
                            retangulosTemperatura,
                            retangulosTexto,
                            vetoresInfoBotoes,
                            temperaturas,
                            vetorSensores[i - 1],
                            botao,
                            targetBotao,
                            penduloData,
                            cena
                        );
                    }
                }
                if (contadorToggle % 2 != 0) {
                    if (!penduloData[i - 1].ativo) {
                        penduloData[i - 1].ativo = true;

                        this.mostrarTemperaturaPenduloSilo3DService.execute(
                            i - 1,
                            thad,
                            retangulosTemperatura,
                            retangulosTexto,
                            vetoresInfoBotoes,
                            temperaturas,
                            vetorSensores[i - 1],
                            botao,
                            targetBotao,
                            penduloData,
                            cena
                        );
                    }
                } else {
                    if (penduloData[i - 1].ativo) {
                        penduloData[i - 1].ativo = false;

                        this.mostrarTemperaturaPenduloSilo3DService.execute(
                            i - 1,
                            thad,
                            retangulosTemperatura,
                            retangulosTexto,
                            vetoresInfoBotoes,
                            temperaturas,
                            vetorSensores[i - 1],
                            botao,
                            targetBotao,
                            penduloData,
                            cena
                        );
                    }
                }
            });
            botao.onPointerUpObservable.add(() => {
                if (penduloData[i - 1].ativo) {
                    penduloData[i - 1].ativo = false;
                } else {
                    penduloData[i - 1].ativo = true;
                }
                this.mostrarTemperaturaPenduloSilo3DService.execute(
                    i - 1,
                    thad,
                    retangulosTemperatura,
                    retangulosTexto,
                    vetoresInfoBotoes,
                    temperaturas,
                    vetorSensores[i - 1],
                    botao,
                    targetBotao,
                    penduloData,
                    cena
                );
            });
            if (penduloData[i - 1].nivelVolume > 0) {
                botao.pointerEnterAnimation = () => {
                    this.ativarTooltip(i - 1, esferaBotao, thad, penduloData);
                };
                botao.pointerOutAnimation = () => {
                    this.desativarTooltip(texturaAvancada);
                };
            }
        }
        cena.render();
    }

    private retornarEscalaCor(valor: number): string {
        let hexBackground: string;
        const limitesTemperatura = [6, 12, 18, 24, 30, 36];
        const coresBackground = ['#6473f5', '#58bef3', '#79cbf5', '#64bd94', '#64ed7b', '#deed7b', '#de737b'];

        if (valor !== undefined && !Number.isNaN(valor)) {
            const indiceCor = limitesTemperatura.findIndex((limite) => valor! < limite);
            hexBackground = indiceCor !== -1 ? coresBackground[indiceCor] : coresBackground[coresBackground.length - 1];
        } else {
            hexBackground = '#cccccc';
        }
        return hexBackground;
    }

    private desativarTooltip(texturaAvancada: GUI.AdvancedDynamicTexture) {
        let retanguloTooltip = texturaAvancada.getControlByName('retanguloTooltip') as GUI.Rectangle;
        if (retanguloTooltip) {
            retanguloTooltip.dispose();
        }
    }

    private ativarTooltip(i: number, esferaBotao: BABYLON.Mesh, thad, penduloData: PenduloSilo3DInterface[]) {
        let retanguloTooltip = thad.texturaAvancada.getControlByName('retanguloTooltip') as GUI.Rectangle;
        if (!retanguloTooltip) {
            let valorMinimo: string = `${penduloData[i].minTemperatura.toFixed(1)}`;
            let valorMedio: string = `${penduloData[i].mediaTemperatura.toFixed(1)}`;
            let valorMaximo: string = `${penduloData[i].maxTemperatura.toFixed(1)}`;
            let tipoPendulo: string = penduloData[i].tipoPendulo;
            let qtdSensores = penduloData[i].qtdsensores;
            let qtdSensoresAtivos: number = penduloData[i].nivelVolume;
            let porcentagemAtivos: string = ((qtdSensoresAtivos / qtdSensores) * 100).toFixed(1);

            retanguloTooltip = new GUI.Rectangle('retanguloTooltip');
            retanguloTooltip.background = 'white';
            retanguloTooltip.width = '120px';
            retanguloTooltip.height = '100px';
            retanguloTooltip.cornerRadius = 5;
            retanguloTooltip.isVisible = true;
            retanguloTooltip.linkOffsetY = -100;
            thad.texturaAvancada.addControl(retanguloTooltip);
            retanguloTooltip.linkWithMesh(esferaBotao);

            let traduzMin = this.translocoService.translate('MIN');
            let traduzMed = this.translocoService.translate('MED');
            let traduzMax = this.translocoService.translate('MAX');
            let traduzNivel = this.translocoService.translate('NIVEL');
            let traduzTipo = this.translocoService.translate('TIPO');

            let tooltipTexto = new GUI.TextBlock(
                'tooltipTexto',
                `${traduzMin}: ${traduzMed}: ${traduzMax}: ${traduzNivel}: ${traduzTipo}:`
            );
            tooltipTexto.fontSize = '12px';
            tooltipTexto.color = '#019DE8';
            tooltipTexto.fontWeight = 'bold';
            tooltipTexto.width = '35px';
            tooltipTexto.textWrapping = true;
            tooltipTexto.lineSpacing = '0px';
            tooltipTexto.horizontalAlignment = 3;
            tooltipTexto.textHorizontalAlignment = 3;
            tooltipTexto.paddingLeft = '2px';
            tooltipTexto.fontFamily = 'obvia-wide-regular';
            retanguloTooltip.addControl(tooltipTexto);

            let textoMinimo = new GUI.TextBlock('textoMinimo', `${valorMinimo}°C`);
            textoMinimo.fontSize = '12px';
            textoMinimo.textHorizontalAlignment = 3;
            textoMinimo.top = '-34px';
            textoMinimo.left = '29px';
            textoMinimo.fontFamily = 'obvia-wide-regular';
            retanguloTooltip.addControl(textoMinimo);

            let textoMedio = new GUI.TextBlock('textoMedio', `${valorMedio}°C`);
            textoMedio.fontSize = '12px';
            textoMedio.textHorizontalAlignment = 3;
            textoMedio.top = '-17px';
            textoMedio.left = '31px';
            textoMedio.fontFamily = 'obvia-wide-regular';
            retanguloTooltip.addControl(textoMedio);

            let textoMaximo = new GUI.TextBlock('textoMaximo', `${valorMaximo}°C`);
            textoMaximo.fontSize = '12px';
            textoMaximo.textHorizontalAlignment = 3;
            textoMaximo.top = '0px';
            textoMaximo.left = '31px';
            textoMaximo.fontFamily = 'obvia-wide-regular';
            retanguloTooltip.addControl(textoMaximo);

            let textoNivel = new GUI.TextBlock(
                'textoNivel',
                `${qtdSensoresAtivos}/${qtdSensores} (${porcentagemAtivos})%`
            );
            textoNivel.fontSize = '12px';
            textoNivel.textHorizontalAlignment = 3;
            textoNivel.top = '17px';
            textoNivel.left = '36px';
            textoNivel.fontFamily = 'obvia-wide-regular';
            retanguloTooltip.addControl(textoNivel);

            let textoPendulo = new GUI.TextBlock('textoPendulo', tipoPendulo);
            textoPendulo.fontSize = '12px';
            textoPendulo.textHorizontalAlignment = 3;
            textoPendulo.top = '34px';
            textoPendulo.left = '34px';
            textoPendulo.fontFamily = 'obvia-wide-regular';
            retanguloTooltip.addControl(textoPendulo);
        }
    }
}
