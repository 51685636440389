import { ObjetoValorNumeroInterface, UnidadeInterface } from "app/modules/gestao-unidades/operacional/shared/interfaces";
import { EquipamentoComponenteDataInterface } from "app/shared/interfaces";

export interface GanhosArmazenagemPropriaInterface {
    id: string;
    estrutura_armazenagem: EstruturaArmazenagemInterface
    unidade: UnidadeInterface;
    tempo_armazenagem: TempoArmazenagemInterface;
    estoque_maximo: ObjetoToneladaInterface;
    desconto_por_umidade: ObjetoValorNumeroInterface;
    desconto_por_impureza: ObjetoValorNumeroInterface;
    desconto_por_avariados: ObjetoValorNumeroInterface;
    desconto_por_outros_defeitos: ObjetoValorNumeroInterface;
    total_descontos_classificacao: ObjetoValorNumeroInterface;
    desconto_taxa_secagem: ObjetoToneladaInterface;
    desconto_taxa_recebimento: ObjetoToneladaInterface;
    desconto_taxa_armazenagem: ObjetoToneladaInterface;
    quantidade_graos: ObjetoToneladaInterface;
    receita: ReceitaMoedaInterface;
    equipamento_comunicacao: EquipamentoComponenteDataInterface
}

interface EstruturaArmazenagemInterface {
    id: string,
    nome: string,
    categoria: string,
}

interface TempoArmazenagemInterface {
    dias: number;
}

interface ObjetoToneladaInterface {
    tonelada: number;
}

interface ReceitaMoedaInterface {
    moeda: string;
    valor: number;
}

export interface TotalizadorGanhosComArmazenagemPropria {
    geracao_valor_quantidade_graos: ObjetoValorNumeroInterface,
    geracao_valor_receita: ObjetoValorNumeroInterface
}
