import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class TratarDadosRequisicaoRecebimentoExpedicaoService {
    constructor() {}

    tratarDadosBuscarExpedicoes(params: {cliente_id: string,
      page : number,
      limit: number,
      'filter.arquivado': boolean,
      'filter.filtro': string}): HttpParams {
        let httpParams = new HttpParams();

        if (params.cliente_id) {
            httpParams = httpParams.set('cliente_id', params.cliente_id);
        }

        if (params.page) {
            httpParams = httpParams.set('page', params.page);
        } else {
            httpParams = httpParams.set('page', 1);
        }

        if (params.limit) {
            httpParams = httpParams.set('limit', params.limit);
        }

        return httpParams;
    }
}
