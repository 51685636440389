export enum CoresProdutos {
    COR_ARROZ = '#7C2A2A',
    COR_AVEIA = '#F39C12',
    COR_AZEVEM = '#34495E',
    COR_BRACHIARIA = '#2980B9',
    COR_CAFEDESCASCADO = '#A93226',
    COR_CAFEPERGAMINHO = '#D4AC0D',
    COR_CANOLA = '#6E2C00',
    COR_CAROCODEALGODAO = '#8E44AD',
    COR_CEVADA = '#C0392B',
    COR_ERVILHA = '#1ABC9C',
    COR_FEIJAO = '#AED6F1',
    COR_GERGELIM = '#F39C12',
    COR_GIRASSOL = '#9B59B6',
    COR_MILHETO = '#D35400',
    COR_MILHO = '#FEEF68',
    COR_MILHOPIPOCA = '#D4AC0D',
    COR_NABOFORRAGEIRO = '#2E86C1',
    COR_PAINCO = '#B7950B',
    COR_POPLACITRICA = '#34DBD1',
    COR_SOJA = '#408808',
    COR_SORGO = '#3498DB',
    COR_TRIGO = '#1F618D',
    COR_TRITICALE = '#16A085',
    COR_OUTROS = '#CCC',
}

export enum CoresGraosEnum {
    COR_SOJA = '#39CA6A',
    COR_MILHO = '#FFBA05',
    COR_TRIGO = '#019DE8',
    COR_ARROZ = '#D32F2F',
    COR_AVEIA = '#7B1FA2',
    COR_AZEVEM = '#0288D1',
    COR_BRACHIARIA = '#C2185B',
    COR_CAFEDESCASCADO = '#512DA8',
    COR_CAFEPERGAMINHO = '#303F9F',
    COR_CANOLA = '#F57C00',
    COR_CAROCODEALGODAO = '#388E3C',
    COR_CEVADA = '#1976D2',
    COR_ERVILHA = '#C62828',
    COR_FEIJAO = '#8E24AA',
    COR_GERGELIM = '#0288D1',
    COR_GIRASSOL = '#00796B',
    COR_MILHETO = '#FFA000',
    COR_MILHOPIPOCA = '#455A64',
    COR_NABOFORRAGEIRO = '#5D4037',
    COR_PAINCO = '#E64A19',
    COR_POPLACITRICA = '#8BC34A',
    COR_SORGO = '#FFC107',
    COR_TRITICALE = '#00ACC1',
    COR_OUTROS = '#CCC',
}
