export interface HistoricoSincronizacaoEquipamentosInterface {
    id: string;
    nome: string;
    categoria: string;
    modelo: string;
    equipamento_comunicacao: EquipamentoComunicacaoInterface;
}

interface EquipamentoComunicacaoInterface {
    id: string;
    instante: string;
    status: boolean;
}
