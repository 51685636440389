export class EquilibrioHigroscopicoModel {
    constructor(partial?: Partial<EquilibrioHigroscopicoModel>) {
        Object.assign(this, partial);
    }
    nome?: string;
    equilibrio_higroscopico: {
        aq: number;
        taq: number;
        uraq: number;
        ehaq: number;
        ehamb: number;
        ehplenum?: number;
    };
}
