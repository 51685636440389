import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { IUnidadeParametrizacaoController, UsuarioController } from 'app/shared/controllers';
import { AutenticacaoUsuarioObservable, UnidadeParametrizacaoObservable } from 'app/shared/observables';
import { PrimeiroAcessoObservable } from 'app/modules/auth/observables/primeiro-acesso.observable';

@Injectable({
    providedIn: 'root',
})
export class InitialDataResolver implements Resolve<any> {
    constructor(
        private unidadeParametrizacaoController: IUnidadeParametrizacaoController,
        readonly unidadeParametrizacaoObservable: UnidadeParametrizacaoObservable,
        readonly autenticacaoUsuarioObservable: AutenticacaoUsuarioObservable,
        private usuarioController: UsuarioController,
        private primeiroAcessoObservable: PrimeiroAcessoObservable
    ) {}

    resolve(): void {
        this.verificaPrimeiroAcesso();
        this.buscaUnidadeParametrizacao();
    }

    verificaPrimeiroAcesso(): void {
        const token = localStorage.getItem('hash_token');
        if (this.primeiroAcessoObservable.buscaPrimeiroAcesso == null) {
            this.usuarioController.verificarPrimeiroAcesso(token).subscribe();
        }
    }

    buscaUnidadeParametrizacao(): void {
        if (!this.unidadeParametrizacaoObservable.getParametrosUnidade$) {
            this.unidadeParametrizacaoController.buscaParametrosUnidade().subscribe();
        }
    }
}
