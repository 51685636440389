import { IdiomaSiglaEnum, UsuarioCategoriaEnum } from 'app/shared/enums';

export interface UsuarioInterface {
    id?: string;
    nome: string;
    sobrenome: string;
    categoria: UsuarioCategoriaEnum;
    telefone?: string;
    email?: string;
    clientes?: ClienteInterface[];
}

export interface UsuarioLogadoInterface {
    id: string;
    nome: string;
    sobrenome: string;
    email: string;
    categoria: string;
    arquivado: boolean;
    idioma: IdiomaSiglaEnum;
    obrigar_resetar_senha: boolean;
    usuario_unidade: {
        id: string;
        permite_cadastro_usuario: boolean;
    };
}

export interface ListaUsuariosInterface {
    id: string;
    arquivado: boolean;
    nome: string;
    sobrenome: string;
    categoria: string;
    email: string;
    clientes: ClienteInterface[];
}

export interface UsuarioAcessosInterface {
    id: string;
    unidades: UnidadesInterface[];
}
interface UnidadesInterface {
    id: string;
    nome: string;
    usuario_unidade: {
        id?: string;
        permite_cadastro_usuario: boolean;
        arquivado: boolean;
    };
    usuario_perfil: {
        id?: string;
        perfil_id: string;
        arquivado: boolean;
    }[];
}

export interface ClienteInterface {
    id: string;
    nome: string;
    unidade?: UnidadesClienteInterface[];
}

interface UnidadesClienteInterface {
    id: string;
    nome: string;
    cidade: {
        id: string;
        nome: string;
        uf: {
            id: string;
            nome: string;
            sigla: string;
        };
    };
    usuario_unidade: {
        id: string;
        usuario_id: string;
        unidade_id: string;
        arquivado: boolean;
        permite_cadastro_usuario: boolean;
    }[];
    usuario_perfil: {
        arquivado: boolean;
        id: string;
        usuario_id: string;
        perfil_id: string;
        perfil: {
            id: string;
            nome: string;
            padrao: boolean;
        };
    }[];
}
