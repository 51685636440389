import { Injectable } from '@angular/core';
import { IApiTransportInterface } from 'app/core/interfaces';
import { ApiTransportService } from 'app/core/services/transport';
import {
    ListaPerfisInterface,
    ListaUsuariosInterface,
    PerfilFuncionalidadeInterface,
    PerfilInterface,
} from 'app/shared/interfaces';
import { Observable } from 'rxjs';
import { PerfilFuncionalidadeAcaoEnum } from 'app/shared/enums';

export abstract class IEstruturaUsuarioController {
    abstract criarUsuario(dados: PerfilInterface): Observable<ListaUsuariosInterface>;
    abstract editarUsuario(id: string, dados: PerfilInterface): Observable<ListaUsuariosInterface>;
    abstract buscarUsuarioPorId(id: string): Observable<ListaUsuariosInterface>;
    abstract buscaPermissaoUsuarioPorChave(
        chave: PerfilFuncionalidadeAcaoEnum
    ): Observable<PerfilFuncionalidadeInterface>;
}

@Injectable({
    providedIn: 'root',
})
export class EstruturaUsuarioController implements IEstruturaUsuarioController {
    private transport: IApiTransportInterface;

    constructor(readonly apiTransportService: ApiTransportService) {
        this.transport = this.apiTransportService.execute('/geral');
    }

    criarUsuario(dados: ListaPerfisInterface): Observable<ListaUsuariosInterface> {
        return this.transport.http.post<ListaUsuariosInterface>(`${this.transport.path}/usuario`, dados);
    }

    editarUsuario(id: string, dados: PerfilInterface): Observable<ListaUsuariosInterface> {
        return this.transport.http.put<ListaUsuariosInterface>(`${this.transport.path}/usuario/${id}`, dados);
    }

    buscarUsuarioPorId(id: string): Observable<ListaUsuariosInterface> {
        return this.transport.http.get<ListaUsuariosInterface>(`${this.transport.path}/usuario/${id}`);
    }

    buscaPermissaoUsuarioPorChave(chave: PerfilFuncionalidadeAcaoEnum): Observable<PerfilFuncionalidadeInterface> {
        return this.transport.http.get<any>(`${this.transport.path}/usuario/perfil/funcionalidade/${chave}`);
    }
}
