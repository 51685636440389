import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseInterface, ResponseInterfaceAeracaoGraos } from 'app/shared/interfaces';
import {
    ParamsAeracaoGrosFilterInterface,
    ParamsGestaoUnidadesInterface,
    SecadoresEmOperacao,
    dadosOrdemCrescente,
    historicoSecadorInterface,
    dadosOrdemCondicoesArmazenagem,
    dadosOrdemCrescenteOutrosSecadores,
    interfaceCondicaoArmazenagem,
    outrosSecadoresInterface,
    ListaGraosInterfaceInterface,
    DadosAeracaoGraos,
    dadosGraficoGestaoEstoqueGrao,
    DadosGraficoGestaoEstoqueGraoCapacidadeInterface,
    filtroPersonalizadoId,
    filtroCondicaoArmazenagem,
} from 'app/modules/gestao-unidades/operacional/shared/interfaces';
import moment from 'moment';

export abstract class ITratarDadosRequisicaoOperacionalService {
    abstract tratarDadosBuscarAeracaoGraos(parametros: ParamsAeracaoGrosFilterInterface): HttpParams;
    abstract tratarDadosBuscarGestaoEstoqueGrao(parametros: ParamsAeracaoGrosFilterInterface): HttpParams;
    abstract tratarDadosBuscarListagemFiltrosCondicaoArmazenagem(usuarioId: string | null): HttpParams;
    abstract tratarDadosBuscarGestaoEstoqueGraoCapacidade(
        parametros: ParamsAeracaoGrosFilterInterface,
        page: number,
        limit: number
    ): HttpParams;
    abstract tratarDadosBuscarSecadoresEmOperacao(
        params: ParamsGestaoUnidadesInterface,
        dadosOrdem: dadosOrdemCrescente,
        page: number,
        limit: number
    ): HttpParams;
    abstract tratarDadosBuscarOutrosSecadores(
        params: ParamsGestaoUnidadesInterface,
        dadosOrdem: dadosOrdemCrescenteOutrosSecadores,
        page: number,
        limit: number
    ): HttpParams;
    abstract tratarDadosBuscarCondicaoArmazenagem(
        params: ParamsGestaoUnidadesInterface,
        dadosOrdem: dadosOrdemCondicoesArmazenagem,
        page: number,
        limit: number,
        filtro?: string | null
    ): HttpParams;
    abstract tratarDadosBuscarHistoricoSecador(
        parametros: ParamsGestaoUnidadesInterface
    ): HttpParams;
}

@Injectable({
    providedIn: 'root',
})
export class TratarDadosRequisicaoOperacionalService implements ITratarDadosRequisicaoOperacionalService {

    public tratarDadosBuscarAeracaoGraos(parametros: ParamsAeracaoGrosFilterInterface): HttpParams {
        let params = new HttpParams();
        if (parametros.produto_tipos.length > 0) {
            params = params.append('produto_tipos', parametros.produto_tipos.join(','));
        }
        if (parametros.unidades.length > 0) {
            params = params.append('unidades', parametros.unidades.join(','));
        }
        if (parametros.cliente_id) {
            params = params.append('cliente_id', parametros.cliente_id);
        }
        return params;
    }

    public tratarDadosBuscarGestaoEstoqueGrao(parametros: ParamsAeracaoGrosFilterInterface): HttpParams {
        let params = new HttpParams();
        if (parametros.produto_tipos.length > 0) {
            params = params.append('produto_tipos', parametros.produto_tipos.join(','));
        }
        if (parametros.unidades.length > 0) {
            params = params.append('unidades', parametros.unidades.join(','));
        }

        if (parametros.cliente_id) {
            params = params.append('cliente_id', parametros.cliente_id);
        }
        return params;
    }

    public tratarDadosBuscarListagemFiltrosCondicaoArmazenagem(usuarioId: string | null): HttpParams {
        let params = new HttpParams();
        return params = usuarioId ? new HttpParams().append('usuario_id', usuarioId) : undefined;
    }

    public tratarDadosBuscarGestaoEstoqueGraoCapacidade(
        parametros: ParamsAeracaoGrosFilterInterface,
        page: number,
        limit: number
    ): HttpParams {
        let params = new HttpParams();

        params = params.set('filter.produto_tipos', parametros.produto_tipos.join(','));
        params = params.set('filter.unidades', parametros.unidades.join(','));
        params = params.set('cliente_id', parametros.cliente_id);
        params = params.set('page', page.toString());
        params = params.set('limit', limit.toString());

        return params;
    }

    public tratarDadosBuscarSecadoresEmOperacao(
        params: ParamsGestaoUnidadesInterface,
        dadosOrdem: dadosOrdemCrescente,
        page: number,
        limit: number
    ): HttpParams {
        let parametro = new HttpParams();

        const mapeamentoFiltros = {
            'entrada:desc': 'temperatura_entrada:DESC',
            'entrada:asc': 'temperatura_entrada:ASC',
            'massa:asc': 'temperatura_massa:ASC',
            'massa:desc': 'temperatura_massa:DESC',
            'saida:asc': 'temperatura_saida:ASC',
            'saida:desc': 'temperatura_saida:DESC',
            'limpeza:desc': 'intervalo_limpeza:DESC',
            'limpeza:asc': 'intervalo_limpeza:ASC',
            'umidade:desc': 'umidade_grao:DESC',
            'umidade:asc': 'umidade_grao:ASC',
            '': '',
        };
        const chaveFiltro = Object.keys(dadosOrdem).find((key) => dadosOrdem[key] !== null);
        const filtroOrdem = chaveFiltro ? mapeamentoFiltros[`${chaveFiltro}:${dadosOrdem[chaveFiltro]}`] : '';

        parametro = parametro.set('filter.data_inicio', moment(params.dataInicio).toISOString());
        parametro = parametro.set('filter.data_fim', moment(params.dataFim).toISOString());
        parametro = parametro.set('filter.produto_tipos', params.produto_tipos.join(','));
        parametro = parametro.set('filter.unidades', params.unidades.join(','));
        parametro = parametro.set('cliente_id', params.cliente_id);
        parametro = parametro.set('sortBy', filtroOrdem);
        parametro = parametro.set('limit', limit);
        parametro = parametro.set('page', page);

        return parametro
    }

    public tratarDadosBuscarOutrosSecadores(
        params: ParamsGestaoUnidadesInterface,
        dadosOrdem: dadosOrdemCrescenteOutrosSecadores,
        page: number,
        limit: number
    ): HttpParams {
        let parametro = new HttpParams();

        const mapeamentoFiltros = {
            'limpeza:desc': 'intervalo_limpeza:DESC',
            'limpeza:asc': 'intervalo_limpeza:ASC',
            '': '',
        };
        const chaveFiltro = Object.keys(dadosOrdem).find((key) => dadosOrdem[key] !== null);
        const filtroOrdem = chaveFiltro ? mapeamentoFiltros[`${chaveFiltro}:${dadosOrdem[chaveFiltro]}`] : '';

        parametro = parametro.set('filter.data_inicio', moment(params.dataInicio).toISOString());
        parametro = parametro.set('filter.data_fim', moment(params.dataFim).toISOString());
        parametro = parametro.set('filter.produto_tipos', params.produto_tipos.join(','));
        parametro = parametro.set('filter.unidades', params.unidades.join(','));
        parametro = parametro.set('sortBy', filtroOrdem);
        parametro = parametro.set('limit', limit);
        parametro = parametro.set('page', page);
        parametro = parametro.set('cliente_id', params.cliente_id);

        return parametro
    }

    public tratarDadosBuscarCondicaoArmazenagem(
        params: ParamsGestaoUnidadesInterface,
        dadosOrdem: dadosOrdemCondicoesArmazenagem,
        page: number,
        limit: number,
        filtro?: string | null
    ): HttpParams {
        let parametro = new HttpParams();

        const mapeamentoFiltros = {
            'maximo:desc': 'temperatura_maxima:DESC',
            'maximo:asc': 'temperatura_maxima:ASC',
            'media:desc': 'temperatura_media:DESC',
            'media:asc': 'temperatura_media:ASC',
            'minima:desc': 'temperatura_minima:DESC',
            'minima:asc': 'temperatura_minima:ASC',
            'estoque:desc': 'intervalo_estoque:DESC',
            'estoque:asc': 'intervalo_estoque:ASC',
            'umidade:desc': 'umidade_grao:DESC',
            'umidade:asc': 'umidade_grao:ASC',
            'alteracao:desc': 'tempo_aeracao:DESC',
            'alteracao:asc': 'tempo_aeracao:ASC',
            '': '',
        };
        const chaveFiltro = Object.keys(dadosOrdem).find((key) => dadosOrdem[key] !== null);
        const filtroOrdem = chaveFiltro ? mapeamentoFiltros[`${chaveFiltro}:${dadosOrdem[chaveFiltro]}`] : '';

        parametro = parametro.set('filter.data_inicio', moment(params.dataInicio).toISOString())
        parametro = parametro.set('filter.data_fim', moment(params.dataFim).toISOString())
        parametro = parametro.set('filter.produto_tipos', params.produto_tipos.join(','))
        parametro = parametro.set('sortBy', filtroOrdem)
        parametro = parametro.set('cliente_id', params.cliente_id)
        if (filtro) {
            parametro = parametro.set('filtro_personalizado', filtro)
        }
        parametro = parametro.set('filter.unidades', params.unidades.join(','))
        parametro = parametro.set('limit', limit);
        parametro = parametro.set('page', page)

        return parametro
    }

    public tratarDadosBuscarHistoricoSecador(
        parametros: ParamsGestaoUnidadesInterface,
    ): HttpParams {
        let parametrosHttp = new HttpParams();

        if (parametros.dataInicio) parametrosHttp = parametrosHttp.set('data_inicio', parametros.dataInicio);
        if (parametros.dataFim) parametrosHttp = parametrosHttp.set('data_fim', parametros.dataFim);
        if (parametros.cliente_id) parametrosHttp = parametrosHttp.set('cliente_id', parametros.cliente_id);

        return parametrosHttp
    }

}
