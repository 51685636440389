import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SemPermissaoAcessoComponent } from './sem-permissao-acesso.component';
import { ISetaTituloPaginaService, SetaTituloPaginaService } from 'app/shared/services';
import { TranslocoModule } from '@ngneat/transloco';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    imports: [CommonModule, RouterModule, TranslocoModule, MatIconModule],
    declarations: [SemPermissaoAcessoComponent],
    providers: [
        {
            provide: ISetaTituloPaginaService,
            useClass: SetaTituloPaginaService,
        },
    ],
})
export class SemPermissaoAcessoModule {}
