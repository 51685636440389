export interface SecadoresEmOperacao {
    id: string;
    instante: string;
    secador: Secador;
    unidade: Unidade;
    produto_tipo: ProdutoTipo;
    modo_operacao: string;
    tempo_secagem: number;
    temperatura_entrada: string;
    temperatura_massa: string;
    temperatura_saida: string;
    umidade_grao: string;
    limpeza: Limpeza;
}

export interface Secador {
    id: string;
    codigo: number;
    modelo_painel: string;
    nome: string;
    operando: boolean;
    intervalo_limpeza: number;
}

export interface Unidade {
    id: string;
    categoria: string;
    nome: string;
}

export interface ProdutoTipo {
    id: number;
    nome: string;
}

export interface Limpeza {
    total: number;
    atual: number;
}

export interface DadosMetrica {
    valor: number;
    instante: string;
}

export interface dadosGraficoSecadoresOperacao {
    name: string;
    data: number[];
}
export interface historicoSecadorInterface {
    velocidade_de_descarga?: DadosMetrica;
    eficiencia_do_secador?: DadosMetrica;
    consumo_de_combustivel?: DadosMetrica;
    medidor_de_fluxo_1?: DadosMetrica;
    medidor_de_fluxo_2?: DadosMetrica;
    pressao_da_entrada_de_ar?: DadosMetrica;
    pressao_da_saida_de_ar?: DadosMetrica;
    instante?: string;
    data_amostra?: string;
    estoque?: string;
    tempo_de_aeracao?: string;
    temperatura_media?: string;
    temperatura_maxima?: string;
}

export interface dadosOrdemCrescente {
    entrada: string | null;
    massa: string | null;
    saida: string | null;
    umidade: string | null;
    limpeza: string | null;
}

export interface tipoOrdemSecadoresEmOperacao {
    active: string;
    direction: string;
}

export interface seriesParaGraficoLinha {
    name: string;
    data: number[];
}
