import * as BABYLON from '@babylonjs/core';
import { Injectable } from '@angular/core';
import { SiloBaseEnum } from '../enums';
export abstract class IAplicaPlanoCorteSilo3DService {
    abstract execute(
        pilaresAtuais: any,
        cilindroAtual: any,
        chapeuAtual: any,
        fundoAtual: any,
        diametroSilo: number,
        siloBase: string,
        materialConstrucao: string,
        cena: BABYLON.Scene
    ): void;
}

@Injectable({ providedIn: 'root' })
export class AplicaPlanoCorteSilo3DService implements IAplicaPlanoCorteSilo3DService {
    execute(
        pilaresAtuais: any,
        cilindroAtual: any,
        chapeuAtual: any,
        fundoAtual: any,
        diametroSilo: number,
        siloBase: string,
        materialConstrucao: string,
        cena: BABYLON.Scene
    ): void {
        const deslocamentoPlanoCorte = -(diametroSilo / 2) * 0.7;
        const deslocamentoPlanoCorteFundo = -(diametroSilo / 2) * 0.3;
        if (pilaresAtuais) {
            let planoCortePilares = new BABYLON.Plane(0, 0, 1, deslocamentoPlanoCorte);

            cena.onBeforeRenderObservable.add(() => {
                planoCortePilares.normal = this.retornarVetorPlanoCorte(cena);
            });

            pilaresAtuais.onBeforeRenderObservable.add(() => {
                cena.clipPlane = planoCortePilares;
            });

            pilaresAtuais.onAfterRenderObservable.add(() => {
                cena.clipPlane = null;
            });
        }

        if (cilindroAtual) {
            let planoCorteCilindro = new BABYLON.Plane(0, 0, 1, deslocamentoPlanoCorte);

            cena.onBeforeRenderObservable.add(() => {
                planoCorteCilindro.normal = this.retornarVetorPlanoCorte(cena);
            });

            cilindroAtual.onBeforeRenderObservable.add(() => {
                cena.clipPlane = planoCorteCilindro;
            });

            cilindroAtual.onAfterRenderObservable.add(() => {
                cena.clipPlane = null;
            });
        }

        if (chapeuAtual) {
            let planoCorteChapeu = new BABYLON.Plane(0, 0, 1, deslocamentoPlanoCorte);

            cena.onBeforeRenderObservable.add(() => {
                planoCorteChapeu.normal = this.retornarVetorPlanoCorte(cena);
            });

            chapeuAtual.onBeforeRenderObservable.add(() => {
                cena.clipPlane = planoCorteChapeu;
            });

            chapeuAtual.onAfterRenderObservable.add(() => {
                cena.clipPlane = null;
            });
        }

        if (fundoAtual) {
            let planoCorteFundo = new BABYLON.Plane(0, 0, 1, deslocamentoPlanoCorteFundo);

            cena.onBeforeRenderObservable.add(() => {
                if (
                    siloBase === SiloBaseEnum.SEMI_V &&
                    cena.activeCamera instanceof BABYLON.ArcRotateCamera &&
                    cena.activeCamera.beta < BABYLON.Tools.ToRadians(95)
                ) {
                    planoCorteFundo.normal = new BABYLON.Vector3(0, 0, 0);
                } else {
                    planoCorteFundo.normal = this.retornarVetorPlanoCorte(cena);
                }
            });

            fundoAtual.onBeforeRenderObservable.add(() => {
                cena.clipPlane = planoCorteFundo;
            });

            fundoAtual.onAfterRenderObservable.add(() => {
                cena.clipPlane = null;
            });
        }

        cena.onBeforeRenderObservable.add(() => {
            const escadaMaterial = cena.getMaterialByName('escadaMaterial');
            if (
                cena.activeCamera instanceof BABYLON.ArcRotateCamera &&
                cena.activeCamera.beta < BABYLON.Tools.ToRadians(15)
            ) {
                if (materialConstrucao === 'madeira' || materialConstrucao === 'concreto') {
                    escadaMaterial.alpha = 0.5;
                } else {
                    escadaMaterial.alpha = 1;
                }
            } else {
                escadaMaterial.alpha = 0;
            }
        });

        cena.render();
    }

    private retornarVetorPlanoCorte(cena: BABYLON.Scene) {
        if (cena.activeCamera instanceof BABYLON.ArcRotateCamera && cena.activeCamera.beta < 0.25) {
            return new BABYLON.Vector3(0, 0, 0);
        } else {
            let direcaoCamera = cena.activeCamera.getDirection(BABYLON.Axis.X);
            let normal = BABYLON.Vector3.Cross(direcaoCamera, BABYLON.Axis.Y).normalize();

            return normal.negate();
        }
    }
}
