import { LocalSensorEnum, NomeLocalSensorEnum } from 'app/shared/enums';

export class NomeLocalSensorHelper {
    public static formataNomeLocalSensor(nomeLocal: string): string {
        const NOME_LOCAL = nomeLocal?.replace(/\s+/g, '').toUpperCase();
        switch (NOME_LOCAL) {
            case LocalSensorEnum.FORNALHA:
                return NomeLocalSensorEnum.FORNALHA;
            case LocalSensorEnum.ENTRADA_AR:
                return NomeLocalSensorEnum.ENTRADA_AR;
            case LocalSensorEnum.MASSA_GRAOS:
                return NomeLocalSensorEnum.MASSA_GRAOS;
            case LocalSensorEnum.SAIDA_AR:
                return NomeLocalSensorEnum.SAIDA_AR;
            case LocalSensorEnum.CARGA_GRAOS:
                return NomeLocalSensorEnum.CARGA_GRAOS;
            case LocalSensorEnum.DESCARGA_GRAOS:
                return NomeLocalSensorEnum.DESCARGA_GRAOS;
            case LocalSensorEnum.RECIRCULACAO:
                return NomeLocalSensorEnum.RECIRCULACAO;
            case LocalSensorEnum.CONDICOES_EXTERNAS:
                return NomeLocalSensorEnum.CONDICAO_EXTERNA;
            default:
                return '';
        }
    }
}
