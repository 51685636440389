export enum FornalhaStatusEnum {
    desligado = 'desligado',
    ligando = 'ligando',
    falha = 'falha',
    manutencao = 'manutencao',
    bloqueado = 'bloqueado',
    ligado = 'ligado',
}

export enum FornalhaModoOperacaoEnum {
    desligado = 'desligado',
    manual = 'manual',
    semi_automatico = 'semi_automatico',
    automatico = 'automatico',
}

export enum FornalhaQueimadorStatusOperacaoEnum {
    manual = 'manual',
    semi_automatico = 'semi_automatico',
    automatico = 'automatico',
}

export enum SecadorStatusTraducaoEnum {
    desligado = 'DESLIGADO',
    ligando = 'LIGANDO',
    falha = 'FALHA',
    manutencao = 'MANUTENCAO',
    bloqueado = 'BLOQUEADO',
    ligado = 'LIGADO',
    manual = 'MANUAL',
    semi_automatico = 'SEMI_AUTOMATICO',
    automatico = 'AUTOMATICO',
    continuo = 'CONTINUO',
    rodizio = 'RODIZIO',
    alerta_para_continuo = 'ALERTA_PARA_CONTINUO',
    alerta_para_rodizio = 'ALERTA_PARA_RODIZIO',
    sem_posicao = 'SEM_POSICAO',
    aberto = 'ABERTO',
    abrindo = 'ABRINDO',
    fechado = 'FECHADO',
    fechando = 'FECHANDO',
}

export enum FluxoSecagemEnum {
    continuo = 'continuo',
    rodizio = 'rodizio',
    alerta_para_continuo = 'alerta_para_continuo',
    alerta_para_rodizio = 'alerta_para_rodizio',
}

export enum RegistroSuperiorEnum {
    sem_posicao = 'sem_posicao',
    aberto = 'aberto',
    abrindo = 'abrindo',
    fechado = 'fechado',
    fechando = 'fechando',
    falha = 'falha',
}
