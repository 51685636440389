import { NomeTipoSensorEnum, NomeControleSensorEnum } from 'app/shared/enums';

export class NomeControleSensorHelper {
    public static formataNomeTipoControle(tipoSensor: string): string {
        switch (tipoSensor) {
            case NomeTipoSensorEnum.TEMPERATURA_DIGITAL:
                return NomeControleSensorEnum.DIGITAL;
            case NomeTipoSensorEnum.PRESSAO:
                return NomeControleSensorEnum.PRESSAO;
            case NomeTipoSensorEnum.TEMPERATURA_TERMOPAR:
                return NomeControleSensorEnum.TERMOPAR;
            case NomeTipoSensorEnum.TEMPERATURA_GRAO:
                return NomeControleSensorEnum.TERMORESISTENCIA;
            case NomeTipoSensorEnum.UMIDADE_GRAO:
                return NomeControleSensorEnum.UMIDADE_GRAO;
            case NomeTipoSensorEnum.UMIDADE_RELATIVA:
                return NomeControleSensorEnum.UMIDADE_RELATIVA;
            default:
                return '';
        }
    }
}
