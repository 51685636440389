import { Injectable } from '@angular/core';
import { SiloBaseEnum } from 'app/shared/enums';
import { SiloPropriedadeInterface } from 'app/shared/interfaces';

export abstract class IPossuiTodasInformacoesSilo3DService {
    abstract execute(siloData: SiloPropriedadeInterface, pendulos: any[]): boolean;
}

@Injectable({
    providedIn: 'root',
})
export class PossuiTodasInformacoesSilo3DService implements IPossuiTodasInformacoesSilo3DService {
    execute(siloData: SiloPropriedadeInterface, pendulos: any[]): boolean {
        if (!this.possuiTodasInformacoesPendulos(pendulos)) return false;

        if (
            siloData?.altura_total == null ||
            siloData?.diametro == null ||
            siloData?.altura_cone_superior == null ||
            siloData?.angulo_cone_superior == null
        )
            return false;

        if (siloData?.base !== SiloBaseEnum.PLANA) {
            if (
                siloData?.altura_cone_inferior == null ||
                siloData?.angulo_cone_inferior == null ||
                siloData?.diametro_inferior == null
            )
                return false;
        }
        return true;
    }

    private possuiTodasInformacoesPendulos(pendulos: any[]): boolean {
        return !pendulos?.some(
            (pendulo: any) =>
                pendulo.pendulo?.pendulo_propriedade?.distancia_base == null ||
                pendulo.pendulo?.pendulo_propriedade?.distancia_telhado == null ||
                pendulo.pendulo?.pendulo_propriedade?.sensor_temperatura_espacamento == null ||
                pendulo.pendulo?.pendulo_propriedade?.sensor_temperatura_quantidade == null ||
                pendulo.pendulo?.silo_pendulo_visualizacao?.posicao_x == null ||
                pendulo.pendulo?.silo_pendulo_visualizacao?.posicao_y == null
        );
    }
}
