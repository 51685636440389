import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CookieService {
    constructor() {}

    definirCookie(nome: string, valor: string, dias?: number) {
        let validade = '';

        const data = new Date();
        data.setTime(data.getTime() + dias ?? 1 * 24 * 60 * 60 * 1000);
        validade = '; Expires=' + data.toUTCString();

        document.cookie = nome + '=' + (valor || '') + validade + '; path=/';
    }

    obterCookie(nome: string) {
        const nomeCookie = nome + '=';
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nomeCookie) == 0) return c.substring(nomeCookie.length, c.length);
        }
        return null;
    }

    apagarCookie(nome: string) {
        document.cookie = nome + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
}
