import { ObjetivoProgramaEnum } from 'app/shared/enums';

export class ProgramaPayloadModel {
    nome: string;
    objetivo_programa: ObjetivoProgramaEnum;
    expressao: string;
    notas?: string;

    constructor(partial?: Partial<ProgramaPayloadModel>) {
        Object.assign(this, partial);
    }
}
