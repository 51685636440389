export interface SecadoresEficienciaResponseInterface {
    data: Array<{
        secador: {
            id: string;
            nome: string;
            codigo: number;
            modelo_painel: string;
        };
        unidade: {
            id: string;
            categoria: string;
            nome: string;
            cidade: string;
            uf: string;
        };
        produto_tipo: {
            id: number;
            nome: string;
            peso_saca: number;
        };
        capacidade_nominal: {
            tonelada: number;
        };
        capacidade_real: {
            tonelada: number;
        };
        eficiencia: {
            porcentagem: number;
        };
        consumo_combustivel: {
            valor: number;
        };
        equipamento_comunicacao: {
            instante: string;
            status: string;
        };
    }>;
    links: {
        current: string;
    };
    meta: {
        itemsPerPage: number;
        totalItems: number;
        currentPage: number;
        totalPages: number;
        sortBy: Array<[string, string]>;
    };
}

export interface SecadoresEficienciaRequestInterface {
    cliente_id: string;
    unidades: string[];
    produtos_tipos: number[];
    page: number;
    limit: number;
    moeda: string;
}
