export enum ModoOperacaoEnum {
    desligado = 'SECADOR_VISAO_SCREEN.DESLIGADO',
    carga = 'SECADOR_VISAO_SCREEN.CARGA',
    descarga_nivel = 'SECADOR_VISAO_SCREEN.DESCARGA_COM_NIVEL',
    descarga_sem_nivel = 'SECADOR_VISAO_SCREEN.DESCARGA_SEM_NIVEL',
}
export enum TipoModoOperacaoEnum {
    DESLIGADO = 'desligado',
    CARGA = 'carga',
    DESCARGA_NIVEL = 'descarga_nivel',
    DESCARGA_SEM_NIVEL = 'descarga_sem_nivel',
}
