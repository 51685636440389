import { Injectable } from '@angular/core';
import { SiloBaseEnum } from 'app/shared/enums';
import { Silo3DPropriedadesInterface } from 'app/shared/interfaces';

export abstract class IRetornaAlturaPendulosSilo3DService {
    abstract execute(siloData: Silo3DPropriedadesInterface, siloAtual: string, posX: number, posZ: number): any;
}

@Injectable({ providedIn: 'root' })
export class RetornaAlturaPendulosSilo3DService implements IRetornaAlturaPendulosSilo3DService {
    execute(siloData: Silo3DPropriedadesInterface, siloAtual: string, posX: number, posZ: number): any {
        let Y1: number;
        let Y2: number;

        if (siloAtual == SiloBaseEnum.PLANA) {
            Y1 = siloData?.alturaPlataforma + siloData?.alturaPlataforma;
            Y2 = alturaNoChapeu(
                siloData?.alturaChapeu,
                siloData?.alturaPlataforma + siloData?.alturaPlataforma + siloData?.alturaSilo + siloData?.alturaChapeu,
                siloData?.diametroChapeu * 0.1,
                siloData?.diametroChapeu,
                posX,
                posZ
            );
        } else if (siloAtual == SiloBaseEnum.EM_V) {
            Y1 = alturaNoFundo(
                siloData?.alturaFundo,
                siloData?.alturaPlataforma,
                0.2,
                siloData?.diametroSilo,
                posX,
                posZ,
                siloAtual
            );
            Y2 = alturaNoChapeu(
                siloData?.alturaChapeu,
                siloData?.alturaPlataforma + siloData?.alturaFundo + siloData?.alturaSilo + siloData?.alturaChapeu,
                siloData?.diametroChapeu * 0.1,
                siloData?.diametroChapeu,
                posX,
                posZ
            );
        } else if (siloAtual == SiloBaseEnum.SEMI_V) {
            Y1 = alturaNoFundo(
                siloData?.alturaFundo,
                0,
                siloData?.diametroInferiorFundo,
                siloData?.diametroSilo,
                posX,
                posZ,
                siloAtual
            );
            Y2 = alturaNoChapeu(
                siloData?.alturaChapeu,
                siloData?.alturaPlataforma + siloData?.alturaFundo + siloData?.alturaSilo + siloData?.alturaChapeu,
                siloData?.diametroChapeu * 0.1,
                siloData?.diametroChapeu,
                posX,
                posZ
            );
        }
        return [Y1, Y2];

        function alturaNoFundo(
            alturaFundo: number,
            alturaPlataforma: number,
            diametroMenor: number,
            diametroMaior: number,
            posX: number,
            posZ: number,
            siloAtual
        ) {
            const raioMaior = diametroMaior / 2;
            const raioMenor = diametroMenor / 2;
            const distancia = Math.sqrt(posX * posX + posZ * posZ);

            if (distancia <= raioMenor) {
                return alturaPlataforma + 0.01;
            } else {
                const proporcao = (distancia - raioMenor) / (raioMaior - raioMenor);

                if (siloAtual == SiloBaseEnum.EM_V) {
                    return alturaFundo * proporcao + alturaPlataforma + 0.05;
                } else {
                    return alturaFundo * proporcao + 0.05;
                }
            }
        }

        function alturaNoChapeu(
            alturaChapeu: number,
            alturaTotal: number,
            diametroMenor: number,
            diametroMaior: number,
            posX: number,
            posZ: number
        ) {
            const raioMaior = diametroMaior / 2;
            const raioMenor = diametroMenor / 2;
            const distancia = Math.sqrt(posX * posX + posZ * posZ);

            if (distancia <= raioMenor) {
                return alturaTotal;
            } else {
                const proporcao = 1 - (distancia - raioMenor) / (raioMaior - raioMenor);
                return alturaTotal - alturaChapeu + alturaChapeu * proporcao;
            }
        }
    }
}
