export interface PaletaDeCoresInterface {
    min?: any;
    max?: any;
    fundo: string;
    cor: string;
}

export interface PaletaDeCoresValorInterface {
    valor?: any;
    fundo: string;
    cor: string;
}
