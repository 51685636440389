import * as BABYLON from '@babylonjs/core';
import { Injectable } from '@angular/core';
import { Silo3DPropriedadesInterface } from 'app/shared/interfaces';
import { SiloBaseEnum } from 'app/shared/enums';
export abstract class ICriaSilo3DBaseVService {
    abstract execute(siloData: Silo3DPropriedadesInterface, cena: BABYLON.Scene): void;
}

@Injectable({ providedIn: 'root' })
export class CriaSilo3DBaseVService implements ICriaSilo3DBaseVService {
    execute(siloData: Silo3DPropriedadesInterface, cena: BABYLON.Scene): void {
        const dimensaoChao: number = 1000;
        const meshesParaDeletar = cena.meshes.filter(
            (mesh) => mesh.name.endsWith('Plana') || mesh.name.endsWith('V') || mesh.name.endsWith('SemiV')
        );
        meshesParaDeletar.forEach((mesh) => {
            mesh.dispose();
        });
        meshesParaDeletar.length = 0;

        const plataformaV = BABYLON.MeshBuilder.CreateBox('plataformaV', {
            height: siloData?.alturaPlataforma,
            width: siloData?.diametroSilo * 2.5,
            depth: siloData?.diametroSilo * 2.5,
        });
        plataformaV.position.y = siloData?.alturaPlataforma / 2;

        const fundoV = BABYLON.MeshBuilder.CreateCylinder(
            `fundo${SiloBaseEnum.EM_V}`,
            {
                height: siloData?.alturaFundo,
                diameter: 0.2,
                diameterTop: siloData?.diametroSilo * 1.01,
                tessellation: 32,
                cap: BABYLON.Mesh.CAP_START,
            },
            cena
        );
        fundoV.position.y = siloData?.alturaPlataforma + siloData?.alturaFundo / 2;

        const cilindroV = BABYLON.MeshBuilder.CreateCylinder(
            `cilindro${SiloBaseEnum.EM_V}`,
            {
                height: siloData?.alturaSilo,
                diameter: siloData?.diametroSilo * 1.01,
                cap: BABYLON.Mesh.NO_CAP,
            },
            cena
        );
        cilindroV.position.y = siloData?.alturaPlataforma + siloData?.alturaFundo + siloData?.alturaSilo / 2;

        const chapeuV = BABYLON.MeshBuilder.CreateCylinder(
            `chapeu${SiloBaseEnum.EM_V}`,
            {
                height: siloData?.alturaChapeu,
                diameter: siloData?.diametroChapeu,
                diameterTop: siloData?.diametroChapeu * 0.1,
                tessellation: 32,
                cap: BABYLON.Mesh.CAP_END,
            },
            cena
        );
        chapeuV.position.y =
            siloData?.alturaPlataforma + siloData?.alturaFundo + siloData?.alturaSilo + siloData?.alturaChapeu / 2;

        let pilaresVetor: BABYLON.Mesh[] = [];
        const quantidadePilares: number = 18;

        for (let i = 0; i < quantidadePilares; i++) {
            const angulo = (Math.PI * 2 * i) / quantidadePilares - Math.PI;

            const x = cilindroV.position.x + (siloData?.diametroSilo / 2) * Math.cos(angulo);
            const y = siloData?.alturaPlataforma + siloData?.alturaFundo / 2;
            const z = cilindroV.position.z + (siloData?.diametroSilo / 2) * Math.sin(angulo);

            pilaresVetor[i] = BABYLON.MeshBuilder.CreateBox(
                `pilar${i}V`,
                { height: siloData?.alturaFundo, width: 0.2, depth: 0.1 },
                cena
            );
            pilaresVetor[i].position = new BABYLON.Vector3(x, y, z);

            const vetorDirecao = new BABYLON.Vector3(
                x - cilindroV.position.x,
                y - cilindroV.position.y,
                z - cilindroV.position.z
            );

            const anguloRotacao = Math.atan2(vetorDirecao.x, vetorDirecao.z);

            pilaresVetor[i].rotationQuaternion = BABYLON.Quaternion.RotationAxis(BABYLON.Axis.Y, anguloRotacao);
        }

        const pilaresV = BABYLON.Mesh.MergeMeshes(pilaresVetor);
        pilaresV.name = `pilares${SiloBaseEnum.EM_V}`;

        const chaoV = BABYLON.MeshBuilder.CreateGround('chaoV', { width: dimensaoChao, height: dimensaoChao }, cena);
        chaoV.position.y = siloData?.alturaPlataforma - siloData?.alturaPlataforma;

        cena.render();
    }
}
