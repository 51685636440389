import { Injectable } from '@angular/core';
import { IApiTransportInterface } from 'app/core/interfaces';
import { ApiTransportGestaoService } from 'app/core/services/transport';
import { Observable } from 'rxjs';
import { ResponseInterface, ResponseInterfaceAeracaoGraos } from 'app/shared/interfaces';
import {
    ParamsAeracaoGrosFilterInterface,
    ParamsGestaoUnidadesInterface,
    SecadoresEmOperacao,
    dadosOrdemCrescente,
    historicoSecadorInterface,
    dadosOrdemCondicoesArmazenagem,
    dadosOrdemCrescenteOutrosSecadores,
    interfaceCondicaoArmazenagem,
    outrosSecadoresInterface,
    ListaGraosInterfaceInterface,
    DadosAeracaoGraos,
    dadosGraficoGestaoEstoqueGrao,
    DadosGraficoGestaoEstoqueGraoCapacidadeInterface,
    filtroPersonalizadoId,
    filtroCondicaoArmazenagem,
} from 'app/modules/gestao-unidades/operacional/shared/interfaces';
import { HttpParams } from '@angular/common/http';
import moment from 'moment';
import { ITratarDadosRequisicaoOperacionalService } from 'app/modules/gestao-unidades/shared/services';

export abstract class IPainelOperacionalController {
    abstract buscarListagemGraos(): Observable<ResponseInterface<ListaGraosInterfaceInterface>>;
    abstract buscarAeracaoGraos(
        parametros: ParamsAeracaoGrosFilterInterface
    ): Observable<ResponseInterfaceAeracaoGraos<DadosAeracaoGraos>>;
    abstract buscarSecadoresEmOperacao(
        params: ParamsGestaoUnidadesInterface,
        dadosOrdem: dadosOrdemCrescente,
        page: number,
        limit: number
    ): Observable<ResponseInterface<SecadoresEmOperacao>>;
    abstract buscarHistoricoSecador(
        params: ParamsGestaoUnidadesInterface,
        idEmOperacao?: string | null,
        idOutros?: string | null
    ): Observable<ResponseInterface<historicoSecadorInterface>>;
    abstract buscarOutrosSecadores(
        params: ParamsGestaoUnidadesInterface,
        dadosOrdem: dadosOrdemCrescenteOutrosSecadores,
        page: number,
        limit: number
    ): Observable<ResponseInterface<outrosSecadoresInterface>>;
    abstract buscarCondicaoArmazenagem(
        params: ParamsGestaoUnidadesInterface,
        dadosOrdem: dadosOrdemCondicoesArmazenagem,
        page: number,
        limit: number,
        filtro?: string | null
    ): Observable<ResponseInterface<interfaceCondicaoArmazenagem>>;
    abstract buscarGestaoEstoqueGrao(
        parametros: ParamsAeracaoGrosFilterInterface
    ): Observable<ResponseInterface<dadosGraficoGestaoEstoqueGrao>>;
    abstract buscarGestaoEstoqueGraoCapacidade(
        parametros: ParamsAeracaoGrosFilterInterface,
        page: number,
        limit: number
    ): Observable<ResponseInterface<DadosGraficoGestaoEstoqueGraoCapacidadeInterface>>;
    abstract buscarListagemFiltrosCondicaoArmazenagem(
        usuarioId: string | null
    ): Observable<ResponseInterface<filtroCondicaoArmazenagem>>;
    abstract buscarFiltroPersonalizadoId(id: string): Observable<ResponseInterfaceAeracaoGraos<filtroPersonalizadoId>>;
    abstract atualizaFiltroPersonalizado(
        id: string | null,
        parametros: filtroPersonalizadoId
    ): Observable<ResponseInterfaceAeracaoGraos<filtroPersonalizadoId>>;
}

@Injectable({
    providedIn: 'root',
})
export class painelOperacionalController implements IPainelOperacionalController {
    private transport: IApiTransportInterface;

    constructor(
        public readonly apiTransportService: ApiTransportGestaoService,
        private tratarDados: ITratarDadosRequisicaoOperacionalService
    ) {
        this.transport = this.apiTransportService.execute('/painel-operacional');
    }

    public buscarListagemGraos(): Observable<any> {
        return this.transport.http.get(`${this.transport.path}/produto-tipo`, this.transport.options);
    }

    public buscarAeracaoGraos(
        parametros: ParamsAeracaoGrosFilterInterface
    ): Observable<ResponseInterfaceAeracaoGraos<DadosAeracaoGraos>> {
        return this.transport.http.get<ResponseInterfaceAeracaoGraos<DadosAeracaoGraos>>(
            `${this.transport.path}/aeracao-graos`,
            {
                params: this.tratarDados.tratarDadosBuscarAeracaoGraos(parametros)
            }
        );
    }

    public buscarGestaoEstoqueGrao(
        parametros: ParamsAeracaoGrosFilterInterface
    ): Observable<ResponseInterface<dadosGraficoGestaoEstoqueGrao>> {
        return this.transport.http.get<ResponseInterface<dadosGraficoGestaoEstoqueGrao>>(
            `${this.transport.path}/grafico-gestao-estoque-por-grao`,
            {
                params: this.tratarDados.tratarDadosBuscarGestaoEstoqueGrao(parametros)
            }
        );
    }

    public buscarListagemFiltrosCondicaoArmazenagem(
        usuarioId: string | null
    ): Observable<ResponseInterface<filtroCondicaoArmazenagem>> {
        let params = new HttpParams();
        const url = `${this.transport.path}/filtros-personalizados`;
        return this.transport.http.get<ResponseInterface<filtroCondicaoArmazenagem>>(url, {
            params: this.tratarDados.tratarDadosBuscarListagemFiltrosCondicaoArmazenagem(usuarioId)
        });
    }

    public buscarFiltroPersonalizadoId(
        usuarioId: string
    ): Observable<ResponseInterfaceAeracaoGraos<filtroPersonalizadoId>> {
        return this.transport.http.get<ResponseInterfaceAeracaoGraos<filtroPersonalizadoId>>(
            `${this.transport.path}/filtro-personalizado/${usuarioId}`
        );
    }

    public atualizaFiltroPersonalizado(
        id: string | null,
        parametros: filtroPersonalizadoId
    ): Observable<ResponseInterfaceAeracaoGraos<filtroPersonalizadoId>> {
        const url = `${this.transport.path}/filtro-personalizado${id ? `/${id}` : ''}`;
        const method = id ? 'put' : 'post';
        return this.transport.http[method]<ResponseInterfaceAeracaoGraos<filtroPersonalizadoId>>(url, parametros);
    }

    public buscarGestaoEstoqueGraoCapacidade(
        parametros: ParamsAeracaoGrosFilterInterface,
        page: number,
        limit: number
    ): Observable<ResponseInterface<DadosGraficoGestaoEstoqueGraoCapacidadeInterface>> {
        return this.transport.http.get<ResponseInterface<DadosGraficoGestaoEstoqueGraoCapacidadeInterface>>(
            `${this.transport.path}/listagem-gestao-estoque-por-grao`,
            {
                params: this.tratarDados.tratarDadosBuscarGestaoEstoqueGraoCapacidade(
                    parametros,
                    page,
                    limit
                ),
            }
        );
    }

    public buscarSecadoresEmOperacao(
        params: ParamsGestaoUnidadesInterface,
        dadosOrdem: dadosOrdemCrescente,
        page: number,
        limit: number
    ): Observable<ResponseInterface<SecadoresEmOperacao>> {
        return this.transport.http.get<ResponseInterface<SecadoresEmOperacao>>(
            `${this.transport.path}/secadores-operacao`,
            {
                params: this.tratarDados.tratarDadosBuscarSecadoresEmOperacao(
                    params,
                    dadosOrdem,
                    page,
                    limit
                )
            }
        );
    }

    public buscarOutrosSecadores(
        params: ParamsGestaoUnidadesInterface,
        dadosOrdem: dadosOrdemCrescenteOutrosSecadores,
        page: number,
        limit: number
    ): Observable<ResponseInterface<outrosSecadoresInterface>> {
        return this.transport.http.get<ResponseInterface<outrosSecadoresInterface>>(
            `${this.transport.path}/outros-secadores`,
            {
                params: this.tratarDados.tratarDadosBuscarOutrosSecadores(
                    params,
                    dadosOrdem,
                    page,
                    limit
                ),
            }
        );
    }

    public buscarCondicaoArmazenagem(
        params: ParamsGestaoUnidadesInterface,
        dadosOrdem: dadosOrdemCondicoesArmazenagem,
        page: number,
        limit: number,
        filtro?: string | null
    ): Observable<ResponseInterface<interfaceCondicaoArmazenagem>> {

        return this.transport.http.get<ResponseInterface<interfaceCondicaoArmazenagem>>(
            `${this.transport.path}/condicoes-armazenagem`,
            {
                params: this.tratarDados.tratarDadosBuscarCondicaoArmazenagem(
                    params,
                    dadosOrdem,
                    page,
                    limit,
                    filtro
                )
            }
        );
    }

    public buscarHistoricoSecador(
        parametros: ParamsGestaoUnidadesInterface,
        idSecadorEmOperacao?: string | null,
        idCondicaoArmazenagem?: string | null
    ): Observable<ResponseInterface<historicoSecadorInterface>> {

        if (idSecadorEmOperacao) {
            return this.transport.http.get<ResponseInterface<historicoSecadorInterface>>(
                `${this.transport.path}/secadores-historico/${idSecadorEmOperacao}`,
                { params: this.tratarDados.tratarDadosBuscarHistoricoSecador(parametros) }
            );
        }

        if (idCondicaoArmazenagem) {
            return this.transport.http.get<ResponseInterface<historicoSecadorInterface>>(
                `${this.transport.path}/condicoes-armazenagem-historico/${idCondicaoArmazenagem}`,
                { params: this.tratarDados.tratarDadosBuscarHistoricoSecador(parametros) }
            );
        }
    }
}
