import { AeradorStatuSodeEnum } from 'app/shared/enums';
import { EstacaoMeteorologicaInterface } from 'app/shared/interfaces';
export interface InformacoesEstruturaArmazenagemInterface {
    id?: string;
    aquecimento_ar?: number;
    controle_aeracao_automatica?: boolean;
    nome?: string;
    sensor_umidade_instalado?: boolean;
    estrutura_armazenagem?: EstruturaArmazenagem;
    programa_aeracao?: ProgramaAeracao;
    equipamentos?: Equipamento[];
    armazem?: Armazem;
    mapa?: Mapa;
    estrutura_armazenagem_divisao_pendulo?: EstruturaArmazenagemDivisaoPendulo[];
    estrutura_armazenagem_divisao_aerador?: EstruturaArmazenagemDivisaoAerador[];
    estoque?: Estoque;
    produto?: Produto;
    log_comando_aeracao?: LogComandoAeracao;
    media_leitura?: MediaLeitura;
    sensores?: Sensores;
    aeracao?: Aeracao2;
    estacao_meteorologica?: EstacaoMeteorologicaInterface;
}

interface EstruturaArmazenagem {
    codigo: number;
    id: string;
    nome: string;
    sensor_umidade_instalado: boolean;
}

interface ProgramaAeracao {
    id: string;
    nome: string;
    objetivo_programa: string;
}

interface Equipamento {
    categoria: string;
    equipamento_comunicacao: EquipamentoComunicacao;
    id: string;
    modelo: string;
    nome: string;
}

interface EquipamentoComunicacao {
    instante?: string;
    status?: boolean;
}

interface Armazem {
    base: string;
}

interface Mapa {
    temperatura: string;
    sensor_nivel_temperatura: string;
    sensor_quantidade_temperatura: string;
    umidade_relativa: string;
    sensor_nivel_umidade: string;
    sensor_quantidade_umidade: string;
    equilibrio_higroscopico: string;
    pendulo_comunicacao: string;
    digital_termopar: string;
    pendulo_ordenacao: string;
    pendulo_central: any;
}

interface EstruturaArmazenagemDivisaoPendulo {
    id: string;
    pendulo: Pendulo;
}

interface Pendulo {
    id: string;
    estrutura_armazenagem_id: string;
    codigo: number;
    pendulo_canal: PenduloCanal;
    pendulo_propriedade: PenduloPropriedade;
    silo_pendulo_visualizacao: any;
}

interface PenduloCanal {
    id: string;
    equipamento_id: string;
}

interface PenduloPropriedade {
    id: string;
    digital_termopar: string;
}

interface EstruturaArmazenagemDivisaoAerador {
    id: string;
    aerador: Aerador;
}

interface Aerador {
    id: string;
    codigo: number;
    aerador_configuracao: AeradorConfiguracao;
    aerador_motor: any;
    aerador_id: string;
    aerador_leitura_status: AeradorLeituraStatus;
    aerador_leitura_chave: AeradorLeituraChave;
    aerador_leitura_rele: AeradorLeituraRele;
    aeracao: Aeracao;
}

interface AeradorConfiguracao {
    id: string;
    chave_equipamento_id: string;
    rele_equipamento_id: string;
    status_equipamento_id: string;
}

interface AeradorLeituraStatus {
    instante: string;
    valor: boolean;
    equipamento_id: string;
}

interface AeradorLeituraChave {
    instante: string;
    valor: boolean;
    equipamento_id: string;
}

interface AeradorLeituraRele {
    instante: string;
    valor: boolean;
    equipamento_id: string;
}

interface Aeracao {
    status: string;
}

interface Estoque {
    quilograma: Quilograma;
    saca: Saca;
    tonelada: Tonelada;
}

interface Quilograma {
    capacidade_estoque: number;
    estoque_estimado: number;
    estoque_informado: number;
}

interface Saca {
    capacidade_estoque: number;
    estoque_estimado: number;
    estoque_informado: number;
}

interface Tonelada {
    capacidade_estoque: number;
    estoque_estimado: number;
    estoque_informado: number;
}

export interface Produto {
    id: string;
    nome: string;
    produto_tipo_id: number;
}

interface LogComandoAeracao {
    id: string;
    instante: string;
    automatico: boolean;
    comando: string;
    status_comando: string;
}

interface MediaLeitura {
    temperatura: Temperatura;
    umidade: Umidade;
    plenum: Plenum;
    volumetria: Volumetria;
    co2: Co2;
}

interface Temperatura {
    digital: Digital;
    termopar: any;
    geral: Geral;
}

interface Digital {
    min: number;
    max: number;
    med: number;
}

interface Geral {
    min: number;
    max: number;
    med: number;
}

interface Umidade {}

interface Plenum {
    temperatura: Temperatura2;
    umidade: Umidade2;
}

interface Temperatura2 {
    min: number;
    max: number;
    med: number;
}

interface Umidade2 {
    min: number;
    max: number;
    med: number;
}

export interface Volumetria {}

export interface Co2 {}

export interface Sensores {
    sensor_co2: any[];
    sensor_plenum: SensorPlenum[];
    sensor_volume: any[];
}

interface SensorPlenum {
    id: string;
    equipamento_id: string;
    estrutura_armazenagem_id: string;
}

interface Aeracao2 {
    status: AeradorStatuSodeEnum;
    automatico: Automatico;
    manual: Manual;
}

interface Automatico {
    tempo: string;
}

interface Manual {
    tempo: string;
}
