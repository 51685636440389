export interface InformacaoMoedasInternacionaisInterface {
    moeda_real: string;
    moeda_comparacao: string;
    valor_compra: string;
    valor_venda: string;
    variacao_diaria_compra: boolean;
    variacao_diaria_venda: boolean;
    icone_bandeira_comparacao: string;
    icone_bandeira_real: string;
}

export interface ListaMoedaInterface {
    id: string;
    nome: string;
    sigla: string;
}

export interface MoedasInternacionaisResponseInterface {
    data: InformacaoMoedasInternacionaisInterface[];
}
