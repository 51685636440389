import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';

import { Observable, of } from 'rxjs';

import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AmbienteDesenvolvimentoGuard implements CanActivate {
    constructor(private router: Router) {}

    canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (!environment.production && window.location.hostname.includes('localhost')) {            
            return of(true);
        }
        return of(false);
    }
}
