import {
    AeradoreVisaoSuperiorInterface,
    AeradoresComFormaGeometrica,
    DivisoesComPedulosInterface,
} from 'app/shared/interfaces';
import { Injectable } from '@angular/core';
import { IConfigurarStatusAeracaoService } from 'app/shared/services';
export abstract class IDefineAeradoresPorDivisaoService {
    abstract execute(
        divisaoComPendulos: DivisoesComPedulosInterface[],
        aeradores: AeradoreVisaoSuperiorInterface[]
    ): AeradoresComFormaGeometrica[];
}

@Injectable({ providedIn: 'root' })
export class DefineAeradoresPorDivisaoService implements IDefineAeradoresPorDivisaoService {
    constructor(private configurarStatusAeracaoService: IConfigurarStatusAeracaoService) {}
    execute(
        divisaoComPendulos: DivisoesComPedulosInterface[],
        aeradores: AeradoreVisaoSuperiorInterface[]
    ): AeradoresComFormaGeometrica[] {
        let aeradoresComFormas: AeradoresComFormaGeometrica[] = [];
        divisaoComPendulos.forEach((pendulo, index) => {
            aeradores.forEach((item, idx) => {
                const itemEncontrado = item.estrutura_armazenagem_divisao_aerador.find(
                    (aerador) => pendulo.idDivisao === aerador.estrutura_armazenagem_divisao_id
                );
                let posicao_y = 0;
                if (divisaoComPendulos.length === 1) {
                    if (item.armazem_aerador_visualizacao?.posicao_y < 50) {
                        posicao_y = item.armazem_aerador_visualizacao?.posicao_y * 0.5;
                    } else {
                        posicao_y = item.armazem_aerador_visualizacao?.posicao_y;
                    }
                } else {
                    posicao_y = item.armazem_aerador_visualizacao?.posicao_y;
                }
                if (itemEncontrado) {
                    if (index === 0 && idx === 0) {
                        aeradoresComFormas = [
                            {
                                idDivisao: pendulo.idDivisao,
                                formaGeometrica: pendulo.formaGeometrica,
                                idAerador: item.armazem_aerador_visualizacao?.aerador_id,
                                posicao_x: item.armazem_aerador_visualizacao?.posicao_x ?? 0,
                                posicao_y: posicao_y,
                                codigo: item.codigo,
                                icone: this.configurarStatusAeracaoService.execute(item.aeracao.status).aerador.cor,
                            },
                        ];
                    } else {
                        aeradoresComFormas.push({
                            idDivisao: pendulo.idDivisao,
                            formaGeometrica: pendulo.formaGeometrica,
                            idAerador: item.armazem_aerador_visualizacao?.aerador_id,
                            posicao_x: item.armazem_aerador_visualizacao?.posicao_x ?? 0,
                            posicao_y: posicao_y,
                            icone: this.configurarStatusAeracaoService.execute(item.aeracao.status).aerador.cor,
                        });
                    }
                }
            });
        });
        return aeradoresComFormas;
    }
}
