import { LegendaTemperaturaUmidadeGraoInterface, OpcoesLegendaInterface } from "app/modules/gestao-unidades/operacional/shared/interfaces";

export const LegendaTemperaturaUmidadeGraoConstant: LegendaTemperaturaUmidadeGraoInterface[] = [
    {
        titulo: 'PAINEL_GERAL_GESTAO_UNIDADE.EXCELENTES_CONDICOES_DE_ARMAZENAGEM',
        cor: '#BBF2C4'
    },
    {
        titulo: 'PAINEL_GERAL_GESTAO_UNIDADE.CONDICOES_QUE_EXIGE_ATENCAO_FACILIDADE',
        cor: '#E9DAB7'
    },
    {
        titulo: 'PAINEL_GERAL_GESTAO_UNIDADE.CONDICOES_QUE_EXIGE_ATENCAO_FACILIDADE_COM_RISCO',
        cor: '#FDC6C7'
    },
    {
        titulo: 'PAINEL_GERAL_GESTAO_UNIDADE.CONDICAO_EXTREMAMENTE_DESFAVORAVEL_PARA_A_ARMAZENAGEM',
        cor: '#E08289'
    },
]

export const OpcoesLegendaConstant: OpcoesLegendaInterface[] = [
    {
        texto: 'Temperatura média',
        valor: 0,
    },
    {
        texto: 'Temperatura máxima',
        valor: 1,
    }
]
