export enum TabelaModalAeracaoGraosAutomatica {
    PAGE_SIZE = 0,
    CURRENT_PAGE = 0,
    TOTAL_ITENS = 0
}

export enum TabelaModalAeracaoGraosResfriamento {
    PAGE_SIZE = 0,
    CURRENT_PAGE = 0,
    TOTAL_ITENS = 0
}

export enum TabelaModalAeracaoAutomatico {
    SILO = 'silo',
    UNIDADE = 'unidade',
    GRAO = 'grao',
    ACAO = 'acao'
}

export enum GraficoAeracaoGraosLabel {
    AERACAO_AUTOMATICA = 'Automatica',
    AERACAO_MANUAL = 'Manual'
}

export enum GraficoSecundarioAeracaoGraosLabel {
    SECAGEM = 'Secagem',
    RESFRIAMENTO = 'Resfriamento',
    RESFRIAMENTO_E_CONSERVACAO = 'Resfriamento e conservação',
    CONTROLE_DO_SISTEMA_EM_MANUAL = 'Controle do sistema em manual'
}
