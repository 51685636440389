export enum AeracaoAutomaticaGanhosEnum {
    COMPONENTE_TITULO = 'Ganhos com a aeração automática',
    SILOS_ARMAZEM = 'Silo/Armazém',
    UNIDADE = 'Unidade',
    GRAO = 'Grão',
    TEMPO_ARMAZENAGEM = 'Tempo de armazenagem (dias)',
    HIGROSCOPICO_MENOR_IGUAL = 'Eq. Higroscópico menor ou igual a 14%',
    HIGROSCOPICO_MAIOR = 'Eq. Higroscópico maior que 14%',
    AERACAO_PAINEL_MANUAL = 'Aeração com painel em manual',
    AERACAO_PAINEL_AUTOMATICO = 'Aeração com painel em automático',
    TOTAL_AERACAO = 'Total de aeração',
    UG_ATUAL = 'Ug atual (t)',
    ESTOQUE_MAXIMO = 'Estoque máximo (t)',
    ESTOQUE_SEM_AERACAO_AUTOMATICA = 'Estoque sem aeração automática',
    EM_QUANTIDADE_GRAOS = 'Em quantidade de grãos (t)',
    EM_RECEITA = 'Em receita',
    TEMPO_AERACAO = 'Tempo de aeração (h)',
    RS = 'R$',
    PROVAVEL_RESULTADO = 'Provável resultado que o armazenista teria apenas com aeração manual',
}

export enum CabecalhoAeracaoAutomaticaGanhosEnum {
    SILOS_ARMAZEM = 'siloArmazem',
    UNIDADE = 'unidade',
    GRAO = 'grao',
    TEMPO_ARMAZENAGEM = 'tempoArmazenagem',
    HIGROSCOPICO_MENOR_IGUAL = 'higroscopicoMenorIgual',
    HIGROSCOPICO_MAIOR = 'higroscopicoMaior',
    AERACAO_PAINEL_MANUAL = 'aeracaoPainelManual',
    AERACAO_PAINEL_AUTOMATICO = 'aeracaoPainelAutomatico',
    TOTAL_AERACAO = 'totalAeracao',
    UG_ATUAL = 'ugAtual',
    ESTOQUE_MAXIMO = 'estoqueMaximo',
    ESTOQUE_SEM_AERACAO_AUTOMATICA = 'estoqueSemAeracaoAutomatica',
    EM_QUANTIDADE_GRAOS = 'EmQuantidadeGraos',
    EM_RECEITA = 'emReceita',
}

export enum CabecalhoAeracaoAutomaticaExpansivoEnum {
    ANALISE = 'analise',
    ABAIXO_DEZ = 'abaixoDez',
    ATE_DOZE = 'ateDoze',
    ATE_QUATORZE = 'ateQuatorze',
    ATE_DEZESSEIS = 'ateDezesseis',
    ATE_DEZOITO = 'ateDezoito',
    MAIOR_DEZOITO = 'maiorDezoito',
    TOTAL = 'total',
}
export enum AeracaoAutomaticaExpansivoEnum {
    ANALISE = 'Análise',
    ABAIXO_DEZ = 'Abaixo de 10,0%',
    ATE_DOZE = '10,0%-12,0%',
    ATE_QUATORZE = '12,1%-14,0%',
    ATE_DEZESSEIS = '14,1%-16,0%',
    ATE_DEZOITO = '16,1%-18,0%',
    MAIOR_DEZOITO = 'Acima de 18,0%',
    TOTAL = 'Total aeração ',
    H = '(h)',
    PAINEL = 'Painel',
    SISTEMA = 'Sistema',
    MANUAL = 'Manual',
    AUTOMATICO = 'Automático',
    TOTAL_FAIXA = 'Total por faixa ',
    RESFRIAMENTO_CONSERVACAO = 'Resfriamento e Conservação',
    FAIXA_EH = 'Faixa de aeração por equilíbrio higroscópíco',
}
