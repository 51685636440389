export enum MensagensErroMapaArmazenagemEnum {
    PENDULOS_TEMPERATURA = 'O número (pendulosConfigTemperatura) de pendulos configurados em "sensor_quantidade_temperatura" estão diferentes do número (pendulosLeituraTemperatura) de pendulos lidos em "temperatura".',
    PENDULOS_TEMPERATURA_NIVEL = 'O número (pendulosConfigTemperatura) de pendulos configurados em "sensor_quantidade_temperatura" estão diferentes do número (pendulosNivelTemperatura) de pendulos lidos em "sensor_nivel_temperatura".',
    PENDULOS_UMIDADE = 'O número (pendulosConfigUmidade) de pendulos configurados em "sensor_quantidade_umidade" estão diferentes do número (pendulosLeituraUmidade) de pendulos lidos em "umidade_relativa".',
    PENDULOS_UMIDADE_NIVEL = 'O número (pendulosConfigUmidade) de pendulos configurados em "sensor_quantidade_umidade" estão diferentes do número (pendulosNivelUmidade) de pendulos lidos em "sensor_nivel_umidade".',
    PENDULOS_EQUILIBRIO = 'O número (pendulosConfigUmidade) de pendulos configurados em "sensor_quantidade_umidade" estão diferentes do número (pendulosLeituraEquilibrio) de pendulos lidos em "equilibrio_higroscopico".',
    SENSORES_TEMPERATURA = 'O número (sensoresTemperaturaConfig) de sensores de temperatura configurados no pendulo ${i} estão diferentes do número (sensoresTemperaturaLeitura) de sensores lidos no pendulo ${i}. Objetos divergentes: sensor_quantidade_temperatura !== temperatura',
    SENSORES_TEMPERATURA_NIVEL = 'O número (sensoresTemperaturaConfig) de sensores de temperatura configurados no pendulo ${i} está menor que o número (sensoresTemperaturaNivel) setado no nivel ${i}. Objetos divergentes: sensor_quantidade_temperatura !== sensor_nivel_temperatura',
    SENSORES_UMIDADE = 'O número (sensoresUmidadeConfig) de sensores de umidade configurados no pendulo ${i} estão diferentes do número (sensoresUmidadeLeitura) de sensores lidos no pendulo ${i}. Objetos divergentes: sensor_quantidade_umidade !== umidade_relativa',
    SENSORES_UMIDADE_NIVEL = 'O número (sensoresUmidadeConfig) de sensores de umidade configurados no pendulo ${i} está menor que o número (sensoresUmidadeNivel) setado no nivel ${i}. Objetos divergentes: sensor_quantidade_umidade !== sensor_nivel_umidade',
    SENSORES_EQUILIBRIO = 'O número (sensoresUmidadeConfig) de sensores de umidade configurados no pendulo ${i} estão diferentes do número (sensoresEquilibrioLeitura) de sensores lidos no pendulo ${i}. Objetos divergentes: sensor_quantidade_umidade !== equilibrio_higroscopico',
}
