import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ItemListagemEstruturaArmazenagemInterface } from 'app/modules/armazenagem/configuracao/estoque/interfaces';
import { SubcelulaVisaoGeralInterface } from 'app/modules/armazenagem/visualizacao/armazens/interfaces';
import { ListagemSilosInterface } from 'app/modules/armazenagem/visualizacao/silos/interfaces';
import { ArmazemVisaoGeralInterface, ResponseInterface } from 'app/shared/interfaces';
import { ApiPaginacao, ObjetoGernericoParaSelectModel } from 'app/shared/models';
import { ApiTransportService } from 'app/core/services/transport';
import { IApiTransportInterface } from 'app/core/interfaces';
import { IObjectToAnyService } from 'app/shared/services';

export abstract class IListagemArmazenagemController {
    abstract buscarListagemArmazens(paginacao: ApiPaginacao): Observable<ResponseInterface<ArmazemVisaoGeralInterface>>;

    abstract buscarListagemSubcelulas(idArmazem: string): Observable<ResponseInterface<SubcelulaVisaoGeralInterface>>;
    abstract buscarListaSimplesSubcelulas(
        idArmazem: string
    ): Observable<ResponseInterface<ObjetoGernericoParaSelectModel>>;
    abstract buscarListagemSilos(paginacao: ApiPaginacao): Observable<ResponseInterface<ListagemSilosInterface>>;
    abstract buscarEstruturasArmazenagem(): Observable<ItemListagemEstruturaArmazenagemInterface[]>;
    abstract buscarListaSimplesArmazem(): Observable<any>;
}

@Injectable({
    providedIn: 'root',
})
export class ListagemArmazenagemController implements IListagemArmazenagemController {
    private transport: IApiTransportInterface;

    constructor(
        public readonly apiTransportService: ApiTransportService,
        private readonly objectToAnyService: IObjectToAnyService
    ) {
        this.transport = this.apiTransportService.execute('/armazenagem');
    }

    public buscarListagemArmazens(paginacao: ApiPaginacao): Observable<any> {
        this.transport.options.params = this.objectToAnyService.toQueryString({
            page: paginacao.page,
            limit: paginacao.limit,
        });
        return this.transport.http.get(`${this.transport.path}/listagem/armazem`, this.transport.options);
    }

    public buscarListagemSubcelulas(idArmazem: string): Observable<any> {
        return this.transport.http.get(
            `${this.transport.path}/listagem/armazem/listagem-estrutura-divisoes/${idArmazem}`
        );
    }

    public buscarListagemSilos(paginacao: ApiPaginacao): Observable<any> {
        this.transport.options.params = this.objectToAnyService.toQueryString({
            page: paginacao.page,
            limit: paginacao.limit,
        });
        return this.transport.http.get(`${this.transport.path}/listagem/silo`, this.transport.options);
    }

    public buscarEstruturasArmazenagem(): Observable<any> {
        return this.transport.http.get(this.transport.path);
    }

    public buscarListaSimplesArmazem(): Observable<any> {
        return this.transport.http.get(`${this.transport.path}/armazem`);
    }

    public buscarListaSimplesSubcelulas(idArmazem: string): Observable<any> {
        return this.transport.http.get(`${this.transport.path}/armazem/${idArmazem}/sub-celula`);
    }
}
