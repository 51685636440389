import { Injectable } from '@angular/core';
import { IApiTransportInterface } from 'app/core/interfaces';
import { ApiTransportService } from 'app/core/services/transport';
import { ListaSimplificadaEstruturaArmazenagemInterface, ResponseInterface } from 'app/shared/interfaces';
import { IObjectToAnyService } from 'app/shared/services';
import { Observable } from 'rxjs';

export abstract class IArmazenagemEquilibrioHigroscopicoController {
    abstract buscarListaSimplificadaArmazens(): Observable<
        ResponseInterface<ListaSimplificadaEstruturaArmazenagemInterface>
    >;
    abstract buscarListaSimplificadaSilos(): Observable<
        ResponseInterface<ListaSimplificadaEstruturaArmazenagemInterface>
    >;
}

@Injectable({
    providedIn: 'root',
})
export class ArmazenagemEquilibrioHigroscopicoController implements IArmazenagemEquilibrioHigroscopicoController {
    private transport: IApiTransportInterface;

    constructor(public readonly apiTransportService: ApiTransportService) {
        this.transport = this.apiTransportService.execute('/armazenagem/equilibrio-higroscopico');
    }

    public buscarListaSimplificadaArmazens(): Observable<any> {
        return this.transport.http.get(`${this.transport.path}/armazem`);
    }

    public buscarListaSimplificadaSilos(): Observable<any> {
        return this.transport.http.get(`${this.transport.path}/silo`);
    }
}
