import { Injectable } from '@angular/core';
import { PerfilFuncionalidadeAcaoEnum } from 'app/shared/enums';
import { BehaviorSubject } from 'rxjs';

export abstract class IControleAcessoPermissaoService {
    abstract pegarTipoPermissa(permissao: string): void;
    abstract informarTipoPermissao(): boolean;
}

@Injectable({
    providedIn: 'root'
})
export class ControleAcessoPermissaoService implements IControleAcessoPermissaoService {
    private valorSubject = new BehaviorSubject<string>(null);
    permissao$ = this.valorSubject.asObservable();

    pegarTipoPermissa(permissao: string): void {
        this.valorSubject.next(permissao);
    }

    informarTipoPermissao(): boolean {
        return this.valorSubject.value === PerfilFuncionalidadeAcaoEnum.ADMINISTRAR;
    }
}
