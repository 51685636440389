export enum SecadorUltimaLeituraHorimetroKwEnum {
    tempo_ventilador = 'tempo_ventilador',
    tempo_descarga = 'tempo_descarga',
    tempo_aspiracao = 'tempo_aspiracao',
    tempo_alimentacao_quemador = 'tempo_alimentacao_quemador',
}

export enum SecadorUltimaLeituraHorimetroKwTranducaoEnum {
    TEMPO_VENTILADOR = 'SECADOR_VISAO_SCREEN.TEMPO_VENTILADOR',
    TEMPO_DESCARGA = 'SECADOR_VISAO_SCREEN.TEMPO_DESCARGA',
    TEMPO_ASPIRACAO = 'SECADOR_VISAO_SCREEN.TEMPO_ASPIRACAO',
    TEMPO_ALIMENTACAO_QUEMADOR = 'SECADOR_VISAO_SCREEN.TEMPO_ALIMENTACAO_QUEMADOR',
}
