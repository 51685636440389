export enum SecadorUltimaLeituraParametrosKwEnum {
    temperatura_controle = 'temperatura_controle',
    temperatura_alarme = 'temperatura_alarme',
    temperatura_bloqueio_exaustor = 'temperatura_bloqueio_exaustor',
    delta_rodizio = 'delta_rodizio',
    delta_continuo = 'delta_continuo',
    umidade_controle = 'umidade_controle',
    tempo_esteira_fornalha_ligada = 'tempo_esteira_fornalha_ligada',
    tempo_esteira_fornalha_desligada = 'tempo_esteira_fornalha_desligada',
    tempo_veneziana_ligado = 'tempo_veneziana_ligado',
    tempo_veneziana_desligado = 'tempo_veneziana_desligado',
    temperatura_controle_fornalha_sensor = 'temperatura_controle_fornalha_sensor',
    valor_controle_fornalha_oleo_gas = 'valor_controle_fornalha_oleo_gas',
    temperatura_controle_fornalha = 'temperatura_controle_fornalha',
    temperatura_controle_defagulhador = 'temperatura_controle_defagulhador',
    sensor_temperatura_defagulhador = 'sensor_temperatura_defagulhador',
}

export enum SecadorUltimaLeituraParametrosKwTranducaoEnum {
    TEMPERATURA_CONTROLE = 'SECADOR_VISAO_SCREEN.TEMPERATURA_CONTROLE',
    TEMPERATURA_ALARME = 'SECADOR_VISAO_SCREEN.TEMPERATURA_ALARME',
    TEMPERATURA_BLOQUEIO_EXAUSTOR = 'SECADOR_VISAO_SCREEN.TEMPERATURA_BLOQUEIO_EXAUSTOR',
    DELTA_RODIZIO = 'SECADOR_VISAO_SCREEN.DELTA_RODIZIO',
    DELTA_CONTINUO = 'SECADOR_VISAO_SCREEN.DELTA_CONTINUO',
    UMIDADE_CONTROLE = 'SECADOR_VISAO_SCREEN.UMIDADE_CONTROLE',
    TEMPO_ESTEIRA_FORNALHA_LIGADA = 'SECADOR_VISAO_SCREEN.TEMPO_ESTEIRA_FORNALHA_LIGADA',
    TEMPO_ESTEIRA_FORNALHA_DESLIGADA = 'SECADOR_VISAO_SCREEN.TEMPO_ESTEIRA_FORNALHA_DESLIGADA',
    TEMPO_VENEZIANA_LIGADO = 'SECADOR_VISAO_SCREEN.TEMPO_VENEZIANA_LIGADO',
    TEMPO_VENEZIANA_DESLIGADO = 'SECADOR_VISAO_SCREEN.TEMPO_VENEZIANA_DESLIGADO',
    TEMPERATURA_CONTROLE_FORNALHA_SENSOR = 'SECADOR_VISAO_SCREEN.TEMPERATURA_CONTROLE_FORNALHA_SENSOR',
    VALOR_CONTROLE_FORNALHA_OLEO_GAS = 'SECADOR_VISAO_SCREEN.VALOR_CONTROLE_FORNALHA_OLEO_GAS',
    TEMPERATURA_CONTROLE_FORNALHA = 'SECADOR_VISAO_SCREEN.TEMPERATURA_CONTROLE_FORNALHA',
    TEMPERATURA_CONTROLE_DEFAGULHADOR = 'SECADOR_VISAO_SCREEN.TEMPERATURA_CONTROLE_DEFAGULHADOR',
    SENSOR_TEMPERATURA_DEFAGULHADOR = 'SECADOR_VISAO_SCREEN.SENSOR_TEMPERATURA_DEFAGULHADOR',
}
