export const EstacaoMeteorologicaStyles = {    
    chovendo : 'text-[#019DE8] bg-[#e9f8ff]',
    ensolarado : 'text-[#F1B826] bg-[#FEF8EA]',
    sem_leitura : 'text-[#292b32] bg-[#ebeced]',
}

export const EstacaoMeteorologicaFonteStyles = {    
    chovendo : 'text-[#019DE8]',
    ensolarado : 'text-[#F1B826]',
    sem_leitura : 'text-[#292b32]',
}
