<div class="w-full py-3 px-1" *ngIf="fraseEntreEmContato">
    <div class="w-full h-50 bg-white text-sm p-6">
        <p
            class="text-center"
            [innerHtml]="'SEM_PERMISSAO_ACESSO_COMPONENT.VOCE_NAO_TEM_PERMISSAO_PARA_ACESSAR_ESSA_TELA' | transloco"
        ></p>
        <p
            class="text-center"
            [innerHtml]="fraseEntreEmContato"
        ></p>
        <p class="text-center flex justify-items-center justify-center mt-5">
            <mat-icon svgIcon="heroicons_solid:phone" class="text-[#019DE8] icon-size-4 my-auto"></mat-icon>
            <span class="text-[#019DE8] mx-2 my-auto">
                Iasmin Martins
            </span>
            <span class="my-auto">
                +55 <strong>(48) 9 9190-9852</strong>
            </span>
            <a [href]="irParaWhatsApp('+5548991909852')" class="my-auto">
                <mat-icon class="p-1 ml-2" [svgIcon]="'icone-whatsapp-azul'"></mat-icon>
            </a>
        </p>
        <img src="assets/gifs/emoji_piscar.gif" class="w-[111px] h-[84px] m-auto"/>
    </div>
</div>
