import { CalendarioSimplesValueInterface } from 'app/shared/components/calendario-simples/interface';

import moment from 'moment';
import { IdiomaSiglaEnum } from '../enums';

export class FormataDataHelper {
    public static formataDataHora(dataHora: string): string {
        if (!moment(dataHora).isValid()) {
            return null;
        }
        return moment(dataHora, moment.ISO_8601).format('DD/MM/yyyy HH:mm');
    }

    public static dataStrParaCalendario(dataHora: string): CalendarioSimplesValueInterface {
        if (!moment(dataHora).isValid()) {
            return null;
        }
        return {
            startDate: moment(dataHora, moment.ISO_8601),
            endDate: moment(dataHora, moment.ISO_8601),
        };
    }

    public static agora(): string {
        return moment().toISOString();
    }

    public static formatoDataHoraIdioma(idioma: string): string {
        switch (idioma) {
            case IdiomaSiglaEnum.INGLES:
                return 'MM/dd/yyyy hh:mm';
            case IdiomaSiglaEnum.ESPANHOL:
                return 'dd/MM/yyyy HH:mm';
            default:
                return 'dd/MM/yyyy HH:mm';
        }
    }
}
