import { Injectable } from '@angular/core';

import { firstValueFrom, take } from 'rxjs';

import { ISincronizadorController } from 'app/layout/common/sincronizador-equipamento';
import { UnidadeParametrizacaoObservable } from 'app/shared/observables';
import { UnidadeInterface } from 'app/shared/interfaces';
import { SincronizadorModel } from 'app/shared/models';
import { environment } from 'environments/environment';
import { TipoAmbiente } from 'app/shared/enums';

export abstract class IVerificaComunicacaoService {
    abstract execute(): Promise<boolean>;
}

@Injectable()
export class VerificaComunicacaoService implements IVerificaComunicacaoService {
    dataSincronizador!: SincronizadorModel;
    parametrosUnidade: UnidadeInterface;
    levelNuvem: boolean = false;

    constructor(
        private readonly unidadeParametrizacaoObservable: UnidadeParametrizacaoObservable,
        private readonly sincronizadorController: ISincronizadorController
    ) {
        this.levelNuvem = environment.level.toLocaleLowerCase() === TipoAmbiente.NUVEM.toLocaleLowerCase();
    }

    public async execute(): Promise<boolean> {
        this.unidadeParametrizacaoObservable.buscaParametrosUnidade$.pipe(take(1)).subscribe((parametros) => {
            this.parametrosUnidade = parametros;
        });

        if (this.levelNuvem) return true;

        if (!this.parametrosUnidade?.unidade_parametrizacao?.instalado_nuvem) return true;

        return this.verificaUltimaSincronizacao();
    }

    private async verificaUltimaSincronizacao(): Promise<boolean> {
        this.dataSincronizador = await firstValueFrom(
            this.sincronizadorController.buscaUltimaSincronizacao().pipe(take(1))
        );
        return this.dataSincronizador?.comunicacao_local_com_nuvem?.status;
    }
}
