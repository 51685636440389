import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class FusoHorarioService {
    constructor() {}

    obterFusoHorario(): string {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
}
