export const MENUS_DEVELOPMENT = [
    {
        menu: 'DESENVOLVIMENTO',
        description: 'DESENVOLVIMENTO',
        link: '#',
        icon: 'icon-menu-desenvolvimento',        
        groups: [
            {
                title: 'Exemplos',
                icon: 'icon-cadastro',                
                separator: false,     
                items: [
                    {
                        menu: "Icones",
                        icon: "icon-info",
                        link: "desenvolvimento/icones",
                    },
                ],                        
            },
        ],
    },
]