import { IconeSensorEnum, LocalSensorEnum } from 'app/shared/enums';

export class IconeSensorHelper {
    public static selecionaIconeSensor(localSensor: string): string {
        const LOCAL_SENSOR = localSensor?.replace(/\s+/g, '').toUpperCase();
        switch (LOCAL_SENSOR) {
            case LocalSensorEnum.CARGA_GRAOS:
                return IconeSensorEnum.ICONE_UMIDADE_GRAO;
            case LocalSensorEnum.CONDICOES_EXTERNAS:
                return IconeSensorEnum.ICONE_UMIDADE_RELATIVA;
            case LocalSensorEnum.DESCARGA_GRAOS:
                return IconeSensorEnum.ICONE_UMIDADE_GRAO;
            case LocalSensorEnum.ENTRADA_AR:
                return IconeSensorEnum.ICONE_TEMPERATURA_DIGITAL;
            case LocalSensorEnum.FORNALHA:
                return IconeSensorEnum.ICONE_TEMPERATURA_DIGITAL;
            case LocalSensorEnum.MASSA_GRAOS:
                return IconeSensorEnum.ICONE_TEMPERATURA_DIGITAL;
            case LocalSensorEnum.RECIRCULACAO:
                return IconeSensorEnum.ICONE_UMIDADE_RELATIVA;
            case LocalSensorEnum.SAIDA_AR:
                return IconeSensorEnum.ICONE_TEMPERATURA_DIGITAL;
        }
    }
}
