export enum SecadorComandoEnum {
    produto_tipo = 'Grão',
    velocidade_descarga = 'Setagem de frequência da velocidade de descarga (Hz)',
    tempo_aberto_mesa = 'Tempo de mesa de descarga aberta (ms)',
    tempo_fechado_mesa = 'Tempo de mesa de descarga fechada (ms)',
    tempo_aberto_veneziana = 'Tempo de veneziana aberta (ms)',
    tempo_fechado_veneziana = 'Tempo de veneziana fechada (ms)',
    tempo_aberto_alimentador = 'Tempo alimentador aberto (ms)',
    tempo_fechado_alimentador = 'Tempo alimentador fechado (ms)',
    tempo_minimo_alimentador_ligado = 'Tempo mínimo do alimentador ligado (ms)',
    temperatura_controle_veneziana = 'Temperatura de controle da veneziana (°C)',
    temperatura_bloqueio_exaustor_massa = 'Temperatura de bloqueio do exaustor na massa de grãos (°C)',
    temperatura_bloqueio_exaustor_saida = 'Temperatura de bloqueio do exaustor da saída ar (°C)',
    temperatura_maxima_fornalha = 'Temperatura máxima da fornalha (ºC)',
    temperatura_minima_fornalha = 'Temperatura mínima da fornalha (ºC)',
    temperatura_maxima_entrada = 'Temperatura máxima da entrada de ar (ºC)',
    temperatura_minima_entrada = 'Temperatura mínima da entrada de ar (ºC)',
    temperatura_maxima_massa = 'Temperatura máxima da massa de grãos (°C)',
    temperatura_minima_massa = 'Temperatura mínima da massa de grãos (°C)',
    temperatura_maxima_saida = 'Temperatura mínima da saída de ar (ºC)',
    temperatura_minima_saida = 'Temperatura máxima da saída de ar (ºC)',
    idioma = 'Idioma',
    umidade_controle = 'Umidade de controle do grão (%)',
    intervalo_limpeza = 'Intervalo para limpeza (h)',
}

export enum AlarmeTemperaturaEnum {
    alarme_temperatura = 'alarme_temperatura',
}
