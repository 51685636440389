import { Injectable } from '@angular/core';
import { PendulosSiloStatusConfiguracaoEnum } from 'app/shared/enums';
import { configuracaoPendulosSilo } from 'app/shared/constants';
import { VerificaModoMobile } from 'app/shared/helpers';

export abstract class IDesenhaPendulosVisaoSuperiorService {
    abstract execute(pendulos: any[], comunicacaoPendulos: string): any;
    abstract calculatePendulumPositions(pendulos: any[]): any[];
}

@Injectable({ providedIn: 'root' })
export class DesenhaPendulosVisaoSuperiorService implements IDesenhaPendulosVisaoSuperiorService {
    execute(pendulos: any[], comunicacaoPendulos: string): any {
        const chapeu = document.getElementById('container-chapeu-silo');
        const margemMobile = 12;
        let numeroPendulosConfigurados = 0;
        pendulos?.forEach((penduloEstrutura, index) => {
            if (this.penduloDesconfigurado(penduloEstrutura)) return;
            if (VerificaModoMobile.modoMobile()) {
                chapeu.innerHTML += `<span class="h-4 w-4 text-xs rounded-full justify-center items-center flex absolute m-0"
                style="left: ${this.retornaCoordenadasPendulo(penduloEstrutura).x / 2 + margemMobile}px;
                top: ${this.retornaCoordenadasPendulo(penduloEstrutura).y / 2 + margemMobile}px;
                background: ${this.configuraPendulo(index, comunicacaoPendulos).background};
                color: ${this.configuraPendulo(index, comunicacaoPendulos).texto}"
            >${penduloEstrutura.pendulo.codigo}</span>`;
            } else {
                chapeu.innerHTML += `<span class="h-7 w-7 rounded-full justify-center items-center flex absolute m-0"
                style="left: ${this.retornaCoordenadasPendulo(penduloEstrutura).x}px;
                top: ${this.retornaCoordenadasPendulo(penduloEstrutura).y}px;
                background: ${this.configuraPendulo(index, comunicacaoPendulos).background};
                color: ${this.configuraPendulo(index, comunicacaoPendulos).texto}"
            >${penduloEstrutura.pendulo.codigo}</span>`;
            }

            numeroPendulosConfigurados++;
        });
        return {
            status: this.pendulosSiloStatusConfiguracao(pendulos, numeroPendulosConfigurados),
            pendulosDesconfigurados: pendulos?.length - numeroPendulosConfigurados,
        };
    }

    calculatePendulumPositions(pendulos: any[]): any[] {
        if (!pendulos || !Array.isArray(pendulos)) {
            return [];
        }

        let numeroPendulosConfigurados = 0;
        const positions = [];

        pendulos.forEach((pendulo) => {
            if (this.penduloDesconfigurado(pendulo)) return;

            const posX = VerificaModoMobile.modoMobile()
                ? pendulo.pendulo.silo_pendulo_visualizacao.posicao_x / 2
                : pendulo.pendulo.silo_pendulo_visualizacao.posicao_x;

            const posY = VerificaModoMobile.modoMobile()
                ? pendulo.pendulo.silo_pendulo_visualizacao.posicao_y / 2
                : pendulo.pendulo.silo_pendulo_visualizacao.posicao_y;

            positions.push({ posX, posY });

            numeroPendulosConfigurados++;
        });

        return positions;
    }

    private configuraPendulo(indexPendulo: number, comunicacaoPendulos: string): any {
        const comunicacaoPendulosArray = comunicacaoPendulos.split('|');

        let configuraPendulo = {
            background: configuracaoPendulosSilo.comunicando.background,
            texto: configuracaoPendulosSilo.comunicando.texto,
        };

        if (comunicacaoPendulosArray[indexPendulo] === '0') {
            configuraPendulo.background = configuracaoPendulosSilo.semComunicacao.background;
            configuraPendulo.texto = configuracaoPendulosSilo.semComunicacao.texto;
        }
        return configuraPendulo;
    }

    private penduloDesconfigurado(penduloEstrutura): boolean | false {
        if (
            !penduloEstrutura?.pendulo.silo_pendulo_visualizacao?.posicao_x ||
            !penduloEstrutura?.pendulo.silo_pendulo_visualizacao?.posicao_y ||
            penduloEstrutura?.pendulo.silo_pendulo_visualizacao?.posicao_x === null ||
            penduloEstrutura?.pendulo.silo_pendulo_visualizacao?.posicao_y === null
        ) {
            return true;
        }
    }

    private pendulosSiloStatusConfiguracao(pendulos: any[], contador: number): PendulosSiloStatusConfiguracaoEnum {
        switch (true) {
            case contador === 0: {
                return PendulosSiloStatusConfiguracaoEnum.NAO_CONFIGURADO;
            }
            case contador < pendulos.length: {
                return PendulosSiloStatusConfiguracaoEnum.PARCIALMENTE_CONFIGURADO;
            }
            case contador === pendulos.length: {
                return PendulosSiloStatusConfiguracaoEnum.CONFIGURADO;
            }
        }
    }

    private retornaCoordenadasPendulo(penduloEstrutura): { x: number; y: number } {
        const x =
            penduloEstrutura.pendulo.silo_pendulo_visualizacao.posicao_x < 180
                ? penduloEstrutura.pendulo.silo_pendulo_visualizacao.posicao_x - 8
                : penduloEstrutura.pendulo.silo_pendulo_visualizacao.posicao_x > 190
                ? penduloEstrutura.pendulo.silo_pendulo_visualizacao.posicao_x + 8
                : penduloEstrutura.pendulo.silo_pendulo_visualizacao.posicao_x;
        const y =
            penduloEstrutura.pendulo.silo_pendulo_visualizacao.posicao_y < 170
                ? penduloEstrutura.pendulo.silo_pendulo_visualizacao.posicao_y - 8
                : penduloEstrutura.pendulo.silo_pendulo_visualizacao.posicao_y > 200
                ? penduloEstrutura.pendulo.silo_pendulo_visualizacao.posicao_y + 8
                : penduloEstrutura.pendulo.silo_pendulo_visualizacao.posicao_y;
        return { x, y };
    }
}
