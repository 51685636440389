import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { IdiomaSiglaEnum } from 'app/shared/enums';
import { CookieService } from 'app/core/services';

@Injectable({
    providedIn: 'root',
})
export class IdiomaService {
    constructor(
        private translocoService: TranslocoService,
        private cookieService: CookieService
    ) {}

    iniciarTraducao(): void {        
        const idioma = this.cookieService.obterCookie('idioma') as IdiomaSiglaEnum;

        if (idioma) {
            this.translocoService.setActiveLang(idioma);
        } else {
            this.translocoService.setActiveLang(IdiomaSiglaEnum.PORTUGUES);
        }
    }

    alterarIdioma(sigla: IdiomaSiglaEnum): void {        
        this.translocoService.setActiveLang(sigla);
        
        this.cookieService.definirCookie('idioma', sigla);
    }

    obterIdioma(): IdiomaSiglaEnum {
        return this.translocoService.getActiveLang() as IdiomaSiglaEnum;
    }
}
