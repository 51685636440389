import { Injectable } from '@angular/core';
import { ConexaoInternetObservable } from '../observables';

export abstract class IAtualizaStatusConexaoInternetService {
    abstract execute(): void;
}

@Injectable({ providedIn: 'root' })
export class AtualizaStatusConexaoInternetService implements IAtualizaStatusConexaoInternetService {
    constructor(private conexaoInternetObservable: ConexaoInternetObservable) {
        window.addEventListener('online', () => this.execute());
        window.addEventListener('offline', () => this.execute());
    }
    execute(): void {
        this.conexaoInternetObservable.online = navigator.onLine;
    }
}
