export interface BuscaEstoqueRespostaInterface {
    data: {
        capacidadeTotal: number;
        estoqueTotalAtual: {
            valor: number;
            porcentagem: number;
        };
        estoqueFixadoEmContrato: {
            valor: number;
            porcentagem: number;
        };
        estoqueTotalAtualPorGrao: [
            {
                grao: string;
                estoque: {
                    valor: number;
                    porcentagem: number;
                };
            }
        ];
    };
}
