export enum NomeLocalSensorEnum {
    FORNALHA = 'Fornalha',
    ENTRADA_AR = 'Entrada de ar',
    MASSA_GRAOS = 'Massa de grãos',
    SAIDA_AR = 'Saída de ar',
    CARGA_GRAOS = 'Carga de graõs',
    DESCARGA_GRAOS = 'Descarga de graõs',
    RECIRCULACAO = 'Recirculação',
    CONDICAO_EXTERNA = 'Condição externa',
}
