import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import {
    IComunicacaoColetorController,
    IComunicacaoNuvemController,
} from 'app/layout/common/sincronizador-equipamento-historico';
import { ISincronizadorEquipamentoHistoricoInterface } from 'app/layout/common/sincronizador-equipamento-historico/interfaces';
import { SincronizadorEquipamentoHistoricoTipoEnum } from 'app/layout/common/sincronizador-equipamento-historico/enums';
import { ApiPaginacao, HistoricoSincronizadorModel } from 'app/shared/models';
import { delay } from 'rxjs/operators';

@Component({
    selector: 'app-sincronizador-equipamento-historico',
    templateUrl: './sincronizador-equipamento-historico.componente.html',
    styleUrls: ['./sincronizador-equipamento-historico.componente.scss'],
})
export class SincronizadorEquipamentoHistoricoComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<boolean>();
    tabelaColunas: string[] = ['data', 'status'];
    historico: HistoricoSincronizadorModel[];
    paginacao = new ApiPaginacao();
    carregando: boolean = true;
    carregandoDadosAdicionais: boolean = false;  
    sincronizadorEquipamentoHistoricoTipoEnum = SincronizadorEquipamentoHistoricoTipoEnum;  

    constructor(
        public readonly dialogRef: MatDialogRef<SincronizadorEquipamentoHistoricoComponent>,
        @Inject(MAT_DIALOG_DATA)
        public readonly dialogData: ISincronizadorEquipamentoHistoricoInterface,
        private readonly comunicacaoColetorController: IComunicacaoColetorController,
        private readonly comunicacaoNuvemController: IComunicacaoNuvemController,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.paginacao.limit = 10;
        this.paginacao.page = 1;
        this.buscarHistorico();
    }

    ngAfterOnInit(): void {
        this.changeDetectorRef.detectChanges();
    }

    onScroll(): void {
        if (this.paginacao.page == this.paginacao.length) return;

        this.paginacao.page += 1;
        this.buscarHistorico(true);
    }

    buscarHistorico(dadosAdicionais?: boolean): void {
        if (dadosAdicionais) this.carregandoDadosAdicionais = true; else this.carregando = true;
        this[
            this.dialogData.tipo === SincronizadorEquipamentoHistoricoTipoEnum.ColetorSincronizador
                ? 'comunicacaoColetorController'
                : 'comunicacaoNuvemController'
        ]
            .buscaHistorico(this.paginacao)
            .pipe(
                takeUntil(this.destroy$),
                delay(1000)
            )
            .subscribe({
                next: (registros) => {
                    this.paginacao.length = Number(registros.meta.totalPages);
                    if (this.paginacao.page > 1) {
                        registros.data = [...this.historico, ...registros.data];
                    }
                    this.historico = registros.data;                    
                },
                error: (error) => {                    
                    console.log('error: ', error);
                },
                complete: () => {
                    if (dadosAdicionais) this.carregandoDadosAdicionais = false; else this.carregando = false;    
                }
            });
    }


    ngOnDestroy(): void {
        this.destroy$.next(null);
        this.destroy$.complete();
    }
}
