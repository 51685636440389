import {
    ArmazemEquipamentoVisualizacaoInterface,
    ArmazemPassarelaVisualizacaoInterface,
    ArmazemPortaVisualizacaoInterface,
    DivisoesComPedulosInterface,
    PenduloVisaoSuperiorInterface,
} from 'app/shared/interfaces';
import { Injectable } from '@angular/core';
import * as d3 from 'd3';
export abstract class ICriaElementosVisuaisVisaoSuperiorArmazemService {
    abstract criaRetanguloArmazem(
        svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>,
        width: number,
        height: number
    ): void;
    abstract criaArcos(svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>, numroArcos: number): void;
    abstract criaLinhas(svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>, numroLinhas: number): void;
    abstract criaPortas(
        svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>,
        portas: ArmazemPortaVisualizacaoInterface[]
    ): void;
    abstract criaPassarelas(
        svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>,
        passarelas: ArmazemPassarelaVisualizacaoInterface[]
    ): void;
    abstract criaEquipamtos(
        svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>,
        equipamentos: ArmazemEquipamentoVisualizacaoInterface[]
    ): void;
}

@Injectable({ providedIn: 'root' })
export class CriaElementosVisuaisVisaoSuperiorArmazemService
    implements ICriaElementosVisuaisVisaoSuperiorArmazemService
{
    paddingSvgPaiX = 50;
    paddingSvgPaiY = 100;
    tamnahoFonte = 10;
    criaRetanguloArmazem(
        svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>,
        width: number,
        height: number
    ): void {
        svg.select('g')
            ?.append('rect')
            .attr('width', `${width - this.paddingSvgPaiX}`)
            .attr('height', `${height - this.paddingSvgPaiY}`)
            .attr('x', `${this.paddingSvgPaiX / 2}`)
            .attr('y', `${this.paddingSvgPaiY / 2}`)
            .attr('fill', 'white')
            .attr('stroke', '#535767')
            .attr('stroke-width', '1.5')
            .attr('id', 'retanguloArmazem');
    }
    criaArcos(svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>, numroArcos: number): void {
        const retanguloArmazem = d3.select('#retanguloArmazem');

        const margemY = parseFloat(retanguloArmazem.attr('y'));
        const margemX = parseFloat(retanguloArmazem.attr('x')) + 40;
        const espacoEntreArcos = (parseFloat(retanguloArmazem.attr('width')) - 2 * 40) / (numroArcos - 1);

        for (let i = 0; i < numroArcos; i++) {
            svg.select('g')
                ?.append('line')
                .attr('x1', `${margemX + i * espacoEntreArcos}`)
                .attr('x2', `${margemX + i * espacoEntreArcos}`)
                .attr('y1', `${margemY}`)
                .attr('y2', `${parseFloat(retanguloArmazem.attr('height')) + margemY}`)
                .attr('stroke', 'rgba(118, 129, 147, 0.80)')
                .attr('stroke-dasharray', '8 8')
                .attr('stroke-width', '1.5')
                .attr('id', `arco${i}`);

            const labelArco = `A ${i + 1}`;
            svg.select('g')
                ?.append('text')
                .attr('font-size', `${this.tamnahoFonte}`)
                .attr('stroke', 'rgba(118, 129, 147, 0.80)')
                .attr('stroke-width', '1')
                .attr('stroke-dasharray', '8 8')
                .attr('dominant-baseline', 'middle ')
                .attr('text-anchor', 'middle')
                .text(labelArco)
                .attr('id', `arcoLabel${i}`)
                .attr('transform', `translate(${margemX + i * espacoEntreArcos + 3},${margemY + 12}) rotate(90)`);
        }
    }

    criaLinhas(svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>, numroLinhas: number): void {
        const retanguloArmazem = d3.select('#retanguloArmazem');
        const margemY = parseFloat(retanguloArmazem.attr('y')) + 40;
        const margemX = parseFloat(retanguloArmazem.attr('x'));
        const espacoEntreLinhas = (parseFloat(retanguloArmazem.attr('height')) - 2 * 40) / (numroLinhas - 1);

        for (let i = 0; i < numroLinhas; i++) {
            svg.select('g')
                ?.append('line')
                .attr('x1', `${margemX}`)
                .attr('x2', `${parseFloat(retanguloArmazem.attr('width')) + margemX}`)
                .attr('y1', `${margemY + i * espacoEntreLinhas}`)
                .attr('y2', `${margemY + i * espacoEntreLinhas}`)
                .attr('stroke', 'rgba(118, 129, 147, 0.80)')
                .attr('stroke-dasharray', '8 8')
                .attr('stroke-width', '1.5')
                .attr('id', `linha${i}`);

            const labelLinha = `L ${i + 1}`;
            svg.select('g')
                ?.append('text')
                .attr('font-size', `${this.tamnahoFonte}`)
                .attr('stroke', 'rgba(118, 129, 147, 0.80)')
                .attr('stroke-width', '1')
                .attr('stroke-dasharray', '8 8')
                .attr('dominant-baseline', 'start ')
                .attr('text-anchor', 'start')
                .text(labelLinha)
                .attr('x', `${margemX + 5}`)
                .attr('y', `${margemY + i * espacoEntreLinhas - 3}`)
                .attr('id', `linhaLabel${i}`);
        }
    }
    criaPortas(
        svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>,
        portas: ArmazemPortaVisualizacaoInterface[]
    ): void {
        const svgPai = document.getElementById('svgPai').getBoundingClientRect();
        const retanguloArmazem = d3.select('#retanguloArmazem');

        const YInicial = parseFloat(retanguloArmazem.attr('y'));
        const XInicial = parseFloat(retanguloArmazem.attr('x'));
        const margemYInicial = (YInicial / svgPai.height) * 100;
        portas.forEach((porta, index) => {
            const porta_x =
                porta.posicao_x < 50 ? XInicial - 2 : parseFloat(retanguloArmazem.attr('width')) + XInicial + 2;
            svg.select('g')
                ?.append('line')
                .attr('stroke', '#4CA0E0')
                .attr('stroke-width', '4')
                .attr('x1', `${porta_x}`)
                .attr('x2', `${porta_x}`)
                .attr('y1', `${porta.posicao_y - 3}%`)
                .attr('y2', `${porta.posicao_y + 9}%`)
                .attr('id', `porta${index}`);
            const posicao_y_px = (porta.posicao_y * svgPai.height) / 100;
            svg.select('g')
                ?.append('text')
                .attr('font-size', `${this.tamnahoFonte}`)
                .attr('dominant-baseline', 'middle')
                .attr('text-anchor', 'middle')
                .attr('fill', '#535767')
                .text('PORTA')
                .attr(
                    'transform',
                    `translate(${
                        porta.posicao_x < 50
                            ? XInicial - 10
                            : parseFloat(retanguloArmazem.attr('width')) + XInicial + 12
                    },${posicao_y_px + margemYInicial + 12}) rotate(270)`
                );
        });
    }
    criaPassarelas(
        svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>,
        passarelas: ArmazemPassarelaVisualizacaoInterface[]
    ): void {
        const svgPai = document.getElementById('svgPai').getBoundingClientRect();
        const retanguloArmazem = d3.select('#retanguloArmazem');

        const YInicial = parseFloat(retanguloArmazem.attr('y'));
        const XInicial = parseFloat(retanguloArmazem.attr('x'));
        const margemYInicial = (YInicial / svgPai.height) * 100;
        const height = 55;
        const centralizarTextoY = (2 / height) * 100 * 2;

        passarelas.forEach((passarela, index) => {
            svg.select('g')
                ?.append('rect')
                .attr('width', `${retanguloArmazem.attr('width')}`)
                .attr('height', `${height}`)
                .attr('stroke', '#535767')
                .attr('stroke-width', '1.5')
                .attr('fill', 'rgba(100, 116, 139, 0.1)')
                .attr('x', `${XInicial}`)
                .attr('y', `${passarela.posicao_y}%`)
                .attr('id', `passarela${index}`);
            svg.select('g')
                ?.append('text')
                .attr('font-size', `${this.tamnahoFonte}`)
                .attr('dominant-baseline', 'middle')
                .attr('text-anchor', 'middle')
                .attr('fill', '#535767')
                .text('PASSARELA')
                .attr('x', `${parseFloat(retanguloArmazem.attr('width')) / 2}`)
                .attr('y', `${passarela.posicao_y + margemYInicial - centralizarTextoY}%`);
        });
    }
    criaEquipamtos(
        svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>,
        equipamentos: ArmazemEquipamentoVisualizacaoInterface[]
    ): void {
        equipamentos.forEach((equipamento, index) => {
            if (!equipamento?.nome?.length) return;
            svg.select('g')
                ?.append('rect')
                .attr('width', `${equipamento?.nome?.length * 6 + this.tamnahoFonte}`)
                .attr('height', '30')
                .attr('stroke', '#535767')
                .attr('stroke-width', '1.5')
                .attr('fill', 'white')
                .attr('x', `${equipamento?.posicao_x}%`)
                .attr('y', `${equipamento?.posicao_y}%`)
                .attr('rx', '4')
                .attr('ry', '4')
                .attr('cursor', 'pointer')
                .attr('id', `equipamento${index}`);
            const svgEquipamento = d3.select(`#equipamento${index}`);
            svgEquipamento.on('click', (e) => {
                this.highlightPendulos(equipamento?.equipamento_id);
                this.highlightEquipamentos(svgEquipamento);
            });

            const centralizarTextoX =
                (2 / parseFloat(svgEquipamento.attr('width')) / parseFloat(svgEquipamento.attr('width'))) * 100;
            const centralizarTextoY =
                (2 / parseFloat(svgEquipamento.attr('height')) / parseFloat(svgEquipamento.attr('height'))) * 100;

            svg.select('g')
                ?.append('text')
                .attr('font-size', `${this.tamnahoFonte}`)
                .attr('dominant-baseline', 'central')
                .attr('fill', '#535767')
                .text(equipamento.nome)
                .attr('x', `${equipamento.posicao_x + centralizarTextoX * 10}%`)
                .attr('id', `equipamentoLabel${index}`)
                .attr('cursor', 'pointer')
                .attr('y', `${equipamento.posicao_y + centralizarTextoY * 10}%`);

            const svgLabelEquipamento = d3.select(`#equipamentoLabel${index}`);
            svgLabelEquipamento.on('click', (e) => {
                this.highlightPendulos(equipamento.equipamento_id);
                this.highlightEquipamentos(svgEquipamento);
            });
        });
    }

    highlightPendulos(id: string): void {
        const pendulo = d3.selectAll(`${'.equip' + id}`);
        if (pendulo.data().length === 0) return;
        if (pendulo.attr('filter') && pendulo.attr('filter') != 'none') {
            pendulo.attr('filter', 'none');
        } else {
            pendulo.attr('filter', 'drop-shadow(0px 0px 4px #019DE8)');
        }
    }

    highlightEquipamentos(svgEquipamento: d3.Selection<d3.BaseType, unknown, HTMLElement, any>): void {
        if (svgEquipamento.attr('filter') && svgEquipamento.attr('filter') != 'none') {
            svgEquipamento.attr('filter', 'none');
        } else {
            svgEquipamento.attr('filter', 'drop-shadow(0px 0px 4px #019DE8)');
        }
    }
}
