import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { PrimeiroAcessoObservable } from 'app/modules/auth/observables';

@Injectable({ providedIn: 'root' })
export class PrimeiroAcessoGuarda implements CanActivate, CanActivateChild {
    constructor(private readonly router: Router, private primeiroAcessoObservable$: PrimeiroAcessoObservable) {}

    public canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        return new Promise((res) => {
            const url: string = localStorage.getItem('urlAtual');
            if (url === 'sign-in-first') {
                res(true);
            } else {
                this.primeiroAcessoObservable$.buscaPrimeiroAcessoObservable().subscribe((obrigarResetarSenha) => {
                    if (obrigarResetarSenha) {
                        res(true);
                    } else {
                        if (!url || url === '/primeiro-acesso') {
                            this.router.navigate(['/signed-in-redirect']);
                        } else {
                            this.router.navigate([url]);
                        }
                    }
                });
            }
        });
    }

    public canActivateChild(): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivate();
    }
}
