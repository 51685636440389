export interface ParamsGestaoUnidadesInterface {
    unidades?: string[];
    cliente_id?: string;
    produto_tipos?: number[];
    dataInicio?: string;
    dataFim?: string;
    filterSortBy?: string;
    page?: number;
    limit?: number;
    moeda?: string;
    categoria?: string
    classificador?: string[]
    codigo?: string;
    operacao?: string[];
    placa?: string;
}
export interface ParamsAeracaoGrosFilterInterface {
    cliente_id?: string;
    unidades?: string[];
    produto_tipos?: number[];
}

