export interface EquipamentosInterface {
    id: number;
    nome: string;
    categoria: string;
    modelo: string;
    equipamento_comunicacao: EquipamentoComunicacaoInterface;
    estrutura_armazenagem_divisao: EstruturaArmazenagemDivisaoInterface[];
}

export interface EstruturaArmazenagemDivisaoInterface {
    id: number;
    nome: string;
    estrutura_armazenagem: EstruturaArmazenagemInterface;
}

export interface EstruturaArmazenagemInterface {
    id: number;
    nome: string;
    categoria: string;
}
export interface EquipamentoComunicacaoInterface {
    equipamento_id: string;
    instante: string;
    status: boolean;
}

export interface SiloInterface {
    id: number;
    nome: string;
    equipamento_ref: number;
}
