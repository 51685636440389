export interface outrosSecadoresInterface {
    id: string;
    instante: string;
    secador: Secador;
    unidade: Unidade;
    produto_tipo: ProdutoTipo;
    modo_operacao: string;
    limpeza: Limpeza;
    ultimaSecagem: ultimaSecagem;
}
interface Unidade {
    id: string;
    categoria: string;
    nome: string;
}
interface ProdutoTipo {
    id: number;
    nome: string;
}
interface Secador {
    id: string;
    codigo: number;
    modelo_painel: string;
    nome: string;
    operando: boolean;
    intervalo_limpeza: number;
}
interface Limpeza {
    total: number;
    atual: number;
}

interface ultimaSecagem{
    duracao: string | null;
    termino: string | null
}
export interface GraficoOutrosSecadores {
    velocidade_de_descarga: ValoresGraficoOutrosSecadores[];
    eficiencia_do_secador: ValoresGraficoOutrosSecadores[];
    consumo_de_combustivel: ValoresGraficoOutrosSecadores[];
    medidor_de_fluxo: ValoresGraficoOutrosSecadores[];
    medidor_de_fluxo_2: ValoresGraficoOutrosSecadores[];
    pressao_da_entrada_de_ar: ValoresGraficoOutrosSecadores[];
    pressao_da_saida_de_ar: ValoresGraficoOutrosSecadores[];
}
interface ValoresGraficoOutrosSecadores {
    horario: string;
    valor: number;
}

export interface dadosOrdemCrescenteOutrosSecadores {
    limpeza: string | null;
}
export interface tipoOrdemOutrosSecadores {
    active: string;
    direction: string;
}

export interface dadosParaTooltipSecadoresInterface {
    idEmSecagem: string,
    clienteSelecionado: string,
}
