export interface EstoqueDisponivelNegociarResponseInterface {
    data: EstoqueDataInterface[];
    meta: MetaInterface;
    links: LinksInterface;
}

export interface EstoqueDataInterface {
    estoqueTotalAtualPorGrao: EstoquePorGraoInterface[];
    totalizadores: TotalizadoresInterface;
}

export interface EstoquePorGraoInterface {
    produto_tipo: GraoHistoricoInterface;
    estoque: EstoqueInterfaceNegociar;
    historico: HistoricoInterface;
}

export interface GraoHistoricoInterface {
    id: number;
    nome: string;
}

export interface EstoqueInterfaceNegociar {
  }

  export interface EstoqueDataInterface {
    estoqueTotalAtualPorGrao: EstoquePorGraoInterface[];
    totalizadores: TotalizadoresInterface;
  }

  export interface EstoqueInterfaceNegociar {
    total: number;
    fixadoEmContrato: number;
    disponivel: number;
    valorDisponivel: number;
}

export interface HistoricoInterface {
    anterior: AnteriorInterface;
    atual: AtualInterface;
}

export interface AnteriorInterface {
  }

  export interface HistoricoInterface {
    instante: string;
    unidadeMedidaEstoque: string;
    fixadoEmContrato: number;
    disponivel: number;
    valorDisponivel: number;
}

export interface AtualInterface {
    instante: string;
    unidadeMedidaEstoque: string;
    fixadoEmContrato: number;
    disponivel: number;
    valorDisponivel: number;
}

export interface TotalizadoresInterface {
  }

  export interface TotalizadoresInterface {
    total: number;
    fixadoEmContrato: number;
    disponivel: number;
    valorDisponivel: number;
}

export interface MetaInterface {
    totalItems: number;
    totalPages: number;
    currentPage: number;
}

export interface LinksInterface {
  }

  export interface MetaInterface {
    totalItems: number;
    totalPages: number;
    currentPage: number;
  }

  export interface LinksInterface {
    first: string;
    previous: string;
    current: string;
    next: string;
    last: string;
}
