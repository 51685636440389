import { Injectable } from '@angular/core';
export abstract class ICalculaAqplenumService {
    abstract execute(sensorTemperatura: number, estacaoTemperatura): number;
}

@Injectable({ providedIn: 'root' })
export class CalculaAqplenumService implements ICalculaAqplenumService {
    execute(sensorTemperatura: number, estacaoTemperatura): number {
        return sensorTemperatura - estacaoTemperatura;
    }
}
