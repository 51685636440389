export enum EstruturaAmostragemLeituraDescargaEnum {
    CENTRAL = 'central',
    DIREITA = 'direita',
    ESQUERDA = 'esquerda',
}

export enum EstruturaAmostragemLeituraDescargaLabelEnum {
    CENTRAL = 'Central',
    DIREITA = 'Lateral Direita',
    ESQUERDA = 'Lateral Esquerda',
}
