import { Route } from '@angular/router';
import { InitialDataResolver } from 'app/app.resolvers';
import { BloqueiaFuncionalidadeAmbienteLocalGuard, NoAuthGuard } from 'app/core/auth/guards';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { PrimeiroAcessoGuarda, CanUseSystemGuard } from 'app/modules/auth/guards';
import { SemPermissaoAcessoComponent } from 'app/core';

export const appRoutes: Route[] = [
    {
        path: '',
        redirectTo: 'armazenagem/visualizacao/painel-geral',
        pathMatch: 'full',
    },

    {
        path: 'signed-in-redirect',
        redirectTo: 'armazenagem/visualizacao/painel-geral',
        pathMatch: 'full',
    },

    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-in',
                loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then((m) => m.AuthSignInModule),
            },
            {
                path: 'mapa-contatos',
                loadChildren: () =>
                    import('app/modules/auth/mapa-contatos/mapa-contatos.module').then((m) => m.MapaContatosModule),
            },
        ],
    },

    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.module').then((m) => m.AuthSignOutModule),
            },
        ],
    },
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'page-builder',
                loadChildren: () =>
                    import('app/modules/landing/page-builder/page-builder.module').then((m) => m.PageBuilderModule),
            },
        ],
    },
    {
        path: '',
        canActivateChild: [AuthGuard, PrimeiroAcessoGuarda],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'primeiro-acesso',
                loadChildren: () =>
                    import('app/modules/auth/primeiro-acesso/primeiro-acesso.module').then(
                        (m) => m.AutorizacaoPrimeiroAcessoModule
                    ),
            },
        ],
    },
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        children: [
            {
                path: 'sem-acesso',
                component: SemPermissaoAcessoComponent,
            },
        ],
    },
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard, CanUseSystemGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'armazenagem',
                pathMatch: 'full',
                redirectTo: 'armazenagem/visualizacao',
            },
            {
                path: 'armazenagem/visualizacao',
                loadChildren: () =>
                    import('app/modules/armazenagem/visualizacao/visualizacao.module').then(
                        (m) => m.ArmazenagemVisualizacaoModule
                    ),
            },
            {
                path: 'armazenagem/analise',
                loadChildren: () =>
                    import('app/modules/armazenagem/analise/analise.module').then((m) => m.ArmazenagemAnaliseModule),
            },
            {
                path: 'armazenagem/cadastro',
                loadChildren: () =>
                    import('app/modules/armazenagem/cadastro/cadastro.module').then((m) => m.ArmazenagemCadastroModule),
            },
            {
                path: 'armazenagem/configuracao',
                loadChildren: () =>
                    import('app/modules/armazenagem/configuracao/configuracao.module').then(
                        (m) => m.ArmazenagemConfiguracaoModule
                    ),
            },
        ],
    },

    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard, CanUseSystemGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'secador',
                pathMatch: 'full',
                redirectTo: 'secador/visualizacao',
            },
            {
                path: 'secador/visualizacao',
                loadChildren: () =>
                    import('app/modules/secador/visualizacao/visualizacao.module').then(
                        (m) => m.SecadorVisualizacaoModule
                    ),
            },
            {
                path: 'secador/cadastro',
                loadChildren: () =>
                    import('app/modules/secador/cadastro/cadastro.module').then((m) => m.SecadorCadastroModule),
            },
            {
                path: 'secador/analise',
                loadChildren: () =>
                    import('app/modules/secador/analise/analise.module').then((m) => m.SecadorAnaliseModule),
            },
            {
                path: 'secador/analise',
                loadChildren: () =>
                    import('app/modules/secador/analise/graficos/graficos.module').then((m) => m.GraficosModule),
            },
        ],
    },
    {
        path: '',
        canActivate: [AuthGuard, BloqueiaFuncionalidadeAmbienteLocalGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'gestao-unidades',
                loadChildren: () =>
                    import('app/modules/gestao-unidades/gestao-unidades.module').then((m) => m.GestaoUnidadesModule),
            },
        ],
    },
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard, CanUseSystemGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'configuracoes',
                loadChildren: () =>
                    import('app/modules/configuracoes/configuracoes.module').then((m) => m.ConfiguracoesModule),
            },
        ],
    },
];
