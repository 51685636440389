export const MENUS_SECAGEM_ES = [
    {
        menu: 'SECADORA',
        description: 'SECADORA',
        link: '#',
        icon: 'icon-module-secador',
        separator: false,
        permission: true,
        groups: [
            {
                title: 'Visualización',
                icon: 'grid_view',
                separator: true,
                items: [
                    {
                        menu: 'Secadoras',
                        icon: 'icon-module-secador',
                        link: 'secador/visualizacao/visao-geral-secadores',
                    },
                ],
            },
            {
                title: 'Análisis',
                icon: 'query_stats',
                separator: true,
                items: [
                    {
                        menu: 'Reportes',
                        icon: 'insert_chart',
                        childrensItems: [
                            {
                                menu: 'Sensores',
                                icon: 'mat_solid:device_thermostat',
                                link: 'secador/analise/relatorios/sensores',
                            },
                            {
                                menu: 'Operaciones',
                                icon: 'icon-operacoes',
                                link: 'secador/analise/relatorios/operacoes-secador',
                            },
                            {
                                menu: 'Limpieza',
                                icon: 'icon-limpeza',
                                link: 'secador/analise/relatorios/limpeza-secador',
                            },
                            {
                                menu: 'Alertas',
                                icon: 'icon-menu-alertas',
                                link: 'secador/analise/relatorios/alertas',
                            },
                        ],
                    },
                    {
                        menu: 'Gráficos',
                        icon: 'area_chart',
                        childrensItems: [
                            {
                                menu: 'Sensores y operaciones',
                                icon: 'icon-grafico-base-dupla',
                                link: 'secador/analise/graficos/sensores-operacoes',
                            },
                        ],
                    },
                ],
            },
            {
                title: 'Registro',
                icon: 'article',
                separator: true,
                items: [
                    {
                        menu: 'Alertas',
                        icon: 'icon-menu-alertas',
                        link: 'secador/cadastro/alertas',
                    },
                ],
            },
        ],
    },
];
