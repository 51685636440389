import { Injectable } from '@angular/core';

import { MensagensErroMapaArmazenagemEnum } from 'app/shared/enums';
export abstract class IValidaMapaArmazenagemService {
    abstract execute(mapa: any): void;
}

@Injectable({ providedIn: 'root' })
export class ValidaMapaArmazenagemService implements IValidaMapaArmazenagemService {
    execute(mapa: any): void {
        this.validadaQuantidadeDePendulos(mapa);
        this.validadeQuantidadeDeSensores(mapa);
    }

    private validadaQuantidadeDePendulos(mapa: any): void {
        if (!mapa) return;

        const pendulosConfigTemperatura = this.retornarQuantidadePendulosConfigurados(
            mapa?.sensor_quantidade_temperatura
        );
        const pendulosLeituraTemperatura = this.retornarQuantidadePendulosLeitura(
            mapa?.temperatura ?? mapa?.sensor_nivel_probabilidade
        );
        const pendulosNivelTemperatura = this.retornarQuantidadePendulosConfigurados(mapa.sensor_nivel_temperatura);
        const pendulosConfigUmidade = this.retornarQuantidadePendulosConfigurados(mapa.sensor_quantidade_umidade);
        const pendulosLeituraUmidade = this.retornarQuantidadePendulosLeitura(mapa.umidade_relativa);
        const pendulosNivelUmidade = this.retornarQuantidadePendulosConfigurados(mapa.sensor_nivel_umidade);
        const pendulosLeituraEquilibrio = this.retornarQuantidadePendulosLeitura(mapa.equilibrio_higroscopico);
        try {
            if (pendulosConfigTemperatura !== pendulosLeituraTemperatura) {
                throw new Error(
                    MensagensErroMapaArmazenagemEnum.PENDULOS_TEMPERATURA.replace(
                        'pendulosConfigTemperatura',
                        `${pendulosLeituraTemperatura}`
                    ).replace('pendulosLeituraTemperatura', `${pendulosLeituraTemperatura}`)
                );
            }
            if (pendulosConfigTemperatura !== pendulosNivelTemperatura) {
                throw new Error(
                    MensagensErroMapaArmazenagemEnum.PENDULOS_TEMPERATURA_NIVEL.replace(
                        'pendulosConfigTemperatura',
                        `${pendulosConfigTemperatura}`
                    ).replace('pendulosNivelTemperatura', `${pendulosNivelTemperatura}`)
                );
            }
            if (pendulosConfigUmidade) {
                if (pendulosConfigUmidade !== pendulosLeituraUmidade) {
                    throw new Error(
                        MensagensErroMapaArmazenagemEnum.PENDULOS_UMIDADE.replace(
                            'pendulosConfigUmidade',
                            `${pendulosConfigUmidade}`
                        ).replace('pendulosLeituraUmidade', `${pendulosLeituraUmidade}`)
                    );
                }
                if (pendulosConfigUmidade !== pendulosNivelUmidade) {
                    throw new Error(
                        MensagensErroMapaArmazenagemEnum.PENDULOS_UMIDADE_NIVEL.replace(
                            'pendulosConfigUmidade',
                            `${pendulosConfigUmidade}`
                        ).replace('pendulosNivelUmidade', `${pendulosNivelUmidade}`)
                    );
                }
                if (pendulosConfigUmidade !== pendulosLeituraEquilibrio) {
                    throw new Error(
                        MensagensErroMapaArmazenagemEnum.PENDULOS_EQUILIBRIO.replace(
                            'pendulosConfigUmidade',
                            `${pendulosConfigUmidade}`
                        ).replace('pendulosLeituraEquilibrio', `${pendulosLeituraEquilibrio}`)
                    );
                }
            }
        } catch (error) {
            if (error?.message) {
                console.log(error.message);
            }
        }
    }

    private validadeQuantidadeDeSensores(mapa): void {
        if (!mapa) return;

        const sensoresTemperaturaConfig = this.retornaQuantidadeDeSensoresConfgPorPendulo(
            mapa?.sensor_quantidade_temperatura
        );
        const sensoresTemperaturaLeitura = this.retornaQuantidadeDeSensoresLeituraPorPendulo(
            mapa?.temperatura ?? mapa?.sensor_nivel_probabilidade
        );
        const sensoresTemperaturaNivel = this.retornaQuantidadeDeSensoresConfgPorPendulo(mapa.sensor_nivel_temperatura);

        const sensoresUmidadeConfig = this.retornaQuantidadeDeSensoresConfgPorPendulo(mapa.sensor_quantidade_umidade);
        const sensoresUmidadeLeitura = this.retornaQuantidadeDeSensoresLeituraPorPendulo(mapa.umidade_relativa);
        const sensoresUmidadeNivel = this.retornaQuantidadeDeSensoresConfgPorPendulo(mapa.sensor_nivel_umidade);

        const sensoresEquilibrioLeitura = this.retornaQuantidadeDeSensoresLeituraPorPendulo(
            mapa.equilibrio_higroscopico
        );

        try {
            for (let i = 0; i < sensoresTemperaturaConfig.length; i++) {
                if (sensoresTemperaturaConfig[i] !== sensoresTemperaturaLeitura[i]) {
                    throw new Error(
                        MensagensErroMapaArmazenagemEnum.SENSORES_TEMPERATURA.replace(
                            'sensoresTemperaturaConfig',
                            `${sensoresTemperaturaConfig[i]}`
                        )
                            .replace('${i}', `${i + 1}`)
                            .replace('sensoresTemperaturaLeitura', `${sensoresTemperaturaLeitura[i]}`)
                            .replace('${i}', `${i + 1}`)
                    );
                }
                if (sensoresTemperaturaConfig[i] < sensoresTemperaturaNivel[i]) {
                    throw new Error(
                        MensagensErroMapaArmazenagemEnum.SENSORES_TEMPERATURA_NIVEL.replace(
                            'sensoresTemperaturaConfig',
                            `${sensoresTemperaturaConfig[i]}`
                        )
                            .replace('${i}', `${i + 1}`)
                            .replace('sensoresTemperaturaNivel', `${sensoresTemperaturaNivel[i]}`)
                            .replace('${i}', `${i + 1}`)
                    );
                }
                if (sensoresUmidadeConfig) {
                    if (sensoresUmidadeConfig[i] !== sensoresUmidadeLeitura[i]) {
                        throw new Error(
                            MensagensErroMapaArmazenagemEnum.SENSORES_UMIDADE.replace(
                                'sensoresUmidadeConfig',
                                `${sensoresUmidadeConfig[i]}`
                            )
                                .replace('${i}', `${i + 1}`)
                                .replace('sensoresUmidadeLeitura', `${sensoresUmidadeLeitura[i]}`)
                                .replace('${i}', `${i + 1}`)
                        );
                    }
                    if (sensoresUmidadeConfig[i] < sensoresUmidadeNivel[i]) {
                        throw new Error(
                            MensagensErroMapaArmazenagemEnum.SENSORES_UMIDADE_NIVEL.replace(
                                'sensoresUmidadeConfig',
                                `${sensoresUmidadeConfig[i]}`
                            )
                                .replace('${i}', `${i + 1}`)
                                .replace('sensoresUmidadeNivel', `${sensoresUmidadeNivel[i]}`)
                                .replace('${i}', `${i + 1}`)
                        );
                    }
                    if (sensoresUmidadeConfig[i] !== sensoresEquilibrioLeitura[i]) {
                        throw new Error(
                            MensagensErroMapaArmazenagemEnum.SENSORES_EQUILIBRIO.replace(
                                'sensoresUmidadeConfig',
                                `${sensoresUmidadeConfig[i]}`
                            )
                                .replace('${i}', `${i + 1}`)
                                .replace('sensoresEquilibrioLeitura', `${sensoresEquilibrioLeitura[i]}`)
                                .replace('${i}', `${i + 1}`)
                        );
                    }
                }
            }
        } catch (error) {
            if (error?.message) {
                console.log(error.message);
            }
        }
    }

    private retornarQuantidadePendulosConfigurados(pendulosConfig: any): number | undefined {
        return pendulosConfig?.split(';').length;
    }

    private retornarQuantidadePendulosLeitura(pendulosLeitura: any): number | undefined {
        return pendulosLeitura?.split('|').length;
    }

    private retornaQuantidadeDeSensoresConfgPorPendulo(pendulosConfig: any): any | undefined {
        return pendulosConfig?.split(';').map((pendulo: any) => parseInt(pendulo));
    }

    private retornaQuantidadeDeSensoresLeituraPorPendulo(pendulosLeitura: any): any | undefined {
        return pendulosLeitura?.split('|').map((pendulo: any) => pendulo?.split(';').length);
    }
}
