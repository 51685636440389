import { Injectable } from '@angular/core';
import { IApiTransportInterface } from 'app/core/interfaces';
import { ApiTransportService } from 'app/core/services/transport';
import { Observable } from 'rxjs';
import { ListaClientesInterface, ResponseInterface } from 'app/shared/interfaces';
import { GrupoClientesModel } from 'app/layout/common/header-select-customer/models';

export abstract class IClientesController {
    abstract buscarClientes(pesquisa?: string): Observable<ResponseInterface<ListaClientesInterface>>;
    abstract buscarClientesPorUsuarioId(usuarioId: string): Observable<ResponseInterface<ListaClientesInterface>>;
    abstract buscarClientesEUnidadesPorUsuario(): Observable<GrupoClientesModel[]>;
}

@Injectable()
export class ClientesController implements IClientesController {
    private transport: IApiTransportInterface;

    constructor(public readonly apiTransportService: ApiTransportService) {
        this.transport = this.apiTransportService.execute('/geral');
    }

    buscarClientesPorUsuarioId(usuarioId: string): Observable<any> {
        return this.transport.http.get(`${this.transport.path}/cliente/usuario/${usuarioId}`);
    }

    buscarClientes(pesquisa?: string): Observable<any> {
        const filtro = pesquisa ? `?search=${pesquisa}` : '';
        return this.transport.http.get(`${this.transport.path}/cliente${filtro}`);
    }

    buscarClientesEUnidadesPorUsuario(): Observable<any> {
        return this.transport.http.get(`${this.transport.path}/cliente/unidade/usuario`);
    }
}
