import { Injectable } from '@angular/core';
import { IApiTransportInterface } from 'app/core/interfaces';
import { ApiTransportService } from 'app/core/services/transport';
import { GraoNaEstruturaArmazenagem } from 'app/shared/interfaces';
import { Observable } from 'rxjs';

export abstract class IListaArmazensComSubcelulasController {
    abstract buscarListagem(): Observable<any>;
}

@Injectable()
export class ListaArmazensComSubcelulasController implements IListaArmazensComSubcelulasController {
    private transport: IApiTransportInterface;

    constructor(public readonly apiTransportService: ApiTransportService) {
        this.transport = this.apiTransportService.execute(
            '/armazenagem/estrutura-armazenagem/estrutura-armazenagem-divisao/armazem'
        );
    }

    buscarListagem(): Observable<any> {
        return this.transport.http.get(`${this.transport.path}`);
    }
}
