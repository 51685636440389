import { UntypedFormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';

import { TipoHorarioEnum } from 'app/modules/armazenagem/analise/relatorio-sensor-co2/enums';

export abstract class IFormatarTempoService {
    abstract execute(arrays: UntypedFormGroup): string;
}

@Injectable({
    providedIn: 'root',
})
export class FormatarTempoService implements IFormatarTempoService {
    constructor() {}

    execute(formGroup: UntypedFormGroup): string {
        if (formGroup.get('tipo_registro').value === TipoHorarioEnum.INTERVALO_TEMPO) {
            return formGroup.get('tempo').value;
        } else {
            const dataAtual = new Date();
            const timeZoneEmHoras = dataAtual.getTimezoneOffset() / 60;

            const [horas, minutos] = formGroup.get('tempo').value.split(':').map(Number);

            let novaHora = horas + timeZoneEmHoras;

            if (novaHora >= 24) {
                novaHora -= 24;
            }

            return `${novaHora.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}`;
        }
    }
}
