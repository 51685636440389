export enum AeradorStatuSodeEnum {
    COD_0A = '0A',
    COD_0B = '0B',
    COD_1 = '-1',
    COD_1A = '1A',
    COD_1B = '1B',
    COD_2A = '2A',
    COD_2B = '2B',
    COD_3A = '3A',
    COD_3B = '3B',
    COD_101 = '101',
    COD_102 = '102',
    COD_103 = '103',
    COD_104 = '104',
    COD_105 = '105',
    COD_106 = '106',
    COD_107 = '107',
    COD_201 = '201',
    COD_202 = '202',
    COD_203 = '203',
    COD_204 = '204',
    COD_205 = '205',
    COD_206 = '206',
    COD_207 = '207',
    COD_401 = '401',
    COD_402 = '402',
}
