import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';

import { ClassicService } from 'app/layout/layouts/vertical/classic/service';
import { AsideMenuObservable, InformacaoesPaginaObservable } from 'app/shared/observables';
import { environment } from 'environments/environment';
import { Menu } from 'app/core/aside-menu/models/menu.model';
import { AsideMenuService } from 'app/core/aside-menu/service';

@Component({
    selector: 'app-aside-menu',
    templateUrl: './aside-menu.component.html',
    styleUrls: ['./aside-menu.component.scss'],
})
export class AsideMenuComponent implements OnInit, OnDestroy {
    modulos$: Observable<any[] | undefined>;
    menus$: Observable<any[] | undefined>;
    testEnviromentMode = false;
    menuAberto = false;
    moduloSelecionado: Menu | undefined;
    menuModuloAberto = false;
    abrirMenuModuloSelecionado: boolean;
    logoMenuFechadoUrl: string = environment.whiteLabel.logoMenuFechadoUrl;
    logoMenuAbertoUrl: string = environment.whiteLabel.logoMenuAbertoUrl;

    get whiteLabelCliente(): string {
        return localStorage.getItem('whiteLabelCliente');
    }

    private unsubscribeAll: Subject<any> = new Subject<any>();
    constructor(
        private menuService: AsideMenuService,
        private asideMenuObservable: AsideMenuObservable,
        readonly classicService: ClassicService,
        private pageInfo: InformacaoesPaginaObservable
    ) {}

    ngOnInit(): void {
        this.testEnviromentMode = !environment.production;
        this.modulos$ = this.asideMenuObservable.modulosSubject.asObservable();
        this.menus$ = this.asideMenuObservable.menusSubject.asObservable();
        this.menuService.carregarMenus();

        this.asideMenuObservable.moduloSelecionadoSubject.pipe(takeUntil(this.unsubscribeAll)).subscribe((module) => {
            if (!module) {
                if (!this.buscaModuloSelecionadoNoLocalStorage()) {
                    this.selecionarModulo(this.menuService.selecionarModuloInicial());
                } else {
                    this.selecionarModulo(this.buscaModuloSelecionadoNoLocalStorage());
                }
                this.asideMenuObservable.menuMinimizadoSubject.next(true);
            }
        });

        this.asideMenuObservable.menuMinimizadoSubject.pipe(takeUntil(this.unsubscribeAll)).subscribe((hide) => {
            this.menuAberto = hide;
            this.menuModuloAberto = hide;
            this.abrirMenuModuloSelecionado = false;
        });
    }

    telaMobile(): boolean {
        return window.screen.width < 600;
    }

    selecionarModulo(menu: any): void {
        this.moduloSelecionado = menu.menu;
        this.menuService.proximoMenu(menu);
        this.asideMenuObservable.menuMinimizadoSubject.next(false);
        this.setaModuloSelecionadoNoLocalStorage(menu);
        this.abrirMenuModuloSelecionado = true;
    }

    selecionarMenu(item, menu?): void {
        this.buscaModuloSelecionadoNoLocalStorage().menu;
        if (item.link !== 'page-builder') {
            this.setaItemMenuSelecionadoNoLocalStorage(item);
        }

        this.pageInfo.setaMenuSelecionadoModal = menu.menu;
        this.pageInfo.setaSubMenuSelecionadoModal = item.menu;

        this.classicService.fecharMenuSobreposto();
        this.abrirMenuModuloSelecionado = false;
    }

    toggleSubMenu(index: number, item: string): void {
        const divSubItens: any = document.getElementById(`subItens${index + item}`);
        const arrowSubItens = document.getElementById(`arrow${index + item}`);
        if (divSubItens?.classList.contains('subMenu')) {
            divSubItens?.classList.remove('subMenu');
            arrowSubItens?.classList.add('rotated');
        } else {
            arrowSubItens?.classList.remove('rotated');
            divSubItens?.classList.add('subMenu');
        }
    }

    fecharMenu(): void {
        if (!this.menuAberto) {
            this.classicService.fecharMenuSobreposto();
            this.abrirMenuModuloSelecionado = false;
        }
    }

    setaModuloSelecionadoNoLocalStorage(menu): void {
        localStorage.setItem('ultimoModuloSelecionadoTitulo', JSON.stringify(menu));
    }

    setaItemMenuSelecionadoNoLocalStorage(item): void {
        localStorage.setItem('ultimoMenuSelecioandoTitulo', JSON.stringify(item));
    }

    buscaModuloSelecionadoNoLocalStorage(): Menu {
        const module = localStorage.getItem('ultimoModuloSelecionadoTitulo');
        return JSON.parse(module);
    }

    buscaItemMenuSelecionadoNoLocalStorage(): Menu {
        const item = localStorage.getItem('ultimoMenuSelecioandoTitulo');
        return JSON.parse(item);
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
