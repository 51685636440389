export interface ResponseInterface<T> {
    data: T[];
    meta?: MetaResponseInterface;
    links?: LinksResponseInterface;
    dataInicio?: string;
    dataFim?: string;
}
export interface ResponseInterfaceAeracaoGraos<T> {
    data: T;
    meta?: MetaResponseInterface;
    links?: LinksResponseInterface;
}
export interface MetaResponseInterface {
    itemsPerPage: number;
    totalItems: number;
    currentPage: number;
    totalPages: number;
    sortBy: string[][];
}

export interface LinksResponseInterface {
    current: string;
}
