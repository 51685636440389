import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { AlertModule } from 'app/core/alert/alert.module';

import { AsideMenuModule } from 'app/core/aside-menu/aside-menu.module';
import { MessagesModule } from 'app/layout/common/messages/messages.module';
import { SincronizadorEquipamentoModule } from 'app/layout/common/sincronizador-equipamento';
import { SincronizadorEquipamentoHistoricoModule } from 'app/layout/common/sincronizador-equipamento-historico';
import { UserModule } from 'app/layout/common/user/user.module';
import { ClassicLayoutComponent } from 'app/layout/layouts/vertical/classic/classic.component';
import { AlertaService, IAlertaService } from 'app/shared/services';
import { SharedModule } from 'app/shared/shared.module';
import { HeaderSelectCustomerModule } from './../../../common/header-select-customer/header-select-customer.module';
import { PageInfoModule } from './../../../common/page-info/page-info.module';
import { TranslocoRootModule } from 'app/transloco-root.module';
import { LoadingBarModule } from 'app/core/loading-bar/loading-bar.module';

@NgModule({
    declarations: [ClassicLayoutComponent],
    imports: [
        HttpClientModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        LoadingBarModule,        
        AlertModule,
        MessagesModule,
        UserModule,
        SharedModule,
        AsideMenuModule,
        HeaderSelectCustomerModule,
        PageInfoModule,
        SincronizadorEquipamentoModule,
        SincronizadorEquipamentoHistoricoModule,
        TranslocoRootModule,
    ],
    exports: [ClassicLayoutComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [{ provide: IAlertaService, useExisting: AlertaService }],
})
export class ClassicLayoutModule {}
