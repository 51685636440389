import { IConfigurarStatusAeracaoService } from 'app/shared/services';
import { Injectable } from '@angular/core';
export abstract class IRetornaSvgAeradorService {
    abstract execute(status: any, callback): any;
}

@Injectable({ providedIn: 'root' })
export class RetornaSvgAeradorService implements IRetornaSvgAeradorService {
    constructor(private configurarStatusAeracaoService: IConfigurarStatusAeracaoService) {}
    execute(status: any, callback): void {
        const configuracaoStatusAerador = this.configurarStatusAeracaoService.execute(status);
        const urlArquivo = `assets/img/aeradores/${configuracaoStatusAerador.aerador.cor}.svg`;
        fetch(urlArquivo)
            .then((response) => response.text())
            .then((data) => {
                const parser = new DOMParser();
                const svgDocument = parser.parseFromString(data, 'image/svg+xml');
                callback(svgDocument.documentElement);
            })
            .catch((error) => console.error('Erro ao carregar SVG:', error));
    }
}
