export interface Silo3DPropriedadesInterface {
    diametroSilo: number;
    diametroChapeu: number;
    diametroInferiorFundo: number;
    alturaSilo: number;
    alturaChapeu: number;
    alturaFundo: number;
    alturaPlataforma: number;
    anguloChapeu: number;
    anguloFundo: number;
}
