import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ObjetivoProgramaEnum } from 'app/shared/enums';
export abstract class IRetornaObjetivoProgramaAeracaoService {
    abstract execute(objeto: ObjetivoProgramaEnum): string;
}

@Injectable({ providedIn: 'root' })
export class RetornaObjetivoProgramaAeracaoService implements IRetornaObjetivoProgramaAeracaoService {    
    constructor(private transloco: TranslocoService) {}

    public execute(objeto: any): string {
        let nome = null;

        const traduzPagina = {
            traducaoConservacao: this.transloco.translate('CONSERVACAO'),
            traducaoCorretiva: this.transloco.translate('CORRETIVA'),
            traducaoResfriamento: this.transloco.translate('RESFRIAMENTO'),
            traducaoResfriamentoConservacao: this.transloco.translate('RESFRIAMENTO_E_CONSERVACAO'),
            traducaoSecagem: this.transloco.translate('SECAGEM'),
        };

        switch (objeto) {
            case ObjetivoProgramaEnum.CONSERVACAO: {
                nome = traduzPagina.traducaoConservacao;
                break;
            }
            case ObjetivoProgramaEnum.CORRETIVA: {
                nome = traduzPagina.traducaoCorretiva;
                break;
            }
            case ObjetivoProgramaEnum.RESFRIAMENTO: {
                nome = traduzPagina.traducaoResfriamento;
                break;
            }
            case ObjetivoProgramaEnum.RESFRIAMENTO_E_CONSERVACAO: {
                nome = traduzPagina.traducaoResfriamentoConservacao;
                break;
            }
            case ObjetivoProgramaEnum.SECAGEM: {
                nome = traduzPagina.traducaoSecagem;
                break;
            }
        }
        return nome;
    }
}
