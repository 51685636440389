import { Injectable } from '@angular/core';
import { PaletaDeCoresInterface } from 'app/shared/interfaces';
import { ProdutoTipoSecadorEnum } from 'app/modules/secador/visualizacao/secadores/visao-detalhada-secador/enums';
import {
    paletaCoresDescargaUmidadeGraoConstant,
    paletaCoresEntradaTemperaturaGraoConstant,
    paletaCoresMassaTemperaturaGraoConstant,
    paletaCoresSaidaTemperaturaGraoConstant,
} from 'app/shared/constants';
import { PaletaCoresGraoSecadoresEnum } from 'app/shared/enums';

export abstract class ICriaPaletaCoresGraoSecadoresService {
    abstract criarPaleta(
        produto: string,
        temperatura: number,
        tipoPaleta: PaletaCoresGraoSecadoresEnum
    ): PaletaDeCoresInterface;
}

@Injectable({ providedIn: 'root' })
export class CriaPaletaCoresGraoSecadoresService implements ICriaPaletaCoresGraoSecadoresService {
    criarPaleta(
        produto: string,
        temperatura: number,
        tipoPaleta: PaletaCoresGraoSecadoresEnum
    ): PaletaDeCoresInterface {
        const paletas = {
            [PaletaCoresGraoSecadoresEnum.ENTRADA_TEMPERATURA]: paletaCoresEntradaTemperaturaGraoConstant,
            [PaletaCoresGraoSecadoresEnum.MASSA_TEMPERATURA]: paletaCoresMassaTemperaturaGraoConstant,
            [PaletaCoresGraoSecadoresEnum.SAIDA_TEMPERATURA]: paletaCoresSaidaTemperaturaGraoConstant,
            [PaletaCoresGraoSecadoresEnum.UMIDADE_GRAO]: paletaCoresDescargaUmidadeGraoConstant,
        };
        const paletaDefault: PaletaDeCoresInterface[] = [{ min: '', max: '', fundo: '#EBEEF2', cor: '#00091F' }];
        let paletaCores: PaletaDeCoresInterface[] = paletaDefault;

        if (temperatura < 0) {
            temperatura = 0;
        }

        if (temperatura > 100) {
            temperatura = 100;
        }

        if (temperatura) {
            const paletaGrao = paletas[tipoPaleta];
            const produtoTipo = this.retornaProduto(produto);
            paletaCores = this.filtrarPaletaDeCores(paletaGrao[produtoTipo], temperatura);
        }

        return paletaCores[0];
    }

    private retornaProduto(produtoTipo: string): string {
        const produtosComEscalaDefinida = [
            ProdutoTipoSecadorEnum.ARROZ,
            ProdutoTipoSecadorEnum.MILHO,
            ProdutoTipoSecadorEnum.SOJA,
            ProdutoTipoSecadorEnum.TRIGO,
        ];

        const produto = produtosComEscalaDefinida.find((p) => p === produtoTipo?.toLowerCase());

        if (produto) {
            return produto;
        } else {
            return produtosComEscalaDefinida[2];
        }
    }

    private filtrarPaletaDeCores(paleta: PaletaDeCoresInterface[], valorSensor: number): PaletaDeCoresInterface[] {
        const valorEncontrado = paleta.find((valor) => valorSensor >= valor.min && valorSensor <= valor.max);

        if (!valorEncontrado) {
            const valorMinimo = paleta[0];
            const valorMaximo = paleta[paleta.length - 1];
            if (valorSensor > valorMaximo.max) {
                return [paleta[paleta.length - 1]];
            } else if (valorSensor < valorMinimo.min) {
                return [paleta[1]];
            }
        }
        return [valorEncontrado];
    }
}
