import { UnidadeInterface } from "app/modules/gestao-unidades/operacional/shared/interfaces";

export interface ListaExpedicaoAmostragensInterface {
    id: string;
    estutura_armazenagem: EstuturaArmazenagem;
    equipamento_comunicacao_status: string;
    unidade: UnidadeInterface;
    instante: string;
    temperatura_media: number;
    umidade_relativa_media: number;
    estrutura_amostragem: EstruturaAmostragemInterface;
    estutura_armazenagem_divisao: EstruturaArmazenagemDivisaoInterface;
    produto_tipo: ProdutoTipoExpedicaoAmostragemInterface;
    programa_aeracao: ProgramaAeracao;
    estoque_estimado: number;
    estoque_informado: number;
    estoque_capacidade: number;
    data_amostra: string;
    motivo: string;
}

export interface ProdutoTipoExpedicaoAmostragemInterface {
    id: number;
    nome: string;
}

export interface EstruturaArmazenagemDivisaoInterface {
    id: string,
    nome: string,
    controle_aeracao_automatica: boolean
}


export interface EstruturaAmostragemInterface {
    quantidade_de_amostragens: number;
    primeira_amostragem: Amostragem;
    ultima_amostragem: Amostragem;
}

export interface Amostragem {
    umidade_grao: number;
    avariados: number;
    impurezas: number;
}

export interface EstuturaArmazenagem {
    id: string;
    nome: string;
    codigo: number;
    sensor_umidade_instalado: boolean;
    categoria: string;
    volume: number;
}

export interface EstuturaArmazenagemDivisao {
    id: string;
    nome: string;
    controle_aeracao_automatica: boolean;
}

export interface ProdutoTipoQualidade {
    id: number;
    nome: string;
}

export interface ProgramaAeracao {
    id: string;
    nome: string;
}

export interface GraficoLinhaExpedicaoAmostragemInterface {
    instante: string;
    data_amostra: string;
    estoque_estimado: ObjetoValorTextoInterface;
    estoque_informado: ObjetoValorTextoInterface;
    estoque_capacidade: ObjetoValorTextoInterface;
    tempo_aeracao: ObjetoValorNumeroInterface;
    temperatura_minima: ObjetoValorTextoInterface;
    temperatura_media: ObjetoValorTextoInterface;
    temperatura_maxima: ObjetoValorTextoInterface;
    umidade_grao: ObjetoValorTextoInterface;
    umidade_relativa_media: ObjetoValorTextoInterface;
    equipamento_comunicacao: EquipamentoComunicacaoInterface;
    motivo: string;
}

export interface EquipamentoComunicacaoInterface {
    instante: string;
    status: string;
}

export interface ObjetoValorTextoInterface {
    valor: string;
}

export interface ObjetoValorNumeroInterface {
    valor: number;
}

export interface GraficoListaExpedicaoAmostragensInterface {
    temperatura_minima: ValorData[];
    temperatura_media: ValorData[];
    temperatura_maxima: ValorData[];
    umidade_relativa: ValorData[];
    umidade_grao: ValorData[];
}

export interface ValorData {
    id: number;
    valor: number;
    data: string;
}

export interface StatusEquipamentoInterface {
    segundaLinhaTooltip: string;
    classTooltip: string;
    cor: string;
}

export interface SeriesGraficoLinhaExpedicaoAmostragemInterface {
    name: string,
    data: number[],
    color: string
}

export interface DadosModalGraficoLinhaInterface {
    dados: GraficoLinhaExpedicaoAmostragemInterface[],
    id?: string,
    buscandoDadosGraficoLinha: boolean,
    erroBuscaDadosGraficoLinha: boolean
}

export interface DetalhesGraficoLinhaInterface {
    id: string;
    datas: FiltroGraficoLinhaInterface
}

export interface FiltroGraficoLinhaInterface {
    data_inicio: string;
    data_fim: string;
  }


export interface PaletaCorAmostragemExpedicao {
    min: number | string;
    max: number | string;
    cor: string;
    fundo: string;
}
