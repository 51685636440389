export enum mth {
    JANEIRO = 'Janeiro',
    FEVEREIRO = 'Fevereiro',
    MARCO = 'Março',
    ABRIL = 'Abril',
    MAIO = 'Maio',
    JUNHO = 'Junho',
    JULHO = 'Julho',
    AGOSTO = 'Agosto',
    SETEMBRO = 'Setembro',
    OUTUBRO = 'Outubro',
    NOVEMBRO = 'Novembro',
    DEZEMBRO = 'Dezembro'
}

export enum shortM {
    JAN = 'Jan',
    FEV = 'Fev',
    MAR = 'Mar',
    ABR = 'Abr',
    MAI = 'Mai',
    JUN = 'Jun',
    JUL = 'Jul',
    AGO = 'Ago',
    SET = 'Set',
    OUT = 'Out',
    NOV = 'Nov',
    DEZ = 'Dez'
}
export enum day {
    DOMINGO = 'Domingo',
    SEGUNDA = 'Segunda-feira',
    TERCA = 'Terça-feira',
    QUARTA = 'Quarta-feira',
    QUINTA = 'Quinta-feira',
    SEXTA = 'Sexta-feira',
    SABADO = 'Sábado'
}
export enum shortD {
    DOM = 'Dom',
    SEG = 'Seg',
    TER = 'Ter',
    QUA = 'Qua',
    QUI = 'Qui',
    SEX = 'Sex',
    SAB = 'Sáb'
}

