export class RegraHistoricoModel {
    id: number;
    regra_id: number;
    instante: string;
    nome_antigo: string;
    nome_novo: string;
    expressao_ligar_antigo: string;
    expressao_ligar_novo: string;
    expressao_manter_ligado_antigo: string;
    expressao_manter_ligado_novo: string;
    usuario: {
        id: string;
        nome: string;
        sobrenome: string;
    };

    constructor(partial?: Partial<RegraHistoricoModel>) {
        Object.assign(this, partial);
    }
}
