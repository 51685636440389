import { UsuarioHistoricoAcoes } from 'app/shared/interfaces';

export interface HistoricoVariavelAquecimentoInterface {
    id: string;
    estrutura_armazenagem_divisao_id: number;
    instante: string;
    aquecimento_ar: string;
    aquecimento_ar_valor: number;
    usuario: UsuarioHistoricoAcoes;
}
