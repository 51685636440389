export const environment = {
    isMockEnabled: false,
    production: false,
    level: 'nuvem',
    api: {
        urlCeres: 'https://api.ceres.dev.procer.co/v1',
        urlGestao: 'https://api-gestao-unidade.ceres.dev.procer.co/v1',
    },
    whiteLabel: {
        logoLoginUrl: 'assets/images/logo/logo_procer_rodape_estatico.svg',
        logoRodape: 'assets/images/logo/logo_procer_rodape.svg',
        logoRedirecionamentoUrl: 'assets/images/logo/logo-procer.png',
        logoMenuFechadoUrl: 'assets/images/logo/logo_menu_fechado_orion.svg',
        logoMenuAbertoUrl: 'assets/images/logo/logo_orion_menu_aberto.svg',
        imgMapaContatos: 'assets/img/mapa-contatos-procer.gif',
    },
};
