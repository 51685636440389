import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UsuarioInterface } from 'app/shared/interfaces';

@Injectable({
    providedIn: 'root',
})
export class UsuarioGeralObservable {
    private usuario: BehaviorSubject<UsuarioInterface | null> = new BehaviorSubject(null);

    set setInformacoesUsuario(value: UsuarioInterface) {
        this.usuario.next(value);
    }

    get getInformacoesUsuario$(): UsuarioInterface {
        return this.usuario.getValue();
    }

    get buscaInformacoesUsuario$(): Observable<UsuarioInterface> {
        return this.usuario.asObservable();
    }
}
