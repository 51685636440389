export interface ArmazemVisaoGeralInterface {
    id: string;
    nome: string;
    equipamentos: Equipamento[];
    mapa: Mapa;
}

export interface Equipamento {
    id: string;
    nome: string;
    modelo: string;
    categoria: string;
    equipamento_comunicacao: EquipamentoComunicacao;
}

export interface EquipamentoComunicacao {
    status: boolean;
    instante: string;
}

export interface Mapa {
    temperatura: string;
    equilibrio_higroscopico?: string;
    sensor_nivel_temperatura: string;
    sensor_quantidade_temperatura: string;
    umidade_relativa: any;
    sensor_nivel_umidade: any;
    sensor_quantidade_umidade: any;
    pendulo_comunicacao: string;
    pendulo_ordenacao?: string;
    digital_termopar: string;
    sensor_umidade_instalado: boolean;
}
