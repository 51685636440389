import { Injectable } from '@angular/core';
import { EstacaoMeteorologicaInterface } from 'app/shared/interfaces';

export abstract class IOrdenarEstacoesService {
    abstract execute(estacoes: EstacaoMeteorologicaInterface[]): void;
}

@Injectable({
    providedIn: 'root',
})
export class OrdenarEstacoesService {
    execute(estacoes: EstacaoMeteorologicaInterface[]): void {
        estacoes.sort((a, b) => {
            switch (true) {
                case a.ultima_leitura?.validacao_leitura?.status === true &&
                    !b.ultima_leitura?.validacao_leitura?.status:
                    return -1;
                case !a.ultima_leitura?.validacao_leitura?.status &&
                    b.ultima_leitura?.validacao_leitura?.status === true:
                    return 1;
                default:
                    return 0;
            }
        });
    }
}
