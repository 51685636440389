export enum ganhosArmazenagemPropriaEnum {
    GANHOS_ARMAZENAGEM_PROPRIA = 'Ganhos com armazenagem própria',
    SILO_ARMAZEM = 'Silo/Armazém',
    UNIDADE = 'Unidade',
    GRAO = 'Grão',
    CAPACIDADE_NOMINAL = 'Capacidade nominal (t/h)',
    CAPACIDADE_REAL = 'Capacidade real  (t/h)',
    EFICIENCIA = 'Eficiência (%)',
    CONSUMO_COMBUSTIVEL = 'Consumo de combustível',
}

export enum CabecalhoGanhosArmazenagemPropriaEnum {
    SILOS_ARMAZEM = 'siloArmazem',
    UNIDADE = 'unidade',
    GRAO = 'grao',
    TEMPO_ARMAZENAGEM = 'tempoArmazenagem',
    ESTOQUE_MAXIMO = 'estoqueMaximo',
    DESCONTO_UMIDADE = 'descontoUmidade',
    DESCONTO_IMPUREZA = 'descontoImpureza',
    DESCONTO_AVARIADOS = 'descontoAvariados',
    DESCONTO_DEFEITOS = 'descontoDefeitos',
    DESCONTO_CLASSIFICAO = 'descontoClassificacao',
    DESCONTO_TAXA_SECAGEM = 'descontoTaxaSecagem',
    DESCONTO_RECEBIMENTO = 'descontoRecebimento',
    DESCONTO_ARMAZENAGEM = 'descontoArmazenagem',
    QUANTIDADE_GRAOS = 'quantidadeGraos',
    RECEITA = 'emReceita'
}

export enum GanhosArmazenagemPropriaEnum {
    SILOS_ARMAZEM = 'Silo/Armazém',
    UNIDADE = 'Unidade',
    GRAO = 'Grão',
    TEMPO_ARMAZENAGEM = 'Tempo de armazenagem (dias)',
    ESTOQUE_MAXIMO = 'Estoque máximo (t)',
    DESCONTO_UNIDADE = 'Desconto por unidade',
    DESCONTO_IMPUREZA = 'Desconto por impureza',
    DESCONTO_AVARIADOS = 'Desconto por avariados',
    DESCONTO_DEFEITOS = 'Desconto por outros defeitos',
    DESCONTO_CLASSIFICAO = 'Total de descontos da classificação',
    DESCONTO_TAXA_SECAGEM = 'Desconto pela taxa de secagem (t)',
    DESCONTO_RECEBIMENTO = 'Desconto pela taxa de recebimento (t)',
    DESCONTO_ARMAZENAGEM = 'Desconto pela taxa de armazenagem (t)',
    QUANTIDADE_GRAOS = 'Em quantidade de grãos (t)',
    RECEITA = 'Em receita (R$)',
    DESCONTOS_QUALIDADE_GRAO = 'Descontos pela qualidade do grão (t)',
    GERACAO_ARMAZENAGEM = 'Geração de valor por estrutura de armazenagem'
}
