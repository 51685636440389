import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import {
    Translation,
    TRANSLOCO_CONFIG,
    TRANSLOCO_LOADER,
    translocoConfig,
    TranslocoLoader,
    TranslocoModule,    
} from '@ngneat/transloco';
import { merge } from 'lodash';
import { catchError, forkJoin, map, of } from 'rxjs';
import { environment } from 'environments/environment';
import { CookieHelper } from 'app/shared/helpers';
import { IdiomaSiglaEnum } from 'app/shared/enums';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    constructor(
        private http: HttpClient        
    ) { }

    getTranslation(lang: string) {   
        
        const idioma = CookieHelper.carregarCookie('idioma');        
        
        if (!lang) {
            lang = idioma || IdiomaSiglaEnum.PORTUGUES;
        }

        const traducao = [
            this.http.get<Translation>(`./assets/i18n/${lang}.json`).pipe(
                catchError(() => of({})) //retorna vazio caso nao encontre o arquivo
            ),
            this.http.get<Translation>(`./assets/i18n/login/${lang}.json`).pipe(catchError(() => of({}))),
            this.http.get<Translation>(`./assets/i18n/components/${lang}.json`).pipe(catchError(() => of({}))),
            this.http.get<Translation>(`./assets/i18n/armazenagem/${lang}.json`).pipe(catchError(() => of({}))),
            this.http.get<Translation>(`./assets/i18n/secador/${lang}.json`).pipe(catchError(() => of({}))),
            this.http.get<Translation>(`./assets/i18n/enums/${lang}.json`).pipe(catchError(() => of({}))),
            this.http.get<Translation>(`./assets/i18n/configuracoes/${lang}.json`).pipe(catchError(() => of({}))),
            this.http.get<Translation>(`./assets/i18n/gestao-unidades/${lang}.json`).pipe(catchError(() => of({}))),
            this.http.get<Translation>(`./assets/i18n/gestao-unidades/components/${lang}.json`).pipe(catchError(() => of({}))),
        ];

        return forkJoin(traducao).pipe(map((response) => merge({}, ...response)));
    }

    setTranslation(lang: string, translation: Translation) {
        return this.http.put(`./assets/i18n/${lang}.json`, translation);
    }
}

@NgModule({
    exports: [TranslocoModule],
    providers: [
        {
            provide: TRANSLOCO_CONFIG,
            useValue: translocoConfig({
                availableLangs: ['en', 'pt', 'es'],
                defaultLang: 'pt',
                fallbackLang: 'pt',
                reRenderOnLangChange: true,
                prodMode: environment.production,
            }),
        },
        { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    ],
})
export class TranslocoRootModule { }
