import { NomesAeracaoGraos } from "app/modules/gestao-unidades/operacional/shared/enums";

export class formatarTituloModal {
    public static execute(objetivo: string | null): string {
        if (objetivo === null) {
            return NomesAeracaoGraos.CONTROLE_MANUAL_DO_SISTEMA;
        }
        if (objetivo === NomesAeracaoGraos.AUTOMATICO) {
            return NomesAeracaoGraos.AUTOMATICO_CORRIGIDO_MINUSCULO
        }
        if (objetivo === NomesAeracaoGraos.RESFRIAMENTO_E_CONSERVACAO_BACK) {
            return NomesAeracaoGraos.RESFRIAMENTO_E_CONSERVACAO_MAIUSCULO_CORRIGIDO
        }
        if (objetivo === NomesAeracaoGraos.CONSERVACAO) {
            return NomesAeracaoGraos.CONSERVACAO_CORRIGIDO
        }
        return objetivo.charAt(0).toUpperCase() + objetivo.slice(1).replace(/_/g, ' ');
    }
}
