import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UsuarioLogadoInterface } from 'app/shared/interfaces';

@Injectable({
    providedIn: 'root',
})
export class AutenticacaoUsuarioObservable {
    private usuario: BehaviorSubject<UsuarioLogadoInterface | null> = new BehaviorSubject(null);

    set setInformacoesAutenticacaoUsuario(value: UsuarioLogadoInterface) {
        this.usuario.next(value);
    }

    get getInformacoesAutenticacaoUsuario(): UsuarioLogadoInterface {
        return this.usuario.getValue();
    }

    get buscaInformacoesAutenticacaoUsuario$(): Observable<UsuarioLogadoInterface> {
        return this.usuario.asObservable();
    }
}
