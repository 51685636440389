export class ProgramaModel {
    id?: string;
    nome?: string;
    condicao_ligar?: string;
    condicao_manter_ligado?: string;
    objetivo_programa?: string;
    ultima_alteracao?: string;
    notas?: string;
    arquivado?: boolean;
    estrutura_armazenagem_divisao?: any[];

    constructor(partial?: Partial<ProgramaModel>) {
        Object.assign(this, partial);
    }
}
