export interface HistoricoAlteracoesProgramaInterface {
    id: string;
    estrutura_armazenagem_divisao_id: number;
    instante: string;
    programa_aeracao : {
        id: string
        nome: string;
        regras: RegrasProgramaInterface[];
    };
    usuario: {
        id: string;
        nome: string;
        sobrenome: string;
    };

}

export interface RegrasProgramaInterface {
    id: string;
    nome: string;
    expressao_ligar: string;
    expressao_manter_ligado: string;
}
