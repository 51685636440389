import { TipoAcionamentoAerador } from 'app/shared/enums';

export class PayloadGraficosArmazenagemModel {
    id: string;
    estruturaArmazenagemDivisaoId: string;
    data_inicio: string;
    data_fim: string;
    tipo_acionamento: TipoAcionamentoAerador;    
    page: number;
    limit: number;    
}
