import { Injectable } from '@angular/core';
import { TabScreenObservable } from 'app/shared/observables';
export abstract class ISetaTabScreenService {
    abstract setaTabScreenService(tabIndex: number): void;
}

@Injectable({ providedIn: 'root' })
export class SetaTabScreenService implements ISetaTabScreenService {
    constructor(private tabScreenObservable: TabScreenObservable) {}
    public setaTabScreenService(tabIndex: number): void {
        this.tabScreenObservable.setatabScreenSelecionada = tabIndex;
    }
}
