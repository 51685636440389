export enum FuncionalidadeChaveEnum {
    PAINEL_GERAL = 'painel_geral',
    VISAO_GERAL_SILO = 'visao_geral_silo',
    VISAO_GERAL_ARMAZEM = 'visao_geral_armazem',
    VISAO_DETALHADA_SILO = 'visao_detalhada_silo',
    VISAO_DETALHADA_ARMAZEM = 'visao_detalhada_armazem',
    RELATORIO_TERMOMETRIA = 'relatorio_termometria',
    RELATORIO_AERACAO_FUNCIONAMENTO = 'relatorio_aeracao_funcionamento',
    RELATORIO_SENSOR_PLENUM = 'relatorio_sensor_plenum',
    RELATORIO_AERACAO_EQUILIBRIO_HIGROSCOPICO = 'relatorio_aeracao_equilibrio_higroscopico',
    RELATORIO_MEDIDOR_VOLUME = 'relatorio_medidor_volume',
    RELATORIO_SENSOR_CO2 = 'relatorio_sensor_co2',
    GRAFICO_UNIDADE_ARMAZENADORA = 'grafico_unidade_armazenadora',
    GRAFICO_PLUVIOMETRIA = 'grafico_pluviometria',
    EVENTOS = 'eventos',
    LISTAGEM_AMOSTRAGEM = 'listagem_amostragem',
    LISTAGEM_GRAO = 'listagem_grao',
    LISTAGEM_PROGRAMA_AERACAO = 'listagem_programa_aeracao',
    LISTAGEM_REGRA_AERACAO = 'listagem_regra_aeracao',
    CADASTRO_REGRA_AERACAO = 'cadastro_regra_aeracao',
    CADASTRO_PROGRAMA_AERACAO = 'cadastro_programa_aeracao',
    CADASTRO_AMOSTRAGEM = 'cadastro_amostragem',
    CADASTRO_GRAO = 'cadastro_grao',
    CADASTRO_ESTOQUE_MANUAL = 'cadastro_estoque_manual',
    CADASTRO_ESTOQUE_AUTOMATICO = 'cadastro_estoque_automatico',
    HISTORICO_AMOSTRAGEM = 'historico_amostragem',
    HISTORICO_VISAO_DETALHADA_SILO = 'historico_visao_detalhada_silo',
    HISTORICO_VISAO_DETALHADA_ARMAZEM = 'historico_visao_detalhada_armazem',
    VISAO_GERAL_SECADOR = 'visao_geral_secador',
    VISAO_DETALHADA_SECADOR = 'visao_detalhada_secador',
    RELATORIO_SENSORES = 'relatorio_sensores',
    RELATORIO_OPERACOES = 'relatorio_operacoes',
    RELATORIO_LIMPEZA = 'relatorio_limpeza',
    RELATORIO_ALERTAS = 'relatorio_alertas',
    GRAFICO_SENSOR_OPERACAO = 'grafico_sensor_operacao',
    LISTAGEM_ALERTA = 'listagem_alerta',
    LISTAGEM_CONTROLE_SECADOR = 'listagem_controle_secador',
    CADASTRO_CONTROLE_SECADOR = 'cadastro_controle_secador',
    CADASTRO_ALERTA = 'cadastro_alerta',
    LISTAGEM_PERFIL = 'listagem_perfil',
    LISTAGEM_USUARIO = 'listagem_usuario',
    CADASTRO_PERFIL = 'cadastro_perfil',
    CADASTRO_USUARIO = 'cadastro_usuario',
    PAINEL_RENTABILIDADE = 'painel_rentabilidade',
    PAINEL_QUALIDADE = 'painel_qualidade',
    PAINEL_OPERACIONAL = 'painel_operacional',
    PARAMETRIZACAO_ORDEM_EXPEDICAO = 'parametrizacao_ordem_expedicao',
    PARAMETRIZACAO_DESCONTO = 'parametrizacao_desconto',
    HISTORICO_SILO = 'historico_silo',
    RELATORIO_MEDIDOR_VOLUME_1D = 'relatorio_medidor_volume_1d',
    RELATORIO_MEDIDOR_VOLUME_3D = 'relatorio_medidor_volume_3d',
    CONTROLE_SECADOR = 'controle_secador',
    GRAFICO_SENSORES = 'grafico_sensores',
    HISTORICO_ARMAZEM = 'historico_armazem',
}

export enum FuncionalidadeChaveTraducaoEnum {
    visao_geral_silo = 'FUNCIONALIDADE_VISAO_GERAL_SILO',
    visao_geral_armazem = 'FUNCIONALIDADE_VISAO_GERAL_ARMAZEM',
    visao_detalhada_silo = 'FUNCIONALIDADE_VISAO_DETALHADA_SILO',
    historico_silo = 'FUNCIONALIDADE_HISTORICO_SILO',
    visao_detalhada_armazem = 'FUNCIONALIDADE_VISAO_DETALHADA_ARMAZEM',
    historico_armazem = 'FUNCIONALIDADE_HISTORICO_ARMAZEM',
    relatorio_termometria = 'FUNCIONALIDADE_RELATORIO_TERMOMETRIA',
    relatorio_aeracao_funcionamento = 'FUNCIONALIDADE_RELATORIO_AERACAO_FUNCIONAMENTO',
    relatorio_aeracao_equilibrio_higroscopico = 'FUNCIONALIDADE_RELATORIO_AERACAO_EQUILIBRIO_HIGROSCOPICO',
    relatorio_sensor_plenum = 'FUNCIONALIDADE_RELATORIO_SENSOR_PLENUM',
    relatorio_sensor_co2 = 'FUNCIONALIDADE_RELATORIO_SENSOR_CO2',
    relatorio_medidor_volume_1d = 'FUNCIONALIDADE_RELATORIO_MEDIDOR_VOLUME_1D',
    relatorio_medidor_volume_3d = 'FUNCIONALIDADE_RELATORIO_MEDIDOR_VOLUME_3D',
    grafico_unidade_armazenadora = 'FUNCIONALIDADE_GRAFICO_UNIDADE_ARMAZENADORA',
    grafico_pluviometria = 'FUNCIONALIDADE_GRAFICO_PLUVIOMETRIA',
    eventos = 'FUNCIONALIDADE_EVENTOS',
    listagem_amostragem = 'FUNCIONALIDADE_LISTAGEM_AMOSTRAGEM',
    cadastro_amostragem = 'FUNCIONALIDADE_CADASTRO_AMOSTRAGEM',
    listagem_grao = 'FUNCIONALIDADE_LISTAGEM_GRAO',
    cadastro_grao = 'FUNCIONALIDADE_CADASTRO_GRAO',
    listagem_programa_aeracao = 'FUNCIONALIDADE_LISTAGEM_PROGRAMA_AERACAO',
    cadastro_programa_aeracao = 'FUNCIONALIDADE_CADASTRO_PROGRAMA_AERACAO',
    listagem_regra_aeracao = 'FUNCIONALIDADE_LISTAGEM_REGRA_AERACAO',
    cadastro_regra_aeracao = 'FUNCIONALIDADE_CADASTRO_REGRA_AERACAO',
    cadastro_estoque_manual = 'FUNCIONALIDADE_CADASTRO_ESTOQUE_MANUAL',
    cadastro_estoque_automatico = 'FUNCIONALIDADE_CADASTRO_ESTOQUE_AUTOMATICO',
    visao_geral_secador = 'FUNCIONALIDADE_VISAO_GERAL_SECADOR',
    controle_secador = 'FUNCIONALIDADE_CONTROLE_SECADOR',
    visao_detalhada_secador = 'FUNCIONALIDADE_VISAO_DETALHADA_SECADOR',
    relatorio_sensores = 'FUNCIONALIDADE_RELATORIO_SENSORES',
    relatorio_alertas = 'FUNCIONALIDADE_RELATORIO_ALERTAS',
    grafico_sensores = 'FUNCIONALIDADE_GRAFICO_SENSORES',
    grafico_sensor_operacao = 'FUNCIONALIDADE_GRAFICO_SENSOR_OPERACAO',
    listagem_alerta = 'FUNCIONALIDADE_LISTAGEM_ALERTA',
    cadastro_alerta = 'FUNCIONALIDADE_CADASTRO_ALERTA',
    painel_rentabilidade = 'FUNCIONALIDADE_PAINEL_RENTABILIDADE',
    painel_qualidade = 'FUNCIONALIDADE_PAINEL_QUALIDADE',
    painel_operacional = 'FUNCIONALIDADE_PAINEL_OPERACIONAL',
    parametrizacao_ordem_expedicao = 'FUNCIONALIDADE_PARAMETRIZACAO_ORDEM_EXPEDICAO',
    parametrizacao_desconto = 'FUNCIONALIDADE_PARAMETRIZACAO_DESCONTO',
    painel_geral = 'FUNCIONALIDADE_PAINEL_GERAL',
}
