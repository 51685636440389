export interface expandedElementInterface {
    Secador: string;
    Unidades: string;
    Grao: string;
    Tempo_de_secagem: number;
    Entrada: number;
    Massa: number;
    Saida: number;
    Ug: number;
    Limpeza: {
        total: number;
        atual: number;
    };
    Grafico: GraficoSecadorEmOperacao;
}

export interface GraficoSecadorEmOperacao {
    velocidade_de_descarga: ValoresGraficoSecadorEmOperacao[];
    eficiencia_do_secador: ValoresGraficoSecadorEmOperacao[];
    consumo_de_combustivel: ValoresGraficoSecadorEmOperacao[];
    medidor_de_fluxo: ValoresGraficoSecadorEmOperacao[];
    medidor_de_fluxo_2: ValoresGraficoSecadorEmOperacao[];
    pressao_da_entrada_de_ar: ValoresGraficoSecadorEmOperacao[];
    pressao_da_saida_de_ar: ValoresGraficoSecadorEmOperacao[];
}

interface ValoresGraficoSecadorEmOperacao {
    horario: string;
    valor: number;
}
