import { Injectable } from '@angular/core';
import { IApiTransportInterface } from 'app/core/interfaces';
import { IEstruturaArmazenagemSelectInterface } from 'app/shared/interfaces';
import { ApiTransportService } from 'app/core/services/transport';
import { Observable } from 'rxjs';

export abstract class IEstruturaArmazenagemController {
    abstract buscaEstruturasSensorPlenum(): Observable<IEstruturaArmazenagemSelectInterface[]>;
    abstract buscaEstruturasAeracao(): Observable<IEstruturaArmazenagemSelectInterface[]>;
}

@Injectable({
    providedIn: 'root',
})
export class EstruturaArmazenagemController implements IEstruturaArmazenagemController {
    private transport: IApiTransportInterface;

    constructor(public readonly apiTransportService: ApiTransportService) {
        this.transport = this.apiTransportService.execute('/armazenagem/estrutura-armazenagem');
    }

    public buscaEstruturasSensorPlenum(): Observable<any> {
        return this.transport.http.get<any>(`${this.transport.path}/sensor-plenum`);
    }

    public buscaEstruturasAeracao(): Observable<any> {
        return this.transport.http.get<any>(`${this.transport.path}/estrutura-armazenagem-divisao/aeracao`);
    }
}
