import { Injectable } from '@angular/core';

export abstract class IRetornaTelefoneFormatadoService {
    abstract execute(telefone: string): string;
}

@Injectable()
export class RetornaTelefoneFormatadoService implements IRetornaTelefoneFormatadoService {
    execute(telefone: string): string {
        if (telefone === null) {
            telefone = '';
        }
        const numeroLimpo = telefone.replace(/\D/g, '');
        if (numeroLimpo.length >= 9) {
            const codigoArea = numeroLimpo.slice(0, 2);
            const restoNumero = numeroLimpo.slice(2);
            const numeroFormatado = `+55 ${codigoArea} ${restoNumero.slice(0, 5)}-${restoNumero.slice(5)}`;
            return numeroFormatado;
        } else {
            return telefone;
        }
    }
}
