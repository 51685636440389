import { Injectable } from '@angular/core';
import { ApiTransportService } from 'app/core/services/transport';
import { Observable, map } from 'rxjs';

import { IApiTransportInterface } from 'app/core/interfaces/';
import { SincronizadorModel } from 'app/shared/models';
import { SincronizadorEquipamentoObservable } from 'app/shared/observables';

export abstract class ISincronizadorController {
    abstract buscaUltimaSincronizacao(): Observable<SincronizadorModel>;
}

@Injectable({
    providedIn: 'root',
})
export class SincronizadorController implements ISincronizadorController {
    private transport: IApiTransportInterface;

    constructor(
        public readonly apiTransportService: ApiTransportService,
        private sincronizadorEquipamentoObservable: SincronizadorEquipamentoObservable
    ) {
        this.transport = this.apiTransportService.execute('/geral/sincronizacao/ultima-sincronizacao');
    }

    public buscaUltimaSincronizacao(): Observable<any> {        
        return this.transport.http.get<any>(`${this.transport.path}`).pipe(
            map((response) => {                
                this.sincronizadorEquipamentoObservable.setSincronizador = response;
                return response;
            })
        );        
    }
}
