import { PaletaDeCoresInterface } from "app/shared/interfaces";

export const paletaCoresDescargaUmidadeGraoConstant: { [key in string]: PaletaDeCoresInterface[] } = {
    arroz: [
        { min: '', max: '', fundo: '#EBEEF2', cor: '#16171B' },
        { min: 0, max: 9.9, fundo: '#d83027', cor: '#FFFFFF' },
        { min: 10, max: 12.4, fundo: '#e75135', cor: '#FFFFFF' },
        { min: 12.5, max: 13.5, fundo: '#3DAC79', cor: '#FFFFFF' },
        { min: 13.6, max: 14.5, fundo: '#25719E', cor: '#FFFFFF' },
        { min: 14.6, max: 40, fundo: '#185C84', cor: '#FFFFFF' },
    ],
    milho: [
        { min: '', max: '', fundo: '#EBEEF2', cor: '#16171B' },
        { min: 0, max: 11.9, fundo: '#d83027', cor: '#FFFFFF' },
        { min: 10, max: 13.4, fundo: '#e75135', cor: '#FFFFFF' },
        { min: 13.5, max: 14.5, fundo: '#3DAC79', cor: '#FFFFFF' },
        { min: 14.6, max: 15.5, fundo: '#25719E', cor: '#FFFFFF' },
        { min: 15.6, max: 40, fundo: '#185C84', cor: '#FFFFFF' },
    ],
    soja: [
        { min: '', max: '', fundo: '#EBEEF2', cor: '#16171B' },
        { min: 0, max: 11.9, fundo: '#d83027', cor: '#FFFFFF' },
        { min: 10, max: 13.4, fundo: '#e75135', cor: '#FFFFFF' },
        { min: 13.5, max: 14.5, fundo: '#3DAC79', cor: '#FFFFFF' },
        { min: 14.6, max: 15.5, fundo: '#25719E', cor: '#FFFFFF' },
        { min: 15.6, max: 40, fundo: '#185C84', cor: '#FFFFFF' },
    ],
    trigo: [
        { min: '', max: '', fundo: '#EBEEF2', cor: '#16171B' },
        { min: 0, max: 11.9, fundo: '#d83027', cor: '#FFFFFF' },
        { min: 10, max: 13.4, fundo: '#e75135', cor: '#FFFFFF' },
        { min: 13.5, max: 14.5, fundo: '#3DAC79', cor: '#FFFFFF' },
        { min: 14.6, max: 15.5, fundo: '#25719E', cor: '#FFFFFF' },
        { min: 15.6, max: 40, fundo: '#185C84', cor: '#FFFFFF' },
    ],
};
