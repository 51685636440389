export interface StatusComunicacao {
    dataMaisRecente: string;
    status: any;
}

export interface EquipamentoDataUltimaComunicacoInterface {
    id: string;
    nome: string;
    modelo: string;
    categoria: string;
    equipamento_comunicacao: {
        instante: string;
        status: boolean;
        }
}
