export const MENUS_CONFIGURACOES_ES = [
    {
        menu: 'CONFIGURACIONES',
        description: 'CONFIGURACIONES',
        link: '#',
        icon: 'icon-module-configuracoes',
        separator: true,
        permission: true,
        groups: [
            {
                title: 'Registro',
                icon: 'icon-cadastro',                
                separator: false,
                items: [
                    {
                        menu: "Perfiles",
                        icon: "icon-menu-perfis",
                        link: "configuracoes/perfis",
                    },
                    {
                        menu: "Usuarios",
                        icon: "icon-menu-usuarios",
                        link: "configuracoes/usuarios",
                    }
                    // {
                    //     menu: 'Permissões do Usuário',
                    //     icon: 'icon-menu-permissoes-usuarios',
                    //     link: 'page-builder',
                    // },
                    // {
                    //     menu: 'Versões do Sistema',
                    //     icon: 'icon-menu-versoes-sistema',
                    //     link: 'page-builder',
                    // },
                ],
            },
        ],
    },
];
