export enum EficienciaSecadoresEnum {
    EFICIENCIA_SECADORES = 'Eficiência dos secadores',
    SILO_ARMAZEM = 'Silo/Armazém',
    UNIDADES = 'Unidades',
    GRAO = 'Grão',
    CAPACIDADE_NOMINAL = 'Capacidade nominal (t/h)',
    CAPACIDADE_REAL = 'Capacidade real  (t/h)',
    EFICIENCIA = 'Eficiência (%)',
    CONSUMO_COMBUSTIVEL = 'Consumo de combustível',
    REAL = 'brl'
}

export enum CabecalhoEficienciaSecadoresEnum {
    SILO_ARMAZEM = 'siloArmazem',
    UNIDADES = 'unidades',
    GRAO = 'grao',
    CAPACIDADE_NOMINAL = 'capacidadeNominal',
    CAPACIDADE_REAL = 'capacidadeReal',
    EFICIENCIA = 'eficiencia',
    CONSUMO_COMBUSTIVEL = 'consumoCombustivel',
}
