import { Injectable } from '@angular/core';
import * as BABYLON from '@babylonjs/core';
import { AlturasGraoSilo3DInterface, PenduloSilo3DInterface, Silo3DPropriedadesInterface } from 'app/shared/interfaces';
import { ICriaTexturaGraoSilo3DService } from 'app/shared/services';
import { SiloBaseEnum } from 'app/shared/enums';
import { meshGraoSilo3D } from 'app/shared/constants';

export abstract class ICriaGraoSilo3DService {
    abstract execute(
        vetorSensores: BABYLON.Mesh[][],
        siloBase: string,
        penduloData: PenduloSilo3DInterface[],
        siloData: Silo3DPropriedadesInterface,
        cena: BABYLON.Scene
    ): void;
}

@Injectable({ providedIn: 'root' })
export class CriaGraoSilo3DService implements ICriaGraoSilo3DService {
    constructor(private criaTexturaGraoSilo3DService: ICriaTexturaGraoSilo3DService) {}
    async execute(
        vetorSensores: BABYLON.Mesh[][],
        siloBase: string,
        penduloData: PenduloSilo3DInterface[],
        siloData: Silo3DPropriedadesInterface,
        cena: BABYLON.Scene
    ): Promise<void> {
        this.resetaMeshesAnteriores(cena, meshGraoSilo3D);

        const vetorSensoresMaisAltoComGrao: BABYLON.Vector3[] = this.criaArraySensoresMaisAltosComGrao(
            penduloData,
            vetorSensores
        );

        let sensorMenorAlturaComGrao: number = Infinity;

        vetorSensoresMaisAltoComGrao.forEach((sensorGrao) => {
            if (sensorGrao.y < sensorMenorAlturaComGrao) {
                sensorMenorAlturaComGrao = sensorGrao.y;
            }
        });

        let { alturaCilindroGrao, alturaGraoFundo } = this.calculaAlturasGrao(
            siloBase,
            siloData,
            sensorMenorAlturaComGrao
        );

        let raioGraoDinamico = siloData?.diametroSilo / 2;
        let graoFundoV: BABYLON.Mesh;
        let fundoGraoSemiV: BABYLON.Mesh;

        let cilindroGrao = BABYLON.CreateCylinder('cilindroGrao', {
            height: alturaCilindroGrao,
            diameter: siloData?.diametroSilo,
            tessellation: 32,
        });

        cilindroGrao.position.y = siloData?.alturaPlataforma + siloData?.alturaPlataforma + alturaCilindroGrao / 2;

        let proporcao: number;
        let diametroProporcional: number;

        if (siloBase == SiloBaseEnum.EM_V) {
            cilindroGrao.position.y = siloData?.alturaPlataforma + siloData?.alturaFundo + alturaCilindroGrao / 2;

            proporcao = alturaGraoFundo / siloData?.alturaFundo;

            diametroProporcional = siloData?.diametroSilo * proporcao;

            if (sensorMenorAlturaComGrao < cilindroGrao.position.y) {
                cilindroGrao.visibility = 0;
                raioGraoDinamico = diametroProporcional / 2;
            }

            if (sensorMenorAlturaComGrao > siloData?.alturaFundo + siloData?.alturaPlataforma) {
                alturaGraoFundo = siloData?.alturaFundo;
                diametroProporcional = siloData?.diametroSilo;
            }

            graoFundoV = BABYLON.MeshBuilder.CreateCylinder(
                'fundoVgrao',
                {
                    height: alturaGraoFundo,
                    diameter: 0.2,
                    diameterTop: diametroProporcional,
                    tessellation: 32,
                },
                cena
            );
            graoFundoV.position.y = siloData?.alturaPlataforma + alturaGraoFundo / 2;

            if (sensorMenorAlturaComGrao == Infinity) {
                graoFundoV.visibility = 0;
            }
        } else if (siloBase == SiloBaseEnum.SEMI_V) {
            cilindroGrao.position.y = siloData?.alturaFundo + alturaCilindroGrao / 2;

            proporcao = sensorMenorAlturaComGrao / siloData?.alturaFundo;

            diametroProporcional =
                siloData?.diametroInferiorFundo +
                (siloData?.diametroSilo - siloData?.diametroInferiorFundo) * proporcao;

            if (sensorMenorAlturaComGrao < cilindroGrao.position.y) {
                cilindroGrao.visibility = 0;
                raioGraoDinamico = diametroProporcional / 2;
            }

            if (sensorMenorAlturaComGrao > siloData?.alturaFundo) {
                alturaGraoFundo = siloData?.alturaFundo;
                diametroProporcional = siloData?.diametroSilo;
            }

            fundoGraoSemiV = BABYLON.MeshBuilder.CreateCylinder(
                'fundoSemiVGrao',
                {
                    height: alturaGraoFundo,
                    diameter: siloData?.diametroInferiorFundo,
                    diameterTop: diametroProporcional,
                    tessellation: 32,
                },
                cena
            );
            fundoGraoSemiV.position.y = alturaGraoFundo / 2;

            if (sensorMenorAlturaComGrao == Infinity) {
                fundoGraoSemiV.visibility = 0;
            }
        }

        const vetorPontosGrao = this.criaVetoresPontosGrao(
            siloBase,
            raioGraoDinamico,
            alturaCilindroGrao,
            siloData,
            cena
        );

        const vetorPontosSensores = [];
        const vetorPontos = [];

        for (const sensorGrao of vetorSensoresMaisAltoComGrao) {
            for (let i = 0; i < vetorPontosGrao.length; i++) {
                vetorPontosSensores.push(sensorGrao);
            }
            for (const pontoGrao of vetorPontosGrao) {
                vetorPontos.push(pontoGrao.position);
            }
        }

        const graoDinamico = BABYLON.MeshBuilder.CreateRibbon('graoDinamico', {
            pathArray: [vetorPontosSensores, vetorPontos],
            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
            closePath: true,
        });

        if (siloBase == SiloBaseEnum.PLANA) {
            this.criaTexturaGraoSilo3DService.execute(cena, graoDinamico, cilindroGrao, null);
        } else if (siloBase == SiloBaseEnum.SEMI_V) {
            this.criaTexturaGraoSilo3DService.execute(cena, graoDinamico, cilindroGrao, fundoGraoSemiV);
        } else if (siloBase == SiloBaseEnum.EM_V) {
            this.criaTexturaGraoSilo3DService.execute(cena, graoDinamico, cilindroGrao, graoFundoV);
        }
    }

    private calculaAlturasGrao(
        siloBase: string,
        siloData: Silo3DPropriedadesInterface,
        sensorMenorAltura: number
    ): AlturasGraoSilo3DInterface {
        let alturaCilindroGrao: number;
        let alturaGraoFundo: number = sensorMenorAltura;

        switch (siloBase) {
            case SiloBaseEnum.PLANA:
                alturaCilindroGrao = sensorMenorAltura - siloData?.alturaPlataforma * 2;
                break;
            case SiloBaseEnum.EM_V:
                alturaCilindroGrao = sensorMenorAltura - siloData?.alturaPlataforma - siloData?.alturaFundo;
                alturaGraoFundo = sensorMenorAltura - siloData?.alturaPlataforma;
                break;
            default:
                alturaCilindroGrao = sensorMenorAltura - siloData?.alturaFundo;
                break;
        }
        return { alturaCilindroGrao, alturaGraoFundo };
    }

    private criaVetoresPontosGrao(
        siloBase: string,
        raioGraoDinamico: number,
        alturaCilindroGrao: number,
        siloData: Silo3DPropriedadesInterface,
        cena: BABYLON.Scene
    ): BABYLON.Mesh[] {
        const quantidadePontos = 32;

        let vetorPontosGrao = [];

        for (let i = 0; i < quantidadePontos; i++) {
            const diametroEsferas = 0.05;
            const angulo = (i / quantidadePontos) * Math.PI * 2;
            const x = Math.sin(angulo) * raioGraoDinamico;
            const z = Math.cos(angulo) * raioGraoDinamico;
            let y = 0;
            switch (siloBase) {
                case SiloBaseEnum.PLANA:
                    y = siloData?.alturaPlataforma + siloData?.alturaPlataforma + alturaCilindroGrao;
                    break;
                case SiloBaseEnum.EM_V:
                    y = siloData?.alturaPlataforma + siloData?.alturaFundo + alturaCilindroGrao;
                    break;
                default:
                    y = siloData?.alturaFundo + alturaCilindroGrao;
                    break;
            }

            const ponto = BABYLON.MeshBuilder.CreateSphere(`ponto${i * 0.0625}`, { diameter: diametroEsferas }, cena);

            ponto.position = new BABYLON.Vector3(x, y, z);
            ponto.isVisible = false;

            vetorPontosGrao.push(ponto);
        }
        return vetorPontosGrao;
    }

    private resetaMeshesAnteriores(cena: BABYLON.Scene, nomeMesh: string[]): void {
        nomeMesh.forEach((nome) => {
            const mesh = cena.getMeshByName(nome);
            mesh?.dispose();
        });
    }

    private criaArraySensoresMaisAltosComGrao(
        penduloData: PenduloSilo3DInterface[],
        vetorSensores: BABYLON.Mesh[][]
    ): BABYLON.Vector3[] {
        let vetorSensoresGrao: BABYLON.Vector3[] = [];
        for (let i = 0; i < penduloData.length; i++) {
            let posicaoSensorMaisAlto: BABYLON.Vector3;
            let valorNivelPendulo = penduloData[i].nivelVolume - 1;

            if (valorNivelPendulo >= 0) {
                posicaoSensorMaisAlto = vetorSensores[i][valorNivelPendulo].position;
                vetorSensoresGrao.push(posicaoSensorMaisAlto);
            }
        }
        return vetorSensoresGrao;
    }
}
