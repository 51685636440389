import { Injectable } from '@angular/core';
import * as BABYLON from '@babylonjs/core';
import * as GUI from '@babylonjs/gui/2D';
import { PenduloSilo3DInterface } from 'app/shared/interfaces';

export abstract class IMostrarTemperaturaPenduloSilo3DService {
    abstract execute(
        indexBotao: number,
        texturaAvancada,
        retangulosTemperatura: { [key: number]: GUI.Rectangle[] },
        retangulosTexto: { [key: number]: GUI.Rectangle[] },
        vetoresInfoBotoes,
        temperaturas: { [key: number]: GUI.TextBlock[] },
        vetorSensoresPendulo: BABYLON.Mesh[],
        botao,
        targetBotao,
        penduloData: PenduloSilo3DInterface[],
        cena: BABYLON.Scene,
        carregamentoInicial?: boolean
    ): void;
}

@Injectable({ providedIn: 'root' })
export class MostrarTemperaturaPenduloSilo3DService implements IMostrarTemperaturaPenduloSilo3DService {
    private botoesAtivados: { [key: number]: boolean } = {};

    async execute(
        indexBotao: number,
        thad,
        retangulosTemperatura: { [key: number]: GUI.Rectangle[] },
        retangulosTexto: { [key: number]: GUI.Rectangle[] },
        vetoresInfoBotoes,
        temperaturas: { [key: number]: GUI.TextBlock[] },
        vetorSensoresPendulo: BABYLON.Mesh[],
        botao,
        targetBotao,
        penduloData: PenduloSilo3DInterface[],
        cena: BABYLON.Scene,
        carregamentoInicial?: boolean
    ): Promise<void> {
        if (this.botoesAtivados[indexBotao] === undefined || carregamentoInicial) {
            this.botoesAtivados[indexBotao] = false;
        }

        if (!this.botoesAtivados[indexBotao]) {
            let qtdText = 0;
            let valorTemperaturaPendulo: string;
            let stringTemperaturaPendulo: string;

            targetBotao.background = this.retornarEscalaCor(penduloData[indexBotao].mediaTemperatura);
            botao.background = this.retornarEscalaCor(penduloData[indexBotao].mediaTemperatura);

            if (!penduloData[indexBotao].tempSensores) {
                penduloData[indexBotao].tempSensores = [];
            }

            if (!retangulosTexto[indexBotao]) {
                retangulosTexto[indexBotao] = [];
            }

            if (!retangulosTemperatura[indexBotao]) {
                retangulosTemperatura[indexBotao] = [];
            }

            vetorSensoresPendulo.forEach((sensor, i) => {
                qtdText++;

                valorTemperaturaPendulo = penduloData[indexBotao].tempSensores[i];
                stringTemperaturaPendulo = penduloData[indexBotao].tempSensoresStr[i];

                let retanguloTemperatura = thad.texturaAvancada?.getControlByName(
                    `retanguloTemperatura_${qtdText}_botao_${indexBotao + 1}`
                ) as GUI.Rectangle;

                if (!retanguloTemperatura) {
                    retanguloTemperatura = new GUI.Rectangle(`retanguloTemperatura_${qtdText}_botao_${indexBotao + 1}`);
                    retanguloTemperatura.background = 'white';
                    retanguloTemperatura.width = '45px';
                    retanguloTemperatura.height = '16px';
                    retanguloTemperatura.cornerRadius = 5;
                    retanguloTemperatura.thickness = 0;
                    retanguloTemperatura.alpha = 0.8;

                    let valortemperatura: number | undefined;
                    if (i < penduloData[indexBotao].nivelVolume) {
                        if (!valorTemperaturaPendulo.includes('---') || i < penduloData[indexBotao].nivelVolume) {
                            valortemperatura = parseFloat(valorTemperaturaPendulo);
                        }
                    }
                    retanguloTemperatura.background = this.retornarEscalaCor(valortemperatura);
                    thad.texturaAvancada.addControl(retanguloTemperatura);
                }

                let numeroPendulo = new GUI.TextBlock(
                    `numeroPendulo_${qtdText}_botao_${indexBotao + 1}`,
                    stringTemperaturaPendulo.split(':')[0] + ':'
                );
                numeroPendulo.fontSize = '11px';
                numeroPendulo.textHorizontalAlignment = 3;
                numeroPendulo.paddingLeft = '2px';
                numeroPendulo.paddingTop = '2px';
                retanguloTemperatura.addControl(numeroPendulo);

                var indiceDoDoisPontos = stringTemperaturaPendulo.indexOf(':');

                let temperatura = new GUI.TextBlock(
                    `temperatura_${qtdText}_botao_${indexBotao + 1}`,
                    stringTemperaturaPendulo.substring(indiceDoDoisPontos)
                );
                temperatura.fontSize = '12px';
                temperatura.textHorizontalAlignment = 3;
                temperatura.paddingLeft = '14px';
                temperatura.paddingTop = '2px';
                temperatura.fontWeight = 'bold';
                retanguloTemperatura.addControl(temperatura);

                retanguloTemperatura.linkWithMesh(sensor);

                vetoresInfoBotoes.forEach((array) => {
                    if (!array[indexBotao]) {
                        array[indexBotao] = [];
                    }
                });

                temperaturas[indexBotao].push(temperatura);
                retangulosTemperatura[indexBotao].push(retanguloTemperatura);
            });

            this.botoesAtivados[indexBotao] = true;
        } else {
            targetBotao.background = 'white';
            botao.background = 'white';

            if (temperaturas[indexBotao]) {
                for (let temperatura of temperaturas[indexBotao]) {
                    temperatura.dispose();
                }
                temperaturas[indexBotao] = [];
            }

            if (retangulosTemperatura[indexBotao]) {
                for (let retanguloTemperatura of retangulosTemperatura[indexBotao]) {
                    retanguloTemperatura.dispose();
                }
                retangulosTemperatura[indexBotao] = [];
            }

            this.botoesAtivados[indexBotao] = false;
        }

        cena.render();
    }

    private retornarEscalaCor(valor: number): string {
        let hexBackground: string;
        const limitesTemperatura = [6, 12, 18, 24, 30, 36];
        const coresBackground = ['#6473f5', '#58bef3', '#79cbf5', '#64bd94', '#64ed7b', '#deed7b', '#de737b'];

        if (valor !== undefined && !Number.isNaN(valor)) {
            const indiceCor = limitesTemperatura.findIndex((limite) => valor! < limite);
            hexBackground = indiceCor !== -1 ? coresBackground[indiceCor] : coresBackground[coresBackground.length - 1];
        } else {
            hexBackground = '#cccccc';
        }
        return hexBackground;
    }
}
