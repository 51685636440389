import {
    ApexAxisChartSeries,
    ApexChart,
    ApexDataLabels,
    ApexGrid,
    ApexLegend,
    ApexMarkers,
    ApexNonAxisChartSeries,
    ApexPlotOptions,
    ApexStroke,
    ApexTitleSubtitle,
    ApexXAxis,
    ApexYAxis,
    ApexTooltip,
} from 'ng-apexcharts';

export type GraficoOpcoes = {
    series: ApexAxisChartSeries | ApexNonAxisChartSeries;
    chart: ApexChart;
    labels: string[];
    plotOptions: ApexPlotOptions;
    ApexXAxis: ApexXAxis;
    yaxis: ApexYAxis;
    xaxis: ApexXAxis;
    grid: ApexGrid;
    colors: string[];
    legend: ApexLegend;
    stroke?: ApexStroke;
    dataLabels?: ApexDataLabels;
    markers?: ApexMarkers;
    tooltip?: ApexTooltip;
    title?: ApexTitleSubtitle;
    noData?: {};
};
