import { Injectable } from '@angular/core';
import { IdiomaEnum, IdiomaSiglaEnum } from 'app/shared/enums';

export abstract class IRetornaIdiomaPelaSiglaService {
    abstract execute(sigla: string): IdiomaEnum;
}

@Injectable()
export class RetornaIdiomaPelaSiglaService implements IRetornaIdiomaPelaSiglaService {
    execute(sigla: string): IdiomaEnum {
        switch (sigla) {
            case IdiomaSiglaEnum.PORTUGUES:
                return IdiomaEnum.PORTUGUES;
            case IdiomaSiglaEnum.INGLES:
                return IdiomaEnum.INGLES;
            case IdiomaSiglaEnum.ESPANHOL:
                return IdiomaEnum.ESPANHOL;
        }
    }
}
