import { Injectable } from '@angular/core';
import moment from 'moment';
import { formataDataComHora } from 'app/shared/constants';
import { AeradorInterface } from '../interfaces';

export abstract class IRetonaInstanteMaisRecenteAeradoresService {
    abstract execute(aeradores: any[], statusCode: any): string;
}

@Injectable({
    providedIn: 'root',
})
export class RetonaInstanteMaisRecenteAeradoresService implements IRetonaInstanteMaisRecenteAeradoresService {
    constructor() {}

    execute(aeradores: any[], statusCode: any): string {
        let datas = [];
        switch (statusCode) {
            case '0A':
            case '0B':
                datas = aeradores?.map((aeradorItem) =>
                    moment(
                        aeradorItem.aerador.aerador_leitura_chave.instante ||
                        aeradorItem.aerador.aerador_leitura_rele.instante ||
                        aeradorItem.aerador.aerador_leitura_status.instante
                    )
                );
            case '1':
            case '1A':
            case '1B':
                datas = aeradores?.map((aeradorItem) =>
                aeradorItem.aerador.aerador_leitura_rele.instante
                        ? moment(aeradorItem.aerador.aerador_leitura_rele.instante)
                        : ''
                );
            case '2A':
            case '2B':
                datas = aeradores?.map((aeradorItem) =>
                    aeradorItem.aerador.aerador_leitura_chave?.instante
                        ? moment(aeradorItem.aerador.aerador_leitura_chave?.instante)
                        : ''
                );
            case '3A':
            case '3B':
                datas = aeradores?.map((aeradorItem) =>
                    aeradorItem.aerador.aerador_leitura_status.instante
                        ? moment(aeradorItem.aerador.aerador_leitura_status.instante)
                        : ''
                );
                return datas.filter((datas) => datas != '').length > 0
                    ? moment
                          .max(datas.filter((datas) => datas != ''))
                          ?.locale(formataDataComHora.locale)
                          .format(formataDataComHora.formato)
                    : '';
        }
    }
}
