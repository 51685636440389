export type EntendaValoresValue = {
    value: string;
    label: string;
};

export enum EntendaValoresEnum {
    T0_0 = '0,0',
    T1_0 = '1,0',
    T2_0 = '2,0',
    T3_0 = '3,0',
    T4_0 = '4,0',
}

const labels = {
    T0_0: '0,0',
    T1_0: '1,0',
    T2_0: '2,0',
    T3_0: '3,0',
    T4_0: '4,0',
};

export default abstract class EntendaValores {
    public static getLabels(): EntendaValoresValue[] {
        const obj = Object.keys(EntendaValoresEnum);
        const ret = [];
        for (const x of obj) {
            ret.push({
                value: EntendaValoresEnum[x],
                label: labels[x],
            });
        }
        return ret;
    }

    public static getLabel(item: any): string {
        const index = Object.values(EntendaValoresEnum).indexOf(item);
        return labels[Object.keys(EntendaValoresEnum)[index]];
    }
}
