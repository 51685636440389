export enum TabelaOutrosSecadores {
    SECADOR = 'secador',
    UNIDADES = 'unidades',
    GRAO = 'grao',
    OPERACAO = 'operacao',
    LIMPEZA_DO_SECADOR = 'limpezaDoSecador',
    MODAL = 'modal',
    DURACAO = 'duracao',
    TERMINO = 'termino'
}

export enum TabelaOutrosSecadoresPage {
    PAGE_SIZE = 5,
    CURRENT_PAGE = 0,
    TOTAL_ITENS = 0,
}

export enum GraficoOutrosSecadoresName {
    VELOCIDADE_DE_DESCARGA = 'Velocidade de descarga (%)',
    EFICIENCIA_DO_SECADOR = 'Eficiência do secador (%)',
    CONSUMO_DE_COMBUSTIVEL = 'Consumo de Combustível (m³/h)',
    MEDIDOR_DE_FLUXO = 'Medidor de fluxo 1 (t/h)',
    MEDIDOR_DE_FLUXO_2 = 'Medidor de fluxo 2 (t/h)',
    PRESSAO_DA_ENTRADA_DE_AR = 'Pressão da entrada de ar (mmCA)',
    PRESSAO_DA_SAIDA_DE_AR = 'Pressão da saída de ar (mmCA)',
}

export enum OrdenacaoOutrosSecadores {
    ASC = 'ASC',
    DESC = 'DESC',
}
