import { Injectable } from '@angular/core';
import { EstadoComunicacaoEnum } from 'app/shared/enums';

export abstract class IRetornaStatusComunicacaoService {
    abstract execute(status: boolean[]): string;
}

@Injectable({
    providedIn: 'root',
})
export class RetornaStatusComunicacaoService {
    execute(status: any[]): string {
        const statusSemIndefinidos = status.map((s) => {            
            if (s === 'indefinido') {
                return false;
            } else {
                return s;
            }
        });
            
        if (statusSemIndefinidos.every((e) => e) && statusSemIndefinidos.length) {            
            return EstadoComunicacaoEnum.ALL;
        } else if (statusSemIndefinidos.some((e) => e)) {
            return EstadoComunicacaoEnum.PARTIAL;
        } else {
            return EstadoComunicacaoEnum.NONE;
        }
    }
}
