import { Injectable } from '@angular/core';
import { FormaGeometricaPenduloEnum } from 'app/shared/enums';
import { EquipamentoVisaoSuperiorInterface, PenduloVisaoSuperiorInterface } from 'app/shared/interfaces';
import * as d3 from 'd3';
import { ICriaPaletaCoresTermometriaService } from './cria-paleta-cores-termometria';
import { DecimalPipe } from '@angular/common';
export abstract class IMontaFormaGeometricaVisaoSuperiorArmazemService {
    abstract montaPendulo(
        svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>,
        objetoPendulo: PenduloVisaoSuperiorInterface,
        forma: FormaGeometricaPenduloEnum,
        tipoTemperatura: string,
        equipamentos?: EquipamentoVisaoSuperiorInterface[]
    ): void;

    abstract montaFormaGeometrica(
        metodo: string,
        x: number,
        y: number,
        lado: number,
        stroke: string,
        tipoSensor?: string,
        fill?: string,
        svg?: any
    ): void;
}

@Injectable({ providedIn: 'root' })
export class MontaFormaGeometricaVisaoSuperiorArmazemService
    implements IMontaFormaGeometricaVisaoSuperiorArmazemService
{
    constructor(
        private criaPaletaCoresTermometriaService: ICriaPaletaCoresTermometriaService,
        private decimalPipe: DecimalPipe
    ) {}
    montaFormaGeometrica(
        metodo: FormaGeometricaPenduloEnum,
        x: number,
        y: number,
        lado: number,
        stroke: string,
        tipoSensor?: string,
        fill?: string,
        svg?: any
    ): void {
        this[this.defineFuncaoMontarFormaGeometrica(metodo)](x, y, lado, stroke, tipoSensor, fill, svg);
    }

    montaPendulo(
        svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>,
        objetoPendulo: PenduloVisaoSuperiorInterface,
        forma: FormaGeometricaPenduloEnum,
        tipoTemperatura: string,
        equipamentos?: EquipamentoVisaoSuperiorInterface[]
    ): void {
        const equipamentoPendulo = equipamentos?.find(
            (equipamento) => equipamento.id === objetoPendulo.pendulo_canal.equipamento_id
        )?.equipamento_comunicacao?.status;
        const tamanhoPendulo = 20;
        const tamanhoPenduloInterno = 14;
        const posicao_x_arco = parseFloat(
            d3.select(`#arco${objetoPendulo.armazem_pendulo_visualizacao.arco - 1}`).attr('x1')
        );
        const posicao_y_linha = parseFloat(
            d3.select(`#linha${objetoPendulo.armazem_pendulo_visualizacao.linha - 1}`).attr('y1')
        );

        const tipoSensor =
            objetoPendulo.pendulo_propriedade.sensor_umidade_quantidade > 0
                ? 'umidade'
                : objetoPendulo.pendulo_propriedade.digital_termopar;

        this[this.defineFuncaoMontarFormaGeometrica(forma)](
            posicao_x_arco - tamanhoPendulo / 2,
            posicao_y_linha - tamanhoPendulo / 2,
            tamanhoPendulo,
            tipoSensor === 'termopar' ? '#A44040' : '#16171B',
            tipoSensor,
            equipamentoPendulo
                ? this.criaPaletaCoresTermometriaService.criarPaletaTemperatura(
                      objetoPendulo.pendulo_ultima_leitura[tipoTemperatura]
                  ).fundo
                : '#868994',
            svg,
            objetoPendulo.codigo,
            objetoPendulo.pendulo_canal.equipamento_id
        );

        if (tipoSensor === 'termopar') {
            this[this.defineFuncaoMontarFormaGeometrica(forma)](
                posicao_x_arco - tamanhoPendulo / 2,
                posicao_y_linha - tamanhoPendulo / 2,
                tamanhoPenduloInterno,
                '#A44040',
                tipoSensor,
                'none',
                svg
            );
        }

        this.addCodigoPendulo(
            posicao_x_arco - tamanhoPendulo / 2,
            posicao_y_linha - tamanhoPendulo / 2,
            equipamentoPendulo
                ? this.criaPaletaCoresTermometriaService.criarPaletaTemperatura(
                      objetoPendulo.pendulo_ultima_leitura.med
                  ).cor
                : '#FFFFFF',
            objetoPendulo.codigo,
            forma,
            svg
        );

        this.formaContainerTooltip(
            posicao_x_arco - tamanhoPendulo / 2,
            posicao_y_linha - tamanhoPendulo / 2 + 1,
            tamanhoPendulo,
            svg,
            objetoPendulo.codigo
        );
        this.montaTooltipPendulo(objetoPendulo);
    }

    montaTooltipPendulo(objetoPendulo: PenduloVisaoSuperiorInterface): void {
        let thisAuxiliar = this;
        const elementoPendulo = d3.select(`#tooltipPendulo${objetoPendulo?.codigo}`);
        const tooltip = d3.select('#containerArmamzem').append('div').attr('class', 'tooltipPendulo');
        const retanguloArmazem = d3.select('#retanguloArmazem');
        elementoPendulo
            .data([objetoPendulo])
            .on('mousemove', function (d) {
                tooltip.style('visibility', 'visible');
                tooltip
                    .html(
                        `
                        <div class="shadow-md">
                            <p class="bg-[#E8EDF4] h-5 min-w-[150px] text-center"> P${d.srcElement.__data__?.codigo}</p>
                            <p class="bg-[#FFF] h-5 min-w-[150px] px-1"> Min: ${
                                d.srcElement.__data__?.pendulo_ultima_leitura?.min
                                    ? thisAuxiliar.decimalPipe.transform(
                                          d.srcElement.__data__?.pendulo_ultima_leitura?.min,
                                          '1.1-1'
                                      ) + '°C'
                                    : '-'
                            }</p>
                            <p class="bg-[#FFF] h-5 min-w-[150px] px-1"> Med: ${
                                d.srcElement.__data__?.pendulo_ultima_leitura?.med
                                    ? thisAuxiliar.decimalPipe.transform(
                                          d.srcElement.__data__?.pendulo_ultima_leitura?.med,
                                          '1.1-1'
                                      ) + '°C'
                                    : '-'
                            }</p>
                            <p class="bg-[#FFF] h-5 min-w-[150px] px-1"> Max: ${
                                d.srcElement.__data__?.pendulo_ultima_leitura?.max
                                    ? thisAuxiliar.decimalPipe.transform(
                                          d.srcElement.__data__?.pendulo_ultima_leitura?.max,
                                          '1.1-1'
                                      ) + '°C'
                                    : '-'
                            } </p>
                            <p class="bg-[#FFF] h-5 min-w-[150px] px-1"> Nível: ${
                                d.srcElement.__data__?.pendulo_nivel_sensor.nivel_sensor ?? '-'
                            }/${d.srcElement.__data__?.pendulo_propriedade.sensor_temperatura_quantidade ?? '-'} (${
                            d.srcElement.__data__?.pendulo_nivel_sensor.nivel_sensor &&
                            d.srcElement.__data__?.pendulo_propriedade.sensor_temperatura_quantidade
                                ? thisAuxiliar.decimalPipe.transform(
                                      (d.srcElement.__data__?.pendulo_nivel_sensor.nivel_sensor /
                                          d.srcElement.__data__?.pendulo_propriedade.sensor_temperatura_quantidade) *
                                          100,
                                      '1.1-1'
                                  ) + '%'
                                : '-'
                        })</p>
                        </div>
                        `
                    )
                    .style('position', 'absolute')
                    .style(
                        'left',
                        `${
                            d.offsetX + tooltip.node().offsetWidth > parseFloat(retanguloArmazem.attr('width'))
                                ? d.offsetX - tooltip.node().offsetWidth - 10
                                : d.offsetX + 10
                        }px`
                    )
                    .style('top', `${d.offsetY - 110}px`);
            })
            .on('mouseout', function (d) {
                tooltip.style('visibility', 'hidden');
            });
    }

    addCodigoPendulo(
        x: number,
        y: number,
        fill: string,
        codigo: number,
        forma: FormaGeometricaPenduloEnum,
        svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>
    ): void {
        const tamanhoFont = 8;

        let pos_x;
        let pos_y;
        if (this.retonarMargemLabelCodigo(forma)) {
            pos_x = x + this.retonarMargemLabelCodigo(forma);
            pos_y = y + this.retonarMargemLabelCodigo(forma);
        } else {
            pos_x = x;
            pos_y = y;
        }
        svg.select('g')
            .append('g')
            .attr('id', `${codigo ? 'grupoPendulo' + codigo : ''}`)
            ?.append('text')
            .attr('font-size', `${tamanhoFont}`)
            .attr('dominant-baseline', 'middle')
            .attr('text-anchor', 'middle')
            .attr('x', `${pos_x}`)
            .attr('y', `${pos_y + 1}`)
            .attr('fill', `${fill}`)
            .attr('id', `${codigo ? 'labelPendulo' + codigo : ''}`)
            .text(codigo);
    }

    retonarMargemLabelCodigo(forma: FormaGeometricaPenduloEnum): number {
        switch (forma) {
            case FormaGeometricaPenduloEnum.QUADRADO:
                return 9;
            case FormaGeometricaPenduloEnum.PENTAGONO:
                return 11;
            case FormaGeometricaPenduloEnum.TRIANGULO:
                return 17;
            case FormaGeometricaPenduloEnum.OCTAGONO:
                return 11;
            case FormaGeometricaPenduloEnum.CIRCUNFERENCIA:
                return 11;
            case FormaGeometricaPenduloEnum.TRAPAZIO:
                return 9;
            default:
                return 9;
        }
    }

    private defineFuncaoMontarFormaGeometrica(forma: FormaGeometricaPenduloEnum): string {
        switch (forma) {
            case FormaGeometricaPenduloEnum.QUADRADO:
                return 'formaQuadrado';
            case FormaGeometricaPenduloEnum.PENTAGONO:
                return 'formaPentagono';
            case FormaGeometricaPenduloEnum.TRIANGULO:
                return 'formaTriangulo';
            case FormaGeometricaPenduloEnum.OCTAGONO:
                return 'formaOctagono';
            case FormaGeometricaPenduloEnum.CIRCUNFERENCIA:
                return 'formaCircunferencia';
            case FormaGeometricaPenduloEnum.TRAPAZIO:
                return 'formaTrapezio';
            default:
                return 'formaQuadrado';
        }
    }

    private formaQuadrado(
        x: number,
        y: number,
        lado: number,
        stroke: string,
        tipoSensor?: string,
        fill?: string,
        svg?: any,
        codigo?: number,
        idEquipamento?: string
    ): void {
        const pos_x = fill === 'none' ? x + 3 : x;
        const pos_y = fill === 'none' ? y + 3 : y;
        svg.select('g')
            .append('g')
            .attr('id', `${codigo ? 'grupoPendulo' + codigo : ''}`)
            ?.append('rect')
            .attr('width', `${lado}`)
            .attr('height', `${lado}`)
            .attr('stroke-width', '1.5')
            .attr('stroke', `${stroke}`)
            .attr('fill', `${fill}`)
            .attr('x', `${pos_x}`)
            .attr('y', `${pos_y}`)
            .attr('stroke-dasharray', `${tipoSensor === 'umidade' ? '3 3' : ''}`)
            .attr(`${idEquipamento ? 'class' : 'sem-equipamento'}`, `${idEquipamento ? 'equip' + idEquipamento : ''}`)
            .attr('id', `${codigo ? 'pendulo' + codigo : ''}`);
    }

    private formaPentagono(
        x: number,
        y: number,
        lado: number,
        stroke: string,
        tipoSensor: string,
        fill: string,
        svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>,
        codigo: number,
        idEquipamento?: string
    ): void {
        const pos_x = fill === 'none' ? x + 3.4 : x;
        const pos_y = fill === 'none' ? y + 3.4 : y;

        let vertices = [];
        const tamanho = lado * 0.6;
        for (let i = 0; i < 5; i++) {
            const posicao_x = tamanho * Math.cos(((2 * Math.PI) / 5) * i - Math.PI / 2) + tamanho;
            const posicao_y = tamanho * Math.sin(((2 * Math.PI) / 5) * i - Math.PI / 2) + tamanho;
            vertices.push(posicao_x + ',' + posicao_y);
        }
        svg.select('g')
            .append('g')
            .attr('id', `${codigo ? 'grupoPendulo' + codigo : ''}`)
            ?.append('polygon')
            .attr('points', vertices.join(' '))
            .attr('stroke-width', '1.5')
            .attr('stroke', `${stroke}`)
            .attr('fill', `${fill}`)
            .attr('transform', `translate(${pos_x},${pos_y})`)
            .attr('stroke-dasharray', `${tipoSensor === 'umidade' ? '3 3' : ''}`)
            .attr(`${idEquipamento ? 'class' : 'sem-equipamento'}`, `${idEquipamento ? 'equip' + idEquipamento : ''}`)
            .attr('id', `${codigo ? 'pendulo' + codigo : ''}`);
    }

    private formaTriangulo(
        x: number,
        y: number,
        lado: number,
        stroke: string,
        tipoSensor: string,
        fill: string,
        svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>,
        codigo: number,
        idEquipamento?: string
    ): void {
        const pos_x = fill === 'none' ? x + 7 : x;
        const pos_y = fill === 'none' ? y + 7 : y;

        let vertices = [];
        const tamanho = lado * (fill === 'none' ? 0.7 : 0.8);
        for (let i = 0; i < 3; i++) {
            const posicao_x = tamanho * Math.cos(((2 * Math.PI) / 3) * i - Math.PI / 2) + tamanho;
            const posicao_y = tamanho * Math.sin(((2 * Math.PI) / 3) * i - Math.PI / 2) + tamanho;
            vertices.push(posicao_x + ',' + posicao_y);
        }
        svg.select('g')
            .append('g')
            .attr('id', `${codigo ? 'grupoPendulo' + codigo : ''}`)
            ?.append('polygon')
            .attr('points', vertices.join(' '))
            .attr('stroke-width', '1.5')
            .attr('stroke', `${stroke}`)
            .attr('fill', `${fill}`)
            .attr('transform', `translate(${pos_x},${pos_y})`)
            .attr('stroke-dasharray', `${tipoSensor === 'umidade' ? '3 3' : ''}`)
            .attr(`${idEquipamento ? 'class' : 'sem-equipamento'}`, `${idEquipamento ? 'equip' + idEquipamento : ''}`)
            .attr('id', `${codigo ? 'pendulo' + codigo : ''}`);
    }

    private formaOctagono(
        x: number,
        y: number,
        lado: number,
        stroke: string,
        tipoSensor: string,
        fill: string,
        svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>,
        codigo: number,
        idEquipamento?: string
    ): void {
        const pos_x = fill === 'none' ? x + 4 : x;
        const pos_y = fill === 'none' ? y + 4 : y;

        let vertices = [];
        const tamanho = lado * (fill === 'none' ? 0.59 : 0.6);
        for (let i = 0; i < 8; i++) {
            const posicao_x = tamanho * Math.cos(((2 * Math.PI) / 8) * i - Math.PI / 2) + tamanho;
            const posicao_y = tamanho * Math.sin(((2 * Math.PI) / 8) * i - Math.PI / 2) + tamanho;
            vertices.push(posicao_x + ',' + posicao_y);
        }
        svg.select('g')
            .append('g')
            .attr('id', `${codigo ? 'grupoPendulo' + codigo : ''}`)
            ?.append('polygon')
            .attr('points', vertices.join(' '))
            .attr('stroke-width', '1.5')
            .attr('stroke', `${stroke}`)
            .attr('fill', `${fill}`)
            .attr('transform', `translate(${pos_x},${pos_y})`)
            .attr('stroke-dasharray', `${tipoSensor === 'umidade' ? '8 1' : ''}`)
            .attr(`${idEquipamento ? 'class' : 'sem-equipamento'}`, `${idEquipamento ? 'equip' + idEquipamento : ''}`)
            .attr('id', `${codigo ? 'pendulo' + codigo : ''}`);
    }

    private formaCircunferencia(
        x: number,
        y: number,
        lado: number,
        stroke: string,
        tipoSensor: string,
        fill: string,
        svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>,
        codigo: number,
        idEquipamento?: string
    ): void {
        const pos_x = fill === 'none' ? x + 4 : x;
        const pos_y = fill === 'none' ? y + 4 : y;
        let vertices = [];
        const tamanho = lado * (fill === 'none' ? 0.59 : 0.6);
        for (let i = 0; i < 25; i++) {
            const posicao_x = tamanho * Math.cos(((2 * Math.PI) / 25) * i - Math.PI / 2) + tamanho;
            const posicao_y = tamanho * Math.sin(((2 * Math.PI) / 25) * i - Math.PI / 2) + tamanho;
            vertices.push(posicao_x + ',' + posicao_y);
        }
        svg.select('g')
            .append('g')
            .attr('id', `${codigo ? 'grupoPendulo' + codigo : ''}`)
            ?.append('polygon')
            .attr('points', vertices.join(' '))
            .attr('stroke-width', '1.5')
            .attr('stroke', `${stroke}`)
            .attr('fill', `${fill}`)
            .attr('transform', `translate(${pos_x},${pos_y})`)
            .attr('stroke-dasharray', `${tipoSensor === 'umidade' ? '5 3' : ''}`)
            .attr(`${idEquipamento ? 'class' : 'sem-equipamento'}`, `${idEquipamento ? 'equip' + idEquipamento : ''}`)
            .attr('id', `${codigo ? 'pendulo' + codigo : ''}`);
    }

    private formaTrapezio(
        x: number,
        y: number,
        lado: number,
        stroke: string,
        tipoSensor: string,
        fill: string,
        svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>,
        codigo: number,
        idEquipamento?: string
    ): void {
        const nameSpace = 'http://www.w3.org/2000/svg';
        const pos_x = fill === 'none' ? x + 3.9 : x;
        const pos_y = fill === 'none' ? y + 3.9 : y;
        let vertices = [];
        const tamanho = lado * (fill === 'none' ? 0.8 : 0.9);
        for (let i = 0; i < 4; i++) {
            if (i === 0) {
                const posicao_x = '0';
                const posicao_y = '0';
                vertices.push(posicao_x + ',' + posicao_y);
            } else if (i === 1) {
                const posicao_x = `${tamanho}`;
                const posicao_y = '0';
                vertices.push(posicao_x + ',' + posicao_y);
            } else if (i === 2) {
                const posicao_x = `${tamanho + tamanho / 2}`;
                const posicao_y = `${tamanho}`;
                vertices.push(posicao_x + ',' + posicao_y);
            } else if (i === 3) {
                const posicao_x = `${0}`;
                const posicao_y = `${tamanho}`;
                vertices.push(posicao_x + ',' + posicao_y);
            }
        }
        svg.select('g')
            .append('g')
            .attr('id', `${codigo ? 'grupoPendulo' + codigo : ''}`)
            ?.append('polygon')
            .attr('points', vertices.join(' '))
            .attr('stroke-width', '1.5')
            .attr('stroke', `${stroke}`)
            .attr('fill', `${fill}`)
            .attr('transform', `translate(${pos_x},${pos_y})`)
            .attr('stroke-dasharray', `${tipoSensor === 'umidade' ? '5 3' : ''}`)
            .attr(`${idEquipamento ? 'class' : 'sem-equipamento'}`, `${idEquipamento ? 'equip' + idEquipamento : ''}`)
            .attr('id', `${codigo ? 'pendulo' + codigo : ''}`);
    }

    private formaContainerTooltip(x: number, y: number, lado: number, svg?: any, codigo?: number): void {
        svg.select('g')
            ?.append('rect')
            .attr('id', `${codigo ? 'tooltipPendulo' + codigo : ''}`)
            .attr('width', `${lado}`)
            .attr('height', `${lado}`)
            .attr('stroke-width', '1.5')
            .attr('stroke', 'none')
            .attr('fill', '#FFFFFF')
            .attr('x', `${x}`)
            .attr('y', `${y}`)
            .style('opacity', 0);
    }
}
