import { Injectable } from '@angular/core';
import { IApiTransportInterface } from 'app/core/interfaces';
import { ApiTransportService } from 'app/core/services/transport';
import { EstacaoMeteorologicaUnidadeModel } from 'app/shared/models';
import { Observable } from 'rxjs';

export abstract class IEstacaoMeteorologicaLocalController {
    abstract buscaUmaEstacaoMeteorologica(): Observable<EstacaoMeteorologicaUnidadeModel>;
}

@Injectable({
    providedIn: 'root',
})
export class EstacaoMeteorologicaLocalController implements IEstacaoMeteorologicaLocalController {
    private transport: IApiTransportInterface;

    constructor(readonly apiTransportService: ApiTransportService) {
        this.transport = this.apiTransportService.execute('/estacao-meteorologica/estacao-local-ativa');
    }

    buscaUmaEstacaoMeteorologica(): Observable<any> {
        return this.transport.http.get<any>(`${this.transport.path}`);
    }
}
