import { Injectable } from '@angular/core';
import { ApiTransportService } from 'app/core/services/transport';
import { Observable } from 'rxjs';

import { IApiTransportInterface } from 'app/core/interfaces/';
import { HistoricoSincronizadorModel, ApiPaginacao } from 'app/shared/models';
import { IObjectToAnyService } from 'app/shared/services';
import { ResponseInterface } from 'app/shared/interfaces';

export abstract class IComunicacaoColetorController {
    abstract buscaHistorico(paginacao: ApiPaginacao): Observable<ResponseInterface<HistoricoSincronizadorModel>>;
}

@Injectable({
    providedIn: 'root',
})
export class ComunicacaoColetorController implements IComunicacaoColetorController {
    private transport: IApiTransportInterface;

    constructor(
        public readonly apiTransportService: ApiTransportService,
        private readonly objectToAnyService: IObjectToAnyService
    ) {
        this.transport = this.apiTransportService.execute('/geral/sincronizacao/historico-coletor');
    }

    buscaHistorico(paginacao: ApiPaginacao): Observable<any> {
        this.transport.options.params = this.objectToAnyService.toQueryString({
            page: paginacao.page,
            limit: paginacao.limit,
        });
        return this.transport.http.get(`${this.transport.path}`, this.transport.options);
    }
}
