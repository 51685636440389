export interface ProdutoTipoInterface {
    id: string;
    nome: string;
    compactacao: number;
    densidade: number;
    emc_constante_a: number;
    emc_constante_b: number;
    emc_constante_c: number;
    emc_formula: string;
    peso_saca: number;
}
