export enum GraficoSecadorOperacaoTitulos {
    SECADOR = 'secador',
    UNIDADES = 'unidades',
    GRAO = 'grao',
    TEMPO_DE_SECAGEM = 'tempoDeSecagem',
    TEMPERATURA = 'temperatura',
    ENTRADA = 'entrada',
    MASSA = 'massa',
    SAIDA = 'saida',
    UG = 'ug',
    LIMPEZA_DO_SECADOR = 'limpezaDoSecador',
    MODAL = 'modal'
}

export enum GraficoSecadorOperacaoPage {
    PAGE_SIZE = 5,
    CURRENT_PAGE = 0,
    TOTAL_ITENS = 0
}

export enum GraficoSecadorOperacaoName {
    VELOCIDADE_DE_DESCARGA = 'Velocidade de descarga (%)',
    EFICIENCIA_DO_SECADOR = 'Eficiência do secador (%)',
    CONSUMO_DE_COMBUSTIVEL = 'Consumo de Combustível (m³/h)',
    MEDIDOR_DE_FLUXO = 'Medidor de fluxo 1 (t/h)',
    MEDIDOR_DE_FLUXO_2 = 'Medidor de fluxo 2 (t/h)',
    PRESSAO_DA_ENTRADA_DE_AR = 'Pressão da entrada de ar (mmCA)',
    PRESSAO_DA_SAIDA_DE_AR = 'Pressão da saída de ar (mmCA)'
}

export enum GraficoSecadorQualidadeNome {
    UMIDADE_DO_GRAO = 'Umidade do grão',
    TEMPERATURA_ENTRADA = 'Temperatura da entrada de ar',
    TEMPERATRA_MASSA = 'Temperatura da massa dos grãos',
    TEMPERATURA_SAIDA = 'Temperatura da saida de ar',
    TEMPERATURA_ENTRADA_CONTROLE = 'Temperatura de controle da entrada de ar',
    TEMPERATURA_MASSA_CONTROLE = 'Temperatura de controle da massa de grãos',
    TEMPERATURA_SAIDA_CONTROLE = 'Temperatura de controle da saida de ar',
}

export enum OrdenacaoSecadoresOperacao {
    ASC = "asc",
    DESC = "desc"
}
