import { Injectable } from '@angular/core';
import { configuracoesStatusAeracaoConstant, configuracoesStatusAeracaoEnConstant, configuracoesStatusAeracaoEsConstant } from 'app/shared/constants';
import { AeradorStatuSodeEnum, IdiomaSiglaEnum } from 'app/shared/enums';
import { StatusAeracaoModel } from 'app/shared//models';
import { CookieHelper } from 'app/shared/helpers';

export abstract class IConfigurarStatusAeracaoService {
    abstract execute(statusCodigo: AeradorStatuSodeEnum): StatusAeracaoModel;
}

@Injectable({
    providedIn: 'root',
})
export class ConfigurarStatusAeracaoService {
    constructor() {}
    execute(statusCodigo: AeradorStatuSodeEnum): StatusAeracaoModel {
        let idioma = CookieHelper.carregarCookie('idioma');
        switch (idioma) {
            case IdiomaSiglaEnum.INGLES:
                return configuracoesStatusAeracaoEnConstant.find((status) => status.codigo === statusCodigo);
            case IdiomaSiglaEnum.ESPANHOL:
                return configuracoesStatusAeracaoEsConstant.find((status) => status.codigo === statusCodigo);
            default:
                return configuracoesStatusAeracaoConstant.find((status) => status.codigo === statusCodigo);
        }
    }
}
