import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ModalMensagemCelularComponent } from 'app/core/aside-menu/modal-mensagem-celular/modal-mensagem-celular.component';
import { DesativarComponenteInterface } from 'app/core/interfaces/auth.interface';

@Injectable()
export class CanLeaveGuard implements CanDeactivate<any> {
    private rotasPermitidas: string[] = [
        '/armazenagem/visualizacao/painel-geral',
        '/armazenagem/visualizacao/visao-detalhada-silo/historico/:id/:instante',
        '/armazenagem/visualizacao/visao-detalhada-silo/principal/:id',
        '/armazenagem/visualizacao/visao-geral-silos',
        '/armazenagem/visualizacao/visao-detalhada-armazem/historico/:id/:subcelula/:instante',
        '/armazenagem/visualizacao/visao-detalhada-armazem/principal/:id/:subcelula',
        '/armazenagem/visualizacao/visao-geral-armazens',
        '/sign-out',
    ];

    constructor(private router: Router, public readonly dialog: MatDialog) {}

    canDeactivate(
        componente: DesativarComponenteInterface,
        rotaAtual: ActivatedRouteSnapshot,
        estadoAtual: RouterStateSnapshot,
        proximoEstado?: RouterStateSnapshot
    ): boolean {
        const larguraTela = window.innerWidth;
        const urlProximaRota = proximoEstado ? proximoEstado.url : null;
        if (larguraTela < 600) {
            const proximaRotaPermitida = this.rotasPermitidas.some(
                (allowedRoute) => proximoEstado && proximoEstado.url.startsWith(allowedRoute)
            );

            if (!proximaRotaPermitida) {
                this.dialog.open(ModalMensagemCelularComponent, {
                    minWidth: '100%',
                    minHeight: 'auto',
                });

                return false;
            }
        }
        return true;
    }
}
