import { Injectable } from '@angular/core';

export abstract class IConfiguraDayParaDataService {
    abstract execute(obj: any): string;
}

@Injectable({ providedIn: 'root' })
export class ConfiguraDayParaDataService implements IConfiguraDayParaDataService {
    public execute(obj: any): string {
        if (obj.$D && obj.$D != 'Invalid Date') {
            const dataFormatada = new Date(obj.$y, obj.$M, obj.$D, obj.$H, obj.$m, obj.$s);

            return dataFormatada.toISOString();
        }
        if (obj._d && obj._d != 'Invalid Date') {
            const dataFormatada = new Date(obj._d);
            return dataFormatada?.toISOString();
        }
    }
}
