export class VerificaObjetoVazio {
    public static execute(obj: any): boolean {
        if (obj === null || obj === undefined) {
            return true;
        }

        if (Object.getOwnPropertyNames(obj).length === 0) {
            return true;
        }

        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }

        return true;
    }
}
