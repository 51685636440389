import { Injectable } from '@angular/core';

import {
    SensorTipoSecadorEnum,
    UnidadeMedidaSensorEnum,
} from 'app/modules/secador/visualizacao/secadores/visao-detalhada-secador/enums';

export abstract class IRetornaUnidadeMedidaPorTipoSensorService {
    abstract execute(tipoSensor: string): string;
}

@Injectable({ providedIn: 'root' })
export class RetornaUnidadeMedidaPorTipoSensorService implements IRetornaUnidadeMedidaPorTipoSensorService {
    constructor() {}
    execute(tipoSensor: string): string {
        switch (tipoSensor) {
            case SensorTipoSecadorEnum.TEMPERATURA_DIGITAL:
                return UnidadeMedidaSensorEnum.TEMPERATURA;

            case SensorTipoSecadorEnum.TEMPERATURA_GRAO:
                return UnidadeMedidaSensorEnum.TEMPERATURA;

            case SensorTipoSecadorEnum.TEMPERATURA_TEMOPAR:
                return UnidadeMedidaSensorEnum.TEMPERATURA;

            case SensorTipoSecadorEnum.UMIDADE_RELATIVA:
                return UnidadeMedidaSensorEnum.UMIDADE;

            case SensorTipoSecadorEnum.UMIDADE_GRAO:
                return UnidadeMedidaSensorEnum.UMIDADE;

            case SensorTipoSecadorEnum.PRESSAO:
                return UnidadeMedidaSensorEnum.PRESSAO;
        }
    }
}
