interface AeracaoTabelaExpansiva {
    tempo_decorrido_abaixo_10: { valor: number; porcentagem: number };
    tempo_decorrido_entre_10_e_12: { valor: number; porcentagem: number };
    tempo_decorrido_entre_12_e_14: { valor: number; porcentagem: number };
    tempo_decorrido_entre_14_e_16: { valor: number; porcentagem: number };
    tempo_decorrido_entre_16_e_18: { valor: number; porcentagem: number };
    tempo_decorrido_acima_18: { valor: number; porcentagem: number };
    tempo_decorrido_total: { valor: number; porcentagem: number };
}

interface PainelInterface {
    manual: AeracaoTabelaExpansiva;
    automatico: AeracaoTabelaExpansiva;
    total: AeracaoTabelaExpansiva;
}

export interface ListaPainelSistemaInterface {
    painel: PainelInterface;
    sistema: {
        resfriamento: AeracaoTabelaExpansiva
        total: AeracaoTabelaExpansiva;
    };
}

export interface RespostaListaPainelSistemaInterface {
    data: ListaPainelSistemaInterface
}

