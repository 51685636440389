import { SiloBaseEnum } from './../enums/silo.enum';
import * as BABYLON from '@babylonjs/core';
import { Injectable } from '@angular/core';
import { Silo3DPropriedadesInterface } from 'app/shared/interfaces';
export abstract class ICriaSilo3DBasePlanaService {
    abstract execute(siloData: Silo3DPropriedadesInterface, cena: BABYLON.Scene): void;
}

@Injectable({ providedIn: 'root' })
export class CriaSilo3DBasePlanaService implements ICriaSilo3DBasePlanaService {
    execute(siloData: Silo3DPropriedadesInterface, cena: BABYLON.Scene): void {
        const dimensaoChao: number = 1000;
        const meshesParaDeletar = cena.meshes.filter(
            (mesh) => mesh.name.endsWith('Plana') || mesh.name.endsWith('V') || mesh.name.endsWith('SemiV')
        );
        meshesParaDeletar.forEach((mesh) => {
            mesh.dispose();
        });
        meshesParaDeletar.length = 0;

        const plataformaPlana = BABYLON.MeshBuilder.CreateBox('plataformaPlana', {
            height: siloData?.alturaPlataforma,
            width: siloData?.diametroSilo * 2.5,
            depth: siloData?.diametroSilo * 2.5,
        });
        plataformaPlana.position.y = siloData?.alturaPlataforma / 2;

        const degrauPlana = BABYLON.MeshBuilder.CreateCylinder(
            'degrauPlana',
            {
                height: siloData?.alturaPlataforma,
                diameter: siloData?.diametroSilo + siloData?.diametroSilo * 0.05,
            },
            cena
        );
        degrauPlana.position.y = siloData?.alturaPlataforma + siloData?.alturaPlataforma / 2;

        const cilindroPlana = BABYLON.MeshBuilder.CreateCylinder(
            `cilindro${SiloBaseEnum.PLANA}`,
            {
                height: siloData?.alturaSilo,
                diameter: siloData?.diametroSilo * 1.01,
                cap: BABYLON.Mesh.NO_CAP,
            },
            cena
        );
        cilindroPlana.position.y = siloData?.alturaPlataforma + siloData?.alturaPlataforma + siloData?.alturaSilo / 2;

        const chapeuPlana = BABYLON.MeshBuilder.CreateCylinder(
            `chapeu${SiloBaseEnum.PLANA}`,
            {
                height: siloData?.alturaChapeu,
                diameter: siloData?.diametroChapeu,
                diameterTop: siloData?.diametroChapeu * 0.1,
                tessellation: 32,
                cap: BABYLON.Mesh.CAP_END,
            },
            cena
        );
        chapeuPlana.position.y =
            siloData?.alturaPlataforma + siloData?.alturaPlataforma + siloData?.alturaSilo + siloData?.alturaChapeu / 2;

        const chaoPlana = BABYLON.MeshBuilder.CreateGround(
            'chaoPlana',
            { width: dimensaoChao, height: dimensaoChao },
            cena
        );
        chaoPlana.position.y = siloData?.alturaPlataforma - siloData?.alturaPlataforma;

        cena.render();
    }
}
