import { Injectable } from '@angular/core';
import { IApiTransportInterface } from 'app/core/interfaces/';
import { ApiTransportService } from 'app/core/services/transport';
import { DadosFiltroModel, EquilibrioHigroscopicoModel } from 'app/shared/models';
import { Observable } from 'rxjs';

export abstract class IEquilibrioHigroscopicoController {
    abstract buscarEquilibrioHigroscopico(
        idEstacao: string,
        tipoEstacao: string,
        produtoTipoId: number,
        aquecimentnumbertplenum: number,
        tplenum?: number,
        urplenum?: number
    ): Observable<EquilibrioHigroscopicoModel>;
}

@Injectable({
    providedIn: 'root',
})
export class EquilibrioHigroscopicoController implements IEquilibrioHigroscopicoController {
    private transport: IApiTransportInterface;

    constructor(readonly apiTransportService: ApiTransportService) {
        this.transport = this.apiTransportService.execute('/estacao-meteorologica/equilibrio-higroscopico');
    }

    buscarEquilibrioHigroscopico(
        idEstacao: string,
        tipoEstacao: string,
        produtoTipoId: number,
        aquecimento_ar: number,
        tplenum?: number,
        urplenum?: number
    ): Observable<any> {
        const addPlenumParamentros = `${tplenum ? '&tplenum=' : ''}${tplenum ?? ''}${urplenum ? '&urplenum=' : ''}${
            urplenum ?? ''
        }`;
        return this.transport.http.get<any>(
            `${
                this.transport.path
            }/${idEstacao}/${tipoEstacao.toLowerCase()}/${produtoTipoId}?aquecimento_ar=${aquecimento_ar}${addPlenumParamentros}`
        );
    }

    buscarHistoricoEquilibrioHigroscopico(siloId: number, filtro: DadosFiltroModel): Observable<any> {
        return this.transport.http.get<any>(
            `${this.transport.path}/geral/${siloId}/historico?dataInicio=${filtro.dataInicio}&dataFim=${filtro.dataFim}`
        );
    }
}
