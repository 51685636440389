import { Injectable } from '@angular/core';
import { IApiTransportInterface } from 'app/core/interfaces';
import { ApiTransportService } from 'app/core/services/transport';
import { Observable } from 'rxjs';
import { ListaProdutosEquilibrioEstruturaInterface, ResponseInterface } from 'app/shared/interfaces';

export abstract class IListaProdutosEquilibrioEstruturaController {
    abstract buscaListaProduto(
        idEstrutura: string
    ): Observable<ResponseInterface<ListaProdutosEquilibrioEstruturaInterface>>;
}

@Injectable()
export class ListaProdutosEquilibrioEstruturaController implements IListaProdutosEquilibrioEstruturaController {
    private transport: IApiTransportInterface;

    constructor(public readonly apiTransportService: ApiTransportService) {
        this.transport = this.apiTransportService.execute('/armazenagem/produto/equilibrio-higroscopico');
    }

    buscaListaProduto(idEstrutura: string): Observable<any> {
        return this.transport.http.get(`${this.transport.path}/${idEstrutura}`);
    }
}
