import { Injectable } from '@angular/core';
export abstract class IFormataHHMMMinutosService {
    abstract execute(valor: string): number;
}

@Injectable({ providedIn: 'root' })
export class FormataHHMMMinutosService implements IFormataHHMMMinutosService {
    execute(valor: string): number {
        if (!valor) return 0;

        const [horas, minutos] = valor.split(':').map(Number);
        return horas * 60 + minutos;
    }
}
