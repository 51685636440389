import { Injectable } from '@angular/core';
import { PaletaDeCoresInterface } from 'app/shared/interfaces';
import { ProdutoTipoSecadorEnum } from 'app/modules/secador/visualizacao/secadores/visao-detalhada-secador/enums';
import {
    paletaCoresCargaUmidadeGraoConstant,
    paletaCoresDescargaUmidadeGraoConstant,
    paletaCoresEntradaTemperaturaGraoConstant,
    paletaCoresMassaTemperaturaGraoConstant,
    paletaCoresSaidaTemperaturaGraoConstant,
} from 'app/shared/constants';
import { TabelaSecadoresQualidadeEnum } from 'app/modules/gestao-unidades/operacional/shared/enums';

export abstract class ICriaPaletaCoresGraoSecadoresQualidadeService {
    abstract criarPaleta(
        produto: string,
        temperatura: number | null,
        tipoPaleta: TabelaSecadoresQualidadeEnum
    ): PaletaDeCoresInterface;
}

@Injectable({ providedIn: 'root' })
export class CriaPaletaCoresGraoSecadoresQualidadeService implements ICriaPaletaCoresGraoSecadoresQualidadeService {
    criarPaleta(produto: string, temperatura: any, tipoPaleta: TabelaSecadoresQualidadeEnum): PaletaDeCoresInterface {
        const paletas = {
            [TabelaSecadoresQualidadeEnum.ENTRADA_TEMPERATURA]: paletaCoresEntradaTemperaturaGraoConstant,
            [TabelaSecadoresQualidadeEnum.MASSA_TEMPERATURA]: paletaCoresMassaTemperaturaGraoConstant,
            [TabelaSecadoresQualidadeEnum.SAIDA_TEMPERATURA]: paletaCoresSaidaTemperaturaGraoConstant,
            [TabelaSecadoresQualidadeEnum.ENTRADA_MAIOR_TEMPERATURA]: paletaCoresEntradaTemperaturaGraoConstant,
            [TabelaSecadoresQualidadeEnum.MASSA_MAIOR_TEMPERATURA]: paletaCoresMassaTemperaturaGraoConstant,
            [TabelaSecadoresQualidadeEnum.SAIDA_MAIOR_TEMPERATURA]: paletaCoresSaidaTemperaturaGraoConstant,
            [TabelaSecadoresQualidadeEnum.CARGA_UMIDADE]: paletaCoresCargaUmidadeGraoConstant,
            [TabelaSecadoresQualidadeEnum.DESCARGA_UMIDADE]: paletaCoresDescargaUmidadeGraoConstant,
            [TabelaSecadoresQualidadeEnum.MAX_UMIDADE_CARGA]: paletaCoresCargaUmidadeGraoConstant,
            [TabelaSecadoresQualidadeEnum.MED_UMIDADE_CARGA]: paletaCoresCargaUmidadeGraoConstant,
            [TabelaSecadoresQualidadeEnum.MIN_UMIDADE_CARGA]: paletaCoresCargaUmidadeGraoConstant,
            [TabelaSecadoresQualidadeEnum.MAX_UMIDADE_DESCARGA]: paletaCoresDescargaUmidadeGraoConstant,
            [TabelaSecadoresQualidadeEnum.MED_UMIDADE_DESCARGA]: paletaCoresDescargaUmidadeGraoConstant,
            [TabelaSecadoresQualidadeEnum.MIN_UMIDADE_DESCARGA]: paletaCoresDescargaUmidadeGraoConstant,
        };

        const paletaDefault: PaletaDeCoresInterface = { min: '', max: '', fundo: '#EBEEF2', cor: '#00091F' };
        let paletaCores: PaletaDeCoresInterface = paletaDefault;

        if (temperatura !== null && tipoPaleta in paletas) {
            const paletaGrao = paletas[tipoPaleta];
            const produtoTipo = this.retornaProduto(produto);
            paletaCores = this.filtrarPaletaDeCores(paletaGrao[produtoTipo], temperatura) || paletaDefault;
        }

        return paletaCores;
    }

    private retornaProduto(produtoTipo: string): string {
        const produtosComEscalaDefinida = [
            ProdutoTipoSecadorEnum.ARROZ,
            ProdutoTipoSecadorEnum.MILHO,
            ProdutoTipoSecadorEnum.SOJA,
            ProdutoTipoSecadorEnum.TRIGO,
        ];

        const produto = produtosComEscalaDefinida.find((p) => p.toLowerCase() === produtoTipo.toLowerCase());

        return produto || ProdutoTipoSecadorEnum.SOJA;
    }

    private filtrarPaletaDeCores(paleta: PaletaDeCoresInterface[], valorSensor: number): PaletaDeCoresInterface | null {
        const valorEncontrado = paleta.find((valor) => valorSensor >= valor.min && valorSensor <= valor.max);

        if (!valorEncontrado) {
            const valorMinimo = paleta[0];
            const valorMaximo = paleta[paleta.length - 1];
            if (valorSensor > valorMaximo.max) {
                return valorMaximo;
            } else if (valorSensor < valorMinimo.min) {
                return valorMinimo;
            }
            return null;
        }
        return valorEncontrado;
    }
}
