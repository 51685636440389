import { Injectable } from '@angular/core';
import { PaletaDeCoresInterface } from 'app/shared/interfaces';

const paletaParaEntradaDeAr: PaletaDeCoresInterface[] = [
    { min: '', max: '', fundo: '#EBEEF2', cor: '#00091F' },
    { min: 0, max: 79, fundo: '#313696', cor: '#FFFFFF' },
    { min: 80, max: 89, fundo: '#74AED2', cor: '#00091F' },
    { min: 90, max: 109, fundo: '#FFFFC0', cor: '#00091F' },
    { min: 110, max: 119, fundo: '#E75135', cor: '#FFFFFF' },
    { min: 120, max: 99999999, fundo: '#D83027', cor: '#FFFFFF' },
];

const paletaParaMassaSaida: PaletaDeCoresInterface[] = [
    { min: '', max: '', fundo: '#EBEEF2', cor: '#00091F' },
    { min: 0, max: 19, fundo: '#313696', cor: '#FFFFFF' },
    { min: 20, max: 29, fundo: '#74AED2', cor: '#00091F' },
    { min: 30, max: 42, fundo: '#FFFFC0', cor: '#00091F' },
    { min: 42, max: 59, fundo: '#E75135', cor: '#FFFFFF' },
    { min: 60, max: 99999999, fundo: '#D83027', cor: '#FFFFFF' },
];

export abstract class ICriaPaletaCoresSecadoresService {
    abstract criarPaletaEntradaDeAr(massa: number): PaletaDeCoresInterface;
    abstract criarPaletaMassaSaida(massaSaida: number): PaletaDeCoresInterface;
}

@Injectable({ providedIn: 'root' })
export class CriaPaletaCoresSecadoresService implements ICriaPaletaCoresSecadoresService {
    criarPaletaEntradaDeAr(sensorEntradaDeAr: number): PaletaDeCoresInterface {
        let paletaCores;
        if (sensorEntradaDeAr) {
            if (sensorEntradaDeAr < 1) {
                sensorEntradaDeAr = 1;
            }
            if (sensorEntradaDeAr > 99999999) {
                sensorEntradaDeAr = 9001;
            }
            paletaCores = paletaParaEntradaDeAr.filter(
                (ar: PaletaDeCoresInterface) => sensorEntradaDeAr >= ar.min && sensorEntradaDeAr <= ar.max
            );
        } else {
            paletaCores = paletaParaEntradaDeAr;
        }
        return paletaCores[0];
    }

    criarPaletaMassaSaida(sensorMassaSaida: number): PaletaDeCoresInterface {
        let paletaCores;
        if (sensorMassaSaida) {
            if (sensorMassaSaida < 1) {
                sensorMassaSaida = 1;
            }
            if (sensorMassaSaida > 99999999) {
                sensorMassaSaida = 9001;
            }
            paletaCores = paletaParaMassaSaida.filter(
                (massaSaida: PaletaDeCoresInterface) =>
                    sensorMassaSaida >= massaSaida.min && sensorMassaSaida <= massaSaida.max
            );
        } else {
            paletaCores = paletaParaMassaSaida;
        }
        return paletaCores[0];
    }
}
