import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ClienteEnum } from 'app/core/enums';
import { IdiomaService } from 'app/core/services';
import { whiteLabelService } from 'app/core/services/white-label';
import { TipoAmbiente } from 'app/shared/enums';
import { IAtualizaStatusConexaoInternetService } from 'app/shared/services';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    constructor(
        private whiteLabelService: whiteLabelService,
        private router: Router,
        private atualizaStatusConexaoInternetService: IAtualizaStatusConexaoInternetService,
        private idiomaService: IdiomaService
    ) {}
    ngOnInit(): void {
        this.idiomaService.iniciarTraducao();
        this.atualizaStatusConexaoInternet();
        this.definirEstiloCliente();
        this.capturaRotaAtual();
    }

    private definirEstiloCliente(): void {
        const cliente = this.whiteLabelService.obterCliente();

        if (cliente === ClienteEnum.KEPLER) {
            this.whiteLabelService.definirEstiloKeplerWeber();
        }
    }

    private capturaRotaAtual(): void {
        this.router.events.subscribe((evento) => {
            if (evento instanceof NavigationEnd) {
                localStorage.setItem('urlAtual', evento.url);
            }
        });
    }

    private atualizaStatusConexaoInternet(): void {
        if (environment.level === TipoAmbiente.LOCAL.toLocaleLowerCase())
            this.atualizaStatusConexaoInternetService.execute();
    }
}
