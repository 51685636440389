export enum IconeLocalPosicaoSensorEnum {
    ICONE_FORNALHA = 'icon-fornalha-cinza',
    ICONE_ENTRADA_AR = 'icon-entrada-ar-cinza',
    ICONE_MASSA_GRAOS = 'icon-massa-graos-cinza',
    ICONE_SAIDA_AR = 'icon-saida-ar-cinza',
    ICONE_CARGA_GRAOS = 'icon-carga-graos-cinza',
    ICONE_DESCARGA_GRAOS = 'icon-descarga-graos-cinza',
    ICONE_RECIRCULACAO = 'icon-recirculacao-cinza',
    ICONE_CONDICOES_EXTERNAS = 'icon-condicoes-externas-cinza',
}
