import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { AsideMenuComponent } from 'app/core/aside-menu/aside-menu.component';
import { AsideMenuObservable } from 'app/shared/observables';
import { ModalMensagemCelularComponent } from 'app/core/aside-menu/modal-mensagem-celular/modal-mensagem-celular.component';
import { TranslocoRootModule } from 'app/transloco-root.module';
import { ISetaTituloPaginaService, SetaTituloPaginaService } from 'app/shared/services';

@NgModule({
    exports: [AsideMenuComponent, ModalMensagemCelularComponent],
    declarations: [AsideMenuComponent, ModalMensagemCelularComponent],
    imports: [CommonModule, MatIconModule, RouterModule, MatTooltipModule, TranslocoRootModule],
    providers: [
        AsideMenuObservable,
        {
            provide: ISetaTituloPaginaService,
            useClass: SetaTituloPaginaService,
        },
    ],
})
export class AsideMenuModule {}
