import { Injectable } from '@angular/core';
import { ApiTransportService } from 'app/core/services/transport';
import { Observable } from 'rxjs';

import { IApiTransportInterface } from 'app/core/interfaces/';
import { EditaInformacoesAeracaoPayloadInterface, HistoricoVariavelAquecimentoInterface, ResponseInterface } from 'app/shared/interfaces';
import { ApiPaginacao } from 'app/shared/models';
import { IObjectToAnyService } from 'app/shared/services';

export abstract class IVariavelAquecimentoController {
    abstract buscarHistorico(
        armazenagemREf: number,
        params: ApiPaginacao
    ): Observable<ResponseInterface<HistoricoVariavelAquecimentoInterface>>;
    abstract editarVariavelAquecimento(id: string, dados?: EditaInformacoesAeracaoPayloadInterface): Observable<any>;
}

@Injectable({
    providedIn: 'root',
})
export class VariavelAquecimentoController implements IVariavelAquecimentoController {
    private transport: IApiTransportInterface;

    constructor(
        public readonly apiTransportService: ApiTransportService,
        private readonly objectToAnyService: IObjectToAnyService
    ) {
        this.transport = this.apiTransportService.execute('/aeracao/variavel-aquecimento/estrutura_armazenagem_divisao');
    }

    buscarHistorico(
        armazenagemREf: number,
        params: ApiPaginacao
    ): Observable<ResponseInterface<HistoricoVariavelAquecimentoInterface>> {
        this.transport.options.params = this.objectToAnyService.toQueryString({
            page: params.page,
            limit: params.limit,
        });

        return this.transport.http.get<ResponseInterface<HistoricoVariavelAquecimentoInterface>>(
            `${this.transport.path}/${armazenagemREf}`,
            {
                params: this.transport.options.params,
            }
        );
    }

    editarVariavelAquecimento(id: string, dados?: EditaInformacoesAeracaoPayloadInterface): Observable<any> {
        return this.transport.http.patch<any>(`${this.transport.path}/${id}`, dados);
    }
}
