import { Injectable } from '@angular/core';

import { AsideMenuObservable } from 'app/shared/observables';
import { Menu } from 'app/core/aside-menu/models/menu.model';
import { environment } from 'environments/environment';
import { IdiomaSiglaEnum, TipoAmbiente } from 'app/shared/enums';
import { CookieService } from 'app/core/services';

import { MENUS_ARMAZENAGEM } from 'app/core/aside-menu/constants/menus-armazenagem.constant';
import { MENUS_ARMAZENAGEM_EN } from 'app/core/aside-menu/constants/menus-armazenagem-en.constant';
import { MENUS_SECAGEM } from 'app/core/aside-menu/constants/menus-secagem.constant';
import { MENUS_GESTAO_UNIDADE } from 'app/core/aside-menu/constants/menus-gestao-unidade.constant';
import { MENUS_CONFIGURACOES } from 'app/core/aside-menu/constants/menus-configuracoes.constant';
import { MENUS_CONFIGURACOES_EN } from 'app/core/aside-menu/constants/menus-configuracoes-en.constant';
import { MENUS_SECAGEM_EN } from 'app/core/aside-menu/constants/menus-secagem-en.constant';
import { MENUS_ARMAZENAGEM_ES } from 'app/core/aside-menu/constants/menus-armazenagem-es.constant';
import { MENUS_CONFIGURACOES_ES } from 'app/core/aside-menu/constants/menus-configuracoes-es.constant';
import { MENUS_SECAGEM_ES } from 'app/core/aside-menu/constants/menus-secagem-es.constant';

@Injectable({
    providedIn: 'root',
})
export class AsideMenuService {
    private modulos: Menu[] = [];

    constructor(private asideMenuObservable: AsideMenuObservable, private cookieService: CookieService) {}

    carregarMenus(): void {
        const idioma = this.cookieService.obterCookie('idioma');
        this.modulos = [];

        switch (idioma) {
            case IdiomaSiglaEnum.INGLES:
                MENUS_ARMAZENAGEM_EN.map((armazenagem: Menu) => this.modulos.push(armazenagem));
                MENUS_SECAGEM_EN.map((secagem: Menu) => this.modulos.push(secagem));
                break;
            case IdiomaSiglaEnum.ESPANHOL:
                MENUS_ARMAZENAGEM_ES.map((armazenagem: Menu) => this.modulos.push(armazenagem));
                MENUS_SECAGEM_ES.map((secagem: Menu) => this.modulos.push(secagem));
                break;
            default:
                MENUS_ARMAZENAGEM.map((armazenagem: Menu) => this.modulos.push(armazenagem));
                MENUS_SECAGEM.map((secagem: Menu) => this.modulos.push(secagem));
                break;
        }

        if (environment.level.toUpperCase() === TipoAmbiente.NUVEM) {
            MENUS_GESTAO_UNIDADE.map((secagem: Menu) => this.modulos.push(secagem));
        }

        switch (idioma) {
            case IdiomaSiglaEnum.INGLES:
                MENUS_CONFIGURACOES_EN.map((configuracoes: Menu) => this.modulos.push(configuracoes));
                break;
            case IdiomaSiglaEnum.ESPANHOL:
                MENUS_CONFIGURACOES_ES.map((configuracoes: Menu) => this.modulos.push(configuracoes));
                break;
            default:
                MENUS_CONFIGURACOES.map((configuracoes: Menu) => this.modulos.push(configuracoes));
                break;
        }

        this.asideMenuObservable.modulosSubject.next(this.modulos);
    }

    proximoMenu(menu: any): void {
        this.asideMenuObservable.menusSubject.next(menu.groups);
    }

    selecionarModuloInicial(): Menu {
        return this.modulos[0];
    }

    selecionarMenuInicial(posicaoModulo?: any): Menu {
        return this.modulos[posicaoModulo ? posicaoModulo : 0].groups[0].items[0];
    }
}
