import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';

import { Observable, take } from 'rxjs';

import { AutenticacaoUsuarioObservable, ConexaoInternetObservable } from 'app/shared/observables';
import { IVerificaComunicacaoService } from 'app/shared/services';
import { Location } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class AcessoCadastroUsuarioGuard implements CanActivate {
    constructor(
        private verificaComunicacaoService: IVerificaComunicacaoService,
        private router: Router,
        readonly autenticacaoUsuarioObservable: AutenticacaoUsuarioObservable,
        private conexaoInternetObservable: ConexaoInternetObservable,
        private location: Location
    ) {}

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let usuarioPodeCadastrar = false;
        const online = this.conexaoInternetObservable.getStatusConexaoInternet;
        this.autenticacaoUsuarioObservable.buscaInformacoesAutenticacaoUsuario$.pipe(take(1)).subscribe((usuario) => {
            usuarioPodeCadastrar = usuario?.usuario_unidade?.permite_cadastro_usuario;
        });

        if (usuarioPodeCadastrar && this.verificaComunicacaoService.execute() && online) {
            return true;
        } else {
            this.location.back();
            return false;
        }
    }
}
