export enum CaminhoRaizImagensSilo3DEnum {
    PASTA_RAIZ = 'assets/img/silo3D/',
}
export enum CaminhoImagensSilo3DEnum {
    BACKGOUND_DIA = CaminhoRaizImagensSilo3DEnum.PASTA_RAIZ + 'skybox/dia',
    BACKGOUND_NOITE = CaminhoRaizImagensSilo3DEnum.PASTA_RAIZ + 'skybox/noite',
    GRAMA = CaminhoRaizImagensSilo3DEnum.PASTA_RAIZ + 'grama.avif',
    MILHO = CaminhoRaizImagensSilo3DEnum.PASTA_RAIZ + 'milho.png',
    FUNDO_METAL = CaminhoRaizImagensSilo3DEnum.PASTA_RAIZ + 'fundo_metal.png',
    CONCRETO = CaminhoRaizImagensSilo3DEnum.PASTA_RAIZ + 'concreto.png',
    MADEIRA = CaminhoRaizImagensSilo3DEnum.PASTA_RAIZ + 'madeira.jpg',
    METAL_HORIZONTAL = CaminhoRaizImagensSilo3DEnum.PASTA_RAIZ + 'metal_horizontal.png',
    METAL_VERTICAL = CaminhoRaizImagensSilo3DEnum.PASTA_RAIZ + 'metal_vertical.png',
}
