import { Injectable } from '@angular/core';
import { IApiTransportInterface } from 'app/core/interfaces';
import { ApiTransportGestaoService } from 'app/core/services/transport';
import { Observable } from 'rxjs';
import {
    ResponseInterfaceAeracaoGraos,
} from 'app/shared/interfaces';
import {
    ParamsAeracaoGrosFilterInterface,
    listaCardsTotaisInterface,
} from 'app/modules/gestao-unidades/operacional/shared/interfaces';
import { HttpParams } from '@angular/common/http';


export abstract class IPainelTotalizadorController {
    abstract buscarListagemCardsTotais(params: ParamsAeracaoGrosFilterInterface): Observable<ResponseInterfaceAeracaoGraos<listaCardsTotaisInterface>>;
}

@Injectable({
    providedIn: 'root',
})
export class PainelTotalizadorController implements IPainelTotalizadorController {
    private transport: IApiTransportInterface;

    constructor(public readonly apiTransportService: ApiTransportGestaoService) {
        this.transport = this.apiTransportService.execute('/painel-totalizador');
    }
    public buscarListagemCardsTotais(parametros: ParamsAeracaoGrosFilterInterface): Observable<any> {
        let params = new HttpParams();
        if (parametros.produto_tipos.length > 0) {
            params = params.append('produto_tipos', parametros.produto_tipos.join(','));
        }
        if (parametros.unidades.length > 0) {
            params = params.append('unidades', parametros.unidades.join(','));
        }
        if (parametros.cliente_id) {
            params = params.append('cliente_id', parametros.cliente_id);
        }
        return this.transport.http.get(`${this.transport.path}/totalizadores`, {
            params,
        });
    }
}
