import { Injectable } from '@angular/core';
import * as BABYLON from '@babylonjs/core';
import { CaminhoImagensSilo3DEnum } from 'app/shared/enums';
export abstract class ICriaTexturaGraoSilo3DService {
    abstract execute(
        cena: BABYLON.Scene,
        graoDinamico: BABYLON.Mesh | null,
        cilindroGrao: BABYLON.Mesh | null,
        fundoGraoAtual: BABYLON.Mesh | null
    ): void;
}

@Injectable({ providedIn: 'root' })
export class CriaTexturaGraoSilo3DService implements ICriaTexturaGraoSilo3DService {
    async execute(
        cena: BABYLON.Scene,
        graoDinamico: BABYLON.Mesh | null,
        cilindroGrao: BABYLON.Mesh | null,
        fundoGraoAtual: BABYLON.Mesh | null
    ): Promise<void> {
        const graoMaterial = new BABYLON.StandardMaterial('coneGraoMaterial', cena);
        graoMaterial.backFaceCulling = false;
        graoMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
        graoMaterial.specularPower = 0;
        graoMaterial.alpha = 1;

        let texturaGrao: BABYLON.Texture;

        texturaGrao = new BABYLON.Texture(CaminhoImagensSilo3DEnum.MILHO.toString(), cena);
        texturaGrao.vScale = 20;
        graoMaterial.diffuseTexture = texturaGrao;

        if (graoDinamico) {
            graoDinamico.material = graoMaterial;
        }
        if (cilindroGrao) {
            cilindroGrao.material = graoMaterial;
        }
        if (fundoGraoAtual) {
            fundoGraoAtual.material = graoMaterial;
        }
    }
}
