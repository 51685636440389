import { BehaviorSubject } from 'rxjs';

export class ConexaoInternetObservable {
    private statusConexaoInternet: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(navigator.onLine);

    get online$() {
        return this.statusConexaoInternet.asObservable();
    }

    get getStatusConexaoInternet(): boolean {
        return this.statusConexaoInternet.getValue();
    }

    set online(valor: boolean) {
        this.statusConexaoInternet.next(valor);
    }
}
