export interface SensoresPorAreaSecadorInterface {
    id: string;
    secador_id: string;
    nome: string;
    sigla: string;
    area_secador: string;
    categoria: string;
}

export interface TipoSensoresPorAreaSecadorInterface {
    categoria: string;
}
