import { Injectable } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { graficoTraducao } from 'app/shared/constants';
import { GraficoOpcoes } from 'app/shared/interfaces';
import { FormataGraficoInterface } from 'app/modules/armazenagem/analise/graficos/unidade-armazenadora/interfaces';
export abstract class IDefineGraficoBarrasService {
    abstract execute(series: FormataGraficoInterface[], categorias: string[], stacked?: boolean): Partial<GraficoOpcoes>;
}

@Injectable({ providedIn: 'root' })
export class DefineGraficoBarrasService implements IDefineGraficoBarrasService {
    constructor(private decimalPipe: DecimalPipe) {}

    execute(series: FormataGraficoInterface[], categorias: string[], stacked?: boolean): Partial<GraficoOpcoes> {
        const that = this;
        return {
            chart: {
                height: 350,
                type: 'bar',
                stacked: stacked || false,
                fontFamily: 'obvia-wide-regular',
                defaultLocale: 'pt-br',
                locales: [graficoTraducao],
                zoom: {
                    enabled: false,
                },
                toolbar: {
                    show: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                width: 1,
            },
            markers: {
                size: 0,
                hover: {
                    sizeOffset: 7,
                },
            },
            grid: {
                borderColor: '#f1f1f1',
            },
            series: series,
            xaxis: {
                labels: {
                    trim: false,
                    showDuplicates: false,                                
                },
                categories: categorias,
                tickPlacement: 'on',
            },
            yaxis: {
                show: true,
                labels: {
                    formatter: function (value: number) {
                        return that.decimalPipe.transform(value, '1.1-1');
                    },
                },
            },
            plotOptions: {
                bar: {
                    columnWidth: '45%',
                },
            },
            legend: {
                markers: {
                    radius: 12,
                },
            },
            tooltip: {
                y: {
                    title: {
                        formatter: function (val: any) {
                            return val + ' (h)';
                        },
                    },
                },
            },
        } as Partial<GraficoOpcoes>;
    }
}
