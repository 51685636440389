import { GraficoExpedicaoAmostragensEnum } from "app/modules/gestao-unidades/operacional/shared/enums"

export const ColunasTabelaAmostragensExpedicaoConstant: string[] = [
    'ordem',
    'silo',
    'unidade',
    'data_inicio',
    'temperatura_media',
    'ur_media',
    'primeira_ug',
    'primeira_avariados',
    'primeira_impurezas',
    'ultima_ug',
    'ultima_avariados',
    'ultima_impurezas',
    'quantidade_amostragens',
    'estoque',
    'data_expedicao',
    'motivo',
    'link'
]


export const TraduzPaginaTooltipConstant = {
    traducaoComunicacao: '',
    traducaoFalhaComunicacao: '',
    traducaoComunicacaoParcial: '',
}


export const CoresGraficoExpedicaoAmostragensConstant: { [key in GraficoExpedicaoAmostragensEnum]: string} = {
    [GraficoExpedicaoAmostragensEnum.TEMPERATURA_MIN]: '#019DE8',
    [GraficoExpedicaoAmostragensEnum.TEMPERATURA_MAX]: '#E7713E',
    [GraficoExpedicaoAmostragensEnum.TEMPERATURA_MEDIA]: '#E46262',
    [GraficoExpedicaoAmostragensEnum.UMIDADE_RELATIVA]: '#185C84',
    [GraficoExpedicaoAmostragensEnum.UMIDADE_GRAO]: '#DEC17B'
}

export const CoresStatusComunicacaoConstant = {
    verde: '#01FF70',
    vermelho: '#D65757E5',
    amarelo: '#d4d411',
};
