export enum IdiomaEnum {
    PORTUGUES = 'Português',
    INGLES = 'English',    
    ESPANHOL = 'Español',
}

export enum IdiomaSiglaEnum {
    PORTUGUES = 'pt',
    INGLES = 'en',
    ESPANHOL = 'es',
}

export enum IdiomaIconeEnum {
    PORTUGUES = 'icon-idiomas-brasil',
    INGLES = 'icon-idiomas-eua',
    ESPANHOL = 'icon-idiomas-espanha',
}
