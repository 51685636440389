export enum ErrosApiEnum {    
    PROGRAMA_MENSAGEM_SALVAR_ERRO = 'Não foi possivel salvar o programa.',
    PROGRAMA_MENSAGEM_ARQUIVAR_ERRO = 'Não foi possível arquivar o programa. Por favor tente novamente.',
    PROGRAMA_MENSAGEM_DESARQUIVAR_ERRO = 'Não foi possível desarquivar o programa. Por favor tente novamente.',
    PROGRAMA_MENSAGEM_DUPLICAR_ERRO = 'Não foi possível duplicar o programa. Por favor tente novamente.',
    PROGRAMA_MENSAGEM_EXPORTAR_ERRO = 'Não foi possível exportar o programa. Por favor tente novamente.',
    GRAO_MENSAGEM_ARQUIVAR_ERRO = 'Não foi possível arquivar o grão. Por favor tente novamente.',
    GRAO_MENSAGEM_DESARQUIVAR_ERRO = 'Não foi possível desarquivar o grão. Por favor tente novamente.',
    GRAO_MENSAGEM_SALVAR_ERRO = 'Não foi possível salvar o grão. Por favor tente novamente.',
    CONTROLE_ALTERADO_ERRO = 'Não foi possível alterar o controle da aeração.',
    PROGRAMA_ALTERADO_ERRO = 'Não foi possível alterar o programa da aeração.',
    VARIAVEL_ALTERADA_ERRO = 'Não foi possível alterar a variável de aquecimento.',
    LIMPEZA_SECADOR_ERRO = 'Não foi possível registrar a limpeza do secador.',

    SECADOR_ALERTA_MENSAGEM_SALVAR_ERRO = 'Não foi possível salvar o alerta. Por favor tente novamente.',
    SECADOR_ALERTA_DESTINATARIO_MENSAGEM_ARQUIVAR_ERRO = 'Não foi possível arquivar o destinatário do alerta. Por favor tente novamente.',
    SECADOR_ALERTA_DESTINATARIO_MENSAGEM_DESARQUIVAR_ERRO = 'Não foi possível desarquivar o destinatário do alerta. Por favor tente novamente.',
    SECADOR_ALERTA_DESTINATARIO_MENSAGEM_SALVAR_ERRO = 'Não foi possível salvar o destinatário do alerta. Por favor tente novamente.',
    SECADOR_ALTERACAO_CONTROLE_ERRO = 'Erro ao alterar o(s) controle(s) do secador.',
    SEM_CONEXAO_INTERNET_ERRO = 'Sem conexão com a internet',
    VERIFIQUE_CONEXAO_INTERNET_ERRO = 'Verifique sua conexão e tente novamente.',
    PERIODO_MAIOR_24H_CSV = 'Não é possível exportar o relatório em CSV para períodos acima de 24 horas',
    ERRO_EXPORTAR_CSV = 'Nenhum resultado encontrado para exportar o arquivo CSV',
}
