import { Injectable } from '@angular/core';
import * as BABYLON from '@babylonjs/core';
import { InformacoesSiloInterface, PenduloSilo3DInterface, Silo3DPropriedadesInterface } from 'app/shared/interfaces';
import { IRetornaAlturaPendulosSilo3DService } from 'app/shared/services';

export abstract class ISincronizarPendulosSilo3DService {
    abstract execute(
        silo: InformacoesSiloInterface,
        vetorPosicaoPendulo: number[][],
        siloData: Silo3DPropriedadesInterface,
        penduloData: PenduloSilo3DInterface[],
        camera: BABYLON.ArcRotateCamera,
        cameraInicial: BABYLON.ArcRotateCamera
    ): Promise<void>;
}

@Injectable({ providedIn: 'root' })
export class SincronizarPendulosSilo3DService implements ISincronizarPendulosSilo3DService {
    constructor(private retornaAlturaPendulosSilo3DService: IRetornaAlturaPendulosSilo3DService) {}

    async execute(
        silo: InformacoesSiloInterface,
        vetorPosicaoPendulo: number[][],
        siloData: Silo3DPropriedadesInterface,
        penduloData: PenduloSilo3DInterface[],
        camera: BABYLON.ArcRotateCamera,
        cameraInicial: BABYLON.ArcRotateCamera
    ): Promise<void> {
        if (silo) {
            //Pega a quantidade de pendulos
            let qtdPendulos = silo?.mapa.pendulo_ordenacao.split(';').length;

            //Pega o nivel de volume por pendulo
            let vetorNivelVolume = silo?.mapa.sensor_nivel_temperatura.split(';').map(Number);

            //Pega a quantidade de sensores por pendulo
            let qtdSensoresPorPendulo: number[] = silo?.mapa.sensor_quantidade_temperatura.split(';').map(Number) || [];

            //Separa a string incial com todos pendulos em uma string para cada pendulo ("22.5;22,1|18.1;19.1" vira "22.5;22.1" e "18.1;19.1")
            let stringVetorTemperaturas: string[] = silo?.mapa.temperatura.split('|'); // Vetor com a string de temperatura dos sensores de cada pendulo

            //Pega o número de cada pendulo (Os pendulos não necessariamente seguem a ordem 1-2-3... pode ser 1-4-2-5)...
            let vetorNumeroDoPendulo: string[] = silo?.mapa.pendulo_ordenacao.split(';').map(String) || []; // Vetor com o numero de cada pendulo

            //Pega a matriz com as posX e posZ dos pendulos da visao superior
            const posicaoPendulos = vetorPosicaoPendulo;

            for (let i = 0; i < qtdPendulos; i++) {
                //Pega apenas a posição X e Z da visao superior desse pendulo
                let posicaoPendulo = vetorPosicaoPendulo[i];

                //Faz um vetor com as temperaturas  de cada sensor
                let valorsensores: string[] = stringVetorTemperaturas[i].split(';');

                //Inicializa a string que vai mostrar em cada sensor na cena, posX, e posZ
                let valorSensoresStr: string[] = [];
                let posX: number;
                let posZ: number;
                //Pega se o pendulo é termopar ou digital
                let tipoPendulo: string =
                    silo?.estrutura_armazenagem_divisao_pendulo[i].pendulo.pendulo_propriedade.digital_termopar;

                let distanciaBase: number =
                    silo?.estrutura_armazenagem_divisao_pendulo[i].pendulo.pendulo_propriedade.distancia_base / 10;
                let distanciaTelhado: number =
                    silo?.estrutura_armazenagem_divisao_pendulo[i].pendulo.pendulo_propriedade.distancia_telhado / 10;
                let sensorTempEspacamento: number =
                    silo?.estrutura_armazenagem_divisao_pendulo[i].pendulo.pendulo_propriedade
                        .sensor_temperatura_espacamento / 10;

                //Se tem valores X e Z pro pendulo na visao superior, converte para o X e Z do silo3D, se não tem posição, cancela esse pendulo
                if (posicaoPendulo) {
                    posX = -(posicaoPendulos[i][0] * (siloData?.diametroSilo / 2 / 186)) + siloData?.diametroSilo / 2;
                    posZ = posicaoPendulos[i][1] * (siloData?.diametroSilo / 2 / 186) - siloData?.diametroSilo / 2;
                } else {
                    break;
                }

                //Acha a media, maior e menor temperatura dos sensores cobertos por grão
                let somaTemperaturas = 0;
                let contador = 0;
                let menorTemperatura = Infinity;
                let maiorTemperatura = -Infinity;
                for (let j = 0; j < qtdSensoresPorPendulo[i]; j++) {
                    let valor = '---';
                    if (valorsensores[j]) {
                        valor = valorsensores[j];
                    }

                    //Se o sensor for '---', cria a string para mostrar na cena, se não for, cria a string e adiciona o sensor para o calculo de min max e med
                    if (valor === '---') {
                        valorSensoresStr.push(`${j + 1}`.padStart(2, '0') + ': ' + valor);
                    } else {
                        const temperatura = parseFloat(valor);
                        if (j < vetorNivelVolume[i]) {
                            somaTemperaturas += temperatura;
                            contador++;
                            if (temperatura < menorTemperatura) {
                                menorTemperatura = temperatura;
                            }
                            if (temperatura > maiorTemperatura) {
                                maiorTemperatura = temperatura;
                            }
                        }

                        valorSensoresStr.push(
                            `${j + 1}`.padStart(2, '0') + ': ' + temperatura.toFixed(1).replace('.', ',')
                        );
                    }
                }

                //Criação do pendulo
                let novoPendulo: PenduloSilo3DInterface = {
                    numeroPendulo: vetorNumeroDoPendulo[i].padStart(2, '0'),
                    mediaTemperatura: somaTemperaturas / contador,
                    maxTemperatura: maiorTemperatura,
                    minTemperatura: menorTemperatura,
                    tempSensoresStr: valorSensoresStr,
                    tempSensores: valorsensores,
                    qtdsensores: qtdSensoresPorPendulo[i],
                    tipoPendulo: tipoPendulo,
                    nivelVolume: vetorNivelVolume[i],
                    posX: posX,
                    posZ: posZ,
                    distanciaBase: distanciaBase,
                    distanciaTelhado: distanciaTelhado,
                    sensorTempEspacamento: sensorTempEspacamento,
                    ativo: false,
                };
                penduloData.push(novoPendulo);
            }

            // Busca pelo pendulo mais proximo da coordenada 0.0 para ser o pendulo central
            let indexPenduloCentral = 0;
            let raioPenduloCentral = Math.sqrt(
                penduloData[0].posX * penduloData[0].posX + penduloData[0].posZ * penduloData[0].posZ
            );
            // Array para armazenar as temperaturas e os índices dos pêndulos
            let temperaturas = [];
            for (let i = 0; i < penduloData.length; i++) {
                // Calcula o raio do pêndulo atual
                let raioPendulo = Math.sqrt(
                    penduloData[i].posX * penduloData[i].posX + penduloData[i].posZ * penduloData[i].posZ
                );

                // Verifica se este pêndulo é o mais próximo do centro
                if (raioPendulo < raioPenduloCentral) {
                    indexPenduloCentral = i;
                    raioPenduloCentral = raioPendulo;
                }

                // Adiciona a temperatura e o índice do pêndulo ao array
                temperaturas.push({ index: i, temperatura: penduloData[i].maxTemperatura });
            }

            // Ordena o array de temperaturas em ordem decrescente
            temperaturas.sort((a, b) => b.temperatura - a.temperatura);

            // Marca o pêndulo central
            penduloData[indexPenduloCentral].central = true;

            // Marca os três pêndulos com as maiores temperaturas como destaque
            for (let i = 0; i < 3; i++) {
                if (i < temperaturas.length) {
                    penduloData[temperaturas[i].index].destaque = true;
                }
            }

            //Calcula alturas Y1 e Y2 do pendulo e pendulo central
            for (let i = 0; i < penduloData.length; i++) {
                let Y: number[] = [];
                if (penduloData[i].central) {
                    Y = this.retornaAlturaPendulosSilo3DService.execute(
                        siloData,
                        silo?.silo.base,
                        penduloData[i].posX,
                        penduloData[i].posZ
                    );
                } else {
                    if (silo?.silo.base == 'plana') {
                        Y = this.retornaAlturaPendulosSilo3DService.execute(
                            siloData,
                            silo?.silo.base,
                            penduloData[i].posX,
                            penduloData[i].posZ
                        );
                    } else if (silo?.silo.base == 'semi_v') {
                        Y = this.retornaAlturaPendulosSilo3DService.execute(
                            siloData,
                            silo?.silo.base,
                            penduloData[i].posX,
                            penduloData[i].posZ
                        );
                    } else {
                        Y = this.retornaAlturaPendulosSilo3DService.execute(
                            siloData,
                            silo?.silo.base,
                            penduloData[i].posX,
                            penduloData[i].posZ
                        );
                    }
                }
                penduloData[i].posY1 = Y[0];
                penduloData[i].posY2 = Y[1];
            }

            //Coloca a câmera no Pêndulo com maior temperatura no sensor
            if (penduloData[temperaturas[0].index].destaque) {
                let alturaCamera;

                if (silo?.silo?.base == 'plana') {
                    alturaCamera = (siloData?.alturaPlataforma + siloData?.alturaSilo + siloData?.alturaChapeu) / 2;
                    camera.target = new BABYLON.Vector3(0, alturaCamera, 0);
                    camera.upperRadiusLimit =
                        (siloData?.alturaPlataforma +
                            siloData?.alturaSilo +
                            siloData?.alturaChapeu +
                            siloData?.diametroSilo) *
                        1.5;
                    camera.setPosition(
                        new BABYLON.Vector3(
                            penduloData[temperaturas[0].index].posX,
                            alturaCamera,
                            penduloData[temperaturas[0].index].posZ
                        )
                    );
                    camera.radius =
                        siloData?.alturaPlataforma +
                        siloData?.alturaSilo +
                        siloData?.alturaChapeu +
                        siloData?.diametroSilo * 1.3;
                } else {
                    alturaCamera =
                        (siloData?.alturaPlataforma +
                            siloData?.alturaFundo +
                            siloData?.alturaSilo +
                            siloData?.alturaChapeu) /
                        2;
                    camera.target = new BABYLON.Vector3(0, alturaCamera, 0);
                    camera.upperRadiusLimit =
                        (siloData?.alturaPlataforma +
                            siloData?.alturaFundo +
                            siloData?.alturaSilo +
                            siloData?.alturaChapeu +
                            siloData?.diametroSilo) *
                        1.5;
                    camera.setPosition(
                        new BABYLON.Vector3(
                            penduloData[temperaturas[0].index].posX,
                            alturaCamera,
                            penduloData[temperaturas[0].index].posZ
                        )
                    );
                    camera.radius =
                        siloData?.alturaPlataforma +
                        siloData?.alturaFundo +
                        siloData?.alturaSilo +
                        siloData?.alturaChapeu +
                        siloData?.diametroSilo * 1.3;
                }
                cameraInicial.target = camera.target;
                cameraInicial.upperRadiusLimit = camera.upperRadiusLimit;
                cameraInicial.setPosition = camera.setPosition;
                cameraInicial.radius = camera.radius;
                cameraInicial.alpha = camera.alpha;
                cameraInicial.beta = camera.beta;
            }
        }
    }
}
