import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { PrimeiroAcessoObservable } from 'app/modules/auth/observables';
import { UsuarioController } from 'app/shared/controllers';
import { Observable, take } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class CanUseSystemGuard implements CanActivate, CanActivateChild {
    constructor(
        private readonly router: Router,
        private primeiroAcessoObservable$: PrimeiroAcessoObservable,
        private usuarioController: UsuarioController
    ) {}

    public canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        return new Promise((res) => {
            this.primeiroAcessoObservable$.buscaPrimeiroAcessoObservable().subscribe((obrigarResetarSenha) => {
                if (obrigarResetarSenha) {
                    this.router.navigate(['/primeiro-acesso']);
                    res(false);
                } else {
                    res(true);
                }
            });
        });
    }

    public canActivateChild(): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivate();
    }
}
