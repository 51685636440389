import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ControleAcoesHistoricoComponent } from './controle-acoes-historico.component';
import { TranslocoRootModule } from 'app/transloco-root.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [ControleAcoesHistoricoComponent],
    imports: [CommonModule, MatIconModule, TranslocoRootModule, MatTooltipModule],
    exports: [ControleAcoesHistoricoComponent],
})
export class ControleAcoesHistoricoModule {}
