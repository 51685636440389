import { Injectable } from '@angular/core';
import { SensorVolumetria3DUltimaLeitura } from 'app/shared/interfaces';

export abstract class IFormataLeiturasSensor3DService {
    abstract execute(leituras: SensorVolumetria3DUltimaLeitura): number[][];
}

@Injectable({ providedIn: 'root' })
export class FormataLeiturasSensor3DService implements IFormataLeiturasSensor3DService {
    public execute(leituras: SensorVolumetria3DUltimaLeitura): number[][] {
        let leiturasFormatadas = [];

        for (let i = 0; i < leituras.pontos_eixo_x.length; i++) {
            leiturasFormatadas.push([
                Number(leituras.pontos_eixo_x[i]),
                Number(leituras.pontos_eixo_y[i]),
                Number(leituras.pontos_eixo_z[i]),
            ]);
        }
        return leiturasFormatadas;
    }
}
