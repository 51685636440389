export enum ColunasEstoqueDisponivelNegociarEnum {
    GRAO = 'grao',
    DATA = 'data',
    ESTOQUE_TOTAL = 'estoqueTotal',
    ESTOQUE_FIXADO_CONTRATO = 'estoqueFixadoContrato',
    ESTOQUE_DISPONIVEL = 'estoqueDisponivel',
    VALOR_ESTOQUE_DISPONIVEL = 'valorEstoqueDisponivel',
    T = 't',
    SC = 'sc',
    TONELADAS = 'toneladas',
    SACAS = 'sacas',
}
