import { Injectable } from '@angular/core';
export abstract class IFormataMinutosHHMMSSService {
    abstract horas(minutos: number): string;
    abstract horasMinutos(minutos: number): string;
}

@Injectable({ providedIn: 'root' })
export class FormataMinutosHHMMSSService implements IFormataMinutosHHMMSSService {
    horas(minutos: number): string {
        const horas = Math.floor(minutos / 60);
        const horasFormatados = horas.toString().padStart(2, '0');
        return `${horasFormatados}`;
    }
    horasMinutos(minutos: number): string {
        const minuto = Math.floor(minutos % 60);
        const minutosFormatados = minuto.toString().padStart(2, '0');
        return `${this.horas(minutos)}:${minutosFormatados}`;
    }
}
