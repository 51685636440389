import { Injectable } from '@angular/core';

import { ValorEquilibrioHigroscopicoInterface } from 'app/modules/armazenagem/analise/relatorio-aeracao-equilibrio-higroscopico/containers/main/interfaces';

@Injectable({
    providedIn: 'root',
})
export class CalcularHorasAeracaoService {
    constructor() {}

    calcularTotalHorasAeracao(array: ValorEquilibrioHigroscopicoInterface[]): string {
        let totalMinutos = 0;

        array.forEach((item) => {
            const tempoParts = item.valor.split(':');
            const horas = parseInt(tempoParts[0], 10);
            const minutos = parseInt(tempoParts[1], 10);

            totalMinutos += horas * 60 + minutos;
        });

        const totalHoras = Math.floor(totalMinutos / 60);
        const minutosRestantes = totalMinutos % 60;

        if (totalHoras === 0 && minutosRestantes === 0) {
            return '00:00';
        } else {
            return `${String(totalHoras).padStart(2, '0')}:${String(minutosRestantes).padStart(2, '0')}`;
        }
    }

    calcularPercentualTempoAeracao(valor: string, horasTotais: ValorEquilibrioHigroscopicoInterface[]): string {
        const totalTempo = this.calcularTotalHorasAeracao(horasTotais);

        if (valor === '00:00' || totalTempo === '00:00') {
            return '0,0%';
        }

        const valorParts = valor.split(':');
        const valorEmHoras = parseInt(valorParts[0]) + parseInt(valorParts[1]) / 60;

        const totalTempoParts = totalTempo.split(':');
        const totalHoras = parseInt(totalTempoParts[0]) + parseInt(totalTempoParts[1]) / 60;

        const percentual = (valorEmHoras / totalHoras) * 100;

        return `${percentual.toFixed(1).replace('.', ',')}%`;
    }

    calcularSomaTotalHorasAeracao(valoresArrays: string[][]): string {
        const totalMinutos = valoresArrays.reduce((somaTotal, array) => {
            return (
                somaTotal +
                array.reduce((somaParcial, valor) => {
                    const [hora, minuto] = valor.split(':');
                    const horaMinutos = parseInt(hora) * 60 + parseInt(minuto);
                    return somaParcial + horaMinutos;
                }, 0)
            );
        }, 0);

        const totalHoras = Math.floor(totalMinutos / 60);
        const minutosRestantes = totalMinutos % 60;

        if (totalHoras === 0 && minutosRestantes === 0) {
            return '00:00';
        } else {
            return `${totalHoras.toString().padStart(2, '0')}:${minutosRestantes.toString().padStart(2, '0')}`;
        }
    }

    somarArraysHorasMinutos(...arrays: string[][]): string[] {
        if (arrays.length === 0 || arrays[0].length === 0) {
            return [];
        }

        const tamanho = arrays[0].length;
        const resultado: string[] = [];

        for (let i = 0; i < tamanho; i++) {
            let totalHoras = 0;
            let totalMinutos = 0;

            for (const array of arrays) {
                const valor = array[i];
                if (valor) {
                    const [hora, minuto] = valor.split(':');
                    const horaFormatada = parseInt(hora);
                    const minutoFormatado = parseInt(minuto);

                    if (!isNaN(horaFormatada) && !isNaN(minutoFormatado)) {
                        totalHoras += horaFormatada;
                        totalMinutos += minutoFormatado;
                    }
                }
            }

            totalHoras += Math.floor(totalMinutos / 60);
            totalMinutos = totalMinutos % 60;

            const novaHora = totalHoras.toString().padStart(2, '0');
            const novoMinuto = totalMinutos.toString().padStart(2, '0');
            resultado.push(`${novaHora}:${novoMinuto}`);
        }

        return resultado;
    }
}
