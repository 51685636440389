export enum SecadorUltimaLeituraAutomacaoKwEnum {
    modo_secagem = 'modo_secagem',
    fluxo_secagem = 'fluxo_secagem',
    energia_termica_real = 'energia_termica_real',
    consumo_combustivel_real = 'consumo_combustivel_real',
    custo_total_secagem = 'custo_total_secagem',
    umidade_entrada_media_ciclo = 'umidade_entrada_media_ciclo',
    umidade_saida_media_ciclo = 'umidade_saida_media_ciclo',
    umidade_saida_rodizio = 'umidade_saida_rodizio',
    umidade_saida_continuo = 'umidade_saida_continuo',
    capacidade_estatica = 'capacidade_estatica',
    fluxo_descarga_atual = 'fluxo_descarga_atual',
    capacidade_secagem = 'capacidade_secagem',
    fluxo_descarga_real = 'fluxo_descarga_real',
    ultima_equalizacao_descarga = 'ultima_equalizacao_descarga',
    ultima_limpeza_periodica = 'ultima_limpeza_periodica',
    umidade_equilibrio_grao = 'umidade_equilibrio_grao',
    registro_superior = 'registro_superior',
    tempo_carga_nivel_maximo = 'tempo_carga_nivel_maximo',
    tempo_carga_nivel_minimo = 'tempo_carga_nivel_minimo',
    produto_tipo_id = 'produto_tipo_id',
    modo_operacao = 'modo_operacao',
    secando = 'secando',
    velocidade_descarga = 'velocidade_descarga',
}

export enum SecadorUltimaLeituraAutomacaoKwTranducaoEnum {
    MODO_SECAGEM = 'SECADOR_VISAO_SCREEN.MODO_SECAGEM',
    FLUXO_SECAGEM = 'SECADOR_VISAO_SCREEN.FLUXO_SECAGEM',
    ENERGIA_TERMICA_REAL = 'SECADOR_VISAO_SCREEN.ENERGIA_TERMICA_REAL',
    CONSUMO_COMBUSTIVEL_REAL = 'SECADOR_VISAO_SCREEN.CONSUMO_COMBUSTIVEL_REAL',
    CUSTO_TOTAL_SECAGEM = 'SECADOR_VISAO_SCREEN.CUSTO_TOTAL_SECAGEM',
    UMIDADE_ENTRADA_MEDIA_CICLO = 'SECADOR_VISAO_SCREEN.UMIDADE_ENTRADA_MEDIA_CICLO',
    UMIDADE_SAIDA_MEDIA_CICLO = 'SECADOR_VISAO_SCREEN.UMIDADE_SAIDA_MEDIA_CICLO',
    UMIDADE_SAIDA_RODIZIO = 'SECADOR_VISAO_SCREEN.UMIDADE_SAIDA_RODIZIO',
    UMIDADE_SAIDA_CONTINUO = 'SECADOR_VISAO_SCREEN.UMIDADE_SAIDA_CONTINUO',
    CAPACIDADE_ESTATICA = 'SECADOR_VISAO_SCREEN.CAPACIDADE_ESTATICA',
    FLUXO_DESCARGA_ATUAL = 'SECADOR_VISAO_SCREEN.FLUXO_DESCARGA_ATUAL',
    CAPACIDADE_SECAGEM = 'SECADOR_VISAO_SCREEN.CAPACIDADE_SECAGEM',
    FLUXO_DESCARGA_REAL = 'SECADOR_VISAO_SCREEN.FLUXO_DESCARGA_REAL',
    ULTIMA_EQUALIZACAO_DESCARGA = 'SECADOR_VISAO_SCREEN.ULTIMA_EQUALIZACAO_DESCARGA',
    ULTIMA_LIMPEZA_PERIODICA = 'SECADOR_VISAO_SCREEN.ULTIMA_LIMPEZA_PERIODICA',
    UMIDADE_EQUILIBRIO_GRAO = 'SECADOR_VISAO_SCREEN.UMIDADE_EQUILIBRIO_GRAO',
    REGISTRO_SUPERIOR = 'SECADOR_VISAO_SCREEN.REGISTRO_SUPERIOR',
    TEMPO_CARGA_NIVEL_MAXIMO = 'SECADOR_VISAO_SCREEN.TEMPO_CARGA_NIVEL_MAXIMO',
    TEMPO_CARGA_NIVEL_MINIMO = 'SECADOR_VISAO_SCREEN.TEMPO_CARGA_NIVEL_MINIMO',
    PRODUTO_TIPO_ID = 'SECADOR_VISAO_SCREEN.PRODUTO_TIPO_ID',
    MODO_OPERACAO = 'SECADOR_VISAO_SCREEN.MODO_OPERACAO',
    SECANDO = 'SECADOR_VISAO_SCREEN.SECANDO',
    VELOCIDADE_DESCARGA = 'SECADOR_VISAO_SCREEN.VELOCIDADE_DESCARGA',
}
