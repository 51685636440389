import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ordemSecadorQualidade } from 'app/modules/gestao-unidades/operacional/shared/constants';
import {
    dadosOrdemCrescenteSecadorQualidade,
    FiltroGraficoLinhaInterface,
    ParamsGestaoUnidadesInterface,
} from 'app/modules/gestao-unidades/operacional/shared/interfaces';
import moment from 'moment';

export abstract class ITratarDadosRequisicaoQualidadeService {
    abstract tratarDadosBuscarExpedicaoAmostragens(
        parametros: ParamsGestaoUnidadesInterface,
        page: number,
        limit: number
    ): HttpParams;
    abstract tratarDadosBuscarDadosExpansivoExpedicaoAmostragem(parametros: FiltroGraficoLinhaInterface): HttpParams;
    abstract tratarDadosBuscarTemperaturaUmidadeGrao(parametros: ParamsGestaoUnidadesInterface): HttpParams;
    abstract tratarDadosBuscarSecadoresQualidade(
        parametros: ParamsGestaoUnidadesInterface,
        dadosOrdem: dadosOrdemCrescenteSecadorQualidade,
        filtro: string | null,
        page: number,
        limit: number
    ): HttpParams;
    abstract tratarDadosBuscarDetalhesSecadorQualidade(
        parametros: ParamsGestaoUnidadesInterface,
        possueData: boolean
    ): HttpParams;
    abstract tratarDadosBuscarListagemFiltrosCondicaoArmazenagem(usuarioId: string | null): HttpParams;
}

@Injectable({
    providedIn: 'root',
})
export class TratarDadosRequisicaoQualidadeService implements ITratarDadosRequisicaoQualidadeService {
    tratarDadosBuscarExpedicaoAmostragens(
        parametros: ParamsGestaoUnidadesInterface,
        page: number,
        limit: number
    ): HttpParams {
        let httpParams = new HttpParams();

        if (parametros.dataInicio) {
            const dataInicioTratada = moment(parametros.dataInicio).format('YYYY-MM-DD HH:mm:ss');
            httpParams = httpParams.set('filter.data_inicio', dataInicioTratada);
        }
        if (parametros.dataFim) {
            const dataFimTratada = moment(parametros.dataFim).format('YYYY-MM-DD HH:mm:ss');
            httpParams = httpParams.set('filter.data_fim', dataFimTratada);
        }

        if (parametros.produto_tipos && parametros.produto_tipos.length) {
            const produtoTiposTratados = parametros.produto_tipos.filter((tipo) => tipo);
            httpParams = httpParams.set('filter.produto_tipos', produtoTiposTratados.join(','));
        }

        if (parametros.unidades && parametros.unidades.length) {
            const unidadesTratadas = parametros.unidades.filter((unidade) => unidade);
            httpParams = httpParams.set('filter.unidades', unidadesTratadas.join(','));
        }

        if (parametros.cliente_id) {
            httpParams = httpParams.set('cliente_id', parametros.cliente_id);
        }

        if (parametros.page) {
            httpParams = httpParams.set('page', page);
        }
        if (parametros.limit) {
            httpParams = httpParams.set('limit', limit);
        }

        return httpParams;
    }

    tratarDadosBuscarDadosExpansivoExpedicaoAmostragem(parametros: FiltroGraficoLinhaInterface): HttpParams {
        let httpParams = new HttpParams();

        if (parametros.data_inicio) {
            const dataInicioTratada = moment(parametros.data_inicio).format('YYYY-MM-DD HH:mm:ss');
            httpParams = httpParams.set('data_inicio', dataInicioTratada);
        }
        if (parametros.data_fim) {
            const dataFimTratada = moment(parametros.data_fim).format('YYYY-MM-DD HH:mm:ss');
            httpParams = httpParams.set('data_fim', dataFimTratada);
        }

        return httpParams;
    }

    tratarDadosBuscarTemperaturaUmidadeGrao(parametros: ParamsGestaoUnidadesInterface): HttpParams {
        let httpParams = new HttpParams();

        if (parametros.produto_tipos && parametros.produto_tipos.length) {
            const produtoTiposTratados = parametros.produto_tipos.filter((tipo) => tipo);
            httpParams = httpParams.set('produto_tipos', produtoTiposTratados.join(','));
        }

        if (parametros.unidades && parametros.unidades.length) {
            const unidadesTratadas = parametros.unidades.filter((unidade) => unidade);
            httpParams = httpParams.set('unidades', unidadesTratadas.join(','));
        }

        if (parametros.cliente_id) {
            httpParams = httpParams.set('cliente_id', parametros.cliente_id);
        }

        return httpParams;
    }

    tratarDadosBuscarSecadoresQualidade(
        parametros: ParamsGestaoUnidadesInterface,
        dadosOrdem: dadosOrdemCrescenteSecadorQualidade,
        filtro: string | null,
        page: number,
        limit: number
    ): HttpParams {
        const mapeamentoFiltros = ordemSecadorQualidade;
        const chaveFiltro = Object.keys(dadosOrdem).find((key) => dadosOrdem[key] !== null);

        let httpParams = new HttpParams();

        if (parametros.produto_tipos && parametros.produto_tipos.length) {
            const produtoTiposTratados = parametros.produto_tipos.filter((tipo) => tipo);
            httpParams = httpParams.set('produto_tipos', produtoTiposTratados.join(','));
        }

        if (parametros.unidades && parametros.unidades.length) {
            const unidadesTratadas = parametros.unidades.filter((unidade) => unidade);
            httpParams = httpParams.set('unidades', unidadesTratadas.join(','));
        }

        if (chaveFiltro) {
            httpParams = httpParams.set('sortBy', mapeamentoFiltros[`${chaveFiltro}:${dadosOrdem[chaveFiltro]}`]);
        } else {
            httpParams = httpParams.set('sortBy', '');
        }

        if (parametros.cliente_id) {
            httpParams = httpParams.set('cliente_id', parametros.cliente_id);
        }

        if (filtro) {
            httpParams = httpParams.set('filtro_personalizado', filtro);
        }

        if (parametros.page) {
            httpParams = httpParams.set('page', page);
        }
        if (parametros.limit) {
            httpParams = httpParams.set('limit', limit);
        }

        return httpParams;
    }

    tratarDadosBuscarDetalhesSecadorQualidade(
        parametros: ParamsGestaoUnidadesInterface,
        possueData: boolean
    ): HttpParams {
        let httpParams = new HttpParams();

        if (parametros.dataInicio && possueData) {
            httpParams = httpParams.set('data_inicio', parametros.dataInicio);
        }

        if (parametros.dataFim && possueData) {
            httpParams = httpParams.set('data_fim', parametros.dataFim);
        }

        return httpParams;
    }

    tratarDadosBuscarListagemFiltrosCondicaoArmazenagem(usuarioId: string | null): HttpParams {
        let httpParams = new HttpParams();

        if (usuarioId) {
            httpParams = httpParams.append('usuario_id', usuarioId);
        }

        return httpParams;
    }
}
