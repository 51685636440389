import { Injectable } from '@angular/core';
import { IApiTransportInterface } from 'app/core/interfaces';
import { ApiTransportService } from 'app/core/services/transport';
import { UltimaAmostragemInterface } from 'app/shared/interfaces';
import { Observable } from 'rxjs';

export abstract class IUltimaLeituraEstruturaAmostragemController {
    abstract buscarUltimaLeituraAmostragem(idEtruturaDivisao: string): Observable<UltimaAmostragemInterface>;
}

@Injectable({
    providedIn: 'root',
})
export class UltimaLeituraEstruturaAmostragemController implements IUltimaLeituraEstruturaAmostragemController {
    private transport: IApiTransportInterface;

    constructor(public readonly apiTransportService: ApiTransportService) {
        this.transport = this.apiTransportService.execute('/geral/ultima-leitura/estrutura-amostragem');
    }

    public buscarUltimaLeituraAmostragem(idEtruturaDivisao: string): Observable<UltimaAmostragemInterface> {
        return this.transport.http.get<UltimaAmostragemInterface>(`${this.transport.path}/${idEtruturaDivisao}`);
    }
}
