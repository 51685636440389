import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { UserComponent } from 'app/layout/common/user/user.component';
import { SharedModule } from 'app/shared/shared.module';
import { TranslocoRootModule } from 'app/transloco-root.module';
import { IUsuarioIdiomaController, UsuarioIdiomaController } from 'app/layout/common/user/controllers';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    declarations: [UserComponent],
    imports: [
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        SharedModule,
        TranslocoRootModule,
        MatProgressSpinnerModule,
    ],
    exports: [UserComponent],
    providers: [{ provide: IUsuarioIdiomaController, useClass: UsuarioIdiomaController }],
})
export class UserModule {}
