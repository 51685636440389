import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

import { EquipamentoCategoriaEnum, EquipamentoTipoEnum, InformacoesEquipamentoCategoriaEnum } from 'app/shared/enums';
export abstract class IDefineCategoriaEquipamentoService {
    abstract execute(tipo: EquipamentoCategoriaEnum): InformacoesEquipamentoCategoriaEnum;
}

@Injectable({ providedIn: 'root' })
export class DefineCategoriaEquipamentoService implements IDefineCategoriaEquipamentoService {
    constructor(private translocoService: TranslocoService) {}

    execute(tipo: EquipamentoCategoriaEnum): InformacoesEquipamentoCategoriaEnum {
        let categoria = InformacoesEquipamentoCategoriaEnum.LEITURA_AUXILIAR;

        if (tipo === EquipamentoCategoriaEnum.TERMOMETRIA) {
            categoria = InformacoesEquipamentoCategoriaEnum.TERMOMETRIA;
        }

        if (tipo === EquipamentoCategoriaEnum.ESTACAO_METEOROLOGICA) {
            categoria = InformacoesEquipamentoCategoriaEnum.ESTACAO_METEOROLOGICA;
        }

        if (tipo === EquipamentoCategoriaEnum.AUTOMACAO) {
            categoria = InformacoesEquipamentoCategoriaEnum.AUTOMACAO;
        }

        if (tipo === EquipamentoCategoriaEnum.AERACAO) {
            categoria = InformacoesEquipamentoCategoriaEnum.AERACAO;
        }

        if (tipo === EquipamentoCategoriaEnum.SECADOR) {
            categoria = InformacoesEquipamentoCategoriaEnum.SECADOR;
        }
        return this.translocoService.translate(categoria);
    }
}
