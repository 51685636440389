import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import localeEs from '@angular/common/locales/es';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { appConfig } from 'app/core/config/app.config';
import { CoreModule } from 'app/core/core.module';
import { LayoutModule } from 'app/layout/layout.module';
import { MarkdownModule } from 'ngx-markdown';
import { NgxMaskModule } from 'ngx-mask';
import { ControleAcoesHistoricoModule } from './shared/components/controle-acoes-historico/controle-acoes-historico.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { HttpClientModule } from '@angular/common/http';
import { TranslocoRootModule } from './transloco-root.module';
import { TranslocoModule } from '@ngneat/transloco';
import { ConfigModule } from 'app/core/config';

registerLocaleData(localePt);
registerLocaleData(localeEs);

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
};

class REMOVED_LABELS extends MatPaginatorIntl {
    nextPageLabel: string = '';
    previousPageLabel: string = '';
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        ConfigModule.forRoot(appConfig),

        CoreModule,

        LayoutModule,

        MarkdownModule.forRoot({}),

        NgxMaskModule.forRoot({ validation: true }),
        ControleAcoesHistoricoModule,
        HttpClientModule,
        TranslocoRootModule,
        TranslocoModule,
    ],
    bootstrap: [AppComponent],
    providers: [
        { provide: LOCALE_ID, useValue: 'pt' },
        { provide: MatPaginatorIntl, useValue: new REMOVED_LABELS() },
    ],
})
export class AppModule {}
