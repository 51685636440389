import { AeradorStatuSodeEnum } from 'app/shared/enums';

export interface AeradorInterface {
    id: string;
    aerador: {
        aeracao: any;
        aerador_leitura_chave: AeradorLeituraInterface;
        aerador_leitura_rele: AeradorLeituraInterface;
        aerador_leitura_status: AeradorLeituraInterface;
        codigo: number;
        corrente_nominal?: number;
        potencia_eletrica?: number;
    };
}

export interface AeracaoInterface {
    status: AeradorStatuSodeEnum;
}

export interface AeradorLeituraInterface {
    equipamento_id: string;
    instante: string;
    valor: boolean;
}
