import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class RemoveParametrosSemDadosService {
    execute(parametros: any): any {
        const parametrosFiltrados = { ...parametros };

        for (const campo in parametrosFiltrados) {
            const valor = parametrosFiltrados[campo];

            if (valor === '' || valor == null) {
                delete parametrosFiltrados[campo];
            } else if (Array.isArray(valor)) {
                const arrayFiltrado = valor.filter((item) => item !== '');

                if (!arrayFiltrado.length) {
                    delete parametrosFiltrados[campo];
                } else {
                    parametrosFiltrados[campo] = arrayFiltrado;
                }
            }
        }

        return parametrosFiltrados;
    }
}
