import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Params, Router } from '@angular/router';
import { IEstruturaUsuarioController } from 'app/shared/controllers';
import { PerfilFuncionalidadeAcaoEnum } from 'app/shared/enums';
import { FuncionalidadePerfilInterface } from 'app/shared/interfaces';
import { PermissaoPerfilFuncionalidadeObservable } from 'app/shared/observables';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PermissoesAcessoFuncionalidadeGuard implements CanActivate {
    constructor(
        private estruturaUsuarioController: IEstruturaUsuarioController,
        private router: Router,
        private permissaoFuncionalidadeObservable: PermissaoPerfilFuncionalidadeObservable
    ) {}

    public canActivate(
        activatedRouteSnapshot: ActivatedRouteSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return new Promise((res) => {
            this.estruturaUsuarioController
                .buscaPermissaoUsuarioPorChave(activatedRouteSnapshot.data.chave)
                .subscribe((perfilFuncionalidade) => {
                    perfilFuncionalidade.parametrosRota = activatedRouteSnapshot.params;

                    this.permissaoFuncionalidadeObservable.setaPermissaoFuncionalidade = {
                        ...perfilFuncionalidade,
                        carregando: false,
                    };

                    if (perfilFuncionalidade?.acao === PerfilFuncionalidadeAcaoEnum.NAO_ACESSAR) {
                        res(false);
                        this.semAcessso(activatedRouteSnapshot);
                    } else {
                        if (location.pathname.includes('undefined')) {
                            this.router.navigate(['/']);
                        }
                        res(true);
                    }
                });
        });
    }

    private setaParametrosRota(parametros: Params): string {
        const id = parametros?.id != 'undefined' ? parametros.id : '';
        const subcelula =
            parametros?.subcelula != 'undefined' || parametros?.subcelula != '' ? parametros.subcelula : '';

        return `${id}/${subcelula ?? ''}`;
    }

    private semAcessso(activatedRouteSnapshot: ActivatedRouteSnapshot): void {
        if (activatedRouteSnapshot.data.tab >= 0) {
            if (!activatedRouteSnapshot.params.id) {
                this.router.navigate([`${activatedRouteSnapshot.data.url}/sem-acesso`]);
            } else if (activatedRouteSnapshot.params.id !== 'undefined') {
                this.router.navigate([
                    `${activatedRouteSnapshot.data.url}/sem-acesso/${this.setaParametrosRota(
                        activatedRouteSnapshot.params
                    )}`,
                ]);
            } else {
                this.router.navigateByUrl('/');
            }
        } else {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/sem-acesso']);
            });
        }
    }
}
