export interface UnidadeClienteInterface {
    selecionado?: boolean;
    permite_cadastro?: boolean;
    id?: string;
    unidade_id?: string;
    nome?: string;
    cliente_id?: string;
    cidade?: CidadeInterface;
    unidade_perfil?: UnidadePerfis[];
    usuario_unidade?: {
        id: string;
        permite_cadastro_usuario: boolean;
        arquivado: boolean;
        usuario_id: string;
        unidade_id: string;
        usuario?: Usuario;
    };
    usuario_perfil?: {
        id: string;
        perfil_id: string;
        arquivado: boolean;
    }[];
}

interface Usuario {
    id: string;
    usuario_perfil: UsuarioPerfil[];
}

interface UsuarioPerfil {
    id: string;
    perfil: Perfil;
}

interface Perfil {
    id: string;
    nome: string;
}

interface CidadeInterface {
    id: string;
    nome: string;
    uf: {
        id: string;
        nome: string;
        sigla: string;
    };
}

export interface UnidadePerfis {
    id?: string;
    perfil_id?: string;
    nome?: string;
    padrao?: boolean;
    selecionado?: boolean;
}

export interface SalvarAcessosPayloadInterface {
    unidades: {
        id?: string;
        usuario_unidade?: {
            permite_cadastro_usuario: boolean;
            arquivado: boolean;
        };
        usuario_perfil?: {
            perfil_id: string;
            arquivado: boolean;
        }[];
    }[];
}
export interface UnidadeClienteTempInterface {
    visivel: boolean;
    cliente_id: string;
    cliente_nome: string;
    unidades_cliente?: UnidadeClienteInterface[];
}

export interface OutPutBuscaUnidadesClienteInterface {
    clienteId: string;
    index: number;
}
