import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
export class ConfigurarMultiSelectService {
    execute(FormControl: AbstractControl, arrayItems: any[], valorAtual?: string): void {
        const valoresSelecionados = FormControl.value.filter((valor: string) => valor !== 'todos');
        const opcaoTodosAtiva = FormControl.value.includes('todos');

        if (opcaoTodosAtiva && valoresSelecionados.length === 1) {
            FormControl.setValue(valoresSelecionados);

            if (valorAtual === 'todos') {
                FormControl.setValue(['todos']);
            }
            return;
        }

        if (valoresSelecionados.length > 0 && opcaoTodosAtiva) {
            FormControl.setValue(['todos']);
            return;
        }

        if (valoresSelecionados.length === arrayItems.length - 1) {
            FormControl.setValue(['todos']);
            return;
        }
    }
}
