import { Injectable } from '@angular/core';
import { IApiTransportInterface } from 'app/core/interfaces';
import { ApiTransportService } from 'app/core/services/transport';
import { GraoNaEstruturaArmazenagem } from 'app/shared/interfaces';
import { Observable } from 'rxjs';

export abstract class IBuscaGraoPorEstruturaController {
    abstract buscaGraoPorEstrutura(idEstruturaArmazenagem: string): Observable<GraoNaEstruturaArmazenagem>;
}

@Injectable()
export class BuscaGraoPorEstruturaController implements IBuscaGraoPorEstruturaController {
    private transport: IApiTransportInterface;

    constructor(public readonly apiTransportService: ApiTransportService) {
        this.transport = this.apiTransportService.execute('/armazenagem/grao/estrutura-armazenagem-divisao');
    }

    buscaGraoPorEstrutura(idEstruturaArmazenagem: string): Observable<any> {
        return this.transport.http.get(`${this.transport.path}/${idEstruturaArmazenagem}`);
    }
}
