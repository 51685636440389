import { Injectable } from '@angular/core';
export abstract class IMaximoNumerosDigitadosService {
    abstract execute(event: any, numerosDigitados: number): void;
}

@Injectable({ providedIn: 'root' })
export class MaximoNumerosDigitadosService implements IMaximoNumerosDigitadosService {
    execute(event: any, numerosDigitados: number): void {
        if (numerosDigitados?.toString().length >= 3) {
            event.preventDefault();
        }
    }
}
