export enum NomePaisesEnum {
    ESTADOS_UNIDOS = 'USD',
    BRASIL = 'BRL',
    URUGUAI = 'UYU',
    COLOMBIA = 'COP',
    CHILE = 'CLP',
    CHINA = 'CNY',
    EUROPA = 'EUR',
    ARGENTINA = 'ARS',
    PARAGUAI = 'PYG',
    PERU = 'PEN',
}
