import { Injectable } from '@angular/core';
import { IApiTransportInterface } from 'app/core/interfaces';
import { ApiTransportService } from 'app/core/services/transport';
import { Observable } from 'rxjs';
import { ResponseInterface, UnidadeClienteInterface, UnidadePerfis } from 'app/shared/interfaces';

export abstract class IUnidadesController {
    abstract buscarUnidadesClientes(clienteId: string, usuarioId: string): Observable<UnidadeClienteInterface[]>;
    abstract buscarPerfisUnidade(unidadeId: string): Observable<UnidadePerfis[]>;
    abstract buscarPerfisModelo(): Observable<ResponseInterface<UnidadePerfis>>;
}

@Injectable()
export class UnidadesController implements IUnidadesController {
    private transport: IApiTransportInterface;

    constructor(public readonly apiTransportService: ApiTransportService) {
        this.transport = this.apiTransportService.execute('/geral');
    }

    buscarUnidadesClientes(clienteId: string, usuarioId: string): Observable<any> {
        return this.transport.http.get(`${this.transport.path}/unidade/cliente/${clienteId}/usuario/${usuarioId}`);
    }

    buscarPerfisUnidade(unidadeId: string): Observable<any> {
        return this.transport.http.get(`${this.transport.path}/perfil/unidade/${unidadeId}`);
    }

    buscarPerfisModelo(): Observable<any> {
        return this.transport.http.get(`${this.transport.path}/perfil/modelo`);
    }
}
