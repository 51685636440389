import { Injectable } from '@angular/core';
import { PaletaDeCoresInterface } from 'app/shared/interfaces';

const paletaParaTemperatura: PaletaDeCoresInterface[] = [
    { min: 0, max: 5.99, fundo: '#3D50F3', cor: '#FFFFFF' },
    { min: 6, max: 12, fundo: '#58BEF3', cor: '#00091F' },
    { min: 12.01, max: 18, fundo: '#3DAC79', cor: '#00091F' },
    { min: 18.01, max: 24, fundo: '#3DE95A', cor: '#00091F' },
    { min: 24.01, max: 30, fundo: '#D6E95A', cor: '#00091F' },
    { min: 30.01, max: 36, fundo: '#D6B25A', cor: '#00091F' },
    { min: 36.01, max: 200, fundo: '#D6505A', cor: '#FFFFFF' },
    { min: '', max: '', fundo: '#CCC', cor: '#00091F' },
];

const paletaParaUmidade: PaletaDeCoresInterface[] = [
    { min: 0, max: 33, fundo: '#A3CBE5', cor: '#00091F' },
    { min: 33.01, max: 66, fundo: '#5CA7D3', cor: '#00091F' },
    { min: 66.01, max: 100, fundo: '#185C84', cor: '#FFFFFF' },
    { min: '', max: '', fundo: '#CCC', cor: '#00091F' },
];

export abstract class ICriaPaletaCoresCondicaoArmazenagemService {
    abstract criarPaletaTemperaturaCondicaoArmazenagem(sensorTemperatura: number): PaletaDeCoresInterface;
    abstract criarPaletaUmidadeCondicaoArmazenagem(sensorUmidade:number, capacidade: number): PaletaDeCoresInterface;
}

@Injectable({ providedIn: 'root' })
export class CriaPaletaCoresCondicaoArmazenagemService implements ICriaPaletaCoresCondicaoArmazenagemService {
    criarPaletaTemperaturaCondicaoArmazenagem(sensorTemperatura: number): PaletaDeCoresInterface {
        let paletaCores;
        if (sensorTemperatura < 0) {
            sensorTemperatura = 0;
        }
        if (sensorTemperatura > 100) {
            sensorTemperatura = 100;
        }
        if (sensorTemperatura) {
            paletaCores = paletaParaTemperatura.filter(
                (temp: any) => sensorTemperatura >= temp.min && sensorTemperatura <= temp.max
            );
        } else {
            paletaCores = paletaParaTemperatura;
        }

        return paletaCores[0];
    }
    criarPaletaUmidadeCondicaoArmazenagem(sensorUmidade:number, capacidade: number): PaletaDeCoresInterface {
        let porcentagem = (sensorUmidade/capacidade)*100
        let paletaCores;
        if (porcentagem < 0) {
            porcentagem = 0;
        }
        if (porcentagem > 100) {
            porcentagem = 100;
        }
        if (porcentagem) {
            paletaCores = paletaParaUmidade.filter(
                (temp: any) => porcentagem >= temp.min && porcentagem <= temp.max
            );
        } else {
            paletaCores = paletaParaUmidade;
        }

        return paletaCores[0];
    }
}
