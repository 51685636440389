export interface interfaceCondicaoArmazenagem {
    id: string;
    instante: string;
    data_amostra: string;
    estutura_armazenagem: EstruturaArmazenagem;
    estutura_armazenagem_divisao: EstruturaArmazenagemDivisao;
    unidade: Unidade;
    produto_tipo: ProdutoTipo;
    programa_aeracao: ProgramaAeracao;
    estoque_estimado: string;
    estoque_informado: string;
    estoque_capacidade: string;
    tempo_de_aeracao: string;
    temperatura_minima: string;
    temperatura_media: string;
    temperatura_maxima: string;
    umidade_grao: string;
    equipamento_comunicacao_status: string;
}
interface EstruturaArmazenagem {
    id: string;
    nome: string;
    codigo: number;
    sensor_umidade_instalado: boolean;
    categoria: string;
    volume: string;
}

interface EstruturaArmazenagemDivisao {
    id: string;
    nome: string;
    controle_aeracao_automatica: boolean;
}

interface Unidade {
    id: string;
    categoria: string;
    nome: string;
}

interface ProdutoTipo {
    id: number;
    nome: string;
}

interface ProgramaAeracao {
    id: string;
    nome: string;
}

export interface elementoInterfaceModalFiltroPersonalizado {
    nome_filtro: string;
}

export interface dadosOrdemCondicoesArmazenagem {
    maximo: string | null;
    minima: string | null;
    media: string | null;
    umidade: string | null;
    estoque: string | null;
    alteracao: string | null;
}

export interface tipoOrdemCondicaoArmazenagem {
    active: string;
    direction: string;
}

export interface dadosHistoricoCondicaoArmazenagem {
    instante: string;
    data_amostra: string;
    estoque: string;
    tempo_de_aeracao: string;
    temperatura_media: string;
    temperatura_maxima: string;
}

export interface seriesParaGraficoGestaoGrao {
    name: string;
    data: DataPoint[];
}

export interface seriesAnotacaoInterface {
    x: number;
    y: number;
    label: labelSeriesAnotacaoInterface;
    marker: markerSeriesInterface;
}

export interface labelSeriesAnotacaoInterface {
    text: string;
    offsetY: number;
    style: {
        color: string;
        background: string | null;
    };
    borderWidth: number;
}

export interface markerSeriesInterface {
    size?: number;
    fillColor?: string;
    strokeColor?: string;
    strokeWidth?: number;
    shape?: string;
    radius?: number;
    OffsetX?: number;
    OffsetY?: number;
    cssClass?: string;
}

export interface DataPoint {
    x: string | number;
    y: number;
    goals: Goal[];
}
interface Goal {
    name: string;
    value: number;
    strokeHeight: number;
    strokeColor: string;
}
export interface parametrosFiltroPersonalizados {
    nome: string;
    valor: string;
}

export interface IRemoverFiltro {
    operador: string;
    parametro: string;
    valor: number;
}

export interface IAtualizarParametro {
    source: any;
    value: {
        id: string;
    };
}

export interface seriesParaGraficoDuplo {
    name: string;
    type: string;
    data: number[];
}
