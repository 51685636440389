import { PerfilFuncionalidadeAcaoEnum } from 'app/shared/enums';

export interface FuncionalidadeInterface {
    id: string;
    arquivado: boolean;
    modulo: string;
    nome: string;
    caminho: string;
    chave?: string;
    acao?: PerfilFuncionalidadeAcaoEnum;
}
