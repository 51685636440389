import { Injectable } from '@angular/core';

import moment from 'moment';

export abstract class IVerificaValidadeDeAmostragemService {
    abstract execute(data: string): boolean;
}

@Injectable()
export class VerificaValidadeDeAmostragemService implements IVerificaValidadeDeAmostragemService {
    public execute(data: string): boolean {
        const dateNow = new Date();
        let isValidSampling: boolean = false;
        let daysWithoutSampling: number = 0;
        let diff: moment.DurationInputArg1;

        if (data) {
            diff = moment(dateNow).diff(moment(data));
            daysWithoutSampling = moment.duration(diff).asDays();
            if (daysWithoutSampling > 15) {
                isValidSampling = false;
            }
            if (daysWithoutSampling <= 15) {
                isValidSampling = true;
            }
        }
        return isValidSampling;
    }
}
