import { EstacaoMeteorologicaInterface } from "app/shared/interfaces"
import { EstacaoMeteorologicaFonteStyles, EstacaoMeteorologicaStyles } from 'app/shared/constants';

export class EstacaoMeteorologicaHelper {
    
    public static carregaEstiloEstacaoMeteorologica(estacao: EstacaoMeteorologicaInterface): string {
        const estacaoMeteorologicaStylesEnum = EstacaoMeteorologicaStyles
        if (!estacao.ultima_leitura || Object.keys(estacao.ultima_leitura).length === 0) {
          return estacaoMeteorologicaStylesEnum.sem_leitura
        } else if (estacao.ultima_leitura.chovendo) {
          return estacaoMeteorologicaStylesEnum.chovendo
        } 

        return estacaoMeteorologicaStylesEnum.ensolarado
        
    }

    public static carregaEstiloFonteEstacaoMeteorologica(estacao: EstacaoMeteorologicaInterface): string {
        const estacaoMeteorologicaStylesEnum = EstacaoMeteorologicaFonteStyles;
        if (!estacao.ultima_leitura || Object.keys(estacao.ultima_leitura).length === 0) {
          return estacaoMeteorologicaStylesEnum.sem_leitura
        } else if (estacao.ultima_leitura.chovendo) {
          return estacaoMeteorologicaStylesEnum.chovendo
        }

        return estacaoMeteorologicaStylesEnum.ensolarado
        
    }
}  