import { Injectable } from '@angular/core';
import { IApiTransportInterface } from 'app/core/interfaces';
import { ApiTransportService } from 'app/core/services/transport';
import { Observable } from 'rxjs';
import { PenduloInterface, ResponseInterface } from 'app/shared/interfaces';

export abstract class IPendulosEstruturaArmazenagemDivisaoController {
    abstract buscarPendulos(idEtruturaDivisao: string): Observable<ResponseInterface<PenduloInterface>>;
}

@Injectable({
    providedIn: 'root',
})
export class PendulosEstruturaArmazenagemDivisaoController implements IPendulosEstruturaArmazenagemDivisaoController {
    private transport: IApiTransportInterface;

    constructor(public readonly apiTransportService: ApiTransportService) {
        this.transport = this.apiTransportService.execute('/armazenagem/pendulo/estrutura-armazenagem-divisao');
    }

    public buscarPendulos(idEtruturaDivisao: string): Observable<ResponseInterface<PenduloInterface>> {
        return this.transport.http.get<ResponseInterface<PenduloInterface>>(
            `${this.transport.path}/${idEtruturaDivisao}`
        );
    }
}
