export interface dadosOrdemSecadoresQualidade {
    maximo: string | null;
    minima: string | null;
    media: string | null;
    umidade: string | null;
    estoque: string | null;
    alteracao: string | null;
}

export interface ClienteQualidade {
    id: string;
    arquivado: boolean;
    nome: string;
}

export interface UnidadeSecadorQualidade {
    id: string;
    categoria: string;
    arquivado: boolean;
    nome: string;
    cidade: string;
    uf: string;
}

export interface ProdutoTipoSecadorQualidade {
    id: number;
    arquivado: boolean;
    nome: string;
    peso_saca: number;
}

export interface SecadorLeituraHistoricoConsolidado {
    id: string;
    instante: string;
    temperatura_atual_entrada: string;
    temperatura_atual_massa: string;
    temperatura_atual_saida: string;
    maior_valor_lido_entrada: string;
    maior_valor_lido_massa: string;
    maior_valor_lido_saida: string;
    numero_alerta_temperatura_maxima_entrada: string;
    numero_alerta_temperatura_maxima_massa: string;
    numero_alerta_temperatura_maxima_saida: string;
    umidade_grao_carga: string;
    umidade_grao_descarga: string;
    ug_carga_minima: string;
    ug_carga_media: string;
    ug_carga_maxima: string;
    ug_descarga_minima: string;
    ug_descarga_media: string;
    ug_descarga_maxima: string;
    equipamento_comunicacao_instante: string;
    equipamento_comunicacao_status: string;
    motivo: string | null;
}

export interface DadosTabelaSecadorQualidade {
    id: string;
    codigo: number;
    modelo_painel: string;
    nome: string;
    secando: boolean;
    intervalo_limpeza: number;
    arquivado: boolean;
    Qualidade: ClienteQualidade;
    unidade: UnidadeSecadorQualidade;
    produto_tipo: ProdutoTipoSecadorQualidade;
    secador_leitura_historico_consolidado: SecadorLeituraHistoricoConsolidado;
}

export interface dadosOrdemCrescenteSecadorQualidade {
    entradaTemperatura: string | null;
    massaTemperatura: string | null;
    saidaTemperatura: string | null;
    entradaMaiorTemperatura: string | null;
    massaMaiorTemperatura: string | null;
    saidaMaiorTemperatura: string | null;
    entradaAlerta: string | null;
    massaAlerta: string | null;
    saidaAlerta: string | null;
    cargaUmidade: string | null;
    descargaUmidade: string | null;
    minimoUmidadeCarga: string | null;
    mediaUmidadeCarga: string | null;
    maximaUmidadeCarga: string | null;
    minimoUmidadeDescarga: string | null;
    mediaUmidadeDescarga: string | null;
    maximaUmidadeDescarga: string | null;
}

export interface detalhesSecadorQualidade {
    instante: string;
    umidade_grao: detalheValor;
    temperatura_entrada_ar: detalheAlerta;
    temperatura_massa_graos: detalheAlerta;
    temperatura_saida_ar: detalheAlerta;
    temperatura_controle_entrada_ar: detalheValor;
    temperatura_controle_massa_grao: detalheValor;
    temperatura_controle_saida_ar: detalheValor;
}

interface detalheValor {
    valor: string | number;
}

interface detalheAlerta {
    valor: string | number;
    alerta: boolean;
}

export interface dadosGraficoSecadoresQualidade {
    name: string;
    data: (string | number )[];
}

export interface serieAnotacaoQualidade {
    x: number | string;
    y: number | string;
    marker: {
        size: number;
        fillColor: string;
        strokeColor: string;
        shape: string;
        radius: number;
    };
    seriesIndex?: number;
}
