export const formataData = {
    locale: 'pt-br',
    formato: 'D/MM/YYYY',
    formatoInverso: 'YYYY/MM/D',
};

export const formataDataComHora = {
    locale: 'pt-br',
    formato: 'DD/MM/YYYY HH:mm:ss',
};

export const formataDataComHoraSemMinutos = {
    locale: 'en-US',
    formato: 'YYYY-MM-DDTHH:mmZ',
};
