export interface EnviaEstoqueFixadoInterface {
    cliente_id: string;
    unidades: string[];
    produto_tipo: number;
    estoque_total: number;
    estoque_fixado_em_contrato: number;
    unidade_medida_estoque: string;
}

export interface EnviaEstoqueFixadoEventoInterface {
    cliente_id?: string;
    unidades?: string[];
    produto_tipo: number;
    estoque_total: number;
    estoque_fixado_em_contrato: number;
    unidade_medida_estoque: string;
}
