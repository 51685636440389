import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiTransportService } from 'app/core/services/transport';
import { IApiTransportInterface } from 'app/core/interfaces';
import {
    ResponseInterface,    
    TipoSensoresPorAreaSecadorInterface,
} from 'app/shared/interfaces';
import { IObjectToAnyService } from 'app/shared/services';

import {
    ListaSecadoresInteface,    
    VisaoDetalhadaSecadorInteface,
    VisaoGeralSecadorInteface,
} from 'app/modules/secador/visualizacao/secadores/visao-detalhada-secador/interfaces';

export abstract class ISecadorController {
    abstract listarSecadores(page: number): Observable<ResponseInterface<VisaoGeralSecadorInteface>>;
    abstract buscaDadosSecador(id: string): Observable<VisaoDetalhadaSecadorInteface>;
    abstract buscaVarios(): Observable<ResponseInterface<ListaSecadoresInteface>>;
    abstract buscaAreasSecador(id: string): Observable<any>;
    abstract buscaInformacoesBaseSecador(id: string): Observable<Partial<VisaoDetalhadaSecadorInteface>>;
    abstract buscaTipoSensoresPorAreaSecador(
        id: string,
        area: string
    ): Observable<TipoSensoresPorAreaSecadorInterface[]>;    
}

@Injectable({
    providedIn: 'root',
})
export class SecadorController implements ISecadorController {
    private transport: IApiTransportInterface;

    constructor(
        readonly apiTransportService: ApiTransportService,
        private readonly objectToAnyService: IObjectToAnyService
    ) {
        this.transport = this.apiTransportService.execute('/secador');
    }

    buscaInformacoesBaseSecador(id: string): Observable<any> {
        return this.transport.http.get(`${this.transport.path}/${id}`);
    }

    buscaDadosSecador(id: string): Observable<any> {
        return this.transport.http.get(`${this.transport.path}/visao-detalhada/${id}`);
    }

    buscaAreasSecador(id: string): Observable<any> {
        return this.transport.http.get(`${this.transport.path}/secador-visao-area/${id}`);
    }

    buscaTipoSensoresPorAreaSecador(id: string, area: string): Observable<any> {
        const params = { somente_sensores_leitura: true };

        return this.transport.http.get(`${this.transport.path}/secador-tipo-sensor/${id}/area-secador/${area}`, {
            params,
        });
    }

    buscaVarios(): Observable<any> {
        this.transport.options.params = this.objectToAnyService.toQueryString({
            page: 1,
            limit: 100,
            'filter.arquivado': '$eq:false',
        });
        return this.transport.http.get(`${this.transport.path}`, this.transport.options);
    }

    listarSecadores(page: number): Observable<ResponseInterface<VisaoGeralSecadorInteface>> {
        const params = {
            page: page,
            limit: 3,
        };
        return this.transport.http.get<ResponseInterface<VisaoGeralSecadorInteface>>(
            `${this.transport.path}/listagem`,
            { params }
        );
    }
}
