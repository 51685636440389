export interface SecadorAlertaInterface {
    id?: number;
    secador_id: number;
    nome: string;
    area_secador: string;
    categoria: string;
    mensagem: string;
    intervalo_verificacao: number;
    expressao_logica: string;
    valor_controle: number;
    descricao: string;
}
