import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class StatusRequisicaoHttpService {
    private requisicoesPendentes = 0;
    private _carregandoRequisicoes = new BehaviorSubject<boolean>(false);

    carregandoRequisicoes = this._carregandoRequisicoes.asObservable();

    inicioRequisicao() {
        this.requisicoesPendentes++;
        if (this.requisicoesPendentes > 0 && !this._carregandoRequisicoes.value) {
            this._carregandoRequisicoes.next(true);
        }
    }

    fimRequisicao() {
        this.requisicoesPendentes--;
        if (this.requisicoesPendentes === 0 && this._carregandoRequisicoes.value) {
            this._carregandoRequisicoes.next(false);
        }
    }
}
