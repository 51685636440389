import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    BuscaRentabilidadeFiltroInterface,
    ParamsGestaoUnidadesInterface,
    SecadoresEficienciaRequestInterface,
} from 'app/modules/gestao-unidades/operacional/shared/interfaces';

export abstract class ITratarDadosRequisicaoRentabilidadeService {
    abstract tratarDadosBuscarEstoqueTotalizadores(params: BuscaRentabilidadeFiltroInterface): HttpParams;
    abstract tratarDadosBuscarListagemEficienciaSecadores(params: SecadoresEficienciaRequestInterface): HttpParams;
    abstract tratarDadosBuscarEstoqueDisponivelNegociar(params: BuscaRentabilidadeFiltroInterface): HttpParams;
    abstract tratarDadosBuscarGanhosComAeracaoAutomatica(params: ParamsGestaoUnidadesInterface): HttpParams;
    abstract tratarDadosBuscarGanhosArmazenagemPropria(params: ParamsGestaoUnidadesInterface): HttpParams;
}

@Injectable({ providedIn: 'root' })
export class TratarDadosRequisicaoRentabilidadeService implements ITratarDadosRequisicaoRentabilidadeService {
    tratarDadosBuscarEstoqueTotalizadores(params: BuscaRentabilidadeFiltroInterface): HttpParams {
        let httpParams = new HttpParams();

        if (params.cliente_id) {
            httpParams = httpParams.set('cliente_id', params.cliente_id);
        }
        if (params.unidades && params.unidades.length) {
            const unidadesTratadas = params.unidades.filter((unidade) => unidade);
            httpParams = httpParams.set('unidades', unidadesTratadas.join(','));
        }
        if (params.produto_tipos && params.produto_tipos.length) {
            const produtoTiposTratados = params.produto_tipos.filter((tipo) => tipo);
            httpParams = httpParams.set('produto_tipos', produtoTiposTratados.join(','));
        }
        if (params.unidade_medida_estoque) {
            httpParams = httpParams.set('unidade_medida_estoque', params.unidade_medida_estoque);
        }

        return httpParams;
    }

    tratarDadosBuscarEstoqueDisponivelNegociar(params: BuscaRentabilidadeFiltroInterface): HttpParams {
        let httpParams = new HttpParams();

        if (params.cliente_id) {
            httpParams = httpParams.set('cliente_id', params.cliente_id);
        }
        if (params.unidades && params.unidades.length) {
            const unidadesTratadas = params.unidades.filter((unidade) => unidade);
            httpParams = httpParams.set('unidades', unidadesTratadas.join(','));
        }
        if (params.produto_tipos && params.produto_tipos.length) {
            const produtoTiposTratados = params.produto_tipos.filter((tipo) => tipo);
            httpParams = httpParams.set('produto_tipos', produtoTiposTratados.join(','));
        }

        if (params.unidade_medida_estoque && params.unidade_medida_estoque.length) {
            const unidadeMedidaEstoqueTratada = params.unidade_medida_estoque;
            httpParams = httpParams.set('unidade_medida_estoque', unidadeMedidaEstoqueTratada);
        }

        if (params.pagina) {
            httpParams = httpParams.set('page', params.pagina);
        } else {
            httpParams = httpParams.set('page', 1);
        }

        if (params.moeda) {
            httpParams = httpParams.set('moeda', params.moeda);
        }
        httpParams = httpParams.set('limit', 3);

        return httpParams;
    }

    tratarDadosBuscarListagemEficienciaSecadores(params: SecadoresEficienciaRequestInterface): HttpParams {
        let httpParams = new HttpParams();

        if (params.cliente_id) {
            httpParams = httpParams.set('cliente_id', params.cliente_id);
        }

        if (params.unidades && params.unidades.length) {
            const unidadesTratadas = params.unidades.filter((unidade) => unidade);
            httpParams = httpParams.set('unidades', unidadesTratadas.join(','));
        }
        if (params.produtos_tipos && params.produtos_tipos.length) {
            const produtoTiposTratados = params.produtos_tipos.filter((tipo) => tipo);
            httpParams = httpParams.set('produto_tipos', produtoTiposTratados.join(','));
        }

        if (params.page) {
            httpParams = httpParams.set('page', params.page);
        }
        if (params.limit) {
            httpParams = httpParams.set('limit', params.limit);
        }
        if (params.moeda) {
            httpParams = httpParams.set('moeda', params.moeda);
        }
        return httpParams;
    }

    tratarDadosBuscarGanhosComAeracaoAutomatica(params: ParamsGestaoUnidadesInterface): HttpParams {
        let httpParams = new HttpParams();

        if (params.cliente_id) {
            httpParams = httpParams.set('cliente_id', params.cliente_id);
        }
        if (params.unidades && params.unidades.length) {
            const unidadesTratadas = params.unidades.filter((unidade) => unidade);
            httpParams = httpParams.set('unidades', unidadesTratadas.join(','));
        }
        if (params.produto_tipos && params.produto_tipos.length) {
            const produtoTiposTratados = params.produto_tipos.filter((tipo) => tipo);
            httpParams = httpParams.set('produto_tipos', produtoTiposTratados.join(','));
        }

        if (params.page) {
            httpParams = httpParams.set('page', params.page);
        }
        if (params.limit) {
            httpParams = httpParams.set('limit', params.limit);
        }
        if (params.moeda) {
            httpParams = httpParams.set('moeda', params.moeda);
        }

        return httpParams;
    }
    tratarDadosBuscarGanhosArmazenagemPropria(params: ParamsGestaoUnidadesInterface): HttpParams {
        let httpParams = new HttpParams();

        if (params.cliente_id) {
            httpParams = httpParams.set('cliente_id', params.cliente_id);
        }
        if (params.unidades && params.unidades.length) {
            const unidadesTratadas = params.unidades.filter((unidade) => unidade);
            httpParams = httpParams.set('unidades', unidadesTratadas.join(','));
        }
        if (params.produto_tipos && params.produto_tipos.length) {
            const produtoTiposTratados = params.produto_tipos.filter((tipo) => tipo);
            httpParams = httpParams.set('produto_tipos', produtoTiposTratados.join(','));
        }

        if (params.page) {
            httpParams = httpParams.set('page', params.page);
        }
        if (params.limit) {
            httpParams = httpParams.set('limit', params.limit);
        }
        if (params.moeda) {
            httpParams = httpParams.set('moeda', params.moeda);
        }

        return httpParams;
    }
}
