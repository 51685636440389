import { Injectable } from '@angular/core';
import { EquipamentoInformacoesSiloInterface } from 'app/shared/interfaces';
export abstract class IMontaListaEquipamentosSensoresService {
    abstract execute(
        sensoresEquipamentos: any[],
        equipamentosEstrutura: EquipamentoInformacoesSiloInterface[]
    ): EquipamentoInformacoesSiloInterface[];
}

@Injectable({ providedIn: 'root' })
export class MontaListaEquipamentosSensoresService implements IMontaListaEquipamentosSensoresService {
    execute(
        sensoresEquipamentos: any[],
        equipamentosEstrutura: EquipamentoInformacoesSiloInterface[]
    ): EquipamentoInformacoesSiloInterface[] {
        let equipamentos: any[] = [];
        sensoresEquipamentos?.forEach((sensor) => {
            equipamentosEstrutura.forEach((equipamento) => {
                if (sensor.equipamento_id === equipamento.id) {
                    equipamentos.push(equipamento);
                }
            });
        });
        return equipamentos.filter((equipamento, index) => equipamentos.indexOf(equipamento) === index);
    }
}
