export const duploYaxisGrafico = [
    {
        seriesName: 'Temperatura da entrada de ar',
        axisBorder: {
            show: true,
            color: '#444',
            width: 1,
        },
        axisTicks: {
            show: true,
            color: '#444',
            width: 5,
        },
        title: {
            text: 'Temperatura (°C)',
            style: {
                color: '#666',
                fontSize: '8px',
            },
        },
    },
    {
        seriesName: 'Umidade do grão',
        axisBorder: {
            show: true,
            color: '#444',
            width: 1,
        },
        axisTicks: {
            show: true,
            color: '#444',
            width: 5,
        },
        title: {
            text: 'Umidade dos grãos (%)',
            style: {
                color: '#666',
                fontSize: '8px',
            },
        },
        opposite: true
    },
    {
        seriesName: 'Umidade do grão',
        show: false
    },
    {
        seriesName: 'Umidade do grão',
        show: false
    },
    {
        seriesName: 'Umidade do grão',
        show: false
    },
    {
        seriesName: 'Umidade do grão',
        show: false
    },
    {
        seriesName: 'Umidade do grão',
        show: false
    },
]

export const unicoYaxisGrafico = {
    axisBorder: {
        show: true,
        color: '#444',
        width: 1,
    },
    axisTicks: {
        show: true,
        color: '#444',
        width: 5,
    },
    title: {
        text: 'Leituras',
        style: {
            color: '#666',
            fontSize: '9',
        },
    },
}
