export interface ListaGraosInterfaceInterface {
    id?: number | string;
    valor?: number | string;
    nome?: string;
}

export interface listaCardsTotaisInterface {
    totalSilos: number;
    totalArmazens: number;
    totalSecadores: number;
    totalSecadoresEmOperacao: number;
}

export interface DadosAeracaoGraos {
    aeracao_painel: AeracaoPainel[];
    aeracao_sistema: AeracaoSistema[];
}

export interface dadosGraficoGestaoEstoqueGrao {
    produto: {
        id: number | null;
        nome: string;
    };
    capacidade: {
        tonelada: number;
        saca: number;
    };
    estoque: {
        tonelada: number;
        saca: number;
    };
    quantidade_estrutura: {
        silo: number;
        armazem: number;
    };
    percentual: {
        porcentagem: number;
    };

    estruturas: EstruturaInterface[];

    unidade: UnidadeInterface;
}

interface UnidadeInterface {
    id: string;
    categoria: string;
    arquivado: boolean;
    nome: string;
    cidade: string;
    uf: string;
}

interface EstruturaInterface {
    id: string;
    categoria: string;
    nome: string;
    nome_divisao?: string;
}

export interface DadosGraficoGestaoEstoqueGraoCapacidadeInterface {
    produto: {
        id: number;
        nome: string;
    };
    capacidade: {
        tonelada: number;
        saca: number;
    };
    estoque: {
        tonelada: number;
        saca: number;
    };
    percentual: {
        porcentagem: number;
    };
}

export interface filtroCondicaoArmazenagem {
    id: string;
    nome: string;
    filtro_padrao: boolean;
    expressao_logica: string;
}

export interface filtroPersonalizadoId {
    id?: string;
    nome: string;
    filtros_personalizados: FiltroPersonalizado[];
    expressao_logica?: string;
    contexto_painel?: string;
    area_secador?: string;
}
interface FiltroPersonalizado {
    parametro?: string;
    operador: '>=' | '<' | '<=' | '=' | '>' | '<>';
    valor: number;
}

export interface AeracaoSistema {
    objetivo: string | null;
    controle_aeracao_automatica: boolean;
    quantidade_estruturas: number;
    quantidade_total_estruturas: number;
    estruturas_divisao: EstruturaDivisao[];
}

export interface AeracaoPainel {
    status_chave: string;
    quantidade_estruturas: number;
    quantidade_total_estruturas: number;
    estruturas_divisao: EstruturaDivisao[];
}

export interface EstruturaDivisao {
    id: string;
    nome: string;
    nome_divisao?: string;
    categoria: string;
    produto_tipo: ProdutoTipoSistema;
    unidade: UnidadeSistema;
}
export interface UnidadeSistema {
    id: string;
    categoria: string;
    arquivado: boolean;
    nome: string;
    cidade: string;
    uf: string;
}

export interface ProdutoTipoSistema {
    id: number;
    arquivado: boolean;
    nome: string;
}

export interface ParamsAeracaoGraos {
    unidades: string[];
    produto_tipos: number[];
}
