import { BehaviorSubject, Observable } from 'rxjs';

export class TabScreenObservable {
    tabScreenSelecionada: BehaviorSubject<any | null> = new BehaviorSubject(
        null
    );

    get tabScreenSelecionada$(): Observable<any> {
        return this.tabScreenSelecionada.asObservable();
    }

    set setatabScreenSelecionada(value: any) {
        this.tabScreenSelecionada.next(value);
    }
}
