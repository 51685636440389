import { NomeTipoSensorEnum, UnidadeMedidaSensoresEnum } from 'app/shared/enums';

export class UnidadesMedidasSensoresHelper {
    public static formataUnidadasMeidasSensores(tipoSensor: string): string {
        switch (tipoSensor) {
            case NomeTipoSensorEnum.TEMPERATURA_DIGITAL:
                return UnidadeMedidaSensoresEnum.DIGITAL;
            case NomeTipoSensorEnum.PRESSAO:
                return UnidadeMedidaSensoresEnum.PRESSAO;
            case NomeTipoSensorEnum.TEMPERATURA_TERMOPAR:
                return UnidadeMedidaSensoresEnum.TERMOPAR;
            case NomeTipoSensorEnum.TEMPERATURA_GRAO:
                return UnidadeMedidaSensoresEnum.TERMORESISTENCIA;
            case NomeTipoSensorEnum.UMIDADE_GRAO:
                return UnidadeMedidaSensoresEnum.UMIDADE_GRAO;
            case NomeTipoSensorEnum.UMIDADE_RELATIVA:
                return UnidadeMedidaSensoresEnum.UMIDADE_RELATIVA;
            default:
                return '';
        }
    }
}
