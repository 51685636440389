export enum LocalPosicaoSensorEnum {
    FORNALHA = 'FORNALHA',
    ENTRADA_AR = 'ENTRADADEAR',
    MASSA_GRAOS = 'MASSADEGRÃOS',
    SAIDA_AR = 'SAÍDADEAR',
    CARGA_GRAOS = 'CARGADEGRÃOS',
    DESCARGA_GRAOS = 'DESCARGADEGRÃOS',
    RECIRCULACAO = 'RECIRCULAÇÃO',
    CONDICOES_EXTERNAS = 'CONDIÇÕESEXTERNAS',
}

export enum LocalSensorEnum {
    FORNALHA = 'FORNALHA',
    ENTRADA_AR = 'ENTRADA_AR',
    MASSA_GRAOS = 'MASSA_GRAOS',
    SAIDA_AR = 'SAIDA_AR',
    CARGA_GRAOS = 'CARGA_GRAOS',
    DESCARGA_GRAOS = 'DESCARGA_PRODUTO',
    RECIRCULACAO = 'RECIRCULACAO',
    CONDICOES_EXTERNAS = 'CONDICAO_EXTERNA',
}
