import { Injectable } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import moment from 'moment';

export abstract class IRetonaInstanteMaisRecenteEquipamentoService {
    abstract execute(instantes: string[]): string;
}

@Injectable({
    providedIn: 'root',
})
export class RetonaInstanteMaisRecenteEquipamentoService {
    constructor(private transloco: TranslocoService) {}

    execute(instantes: string[]): string {
        const datas = [];
        instantes?.forEach((instante) => {
            if (instante) {                
                datas.push(moment(instante));
            }
        });
        if (moment.max(datas).isValid() && datas.length) {
            return moment.max(datas)?.format();
        } else {
            return this.transloco.translate('COMPARTILHADO.AGUARDANDO_LEITURA');
        }
    }
}
