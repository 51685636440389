import { Injectable } from '@angular/core';
import { ApiTransportService } from 'app/core/services/transport';
import { Observable } from 'rxjs';
import { IApiTransportInterface } from 'app/core/interfaces/';
import { IObjectToAnyService } from 'app/shared/services';
import { IdiomaSiglaEnum } from 'app/shared/enums';

export abstract class IUsuarioIdiomaController {
    abstract alterarIdioma(paginacao: IdiomaSiglaEnum): Observable<any>;
}

@Injectable({
    providedIn: 'root',
})
export class UsuarioIdiomaController implements IUsuarioIdiomaController {
    private transport: IApiTransportInterface;

    constructor(
        public readonly apiTransportService: ApiTransportService,
        private readonly objectToAnyService: IObjectToAnyService
    ) {
        this.transport = this.apiTransportService.execute('/geral/usuario/idioma');
    }

    alterarIdioma(idioma: IdiomaSiglaEnum): Observable<any> {
        return this.transport.http.patch(`${this.transport.path}`, { idioma });
    }
}
