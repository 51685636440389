export interface InformacoesGrao {
    grao: string;
    valor: string;
    status: boolean;
    icone: string;
}

export interface ListaGraosResponse {
    data: InformacoesGrao[];
    date: string;
}
