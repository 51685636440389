export const MENUS_SECAGEM_EN = [
    {
        menu: 'DRYER',
        description: 'DRYER',
        link: '#',
        icon: 'icon-module-secador',
        separator: false,
        permission: true,
        groups: [
            {
                title: 'Visualization',
                icon: 'grid_view',
                separator: true,
                items: [
                    {
                        menu: 'Dryers',
                        icon: 'icon-module-secador',
                        link: 'secador/visualizacao/visao-geral-secadores',
                    },
                ],
            },
            {
                title: 'Analysis',
                icon: 'query_stats',
                separator: true,
                items: [
                    {
                        menu: 'Reports',
                        icon: 'insert_chart',
                        childrensItems: [
                            {
                                menu: 'Sensors',
                                icon: 'mat_solid:device_thermostat',
                                link: 'secador/analise/relatorios/sensores',
                            },
                            {
                                menu: 'Operations',
                                icon: 'icon-operacoes',
                                link: 'secador/analise/relatorios/operacoes-secador',
                            },
                            {
                                menu: 'Cleaning',
                                icon: 'icon-limpeza',
                                link: 'secador/analise/relatorios/limpeza-secador',
                            },
                            {
                                menu: 'Alerts',
                                icon: 'icon-menu-alertas',
                                link: 'secador/analise/relatorios/alertas',
                            },
                        ],
                    },
                    {
                        menu: 'Graphs',
                        icon: 'area_chart',
                        childrensItems: [
                            {
                                menu: 'Sensors and operations',
                                icon: 'icon-grafico-base-dupla',
                                link: 'secador/analise/graficos/sensores-operacoes',
                            },
                        ],
                    },
                ],
            },
            {
                title: 'Register',
                icon: 'article',
                separator: true,
                items: [
                    {
                        menu: 'Alerts',
                        icon: 'icon-menu-alertas',
                        link: 'secador/cadastro/alertas',
                    },
                ],
            },
        ],
    },
];
