import { Injectable } from '@angular/core';
import { IApiTransportInterface } from 'app/core/interfaces';
import { ApiTransportService } from 'app/core/services/transport';
import { Observable } from 'rxjs';
import { ResponseInterface, SensorPlenumInterface } from 'app/shared/interfaces';

export abstract class IBuscaSensoresPlenumController {
    abstract buscaSensoresPlenumUltimaLeitura(
        idEstruturaArmazenagem: string
    ): Observable<ResponseInterface<SensorPlenumInterface>>;
}

@Injectable()
export class BuscaSensoresPlenumController implements IBuscaSensoresPlenumController {
    private transport: IApiTransportInterface;

    constructor(public readonly apiTransportService: ApiTransportService) {
        this.transport = this.apiTransportService.execute('/armazenagem/sensor-plenum');
    }

    buscaSensoresPlenumUltimaLeitura(idEstruturaArmazenagem: string): Observable<any> {
        return this.transport.http.get(
            `${this.transport.path}/ultima-leitura/estrutura-armazenagem/${idEstruturaArmazenagem}`
        );
    }
}
