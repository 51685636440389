export class PrimeiroAcessoResponseModel {
    ativo: boolean;
    email: string;
    id: number;
    nivel: string;
    nome: string;
    obrigar_resetar_senha: boolean;
    senha: string;
    sobrenome: string;
    telefone: string;
    ultima_autenticacao: string;
}
