import * as BABYLON from '@babylonjs/core';
import { Injectable } from '@angular/core';
import { Silo3DPropriedadesInterface } from 'app/shared/interfaces';
import { CaminhoImagensSilo3DEnum, SiloBaseEnum } from 'app/shared/enums';
export abstract class ICriaSilo3DBaseSemiVService {
    abstract execute(siloData: Silo3DPropriedadesInterface, cena: BABYLON.Scene): void;
}

@Injectable({ providedIn: 'root' })
export class CriaSilo3DBaseSemiVService implements ICriaSilo3DBaseSemiVService {
    execute(siloData: Silo3DPropriedadesInterface, cena: BABYLON.Scene): void {
        const dimensaoChao: number = 1000;
        const meshesParaDeletar = cena.meshes.filter(
            (mesh) => mesh.name.endsWith('Plana') || mesh.name.endsWith('V') || mesh.name.endsWith('SemiV')
        );
        meshesParaDeletar.forEach((mesh) => {
            mesh.dispose();
        });
        meshesParaDeletar.length = 0;

        let texturaChao = new BABYLON.Texture(CaminhoImagensSilo3DEnum.GRAMA.toString(), cena);
        texturaChao.uScale = 250;
        texturaChao.vScale = 250;

        let materialChao = new BABYLON.StandardMaterial('materialChaoSemiV', cena);
        materialChao.diffuseTexture = texturaChao;

        const fundoSemiV = BABYLON.MeshBuilder.CreateCylinder(
            `fundo${SiloBaseEnum.SEMI_V}`,
            {
                height: siloData?.alturaFundo,
                diameter: siloData?.diametroInferiorFundo,
                diameterTop: siloData?.diametroSilo * 1.01,
                tessellation: 32,
                cap: BABYLON.Mesh.CAP_START,
            },
            cena
        );
        fundoSemiV.position.y = siloData?.alturaFundo / 2;

        const chaoSemiV = BABYLON.MeshBuilder.CreateBox(
            'chaoSemiV',
            { width: dimensaoChao, depth: dimensaoChao, height: 0.01 },
            cena
        );
        chaoSemiV.position.y = siloData?.alturaFundo - 0.01;
        chaoSemiV.material = materialChao;

        const plataformaSemiV = BABYLON.MeshBuilder.CreateBox('plataformaSemiV', {
            height: siloData?.alturaPlataforma,
            width: siloData?.diametroSilo * 2.5,
            depth: siloData?.diametroSilo * 2.5,
        });
        plataformaSemiV.position.y = siloData?.alturaFundo + siloData?.alturaPlataforma / 2;

        const corteCilindro = BABYLON.MeshBuilder.CreateCylinder(
            'corteCilindro',
            {
                height: siloData?.alturaPlataforma + 0.01,
                diameter: siloData?.diametroSilo,
                tessellation: 32,
            },
            cena
        );
        corteCilindro.position.y = siloData?.alturaFundo + siloData?.alturaPlataforma / 2;

        const corteCilindroChao = BABYLON.MeshBuilder.CreateCylinder(
            'corteCilindroChao',
            {
                height: 0.01,
                diameter: siloData?.diametroSilo,
                tessellation: 32,
            },
            cena
        );
        corteCilindroChao.position.y = siloData?.alturaFundo - 0.01;

        const cortePlataformaSemiV = BABYLON.CSG.FromMesh(plataformaSemiV)
            .subtract(BABYLON.CSG.FromMesh(corteCilindro))
            .toMesh('cortePlataformaSemiV', plataformaSemiV.material, cena);
        const corteChaoSemiV = BABYLON.CSG.FromMesh(chaoSemiV)
            .subtract(BABYLON.CSG.FromMesh(corteCilindroChao))
            .toMesh('corteChaoSemiV', chaoSemiV.material, cena);

        plataformaSemiV.dispose();
        chaoSemiV.dispose();
        corteCilindro.dispose();
        corteCilindroChao.dispose();

        const cilindroSemiV = BABYLON.MeshBuilder.CreateCylinder(
            `cilindro${SiloBaseEnum.SEMI_V}`,
            {
                height: siloData?.alturaSilo,
                diameter: siloData?.diametroSilo * 1.01,
                cap: BABYLON.Mesh.NO_CAP,
            },
            cena
        );
        cilindroSemiV.position.y = siloData?.alturaFundo + siloData?.alturaPlataforma + siloData?.alturaSilo / 2;

        const chapeuSemiV = BABYLON.MeshBuilder.CreateCylinder(
            `chapeu${SiloBaseEnum.SEMI_V}`,
            {
                height: siloData?.alturaChapeu,
                diameter: siloData?.diametroChapeu,
                diameterTop: siloData?.diametroChapeu * 0.1,
                tessellation: 32,
                cap: BABYLON.Mesh.CAP_END,
            },
            cena
        );
        chapeuSemiV.position.y =
            siloData?.alturaFundo + siloData?.alturaPlataforma + siloData?.alturaSilo + siloData?.alturaChapeu / 2;

        cena.render();
    }
}
