export interface ListaProdutosEquilibrioEstruturaInterface {
    id: string;
    nome: string;
    produto_tipo_id: number;
    estrutura_armazenagem: EstruturaArmazenagem;
}

interface EstruturaArmazenagem {
    id: string;
    estrutura_armazenagem_divisao: EstruturaArmazenagemDivisao;
    ultima_leitura: UltimaLeitura;
}

interface EstruturaArmazenagemDivisao {
    id: string;
    aquecimento_ar: number;
}

interface UltimaLeitura {
    sensor_plenum_ultima_leitura: SensorPlenumUltimaLeitura;
}

interface SensorPlenumUltimaLeitura {
    temperatura: number;
    umidade: number;
}
