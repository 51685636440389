import { Injectable } from '@angular/core';
import { ApiTransportService } from 'app/core/services/transport';
import { Observable } from 'rxjs';

import { IApiTransportInterface } from 'app/core/interfaces/';
import { HistoricoComandoAeracaoInterface, ResponseInterface } from 'app/shared/interfaces';
import { ApiPaginacao } from 'app/shared/models';
import { IObjectToAnyService } from 'app/shared/services';
import { CategoriaEstruturaArmazenagemEnum } from 'app/shared/enums';

export abstract class IComandoAeracaoAutomaticaController {
    abstract buscarHistorico(
        armazenagemREf: number,
        params: ApiPaginacao
    ): Observable<ResponseInterface<HistoricoComandoAeracaoInterface>>;
}

@Injectable({
    providedIn: 'root',
})
export class ComandoAeracaoAutomaticaController implements IComandoAeracaoAutomaticaController {
    private transport: IApiTransportInterface;

    constructor(
        public readonly apiTransportService: ApiTransportService,
        private readonly objectToAnyService: IObjectToAnyService
    ) {
        this.transport = this.apiTransportService.execute('/aeracao/comando-aeracao/estrutura_armazenagem_divisao');
    }

    buscarHistorico(
        armazenagemRef: number,
        params: ApiPaginacao
    ): Observable<ResponseInterface<HistoricoComandoAeracaoInterface>> {
        this.transport.options.params = this.objectToAnyService.toQueryString({
            armazenagem_ref: armazenagemRef,
            origem_armazenagem: CategoriaEstruturaArmazenagemEnum.SILO,
            page: params.page,
            limit: params.limit,
        });

        return this.transport.http.get<ResponseInterface<HistoricoComandoAeracaoInterface>>(
            `${this.transport.path}/${armazenagemRef}`,
            {
                params: this.transport.options.params,
            }
        );
    }
}
