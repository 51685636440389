import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export abstract class IPrimeiroAcessoObservable {
    abstract setaPrimeiroAcessoObservable(primeiroAcesso: boolean): void;
    abstract buscaPrimeiroAcessoObservable(): Observable<boolean>;
}
@Injectable({
    providedIn: 'root',
})
export class PrimeiroAcessoObservable implements IPrimeiroAcessoObservable {
    private primeiroAcessoObservable = new BehaviorSubject<boolean>(false);

    setaPrimeiroAcessoObservable(primeiroAcesso: boolean): void {
        this.primeiroAcessoObservable.next(primeiroAcesso);
    }

    buscaPrimeiroAcessoObservable(): Observable<boolean> {
        return this.primeiroAcessoObservable.asObservable();
    }

    get buscaPrimeiroAcesso(): boolean {
        return this.primeiroAcessoObservable.getValue();
    }
}
