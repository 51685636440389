import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SincronizadorModel } from 'app/shared/models';

@Injectable({
    providedIn: 'root',
})
export class SincronizadorEquipamentoObservable {
    private sincronizador: BehaviorSubject<SincronizadorModel | null> = new BehaviorSubject(null);

    set setSincronizador(value: SincronizadorModel) {        
        this.sincronizador.next(value);
    }

    get getSincronizador$(): SincronizadorModel {
        return this.sincronizador.getValue();
    }

    get buscaSincronizador$(): Observable<SincronizadorModel> {
        return this.sincronizador.asObservable();
    }
}
