export enum FiltroPainelGestaoUnidadesEnum {
    CLIENTE = 'Cliente',
    UNIDADES = 'Unidades',
    GRAOS = 'Grão(s)',
    MOEDA = 'Moeda',
    CLASSIFICADOR = 'Classificador',
    PERIODO = 'Período',
    CODIGO = 'Código',
    CATEGORIA = 'Categoria',
}

export enum NomesPaineisEnum {
    OPERACIONAL = 'operacional',
    QUALIDADE = 'qualidade',
    RENTABILIDADE = 'rentabilidade',
    PAINEL_LISTAGEM = 'painel-listagem'
}
