import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, finalize, Observable, throwError } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { CookieService, FusoHorarioService } from 'app/core/services';
import { StatusRequisicaoHttpService } from 'app/shared/services';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private _authService: AuthService,
        private cookieService: CookieService,
        private fusoHorarioService: FusoHorarioService,
        private statusRequisicaoHttpService: StatusRequisicaoHttpService,
        private router: Router
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        this.statusRequisicaoHttpService.inicioRequisicao();

        let newReq = req.clone();

        if (!this.cookieService.obterCookie('unidadeId') || !this.cookieService.obterCookie('clienteId')) {
            this._authService.signOut();
        }
        if (this._authService.accessToken && !AuthUtils.isTokenExpired(this._authService.accessToken)) {
            const usuario = JSON.parse(localStorage.getItem('usuarioLogado'));
            newReq = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + this._authService.accessToken),
                setHeaders: {
                    'x-unidade-id': this.cookieService.obterCookie('unidadeId'),
                    'x-cliente-id': this.cookieService.obterCookie('clienteId'),
                    'usuario-id': `${usuario?.id}`,
                    'fuso-horario': this.fusoHorarioService.obterFusoHorario(),
                },
            });
        }

        return next.handle(newReq).pipe(
            catchError((error) => {
                return throwError(() => {
                    if (error?.error?.error?.status === 401) {
                        this._authService.signOut();
                        this.router.navigate(['/sign-in']);
                    }
                    console.log(error);
                    return error;
                });
            }),
            finalize(() => {
                this.statusRequisicaoHttpService.fimRequisicao();
            })
        );
    }
}
