import { ProdutoInterface } from 'app/shared/interfaces';
export interface ListaSimplificadaEstruturaArmazenagemInterface {
    id: string;
    nome: string;
    sensor_umidade_instalado?: boolean;
    aquecimento_ar?: number;
    estrutura_armazenagem: EsrtuturaArmazenagemInterface;
    produto?: ProdutoInterface;
}

export interface EsrtuturaArmazenagemInterface {
    id: string;
    categoria: string;
    nome: string;
}
