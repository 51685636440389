import { MatPaginatorIntl } from '@angular/material/paginator';
import { CookieHelper } from 'app/shared/helpers';
import { IdiomaSiglaEnum } from 'app/shared/enums';

export class PaginacaoCustomizadaService {
    getCustomPaginationIntl(): MatPaginatorIntl {
        const customPaginatorIntl = new MatPaginatorIntl();
        const idioma = CookieHelper.carregarCookie('idioma');

        switch (idioma) {
            case IdiomaSiglaEnum.INGLES:
                customPaginatorIntl.itemsPerPageLabel = 'Results by page';
                customPaginatorIntl.firstPageLabel = 'First page';
                customPaginatorIntl.lastPageLabel = 'Last page';
                customPaginatorIntl.nextPageLabel = 'Next page';
                customPaginatorIntl.previousPageLabel = 'Previous page';
                break;
            case IdiomaSiglaEnum.ESPANHOL:
                customPaginatorIntl.itemsPerPageLabel = 'Resultados por página';
                customPaginatorIntl.firstPageLabel = 'Primeira página';
                customPaginatorIntl.lastPageLabel = 'Última página';
                customPaginatorIntl.nextPageLabel = 'Próxima página';
                customPaginatorIntl.previousPageLabel = 'Página anterior';
                break;
            default:
                customPaginatorIntl.itemsPerPageLabel = 'Resultados por página';
                customPaginatorIntl.firstPageLabel = 'Primeira página';
                customPaginatorIntl.lastPageLabel = 'Última página';
                customPaginatorIntl.nextPageLabel = 'Próxima página';
                customPaginatorIntl.previousPageLabel = 'Página anterior';
                break;
        }

        customPaginatorIntl.getRangeLabel = this.getRangeLabel.bind(this);
        return customPaginatorIntl;
    }

    private getRangeLabel = (page: number, pageSize: number, length: number): string => {
        const idioma = CookieHelper.carregarCookie('idioma');
        const textoTraduzido: string[] = [];

        switch (idioma) {
            case IdiomaSiglaEnum.INGLES:
                textoTraduzido.push('0 of');
                textoTraduzido.push('Showing between');
                textoTraduzido.push('and');
                textoTraduzido.push('results of');
                break;
            case IdiomaSiglaEnum.ESPANHOL:
                textoTraduzido.push('0 de');
                textoTraduzido.push('Mostrando entre');
                textoTraduzido.push('y');
                textoTraduzido.push('resultados de');
            default:
                textoTraduzido.push('0 de');
                textoTraduzido.push('Mostrando entre');
                textoTraduzido.push('e');
                textoTraduzido.push('resultados de');
                break;
        }

        if (length == 0 || pageSize == 0) {
            return `${textoTraduzido[0]} ${length}`;
        }

        length = Math.max(length, 0);

        const startIndex = page * pageSize;

        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

        return `${textoTraduzido[1]} ${startIndex + 1} ${textoTraduzido[2]} ${endIndex} ${textoTraduzido[3]} ${length}`;
    };
}
