import { EstruturaArmazenagemDivisaoInterface } from "app/shared/interfaces";
import { OpcoesLegendaEnum } from "app/modules/gestao-unidades/operacional/shared/enums";
import { ObjetoValorTextoInterface } from "app/modules/gestao-unidades/operacional/shared/interfaces";

export interface LegendaTemperaturaUmidadeGraoInterface {
    cor: string;
    titulo: string;
}

export interface OpcoesLegendaInterface {
    texto: string;
    valor: OpcoesLegendaEnum;
}

export interface DadosTemperaturaUmidadeGraoInterface {
    id: string;
    temperatura_minima: ObjetoValorTextoInterface;
    temperatura_media: ObjetoValorTextoInterface;
    temperatura_maxima: ObjetoValorTextoInterface;
    umidade_grao: ObjetoValorTextoInterface;
    estoque_estimado: ObjetoValorTextoInterface;
    estoque_informado: ObjetoValorTextoInterface;
    estoque_capacidade: ObjetoValorTextoInterface;
    estoque_percentual: ObjetoValorTextoInterface;
    cliente: ClienteInterface;
    estrutura_armazenagem_divisao: EstruturaArmazenagemDivisaoInterface;
    produto_tipo: ProdutoTipoInterface;
}

export interface ClienteInterface {
    id: string;
    arquivado: boolean;
    nome: string;
}

export interface EstruturaArmazenagemInterface {
    id: string;
    categoria: string;
    arquivado?: boolean;
    codigo: number;
    nome: string;
    volume: string;
    sensor_umidade_instalado: boolean;
    unidade?: UnidadeInterface;
    equipamento_comunicacao_status?: string;
}

export interface UnidadeInterface {
    id: string;
    categoria: string;
    arquivado?: boolean;
    nome: string;
    cidade: string;
    uf: string;
}

export interface ProdutoTipoInterface {
    id: number;
    arquivado?: boolean;
    nome: string;
    peso_saca: number;
}
