import { Injectable } from '@angular/core';
import { IApiTransportInterface } from 'app/core/interfaces';
import { ApiTransportService } from 'app/core/services/transport';
import { Observable } from 'rxjs';

export abstract class IProdutoTipoController {
    abstract buscaTipoProduto(): Observable<any>;
}

@Injectable()
export class ProdutoTipoController implements IProdutoTipoController {
    private transport: IApiTransportInterface;

    constructor(
        public readonly apiTransportService: ApiTransportService,        
    ) {
        this.transport = this.apiTransportService.execute('/base/produto-tipo');
    }

    buscaTipoProduto(): Observable<any> {
        return this.transport.http.get(`${this.transport.path}`);
    }
}
