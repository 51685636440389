import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CarregaVersaoService {
  constructor(private http: HttpClient) {}

  retornaVersao(): Observable<string> {
    return this.http.get<{ version: string }>('/assets/package.json').pipe(
      map(packageJson => packageJson.version)
    );
  }
}