import { TipoEstacaoMeteorologicaEnum } from "app/shared/enums";

export interface EstacaoMeteorologicaInterface {
    id: string;
    nome: string;
    tipo: TipoEstacaoMeteorologicaEnum;
    codigo?: number;
    equipamento?: EquipamentoEstacaoMeteorologicaInterface;
    ultima_leitura: UltimaLeituraEstacaoMeteorologicaInterface;
}
export interface EquipamentoEstacaoMeteorologicaInterface {
    id: string;
    modelo: string;
    nome: string;
    tempo_retencao_dados: number;
    tempo_validacao_comunicacao: number;
}
export interface ValidacaoLeituraInterface {
    status: boolean;
    instante: string;
}
export interface UltimaLeituraEstacaoMeteorologicaInterface {
    id: string;
    chuva: number;
    chovendo: boolean;
    temperatura: number;
    umidade: number;
    validacao_leitura: ValidacaoLeituraInterface;
}

export interface EstacaoMetereologicaTipoInterface {
    id: string;
    codigo: number;
    nome: string;
    tipo: TipoEstacaoMeteorologicaEnum;
}


