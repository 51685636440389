import { Injectable } from '@angular/core';
import * as BABYLON from '@babylonjs/core';

import { PenduloSilo3DInterface, Silo3DPropriedadesInterface } from 'app/shared/interfaces';

export abstract class IMontraVetorSensoresSilo3DService {

    abstract execute(
        penduloData: PenduloSilo3DInterface[],
        siloData: Silo3DPropriedadesInterface,
        cena: BABYLON.Scene
    ): BABYLON.Mesh[][];
}

@Injectable({ providedIn: 'root' })
export class MontraVetorSensoresSilo3DService implements IMontraVetorSensoresSilo3DService {
    execute(
        penduloData: PenduloSilo3DInterface[],
        siloData: Silo3DPropriedadesInterface,
        cena: BABYLON.Scene
    ): BABYLON.Mesh[][] {
        const vetorSensores: BABYLON.Mesh[][] = [];

        for (let i = 1; i <= penduloData.length; i++) {
            const pendulo = penduloData[i - 1];
            const pendulumSensors: BABYLON.Mesh[] = [];
            let alturaInicio = pendulo.distanciaBase;
            let qtdText = 0;

            for (let j = 0; j < pendulo.qtdsensores; j++) {
                qtdText++;

                let sensorPosY;

                if (j == 0) {
                    sensorPosY = pendulo.posY1 + alturaInicio;
                } else {
                    alturaInicio += pendulo.sensorTempEspacamento;
                    sensorPosY = pendulo.posY1 + alturaInicio;
                }

                let sensor = BABYLON.MeshBuilder.CreateSphere(
                    `sensor_${qtdText}_btn_${i}`,
                    { diameter: siloData.diametroSilo * 0.01 },
                    cena
                );
                sensor.position = new BABYLON.Vector3(pendulo.posX, sensorPosY, pendulo.posZ);

                pendulumSensors.push(sensor);
            }
            vetorSensores.push(pendulumSensors);
        }
        return vetorSensores;
    }
}
