import { PaletaCorAmostragemExpedicao } from "app/modules/gestao-unidades/operacional/shared/interfaces"

export const PaletaAmostragensExpedicaoTipoStatusSiloConstant = {
    falha: 'bg-[#A44040]',
    aguardandoLeitura: 'bg-[#FBBC05]',
    comunicando: 'bg-[#34901C]',
}

export const PaletaAmostragensExpedicaoColunaMotivoConstant = {
    graosAbaixo: 'bg-[#FFFFE6]',
    avariadosElevado: 'bg-[#E751354D]',
    avariadosPadrao: 'bg-[#C1E5D4]',
}

export const paletaCoresTemperaturaAmostragensExpedicaoConstant: PaletaCorAmostragemExpedicao[] = [
    { min: '', max: '', fundo: '#CCC', cor: '#00091F' },
    { min: 0, max: 5, fundo: '#3D50F3', cor: '#FFFFFF' },
    { min: 6, max: 11, fundo: '#58BEF3', cor: '#FFFFFF' },
    { min: 12, max: 17, fundo: '#3DAC79', cor: '#FFFFFF' },
    { min: 18, max: 23, fundo: '#3DE95A', cor: '#16171B' },
    { min: 24, max: 29, fundo: '#D6E95A', cor: '#16171B' },
    { min: 30, max: 35, fundo: '#D6B25A', cor: '#16171B' },
    { min: 36, max: 999999999, fundo: '#D6505A', cor: '#16171B' },
]

export const paletaCoresUmidadeAmostragensExpedicaoConstant: PaletaCorAmostragemExpedicao[] = [
    { min: '', max: '', fundo: '#CCC', cor: '#00091F' },
    { min: 0, max: 33, fundo: '#A3CBE5', cor: '#16171B' },
    { min: 34, max: 66, fundo: '#5CA7D3', cor: '#16171B' },
    { min: 67, max: 100, fundo: '#185C84', cor: '#FFFFFF' },
]
