export const ComunicacaoCondicaoEnum = {
    CABECALHO: 'Comunicação geral dos equipamentos',
    COMUNICANDO: 'Comunicando',
    COMUNICANDO_PARCIAL: 'Comunicando parcial',
    PARCIAL: 'Comunicação parcial',
    FALHA_COMUNICACAO: 'Falha de comunicação',
}

export const StatusComunicacaoBackEnum = {
    COMUNICANDO: 'COMUNICANDO',
    COMUNICANDO_PARCIAL: 'COMUNICANDO_PARCIAL',
    PARCIAL: 'PARCIAL',
    FALHA_COMUNICACAO: 'FALHA_COMUNICACAO',
}

