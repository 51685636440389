export enum PpmEnum {
    NIVEIS_DO_AR_AMBIENTE = 'NIVEIS_DO_AR_AMBIENTE',
    NIVEIS_NORMAIS = 'NIVEIS_NORMAIS',
    DESTERIORACAO_INCIPIENTE = 'DESTERIORACAO_INCIPIENTE',
    LEVE_INFESTACAO_DE_INSETOS_E_MICROORGANISMO = 'LEVE_INFESTACAO_DE_INSETOS_E_MICROORGANISMO',
    ALTA_INFESTACAO_DE_INSETOS_E_MICROORGANISMO = 'ALTA_INFESTACAO_DE_INSETOS_E_MICROORGANISMO',
    ELEVADA_DETERIORACAO = 'ELEVADA_DETERIORACAO',
    NIVEIS_ALTAMENTE_INADEQUADOS = 'NIVEIS_ALTAMENTE_INADEQUADOS',
    VALOR_INVALIDO = 'VALOR_INVALIDO',
}
