import { Injectable } from '@angular/core';
import { ApiTransportService } from 'app/core/services/transport';
import { Observable } from 'rxjs';

import { IApiTransportInterface } from 'app/core/interfaces/';
import { EquipamentosInterface, ResponseInterface } from 'app/shared/interfaces';
import { IObjectToAnyService } from 'app/shared/services';
import { ApiPaginacao } from 'app/shared/models';

export abstract class IEquipamentosController {
    abstract buscarEquipamentos(params: ApiPaginacao): Observable<ResponseInterface<EquipamentosInterface>>;
}

@Injectable({
    providedIn: 'root',
})
export class EquipamentosController implements IEquipamentosController {
    private transport: IApiTransportInterface;

    constructor(
        public readonly apiTransportService: ApiTransportService,
        private readonly objectToAnyService: IObjectToAnyService
    ) {
        this.transport = this.apiTransportService.execute('/geral');
    }

    buscarEquipamentos(params: ApiPaginacao): Observable<ResponseInterface<EquipamentosInterface>> {
        this.transport.options.params = this.objectToAnyService.toQueryString({
            page: params.page,
            limit: params.limit,
        });

        return this.transport.http.get<ResponseInterface<EquipamentosInterface>>(
            `${this.transport.path}/painel-equipamento`,
            {
                params: this.transport.options.params,
            }
        );
    }
}
