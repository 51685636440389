export class CookieHelper {
    public static carregarCookie(nome: string): string {
        const nomeCookie = nome + '=';
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nomeCookie) == 0) return c.substring(nomeCookie.length, c.length);
        }
        return null;
    }
}
