import { caracteresProibidosInputNumberConstant } from 'app/shared/constants';
import { Injectable } from '@angular/core';
export abstract class IApenasNumerosPermitidosService {
    abstract execute(evento: any): void;
}

@Injectable({ providedIn: 'root' })
export class ApenasNumerosPermitidosService implements IApenasNumerosPermitidosService {
    execute(evento: any): void {
        caracteresProibidosInputNumberConstant.forEach((caracter) => {
            if (evento.key === caracter) {
                evento.preventDefault();
            }
        });
    }
}
