export interface SelectParametroInterface {
    valor: string;
    texto: string;
    id?: string;
    selecionado?: boolean;
}

export interface SelectParametroInterfaceUnidadesGraos {
    valor?: string | number;
    texto?: string;
    id?: string | number;
    nome?: string;
    selecionado?: boolean;
}

export interface listaClientes {
    nome: string;
    id: string;
    unidades?: SelectParametroInterface[]
}
