import { Injectable } from '@angular/core';
import { ApiTransportService } from 'app/core/services/transport';
import { Observable } from 'rxjs';

import { IApiTransportInterface } from 'app/core/interfaces/';
import { IObjectToAnyService } from 'app/shared/services';

export abstract class IListagemRegrasController {
    abstract buscaTodasRegras(): Observable<any>;
}

@Injectable({
    providedIn: 'root',
})
export class ListagemRegrasController implements IListagemRegrasController {
    private transport: IApiTransportInterface;

    constructor(
        public readonly apiTransportService: ApiTransportService,
        private readonly objectToAnyService: IObjectToAnyService
    ) {
        this.transport = this.apiTransportService.execute('/aeracao');
    }

    buscaTodasRegras(): Observable<any> {
        const arquivado = { 'filter.arquivado': 'false' };
        this.transport.options.params = this.objectToAnyService.toQueryString({
            page: 1,
            limit: 1000,
            ...arquivado,
        });
        return this.transport.http.get(`${this.transport.path}/regra-aeracao`, this.transport.options);
    }
}
