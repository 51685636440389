export const EmSecagemConstants = {
    emSecagem: 'Em secagem',
    cabecalho: 'Comunicação geral dos equipamentos',
    versao: '1 - HD v1.1',
    comunicando: 'Comunicando',
    falhaComunicacao: 'Falha de comunicação',
    vazio: '-',
    secador: 'Secador',
    ultimas24Horas: 'Últimas 24 horas',
    ampliarGrafico: 'Ampliar Gráfico',
    parcial: "Comunicação parcial",
    semComunicacao: "Sem comunicação",
    aguardandoLeitura: "Aguardando leitura"
};
