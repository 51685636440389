import { Injectable } from '@angular/core';
import { IApiTransportInterface } from 'app/core/interfaces';
import { ApiTransportService } from 'app/core/services/transport';
import { FuncionalidadeInterface, ListaPerfisInterface, PerfilInterface } from 'app/shared/interfaces';
import { Observable } from 'rxjs';


export abstract class IEstruturaPerfilController {    
    abstract criarPerfil(dados: PerfilInterface): Observable<PerfilInterface>;
    abstract editaPerfil(id: string, dados: PerfilInterface): Observable<ListaPerfisInterface>;
    abstract duplicarPerfil(id: string): Observable<ListaPerfisInterface>;
    abstract buscarPerfilPorId(id: string): Observable<PerfilInterface>;
    abstract buscaListaFuncionalidades(): Observable<FuncionalidadeInterface[]>;
    abstract exportarPerfil(id: string, unidadeId: number): Observable<ListaPerfisInterface>;
}

@Injectable({
    providedIn: 'root',
})
export class EstruturaPerfilController implements IEstruturaPerfilController {
    private transport: IApiTransportInterface;

    constructor(public readonly apiTransportService: ApiTransportService) {
        this.transport = this.apiTransportService.execute('/geral');
    }    

    public criarPerfil(dados: ListaPerfisInterface): Observable<PerfilInterface> {
        return this.transport.http.post<PerfilInterface>(`${this.transport.path}/perfil`, dados);
    }

    public editaPerfil(id: string, dados: PerfilInterface): Observable<PerfilInterface> {
        return this.transport.http.put<ListaPerfisInterface>(`${this.transport.path}/perfil/${id}`, dados);
    }

    public duplicarPerfil(id: string): Observable<ListaPerfisInterface> {
        return this.transport.http.post<ListaPerfisInterface>(`${this.transport.path}/perfil/duplicacao/${id}`, {});
    }

    public buscarPerfilPorId(id: string): Observable<PerfilInterface> {
        return this.transport.http.get<PerfilInterface>(`${this.transport.path}/perfil/${id}`);
    }

    public buscaListaFuncionalidades(): Observable<FuncionalidadeInterface[]> {
        return this.transport.http.get<FuncionalidadeInterface[]>(`${this.transport.path}/funcionalidade`);
    }

    public exportarPerfil(id: string, unidadeId: number): Observable<ListaPerfisInterface> {
        return this.transport.http.get<ListaPerfisInterface>(`${this.transport.path}/perfil/exportacao/${id}/${unidadeId}`);
    }
}
