import { Params } from '@angular/router';
import { PerfilFuncionalidadeAcaoEnum } from 'app/shared/enums';

export interface ListaPerfisInterface {
    id?: string;
    arquivado?: boolean;
    nome?: string;
    notas?: string;
    padrao?: boolean;
    usuario_perfil?: UsuarioPerfilInterface[];
}

export interface PerfilInterface {
    id?: string;
    arquivado?: boolean;
    nome?: string;
    notas?: string;
    padrao?: boolean;
    usuario_perfil?: UsuarioPerfilInterface[];
    perfil_funcionalidade?: PerfilFuncionalidadeInterface[];
}

export interface UsuarioPerfilInterface {
    id?: string;
    arquivado?: boolean;
    usuario?: UsuarioInterface;
}

export interface PerfilFuncionalidadeInterface {
    id?: string;
    perfil_id?: string;
    acao?: PerfilFuncionalidadeAcaoEnum;
    funcionalidade?: FuncionalidadePerfilInterface;
    parametrosRota?: Params;
    carregando?: boolean;
}

export interface FuncionalidadePerfilInterface {
    id: string;
    arquivado?: boolean;
    modulo?: string;
    nome?: string;
    caminho?: string;
    chave?: string;
}

interface UsuarioInterface {
    id?: string;
    arquivado?: boolean;
    nome?: string;
    sobrenome?: string;
    email?: string;
}
