export const ComunicacaoCondicaoConstantes = {
    CABECALHO : 'Comunicação geral dos equipamentos',
    COMUNICANDO : 'Comunicando',
    PARCIAL: 'Comunicação parcial',
    FALHA_COMUNICACAO : 'Falha de comunicação',
}

export const StatusComunicacaoBackConstantes = {
    COMUNICANDO: 'COMUNICANDO',
    PARCIAL: 'PARCIAL',
    FALHA: 'FALHA'
}

