import { UsuarioHistoricoAcoes } from 'app/shared/interfaces';
import { AeradorStatuSodeEnum } from 'app/shared/enums';
import { StatusAeracaoModel } from 'app/shared/models';

export interface HistoricoComandoAeracaoInterface {
    id: string;
    estrutura_armazenagem_divisao_id: number;
    instante_criacao: string;
    instante_validacao: string;
    comando: string;
    status_comando: boolean;
    automatico: boolean;
    aeracao: {
        status: AeradorStatuSodeEnum;
      }
    usuario: UsuarioHistoricoAcoes;
    status_aeracao?: StatusAeracaoModel;
}

