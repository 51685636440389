import { PerfilFuncionalidadeInterface } from 'app/shared/interfaces';
import { BehaviorSubject, Observable } from 'rxjs';

export class PermissaoPerfilFuncionalidadeObservable {
    private permissaoFuncionalidade: BehaviorSubject<PerfilFuncionalidadeInterface> = new BehaviorSubject(null);

    get permissaoFuncionalidade$(): Observable<PerfilFuncionalidadeInterface> {
        return this.permissaoFuncionalidade.asObservable();
    }

    set setaPermissaoFuncionalidade(valor: PerfilFuncionalidadeInterface) {
        this.permissaoFuncionalidade.next(valor);
    }
}
