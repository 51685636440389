export interface ListaRecursosInterface<T> {
  data: T[];
  total: number;
  paginacao: PaginacaoInterface;
}

export interface PaginacaoInterface {
  // take: number;
  // skip: number;
  // previous: number;
}
