export interface SensorPlenumInterface {
    id: string;
    sensor_plenum_id: string;
    instante: string;
    temperatura: number;
    umidade: number;
    sensor_plenum: {
        id: string;
        nome: string;
    };
}
