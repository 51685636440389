import {
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Subject, takeUntil } from 'rxjs';
import { LoadingService } from 'app/core/services/loading/loading.service';

@Component({
    selector: 'loading-bar',
    templateUrl: './loading-bar.component.html',
    styleUrls: ['./loading-bar.component.scss'],
    encapsulation: ViewEncapsulation.None,    
})
export class LoadingBarComponent implements OnChanges, OnInit, OnDestroy {
    @Input() autoMode: boolean = true;
    mode: 'determinate' | 'indeterminate';
    progress: number = 0;
    show: boolean = false;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(private _loadingService: LoadingService, private _changeDetectorRef: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges): void {
        if ('autoMode' in changes) {
            this._loadingService.setAutoMode(coerceBooleanProperty(changes.autoMode.currentValue));
        }
    }

    ngOnInit(): void {
        this._loadingService.mode$.pipe(takeUntil(this._unsubscribeAll)).subscribe((value) => {
            this.mode = value;
        });

        this._loadingService.progress$.pipe(takeUntil(this._unsubscribeAll)).subscribe((value) => {
            this.progress = value;
        });

        this._loadingService.show$.pipe(takeUntil(this._unsubscribeAll)).subscribe((value) => {            
            this.show = value;
            this._changeDetectorRef.detectChanges();
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
