export class FormataNomeSobrenomeUsuario {
    public static execute(usuario: any): string {
        if (usuario && usuario.nome && usuario.sobrenome) {
            const nomeFormatado = `${usuario.nome} ${usuario.sobrenome}`;
            const nomeCapitalizado = nomeFormatado.toLowerCase().replace(/(?:^|\s)\S/g, (letra) => {
                return letra.toUpperCase();
            });
            return nomeCapitalizado;
        }
        return '-';
    }
}
