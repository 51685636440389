export enum AreasSecadorEnum {
    todos = 'TODOS',
    nenhum = 'NENHUM',
    carga_produto = 'SECADOR_VISAO_SCREEN.CARGA_GRAOS',
    descarga_produto = 'SECADOR_VISAO_SCREEN.DESCARGA_GRAOS',
    entrada_ar = 'SECADOR_VISAO_SCREEN.ENTRADA_AR',
    saida_ar = 'SECADOR_VISAO_SCREEN.SAIDA_AR',
    produto = 'PRODUTO',
    fornalha = 'SECADOR_VISAO_SCREEN.FORNALHA',
    condicao_externa = 'SECADOR_VISAO_SCREEN.CONDICAO_EXTERNA',
    recirculacao = 'SECADOR_VISAO_SCREEN.RECIRCULACAO',
    massa_grao = 'SECADOR_VISAO_SCREEN.MASSA_GRAOS',
}

export enum AreaSecadorSemLabelEnum {
    NENHUM = 'nenhum',
    CARGA_PRODUTO = 'carga_produto',
    DESCARGA_PRODUTO = 'descarga_produto',
    ENTRADA_AR = 'entrada_ar',
    SAIDA_AR = 'saida_ar',
    PRODUTO = 'produto',
    FORNALHA = 'fornalha',
    CONDICAO_EXTERNA = 'condicao_externa',
    RECIRCULACAO = 'recirculacao',
    MASSA_GRAO = 'massa_grao',
}

export enum AreaSecadorComLabelEnum {
    NENHUM = 'Nenhum',
    CARGA_PRODUTO = 'Carga de produto',
    DESCARGA_PRODUTO = 'Descarga de produto',
    ENTRADA_AR = 'Entrada de ar',
    SAIDA_AR = 'Saída de ar',
    MASSA_GRAO = 'Massa de grão',
    FORNALHA = 'Fornalha',
    CONDICAO_EXTERNA = 'Condição externa',
    RECIRCULACAO = 'Recirculação',
}
