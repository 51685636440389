import { GraoInterface } from 'app/shared/interfaces';
import { AeradorStatuSodeEnum } from 'app/shared/enums';

export interface InformacoesSiloInterface {
    id: string;
    nome: string;
    sensor_umidade_instalado: boolean;
    controle_aeracao_automatica: boolean;
    aquecimento_ar: number;
    estrutura_armazenagem: EstruturaArmazenagemSiloInterface;
    programa_aeracao: {
        id: string;
        nome: string;
        objetivo_programa: string;
    };
    produto: GraoInterface;
    equipamentos: EquipamentoInformacoesSiloInterface[];
    silo: SiloPropriedadeInterface;
    mapa: MapaInformacoesSiloInterface;
    estrutura_armazenagem_divisao_pendulo: PenduloInformacoesSiloInterface[];
    estrutura_armazenagem_divisao_aerador: EstruturaArmazenagemDivisaoAerador[];
    estoque: EstoqueInformacoesSiloInterface;
    sensores: SensoresInformacoesSiloInterface;
    media_leitura: MediaLeituraInformacoesSiloInterface;
    aeracao: AeracaoInformacoesSiloInterface;
    log_comando_aeracao: LogComandoAeracaoInformacoesSiloInterface;
    estrutura_armazenagem_propriedade: any;
}

export interface SensoresInformacoesSiloInterface {
    sensor_co2: SensorInterface[];
    sensor_plenum: SensorInterface[];
    sensor_volume: SensorInterface[];
    sensor_volume_3d: SensoresVolumetria3DInterface[];
}

interface SensorInterface {
    id: string;
    equipamento_id: string;
    estrutura_armazenagem_id: string;
}

export interface MediaLeituraInformacoesSiloInterface {
    temperatura: {
        digital: {
            min: number;
            med: number;
            max: number;
        };
        termopar: {
            min: number;
            med: number;
            max: number;
        };
        geral: {
            min: number;
            med: number;
            max: number;
        };
    };
    umidade: {
        min: number;
        med: number;
        max: number;
    };
    volumetria: {
        quilograma: number;
        saca: number;
        tonelada: number;
    };
    plenum: {
        temperatura: {
            min: number;
            med: number;
            max: number;
        };
        umidade: {
            min: number;
            med: number;
            max: number;
        };
    };
    co2: {
        co2: {
            min: number;
            med: number;
            max: number;
        };
        temperatura: number;
        temperatura_chapa: number;
        umidade: number;
        validacao: {
            condensacao: number;
            ppm: number;
        };
    };
}

export interface LogComandoAeracaoInformacoesSiloInterface {
    id: string;
    instante: string;
    automatico: string;
    comando: string;
    status_comando: boolean;
}
export interface AeracaoInformacoesSiloInterface {
    status: AeradorStatuSodeEnum;
    manual: {
        tempo: string;
    };
    automatico: {
        tempo: string;
    };
}
export interface EstruturaArmazenagemDivisaoAerador {
    id: string;
    aerador: {
        id: string;
        codigo: number;
        aerador_motor: {
            id: string;
            corrente_nominal: number;
            potencia_eletrica: number;
            rendimento: number;
            tempo_acionamento: number;
        };
        aerador_configuracao: {
            id: string;
            chave_equipamento_id: string;
            status_equipamento_id: string;
            rele_equipamento_id: string;
        };
        aerador_leitura_status: {
            instante: string;
            valor: boolean;
            equipamento_id: string;
        };
        aerador_leitura_chave: {
            instante: string;
            valor: boolean;
            equipamento_id: string;
        };
        aerador_leitura_rele: {
            instante: string;
            valor: boolean;
            equipamento_id: string;
        };
        aeracao: {
            status: AeradorStatuSodeEnum;
        };
    };
}
export interface EstruturaArmazenagemSiloInterface {
    codigo: number;
    id: string;
    nome: string;
    sensor_umidade_instalado: boolean;
}
export interface MedidasEstoqueInformacoesSiloInterface {
    capacidade_estoque: number;
    estoque_estimado: number;
    estoque_informado: number;
}

export interface MedidasTemperaturaUmidadeInformacoesSiloInterface {
    minima: number;
    maxima: number;
    media: number;
}

export interface PenduloInformacoesSiloInterface {
    id: string;
    pendulo: {
        estrutura_armazenagem_id: string;
        id: string;
        codigo: number;
        pendulo_canal: PenduloCanalInformacoesSiloInterface;
        pendulo_propriedade: PenduloPropriedadeInformacoesInterface;
        pendulo_nivel_sensor?: PenduloNivelSensorInterface;
        silo_pendulo_visualizacao: SiloPenduloVisualizacaoInterface;
    };
}

export interface SiloPenduloVisualizacaoInterface {
    id: string;
    pendulo_id: string;
    posicao_x: number;
    posicao_y: number;
}
export interface PenduloCanalInformacoesSiloInterface {
    id: string;
    equipamento_id: string;
}

export interface PenduloPropriedadeInformacoesInterface {
    digital_termopar: string;
    sensor_temperatura_quantidade: number;
    sensor_umidade_quantidade: number;
    sensor_temperatura_espacamento: number;
    distancia_base: number;
    distancia_telhado: number;
    comprimento?: number;
}

export interface MapaInformacoesSiloInterface {
    temperatura: string;
    sensor_nivel_temperatura: string;
    sensor_nivel_umidade: string;
    sensor_quantidade_temperatura: string;
    equilibrio_higroscopico: string;
    umidade_relativa: string;
    pendulo_comunicacao: string;
    pendulo_ordenacao: string;
    digital_termopar: string;
    sensor_quantidade_umidade: string;
    pendulo_central: boolean;
}
export interface EquipamentoInformacoesSiloInterface {
    id: string;
    nome: string;
    modelo: string;
    categoria: string;
    equipamento_comunicacao: {
        instante: string;
        status: boolean;
    };
}

export interface ProdutoInformacoesSiloInterface {
    id: number;
    nome: string;
    produto_tipo_ref: number;
}

export interface EstoqueInformacoesSiloInterface {
    tonelada: MedidasEstoqueInformacoesSiloInterface;
    quilograma: MedidasEstoqueInformacoesSiloInterface;
    saca: MedidasEstoqueInformacoesSiloInterface;
}

export interface TemperaturaInformacoesSiloInterface {
    digital: MedidasTemperaturaUmidadeInformacoesSiloInterface;
    termopar: MedidasTemperaturaUmidadeInformacoesSiloInterface;
    geral: MedidasTemperaturaUmidadeInformacoesSiloInterface;
}

export interface PenduloNivelSensorInterface {
    id: number;
    nivel_sensor: number;
    instante: string;
}
export interface SiloPropriedadeInterface {
    diametro: number;
    id: number;
    base: string;
    silo_aneis: SiloAneisInterface[];
    altura_total: number;
    altura_cone_superior: number;
    altura_cone_inferior: number;
    angulo_cone_superior: number;
    angulo_cone_inferior: number;
    diametro_inferior: number;
}
export interface SiloAneisInterface {
    id: string;
    suporte_quantidade: number;
    raio: number;
}
export interface SensorVolumetriaInterface {
    quilograma?: number;
    saca?: number;
    tonelada?: number;
}

export interface SensoresVolumetria3DInterface {
    id: string;
    equipamento_id: string;
    estrutura_armazenagem_id: string;
    estrutura_armazenagem_divisao_id: string;
    nome: string;
    sensor_volume_3d_ultima_leitura: SensorVolumetria3DUltimaLeitura;
}

export interface SensorVolumetria3DUltimaLeitura {
    id?: string;
    pontos_eixo_x: number[];
    pontos_eixo_y: number[];
    pontos_eixo_z: number[];
    volume_estimado?: number;
}
