import { parametrosFiltroPersonalizados } from 'app/modules/gestao-unidades/operacional/shared/interfaces';

export enum TabelaSecadoresQualidadeEnum {
    SECADOR = 'secador',
    UNIDADES = 'unidades',
    GRAO = 'grao',
    ENTRADA_TEMPERATURA = 'entradaTemperatura',
    MASSA_TEMPERATURA = 'massaTemperatura',
    SAIDA_TEMPERATURA = 'saidaTemperatura',
    ENTRADA_MAIOR_TEMPERATURA = 'entradaMaiorTemperatura',
    MASSA_MAIOR_TEMPERATURA = 'massaMaiorTemperatura',
    SAIDA_MAIOR_TEMPERATURA = 'saidaMaiorTemperatura',
    ENTRADA_ALERTA = 'entradaAlerta',
    MASSA_ALERTA = 'massaAlerta',
    SAIDA_ALERTA = 'saidaAlerta',
    CARGA_UMIDADE = 'cargaUmidade',
    DESCARGA_UMIDADE = 'descargaUmidade',
    MAX_UMIDADE_CARGA = 'maximaUmidadeCarga',
    MED_UMIDADE_CARGA = 'mediaUmidadeCarga',
    MIN_UMIDADE_CARGA = 'minimoUmidadeCarga',
    MAX_UMIDADE_DESCARGA = 'maximaUmidadeDescarga',
    MED_UMIDADE_DESCARGA = 'mediaUmidadeDescarga',
    MIN_UMIDADE_DESCARGA = 'minimoUmidadeDescarga',
    MODAL = 'modal',
}

export const StatusComunicacaoSecadorQualidadeBackEnum = {
    COMUNICANDO: 'COMUNICANDO',
    PARCIAL: 'COMUNICANDO_PARCIAL',
    FALHA: 'FALHA_COMUNICACAO',
    AGUARDANDO_LEITURA: 'AGUARDANDO_LEITURA',
};

export const contextoPainelEnum = {
    contexto_qualidade: 'painel_qualidade'
}

export const OperadoresFiltrosPersonalizadosTiposDeSensores: parametrosFiltroPersonalizados[] = [
    {
        nome: 'Temperatura digital',
        valor: 'secador_leitura_historico_consolidado.temperatura_digital',
    },
    {
        nome: 'Temperatura termopar',
        valor: 'secador_leitura_historico_consolidado.temperatura_termopar',
    },
    {
        nome: 'Temperatura termoresistencia',
        valor: 'secador_leitura_historico_consolidado.temperatura_termoresistencia',
    },
    {
        nome: 'Umidade relativa',
        valor: 'secador_leitura_historico_consolidado.umidade_relativa',
    },
    {
        nome: 'Umidade do grao',
        valor: 'secador_leitura_historico_consolidado.umidade_grao',
    },
    {
        nome: 'Pressão',
        valor: 'secador_leitura_historico_consolidado.pressao',
    },
];

export const OperadoresFiltrosPersonalizadosAreas: parametrosFiltroPersonalizados[] = [
    {
        nome: 'Fornalha',
        valor: 'fornalha',
    },
    {
        nome: 'Entrada de ar',
        valor: 'entrada_de_ar',
    },
    {
        nome: 'Massa de graõs',
        valor: 'massa_de_grao',
    },
    {
        nome: 'Saída de ar',
        valor: 'saida_de_ar',
    },
    {
        nome: 'Carga de grãos',
        valor: 'carga_de_grao',
    },
    {
        nome: 'Descarga de grãos',
        valor: 'descarga_de_grao',
    },
    {
        nome: 'Recirculação',
        valor: 'recirculacao',
    },
    {
        nome: 'Condição externa',
        valor: 'condicao_externa',
    }
];
