export interface AlertasListaInterface {
    arquivado?: boolean;
    area_secador?: string;
    categoria?: string;
    expressao_logica?: string;
    id?: number;
    mensagem?: boolean;
    nome?: string;
    secador?: string;
    sistema?: boolean;
    valor_controle?: number;
}
