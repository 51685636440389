import { FormaGeometricaPenduloEnum } from '../enums';

export interface ArmazemVisaoSuperiorInterfaceInterface {
    id: string;
    pendulos: PenduloVisaoSuperiorInterface[];
    aeradores: AeradoreVisaoSuperiorInterface[];
    equipamentos: EquipamentoVisaoSuperiorInterface[];
    armazem: ArmazemInterface;
}

export interface PenduloVisaoSuperiorInterface {
    id: string;
    codigo: number;
    estrutura_armazenagem_divisao_pendulo: EstruturaArmazenagemDivisaoPenduloInterface;
    pendulo_ultima_leitura: PenduloUltimaLeituraInterface;
    pendulo_canal: PenduloCanalInterface;
    pendulo_propriedade: PenduloPropriedadeInterface;
    armazem_pendulo_visualizacao: ArmazemPenduloVisualizacaoInterface;
}

export interface EstruturaArmazenagemDivisaoPenduloInterface {
    id: string;
    estrutura_armazenagem_divisao_id: string;
}

export interface PenduloUltimaLeituraInterface {
    min: number;
    med: number;
    max: number;
}

export interface PenduloCanalInterface {
    id: string;
    equipamento_id: string;
}

export interface PenduloPropriedadeInterface {
    id: string;
    digital_termopar: string;
    sensor_umidade_quantidade: number;
}

export interface ArmazemPenduloVisualizacaoInterface {
    id: string;
    pendulo_id: string;
    armazem_id: string;
    arco: number;
    linha: number;
    posicao_x: number;
    posicao_y: number;
}

export interface AeradoreVisaoSuperiorInterface {
    id: string;
    codigo: number;
    estrutura_armazenagem_divisao_aerador: EstruturaArmazenagemDivisaoAeradorInterface[];
    aeracao: {
        status: any;
    };
    armazem_aerador_visualizacao: ArmazemAeradorVisualizacaoInterface;
}

export interface EstruturaArmazenagemDivisaoAeradorInterface {
    id: string;
    estrutura_armazenagem_divisao_id: string;
}

export interface ArmazemAeradorVisualizacaoInterface {
    id: string;
    armazem_id: string;
    aerador_id: string;
    posicao_x: number;
    posicao_y: number;
}

export interface EquipamentoVisaoSuperiorInterface {
    id: string;
    nome: string;
    modelo: string;
    categoria: string;
    equipamento_comunicacao: {
        status: boolean;
        instante: string;
    };
}

export interface ArmazemInterface {
    id: string;
    armazem_passarela_visualizacao: ArmazemPassarelaVisualizacaoInterface[];
    armazem_equipamento_visualizacao: ArmazemEquipamentoVisualizacaoInterface[];
    armazem_porta_visualizacao: ArmazemPortaVisualizacaoInterface[];
}

export interface ArmazemPassarelaVisualizacaoInterface {
    id: string;
    armazem_id: string;
    posicao_y: number;
}

export interface ArmazemEquipamentoVisualizacaoInterface {
    id: string;
    armazem_id: string;
    equipamento_id: string;
    posicao_x: number;
    posicao_y: number;
    nome?: string;
}

export interface ArmazemPortaVisualizacaoInterface {
    id: string;
    armazem_id: string;
    posicao_x: number;
    posicao_y: number;
}

export interface AeradoresComFormaGeometrica {
    idDivisao?: string;
    formaGeometrica?: string;
    idAerador?: string;
    posicao_x?: number;
    posicao_y?: number;
    codigo?: number;
    icone?: string;
}

export interface DivisoesComPedulosInterface {
    formaGeometrica: FormaGeometricaPenduloEnum;
    idDivisao: string;
    pendulos: PenduloVisaoSuperiorInterface[];
}
