import { Injectable } from '@angular/core';
export abstract class IFormataSegundosHHMMSSService {
    abstract horas(segundos: number): string;
    abstract horasMinutos(segundos: number): string;
    abstract horasMinutosSegundos(segundos: number): string;
}

@Injectable({ providedIn: 'root' })
export class FormataSegundosHHMMSSService implements IFormataSegundosHHMMSSService {
    horas(segundos: number): string {
        const horas = Math.floor(segundos / 3600);
        const horasFormatados = horas.toString().padStart(2, '0');
        return `${horasFormatados}`;
    }
    horasMinutos(segundos: number): string {
        const minutos = Math.floor((segundos % 3600) / 60);
        const minutosFormatados = minutos.toString().padStart(2, '0');
        return `${this.horas(segundos)}:${minutosFormatados}`;
    }
    horasMinutosSegundos(segundos: number): string {
        const segundosRestantes = segundos % 60;
        const segundosFormatados = segundosRestantes.toString().padStart(2, '0');
        return `${this.horasMinutos(segundos)}:${segundosFormatados}`;
    }
}
