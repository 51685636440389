import { NgModule } from '@angular/core';
import { MediaWatcherService } from 'app/core/media-watcher/media-watcher.service';


@NgModule({
    providers: [
        MediaWatcherService
    ]
})
export class MediaWatcherModule
{
    /**
     * Constructor
     */
    constructor(private _mediaWatcherService: MediaWatcherService)
    {
    }
}
