export enum IconesSensorEnum {
    TEMPERATURA_CINZA = 'icon-temperatura-cinza',
    TEMPERATURA_BRANCO = 'icon-temperatura-branco',
    UMIDADE_RELATIVA_BRANCO = 'icon-umidade-branco',
    UMIDADE_RELATIVA_CINZA = 'icon-umidade-cinza',
    UMIDADE_GRAO_BRANCO = 'icon-umidade-grao-branco',
    UMIDADE_GRAO_CINZA = 'icon-umidade-grao-cinza',
    PRESSAO_BRANCO = 'icone-sensor-pressao-branco',
    PRESSAO_CINZA = 'icone-sensor-pressao-cinza',
}

export enum UnidadeMedidaSensorEnum {
    TEMPERATURA = 'UNIDADE_MEDIDA_TEMPERATURA',
    UMIDADE = 'UNIDADE_MEDIDA_UMIDADE',
    PRESSAO = 'UNIDADE_MEDIDA_PRESSAO',
}
