<ng-container *ngIf="!telaMobile()">
    <div
        id="sideBarMenu"
        class="dark wl-bg-cor-terciaria print:hidden h-full w-[280px] overflow-y-auto overflow-x-hidden absolute z-999 cursor-default"
        [ngClass]="{ asideMenu: menuAberto, asideMenuOpen: !menuAberto }"
    >
        <ng-container *ngIf="modulos$ | async as modules">
            <div
                class="vertical-navigation-wrapper fixed top-0 left-[-205px]"
                [ngClass]="{ modulesHidden: !menuAberto }"
            >
                <div
                    class="flex vertical-navigation-content-header cursor-pointer w-[280px] text-right justify-end p-3 mb-9"
                    routerLink="./"
                >
                    <img class="w-[48px] h-[42px]" [src]="logoMenuFechadoUrl" />
                </div>

                <ng-container *ngFor="let module of modules">
                    <!-- Separator -->
                    <div *ngIf="module.separator">
                        <div class="separator my-1"></div>
                    </div>

                    <!-- Modules Icon -->
                    <div class="w-full flex justify-end h-14 items-center mb-4">
                        <div
                            #tooltip="matTooltip"
                            routerLinkActive="menu-item-selected"
                            matTooltip="{{ module.menu }}"
                            matTooltipClass="tooltip-modules"
                            class="module-icon cursor-pointer"
                            (click)="selecionarModulo(module); classicService.abrirMenuSobreposto()"
                        >
                            <span class="menu-icon">
                                <mat-icon class="vertical-navigation-item-icon" [svgIcon]="module.icon"> </mat-icon>
                            </span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="menus$ | async as menus">
            <div
                class="vertical-navigation-wrapper fixed top-0 h-full w-[280px] overflow-y-auto overflow-x-hidden"
                [ngClass]="{ menuiHidden: menuAberto }"
            >
                <ng-container verticalNavigationContentHeader>
                    <div class="flex items-center h-20 p-6 pb-0 cursor-pointer" routerLink="./">
                        <img class="hidden dark:flex ml-0" [src]="logoMenuAbertoUrl" />
                    </div>
                    <div
                        *ngIf="testEnviromentMode"
                        class="text-rose-400 tracking-widest text-center font-semibold text-[16px] ml-5 mt-7 mb-7 mr-4 p-3 rounded-md bg-red-500/[0.14]"
                    >
                        AMBIENTE DE TESTE
                    </div>
                    <div
                        *ngIf="moduloSelecionado"
                        class="bg-[#DCE6FF24] wl-cor-primaria font-semibold text-[14px] mt-7 mb-7 py-3 px-2 flex-row flex justify-between items-center"
                    >
                        <div class="items-center">
                            <span class="flex"> CERES | {{ moduloSelecionado }} </span>
                        </div>
                        <div class="flex cursor-pointer closeMenu h-[25px] items-center rounded" (click)="fecharMenu()">
                            <span class="firstIcon flex">
                                <mat-icon svgIcon="heroicons_outline:chevron-left" class="icon-size-5"> </mat-icon>
                            </span>
                            <span class="secondIcon flex">
                                <mat-icon svgIcon="heroicons_outline:chevron-left" class="icon-size-5 ml-[-11px]">
                                </mat-icon>
                            </span>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngFor="let menu of menus; let i = index">
                    <div *ngIf="menu.title" class="menu-group" [ngClass]="{ 'menu-first-group': i === 0 }">
                        <mat-icon class="icon-size-[14px] mr-[5px] wl-cor-primaria" [svgIcon]="menu.icon"></mat-icon>
                        <span class="ms-3">{{ menu.title }}</span>
                    </div>
                    <ng-container *ngFor="let item of menu.items; let idx = index">
                        <div
                            *ngIf="!item.childrensItems"
                            class="menu-link"
                            [routerLink]="[item.link]"
                            routerLinkActive="menu-item-selected"
                            (click)="selecionarMenu(item, 'noSubmenu')"
                        >
                            <span class="menu-icon">
                                <mat-icon
                                    class="icon-size-[17px] mr-[5px]"
                                    [ngClass]="whiteLabelCliente ? 'text-white' : 'text-[#BFD4FF]'"
                                    [svgIcon]="item.icon"
                                ></mat-icon>
                            </span>
                            <span
                                class="font-normal text-[14px]"
                                [ngClass]="whiteLabelCliente ? 'text-white' : 'text-[#BFD4FF]'"
                                >{{ item.menu }}</span
                            >
                        </div>
                        <div
                            *ngIf="item.childrensItems"
                            class="menu-link without-sub flex"
                            (click)="toggleSubMenu(idx, item.menu)"
                        >
                            <div class="flex w-[100%] items-center">
                                <span class="menu-icon">
                                    <mat-icon
                                        class="icon-size-[17px] mr-[5px]"
                                        [ngClass]="whiteLabelCliente ? 'text-white' : 'text-[#BFD4FF]'"
                                        [svgIcon]="item.icon"
                                    ></mat-icon>
                                </span>
                                <span
                                    class="font-normal text-[13px]"
                                    [ngClass]="whiteLabelCliente ? 'text-white' : 'text-[#BFD4FF]'"
                                    >{{ item.menu }}</span
                                >
                            </div>
                            <div class="m-0 flex justify-end items-center">
                                <mat-icon id="{{ 'arrow' + idx + item.menu }}" svgIcon="mat_solid:keyboard_arrow_right">
                                </mat-icon>
                            </div>
                        </div>
                        <div *ngIf="item.childrensItems" id="{{ 'subItens' + idx + item.menu }}" class="subMenu">
                            <ng-container *ngFor="let subItem of item.childrensItems; let idx = index">
                                <div class="menu-item" (click)="selecionarMenu(subItem, item)">
                                    <div
                                        class="menu-link without-sub"
                                        routerLink="{{ subItem.link }}"
                                        routerLinkActive="menu-item-selected"
                                    >
                                        <span class="menu-icon ml-4">
                                            <mat-icon
                                                class="icon-size-[17px] mr-[5px]"
                                                [ngClass]="whiteLabelCliente ? 'text-white' : 'text-[#BFD4FF]'"
                                                [svgIcon]="subItem.icon"
                                            ></mat-icon>
                                        </span>
                                        <span
                                            class="font-normal text-[13px]"
                                            [ngClass]="whiteLabelCliente ? 'text-white' : 'text-[#BFD4FF]'"
                                            [innerHTML]="subItem.menu"
                                        ></span>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                    <div *ngIf="menu.separator">
                        <div class="separator-group"></div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>
</ng-container>

<ng-container *ngIf="telaMobile()">
    <div
        class="dark bg-gray-900 print:hidden h-full w-[280px] overflow-y-auto overflow-x-hidden absolute z-999"
        [ngClass]="{ exibeModuloMobile: !abrirMenuModuloSelecionado }"
        *ngIf="!menuModuloAberto"
    >
        <ng-container *ngIf="modulos$ | async as modules">
            <div class="vertical-navigation-wrapper fixed top-0 left-[-205px]" *ngIf="!abrirMenuModuloSelecionado">
                <div
                    class="flex vertical-navigation-content-header cursor-pointer w-[280px] text-right justify-end p-3 mb-9"
                    routerLink="./"
                >
                    <img class="w-[19%]" src="assets/images/logo/logo_menu_fechado_orion.svg" alt="Procer" />
                </div>
                <ng-container *ngFor="let module of modules">
                    <div *ngIf="module.separator">
                        <div class="separator my-1"></div>
                    </div>
                    <div class="w-full flex justify-end h-14 items-center mb-4">
                        <div
                            #tooltip="matTooltip"
                            routerLinkActive="menu-item-selected"
                            matTooltip="{{ module.menu }}"
                            matTooltipClass="tooltip-modules"
                            class="module-icon cursor-pointer"
                            (click)="selecionarModulo(module); classicService.abrirMenuSobreposto()"
                        >
                            <span class="menu-icon">
                                <mat-icon class="fuse-vertical-navigation-item-icon" [svgIcon]="module.icon">
                                </mat-icon>
                            </span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="menus$ | async as menus">
            <div
                class="vertical-navigation-wrapper fixed top-0 w-[283px] overflow-auto h-full"
                *ngIf="abrirMenuModuloSelecionado"
            >
                <ng-container verticalNavigationContentHeader>
                    <div class="flex items-center h-20 p-6 pb-0 cursor-pointer" routerLink="./">
                        <img
                            class="dark:hidden ml-0"
                            src="assets/images/logo/logo_orion_menu_aberto.svg"
                            alt="Procer"
                        />
                        <img
                            class="hidden dark:flex ml-0"
                            src="assets/images/logo/logo_orion_menu_aberto.svg"
                            alt="Procer"
                        />
                    </div>
                    <div
                        *ngIf="testEnviromentMode"
                        class="text-rose-400 tracking-widest text-center font-semibold text-[16px] ml-5 mt-7 mb-7 mr-4 p-3 rounded-md bg-red-500/[0.14]"
                    >
                        AMBIENTE DE TESTE
                    </div>
                    <div
                        *ngIf="moduloSelecionado"
                        class="bg-[#DCE6FF24] wl-cor-primaria font-semibold text-[14px] mt-7 mb-7 py-3 pl-2 flex-row flex"
                    >
                        <div class="items-center">
                            <span class="flex"> CERES | {{ moduloSelecionado }} </span>
                        </div>
                        <div
                            class="flex ml-[60px] cursor-pointer closeMenu h-[25px] items-center rounded"
                            (click)="fecharMenu()"
                        >
                            <span class="firstIcon flex">
                                <mat-icon svgIcon="heroicons_outline:chevron-left" class="icon-size-5"> </mat-icon>
                            </span>
                            <span class="secondIcon flex">
                                <mat-icon svgIcon="heroicons_outline:chevron-left" class="icon-size-5 ml-[-11px]">
                                </mat-icon>
                            </span>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngFor="let menu of menus; let i = index">
                    <div *ngIf="menu.title" class="menu-group" [ngClass]="{ 'menu-first-group': i === 0 }">
                        <mat-icon class="icon-size-[14px] mr-[5px]" [svgIcon]="menu.icon"></mat-icon>
                        <span class="ms-3">{{ menu.title }}</span>
                    </div>
                    <ng-container *ngFor="let item of menu.items; let idx = index">
                        <div
                            *ngIf="!item.childrensItems"
                            class="menu-link"
                            [routerLink]="[item.link]"
                            routerLinkActive="menu-item-selected"
                            (click)="selecionarMenu(item, 'noSubmenu')"
                        >
                            <span class="menu-icon">
                                <mat-icon class="icon-size-[17px] mr-[5px]" [svgIcon]="item.icon"></mat-icon>
                            </span>
                            <span class="font-normal text-white">{{ item.menu }}</span>
                        </div>
                        <div
                            *ngIf="item.childrensItems"
                            class="menu-link without-sub flex"
                            (click)="toggleSubMenu(idx, item.menu)"
                        >
                            <div class="flex w-[100%] items-center">
                                <span class="menu-icon">
                                    <mat-icon class="icon-size-[17px] mr-[5px]" [svgIcon]="item.icon"></mat-icon>
                                </span>
                                <span class="font-normal text-white">{{ item.menu }}</span>
                            </div>
                            <div class="m-0 flex justify-end items-center">
                                <mat-icon id="{{ 'arrow' + idx + item.menu }}" svgIcon="mat_solid:keyboard_arrow_right">
                                </mat-icon>
                            </div>
                        </div>
                        <div *ngIf="item.childrensItems" id="{{ 'subItens' + idx + item.menu }}" class="subMenu">
                            <ng-container *ngFor="let subItem of item.childrensItems; let idx = index">
                                <div class="menu-item" (click)="selecionarMenu(subItem, item)">
                                    <div
                                        class="menu-link without-sub"
                                        routerLink="{{ subItem.link }}"
                                        routerLinkActive="menu-item-selected"
                                    >
                                        <span class="menu-icon">
                                            <mat-icon
                                                class="icon-size-[17px] mr-[5px]"
                                                [svgIcon]="subItem.icon"
                                            ></mat-icon>
                                        </span>
                                        <span class="font-normal text-white" [innerHTML]="subItem.menu"></span>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                    <div *ngIf="menu.separator">
                        <div class="separator-group"></div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>
</ng-container>
