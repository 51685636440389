import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { IApiTransportInterface } from 'app/core/interfaces';
import { environment } from 'environments/environment';

export abstract class IApiTransporGestaotService {
    abstract execute(path: string): IApiTransportInterface;
}

@Injectable({
    providedIn: 'root'
})
export class ApiTransportGestaoService implements IApiTransporGestaotService {
    public http: HttpClient;

    public options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        }),
        params: {}
    };

    constructor(
        protected injector: Injector,
    ) {
        this.http = injector.get(HttpClient);
    }

    public execute(path: string): IApiTransportInterface {
        return {
            http: this.http,
            options: this.options,
            path: environment.api.urlGestao + path,
        };
    }
}
