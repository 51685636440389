import { Injectable } from '@angular/core';

export abstract class IDefineBarraDeProgressoService {
    abstract calculaBarraDeProgessoEmPorcentagem(atual: number, total: number);
    abstract defineCorBarraDeProgresso(atual: number, total: number);
}

@Injectable({ providedIn: 'root' })
export class DefineBarraDeProgressoService implements IDefineBarraDeProgressoService {
    defineCorBarraDeProgresso(atual: number, total: number) {
        if (atual >= total) {
            const percentage = 100;
            return 'bg-[#D6505A]';
        } else {
            const percentage = (atual / total) * 100;
            if (percentage <= 50) {
                return 'bg-[#6BBE66]';
            } else if (percentage > 50 && percentage < 91) {
                return 'bg-[#F6B100]';
            } else {
                return 'bg-[#D6505A]';
            }
        }
    }
    calculaBarraDeProgessoEmPorcentagem(atual: number, total: number) {
        if (atual >= total) {
            return 100
        } else {
            return (atual / total) * 100;
        }
    }
}
