<div class="w-full flex flex-col px-4">
    <div class="flex w-full items-center place-content-between">
        <span class="font-bold text-sm sm:min-w-28 hidden sm:block"> {{ "ULTIMA_SINCRONIZACAO_COMPONENT.ULTIMA" | transloco }} </span>
        <span
            class="min-w-max sm:min-w-40 flex flex-row text-xs sm:text-sm"
            matTooltip="{{ 'ULTIMA_SINCRONIZACAO_COMPONENT.TOOLTIP_MOMENTO_DA_ULTIMA_SINCRONIZACAO_LOCAL_NUVEM' | transloco }}"
            matTooltipClass="tooltip-padrao"
            *ngIf="!objetoVazio"
        >
            <span class="order-1 sm:order-none">{{ "ULTIMA_SINCRONIZACAO_COMPONENT.LOCAL" | transloco }}/</span>
            <span class="order-1 sm:order-none">{{ "ULTIMA_SINCRONIZACAO_COMPONENT.NUVEM" | transloco }}</span>
        </span>
        <span class="min-w-[40%] sm:min-w-40 flex flex-row text-xs sm:text-sm" *ngIf="objetoVazio">
            <span class="order-1 sm:order-none">{{ "ULTIMA_SINCRONIZACAO_COMPONENT.LOCAL" | transloco }}/</span>
            <span class="order-1 sm:order-none">{{ "ULTIMA_SINCRONIZACAO_COMPONENT.NUVEM" | transloco }}</span>
        </span>
        <span
            class="text-sm min-w-[120px] sm:min-w-48 block items-center justify-center ml-[44px] sm:ml-0"
            [ngClass]="{'flex sm:block min-w-[30%] sm:min-w-32 ml-[0px]': objetoVazio}"
        >
            <div
                class="circle mr-1 sm:mr-[6px]"
                *ngIf="!objetoVazio"
                [ngClass]="{
                    'com-execucao': dataSincronizador?.comunicacao_local_com_nuvem?.status,
                    'sem-execucao': !dataSincronizador?.comunicacao_local_com_nuvem?.status
                }"
            ></div>
            <div class="circle sem-execucao mt-[-1px] sm:mt-[4px]" *ngIf="objetoVazio"></div>
            <div class="block">
                <span *ngIf="!objetoVazio" class="inline text-xs sm:text-sm">
                    {{ dataSincronizador?.comunicacao_local_com_nuvem?.instante | date: 'dd/MM/yyyy' }}
                </span>
                <span *ngIf="!objetoVazio" class="inline text-xs sm:text-sm">
                    {{ dataSincronizador?.comunicacao_local_com_nuvem?.instante | date: 'HH:mm:ss' }}
                </span>
            </div>
            <span class="inline text-xs sm:text-sm" *ngIf="objetoVazio">{{ "ULTIMA_SINCRONIZACAO_COMPONENT.SEM_REGISTROS" | transloco }}</span>
        </span>
        <ng-container *ngIf="!objetoVazio">
            <span class="text-sm min-w-max cursor-pointer" (click)="mostrarHistorico(tipoSincronizadorEnum.NuvemLocal)">
                <div class="equipamento com-execucao min-w-[120px]" *ngIf="dataSincronizador?.comunicacao_local_com_nuvem?.status">
                    <span class="items-center block"
                        ><span class="mensagemHeader">{{ "ULTIMA_SINCRONIZACAO_COMPONENT.EM_EXECUCAO" | transloco }}</span>
                        <mat-icon class="icone-status inline-flex w-6 min-w-6 ml-[-18px] sm:ml-0">
                            check_circle
                        </mat-icon>
                    </span>
                </div>
                <div class="equipamento sem-execucao min-w-[120px]" *ngIf="!dataSincronizador?.comunicacao_local_com_nuvem?.status">
                    <span class="items-center block">
                        <span class="mensagemHeader">{{ "ULTIMA_SINCRONIZACAO_COMPONENT.SEM_COMUNICACAO" | transloco }}</span>
                        <mat-icon class="icone-status inline-flex w-6 min-w-6 ml-[-18px] sm:ml-0"> warning </mat-icon>
                    </span>
                </div>
            </span>
        </ng-container>
        <ng-container *ngIf="objetoVazio">
            <span class="min-w-[30%] sm:min-w-max flex justify-end text-xs sm:text-sm"> {{ "ULTIMA_SINCRONIZACAO_COMPONENT.INDISPONIVEL" | transloco }} </span>
        </ng-container>
    </div>

    <div class="flex w-full items-center place-content-between">
        <span class="font-bold text-sm sm:min-w-28 hidden sm:block"> {{ "ULTIMA_SINCRONIZACAO_COMPONENT.SINCRONIZACAO" | transloco }}: </span>
        <span
            class="min-w-max sm:min-w-40 flex flex-row text-xs sm:text-sm"
            matTooltip="{{ 'ULTIMA_SINCRONIZACAO_COMPONENT.TOOLTIP_MOMENTO_DA_ULTIMA_SINCRONIZACAO_COLETOR_EQUIPAMENTO' | transloco }}"
            matTooltipClass="tooltip-padrao"
            *ngIf="!objetoVazio"
        >
            <span class="order-1 sm:order-none">{{ "ULTIMA_SINCRONIZACAO_COMPONENT.COLETOR" | transloco }}/</span>
            <span class="order-1 sm:order-none">{{ "ULTIMA_SINCRONIZACAO_COMPONENT.SINCRONIZADOR" | transloco }}</span>
        </span>
        <span class="min-w-[40%] sm:min-w-40 flex flex-row text-xs sm:text-sm" *ngIf="objetoVazio">
            <span class="order-1 sm:order-none">{{ "ULTIMA_SINCRONIZACAO_COMPONENT.COLETOR" | transloco }}/</span>
            <span class="order-1 sm:order-none">{{ "ULTIMA_SINCRONIZACAO_COMPONENT.SINCRONIZADOR" | transloco }}</span>
        </span>
        <span
            class="text-sm min-w-[120px] sm:min-w-48 block items-center justify-center"
            [ngClass]="{'flex sm:block min-w-[30%] sm:min-w-32 ml-[0px]': objetoVazio}"
        >
            <div
                class="circle mr-1 sm:mr-[6px]"
                *ngIf="!objetoVazio"
                [ngClass]="{
                    'com-execucao': dataSincronizador?.coletor_comunicacao?.status,
                    'sem-execucao': !dataSincronizador?.coletor_comunicacao?.status
                }"
            ></div>
            <div class="circle sem-execucao mt-[-1px] sm:mt-[4px]" *ngIf="objetoVazio"></div>
            <div class="block">
                <span *ngIf="!objetoVazio" class="inline text-xs sm:text-sm">
                    {{ dataSincronizador?.coletor_comunicacao?.instante | date: 'dd/MM/yyyy' }}
                </span>
                <span *ngIf="!objetoVazio" class="inline text-xs sm:text-sm">
                    {{ dataSincronizador?.coletor_comunicacao?.instante | date: 'HH:mm:ss' }}
                </span>
            </div>
            <span class="inline text-xs sm:text-sm" *ngIf="objetoVazio">{{ "ULTIMA_SINCRONIZACAO_COMPONENT.SEM_REGISTROS" | transloco }}</span>
            
        </span>
        <span
            class="text-sm min-w-max cursor-pointer"
            [ngClass]="{ 'min-w-max': !objetoVazio }"
            (click)="mostrarHistorico(tipoSincronizadorEnum.ColetorSincronizador)"
        >
            <div *ngIf="!objetoVazio" class="min-w-max">
                <div class="equipamento com-execucao min-w-[120px]" *ngIf="dataSincronizador?.coletor_comunicacao?.status">
                    <span class="items-center block"
                        ><span class="mensagemHeader">{{ "ULTIMA_SINCRONIZACAO_COMPONENT.EM_EXECUCAO" | transloco }}</span>
                        <mat-icon class="icone-status inline-flex w-6 min-w-6 ml-[-18px] sm:ml-0">
                            check_circle
                        </mat-icon>
                    </span>
                </div>
                <div class="equipamento sem-execucao min-w-[120px]" *ngIf="!dataSincronizador?.coletor_comunicacao?.status">
                    <span class="items-center block">
                        <span class="mensagemHeader">{{ "ULTIMA_SINCRONIZACAO_COMPONENT.SEM_COMUNICACAO" | transloco }}</span>
                        <mat-icon class="icone-status inline-flex w-6 min-w-6 ml-[-18px] sm:ml-0">warning</mat-icon>
                    </span>
                </div>
            </div>
        </span>
        <span class="min-w-[30%] sm:min-w-max flex justify-end text-xs sm:text-sm" *ngIf="objetoVazio"
            >{{ "ULTIMA_SINCRONIZACAO_COMPONENT.INDISPONIVEL" | transloco }}</span
        >
    </div>
</div>
