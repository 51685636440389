import { IconesProdutos, NomesProdutos } from 'app/shared/enums';

export class IconeProdutoHelper {
    private static nomeParaIcone: { [key: string]: string } = {
        [NomesProdutos.ARROZ]: IconesProdutos.ICONE_ARROZ,
        [NomesProdutos.AVEIA]: IconesProdutos.ICONE_AVEIA,
        [NomesProdutos.AZEVEM]: IconesProdutos.ICONE_AZEVEM,
        [NomesProdutos.BRACHIARIA]: IconesProdutos.ICONE_BRACHIARIA,
        [NomesProdutos.CAFEDESCASCADO]: IconesProdutos.ICONE_CAFEDESCASCADO,
        [NomesProdutos.CAFEPERGAMINHO]: IconesProdutos.ICONE_CAFEPERGAMINHO,
        [NomesProdutos.CANOLA]: IconesProdutos.ICONE_CANOLA,
        [NomesProdutos.CAROCODEALGODAO]: IconesProdutos.ICONE_CAROCODEALGODAO,
        [NomesProdutos.CEVADA]: IconesProdutos.ICONE_CEVADA,
        [NomesProdutos.ERVILHA]: IconesProdutos.ICONE_ERVILHA,
        [NomesProdutos.FEIJAO]: IconesProdutos.ICONE_FEIJAO,
        [NomesProdutos.GERGELIM]: IconesProdutos.ICONE_GERGELIM,
        [NomesProdutos.GIRASSOL]: IconesProdutos.ICONE_GIRASSOL,
        [NomesProdutos.MILHETO]: IconesProdutos.ICONE_MILHETO,
        [NomesProdutos.MILHO]: IconesProdutos.ICONE_MILHO,
        [NomesProdutos.MILHOPIPOCA]: IconesProdutos.ICONE_MILHOPIPOCA,
        [NomesProdutos.NABOFORRAGEIRO]: IconesProdutos.ICONE_NABOFORRAGEIRO,
        [NomesProdutos.PAINCO]: IconesProdutos.ICONE_PAINCO,
        [NomesProdutos.SOJA]: IconesProdutos.ICONE_SOJA,
        [NomesProdutos.SORGO]: IconesProdutos.ICONE_SORGO,
        [NomesProdutos.TRIGO]: IconesProdutos.ICONE_TRIGO,
        [NomesProdutos.TRITICALE]: IconesProdutos.ICONE_TRITICALE,
    };

    public static selecionaIconeProduto(nomeProduto: string): string {
        if (!nomeProduto) {
            return '';
        }
        const icone = this.nomeParaIcone[nomeProduto?.toUpperCase()];
        return icone || IconesProdutos.ICONE_OUTROS;
    }
}
