import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiTransportService } from 'app/core/services/transport';
import { IApiTransportInterface } from 'app/core/interfaces';

import { IObjectToAnyService } from 'app/shared/services';

import { PayloadFormularioGraficoInterface } from 'app/modules/secador/visualizacao/secadores/visao-detalhada-secador/interfaces';

export abstract class IGraficoSecadorController {
    abstract buscaDadosGraficoSecador(idSecador: number, params: PayloadFormularioGraficoInterface): Observable<any>;
}

@Injectable({
    providedIn: 'root',
})
export class GraficoSecadorController implements IGraficoSecadorController {
    private transport: IApiTransportInterface;

    constructor(
        readonly apiTransportService: ApiTransportService,
        private readonly objectToAnyService: IObjectToAnyService
    ) {
        this.transport = this.apiTransportService.execute('/relatorio');
    }

    buscaDadosGraficoSecador(idSecador: number, params: any): Observable<any> {
        return this.transport.http.get(`${this.transport.path}/grafico-ultima-secagem/${idSecador}`, { params });
    }
}
