import { LegendaTemperaturaUmidadeGraoInterface, OpcoesLegendaInterface } from "app/modules/gestao-unidades/operacional/shared/interfaces";

export const LegendaTemperaturaUmidadeGraoConstant: LegendaTemperaturaUmidadeGraoInterface[] = [
    {
        titulo: 'Excelente condição de armazenagem, favorável para a conservação dos grãos.',
        cor: '#BBF2C4'
    },
    {
        titulo: 'Condição que exige atenção, facilidade para a proliferação de insetos e para iniciar a deterioração dos grãos.',
        cor: '#E9DAB7'
    },
    {
        titulo: 'Condição que exige atenção, com risco de iniciar e evoluir a deterioração dos grãos.',
        cor: '#FDC6C7'
    },
    {
        titulo: 'Condição extremamente desfavorável para a armazenagem, com risco elevado de deterioração dos grãos.',
        cor: '#E08289'
    },
]

export const OpcoesLegendaConstant: OpcoesLegendaInterface[] = [
    {
        texto: 'Temperatura média',
        valor: 0,
    },
    {
        texto: 'Temperatura máxima',
        valor: 1,
    }
]
