export enum StatusOperacaoEnum {
    desligado = 'desligado',
    ligado = 'ligado',
    defeito_alarme = 'defeito_alarme',
    manutencao = 'manutencao',
}

export enum StatusPainelFormatadoEnum {
    desligado = 'STATUS_PAINEL_FORMATADO_COMPONENT.DESLIGADO',
    ligado = 'STATUS_PAINEL_FORMATADO_COMPONENT.LIGADO',
    defeito_alarme = 'STATUS_PAINEL_FORMATADO_COMPONENT.DEFEITO_ALARME',
    manutencao = 'STATUS_PAINEL_FORMATADO_COMPONENT.MANUTENCAO',
}
