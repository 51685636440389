import { BooleanInput } from '@angular/cdk/coercion';
import {
    AfterContentChecked,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { CookieService, IdiomaService } from 'app/core/services';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { IdiomaEnum, IdiomaIconeEnum, IdiomaSiglaEnum, TipoAmbiente, UsuarioCategoriaEnum } from 'app/shared/enums';
import { finalize, Subject, takeUntil } from 'rxjs';
import { IUsuarioIdiomaController } from 'app/layout/common/user/controllers/';
import { IAlertaService } from 'app/shared/services';
import { MatMenuTrigger } from '@angular/material/menu';
import { environment } from 'environments/environment';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user',
})
export class UserComponent implements OnInit, AfterContentChecked, OnDestroy {
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: Event): void {
        if (!this._elementRef.nativeElement.contains(event.target)) {
            this.selectAberto = false;
        }
    }

    static ngAcceptInputType_showAvatar: BooleanInput;

    user: User;
    selectAberto = false;
    idiomaSelecionado: string;
    iconeSelecionado: string;
    idiomaSigla = IdiomaSiglaEnum;
    idiomaEnum = IdiomaEnum;
    carregandoAlteracaoIdioma: boolean = false

    private unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private _router: Router,
        private _userService: UserService,
        private _elementRef: ElementRef,
        private _cookieService: CookieService,
        private _idiomaService: IdiomaService,
        private _translocoService: TranslocoService,
        private usuarioIdiomaController: IUsuarioIdiomaController,
        private alertaService: IAlertaService
    ) {}

    ngAfterContentChecked(): void {
        const usuarioLogado = localStorage.getItem('usuarioLogado');
        if (JSON.parse(usuarioLogado)?.nome) {
            this.mostraUsuarioLogado(usuarioLogado);
        } else {
            this.user = undefined;
        }
    }

    ngOnInit(): void {
        this.mostraUsuarioLogado(localStorage.getItem('usuarioLogado'));
        this.mostrarIdiomaselecionado(this._cookieService.obterCookie('idioma') as IdiomaSiglaEnum);
    }

    abrirFecharSelect(): void {
        if (this.verificarUsuarioLocalETipoProcer() || this.carregandoAlteracaoIdioma) {
            return;
        }

        this.selectAberto = !this.selectAberto;
    }

    verificarUsuarioLocalETipoProcer(): boolean {
        const usuario = JSON.parse(localStorage.getItem('usuarioLogado'));        
        if (
            environment.level === TipoAmbiente.LOCAL.toLocaleLowerCase() &&
            usuario?.categoria === UsuarioCategoriaEnum.PROCER
        ) {
            return true;
        }
        return false;
    }

    mostrarIdiomaselecionado(idiomaSigla: IdiomaSiglaEnum): void {
        switch (idiomaSigla) {
            case IdiomaSiglaEnum.INGLES:
                this.idiomaSelecionado = IdiomaEnum.INGLES;
                this.iconeSelecionado = IdiomaIconeEnum.INGLES;
                break;
            case IdiomaSiglaEnum.ESPANHOL:
                this.idiomaSelecionado = IdiomaEnum.ESPANHOL;
                this.iconeSelecionado = IdiomaIconeEnum.ESPANHOL;
                break;
            default:
                this.idiomaSelecionado = IdiomaEnum.PORTUGUES;
                this.iconeSelecionado = IdiomaIconeEnum.PORTUGUES;
                break;
        }

        this.selectAberto = false;
    }

    alterarIdioma(idiomaSigla: IdiomaSiglaEnum, idioma: IdiomaEnum): void {
        if (this.idiomaSelecionado === idioma) {
            this.selectAberto = false;
            return;
        }

        this.carregandoAlteracaoIdioma = true;
        this.usuarioIdiomaController
            .alterarIdioma(idiomaSigla)
            .pipe(
                takeUntil(this.unsubscribeAll),
                finalize(() => {
                    this.carregandoAlteracaoIdioma = false;
                })
            )
            .subscribe({
                next: () => {
                    this.idiomaSelecionado = idioma;
                    this._idiomaService.alterarIdioma(idiomaSigla);                    
                    window.location.reload();
                },
                error: () => {                    
                    this.trigger.closeMenu();                    
                    this.alertaService.mostrarErro(this._translocoService.translate('ERRO_ALTERAR_IDIOMA'), '');
                },              
            });
    }

    mostraUsuarioLogado(usuarioLogado: string): void {
        const usuario = JSON.parse(usuarioLogado);

        this.user = {
            id: null,
            name: usuario.nome + ' ' + usuario.sobrenome,
            email: usuario.email,
        };
    }

    updateUserStatus(status: string): void {
        // Return if user is not available
        if (!this.user) {
            return;
        }

        // Update the user
        this._userService
            .update({
                ...this.user,
                status,
            })
            .subscribe();
    }

    signOut(): void {
        this._router.navigate(['/sign-out']);
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
