import { DivisoesComPedulosInterface, PenduloVisaoSuperiorInterface } from 'app/shared/interfaces';
import { Injectable } from '@angular/core';
export abstract class IAgrupaPendulosPorDivisaoService {
    abstract execute(pendulos: PenduloVisaoSuperiorInterface[]): DivisoesComPedulosInterface[];
}

@Injectable({ providedIn: 'root' })
export class AgrupaPendulosPorDivisaoService implements IAgrupaPendulosPorDivisaoService {
    execute(pendulos: PenduloVisaoSuperiorInterface[]): DivisoesComPedulosInterface[] {
        let divisoes = [];
        for (let i = 0; i < pendulos.length; i++) {
            const idDivisaoAtual = pendulos[i].estrutura_armazenagem_divisao_pendulo.estrutura_armazenagem_divisao_id;
            if (i === 0) {
                divisoes.push({
                    idDivisao: idDivisaoAtual,
                    pendulos: [pendulos[i]],
                });
            } else {
                const divisaoJaExiste = divisoes.findIndex((d) => d.idDivisao === idDivisaoAtual);
                if (divisaoJaExiste !== -1) {
                    divisoes[divisaoJaExiste].pendulos.push(pendulos[i]);
                } else {
                    divisoes.push({
                        idDivisao: idDivisaoAtual,
                        pendulos: [pendulos[i]],
                    });
                }
            }
        }
        return divisoes;
    }
}
