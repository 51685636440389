    export interface EstruturaAmostragem {
        id: number;
        nome: string;
        codigo: number;
        tipo: string;
    }

    export interface Umidade {
        id: number;
        estrutura_amostragem: EstruturaAmostragem;
        usuario?: any;
        instante_inclusao: Date;
        data_amostra: Date;
        valor: number;
    }

    export interface EstruturaAmostragem2 {
        id: number;
        nome: string;
        codigo: number;
        tipo: string;
    }

    export interface Avariado {
        id: number;
        estrutura_amostragem: EstruturaAmostragem2;
        usuario?: any;
        instante_inclusao: Date;
        data_amostra: Date;
        valor: number;
    }

    export interface EstruturaAmostragem3 {
        id: number;
        nome: string;
        codigo: number;
        tipo: string;
    }

    export interface Impureza {
        id: number;
        estrutura_amostragem: EstruturaAmostragem3;
        usuario?: any;
        instante_inclusao: Date;
        data_amostra: Date;
        valor: number;
    }

    export interface IGraficoDados {
        umidades: Umidade[];
        avariados: Avariado[];
        impurezas: Impureza[];
    }

    export interface INomesIds {
        data: INomesIdsData[];
        pagination: object;
        total: number;
    }
    export interface INomesIdsData {
        id: number;
        nome: string;
    }
