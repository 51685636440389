import * as BABYLON from '@babylonjs/core';
import { Injectable } from '@angular/core';
import { CaminhoImagensSilo3DEnum, SiloBaseEnum } from 'app/shared/enums';
export abstract class IDefineMaterialConstrucaoSilo3DService {
    abstract execute(siloBase: string, materialSiloAtual: string, cena: BABYLON.Scene): void;
}

@Injectable({ providedIn: 'root' })
export class DefineMaterialConstrucaoSilo3DService implements IDefineMaterialConstrucaoSilo3DService {
    async execute(siloBase: string, materialSiloAtual: string, cena: BABYLON.Scene): Promise<void> {
        let materialChao: BABYLON.StandardMaterial;
        let texturaChao = new BABYLON.Texture(CaminhoImagensSilo3DEnum.GRAMA.toString(), cena);
        texturaChao.uScale = 200;
        texturaChao.vScale = 200;

        let texturaCilindro: BABYLON.Texture;
        let texturaChapeu: BABYLON.Texture;
        let texturaFundo = new BABYLON.Texture(CaminhoImagensSilo3DEnum.FUNDO_METAL.toString(), cena);

        if (materialSiloAtual === 'concreto') {
            texturaCilindro = new BABYLON.Texture(CaminhoImagensSilo3DEnum.CONCRETO.toString(), cena);
        } else if (materialSiloAtual === 'madeira') {
            texturaCilindro = new BABYLON.Texture(CaminhoImagensSilo3DEnum.MADEIRA.toString(), cena);
            texturaCilindro.uScale = 4;
        } else {
            texturaCilindro = new BABYLON.Texture(CaminhoImagensSilo3DEnum.METAL_HORIZONTAL.toString(), cena);
            texturaChapeu = new BABYLON.Texture(CaminhoImagensSilo3DEnum.METAL_VERTICAL.toString(), cena);
            texturaChapeu.uScale = 4;
        }

        const materialCilindro = new BABYLON.StandardMaterial('materialCilindro', cena);
        materialCilindro.diffuseTexture = texturaCilindro;
        materialCilindro.diffuseColor = new BABYLON.Color3(3, 3, 3);

        const materialChapeu = new BABYLON.StandardMaterial('materialChapeu', cena);
        materialChapeu.diffuseTexture = texturaChapeu;
        materialChapeu.diffuseColor = new BABYLON.Color3(3, 3, 3);

        const materialFundo = new BABYLON.StandardMaterial('materialFundo', cena);
        materialFundo.diffuseTexture = texturaFundo;
        materialFundo.diffuseColor = new BABYLON.Color3(3, 3, 3);

        if (siloBase === 'plana') {
            const chaoPlana = cena.getMeshByName('chaoPlana');
            const cilindroPlana = cena.getMeshByName(`cilindro${siloBase}`);
            const chapeuPlana = cena.getMeshByName(`chapeu${siloBase}`);
            const degrauPlana = cena.getMeshByName('degrauPlana');

            materialChao = new BABYLON.StandardMaterial('groundMaterialPadrao', cena);
            materialChao.diffuseTexture = texturaChao;
            chaoPlana.material = materialChao;

            cilindroPlana.material = materialCilindro;
            cilindroPlana.material.backFaceCulling = false;

            chapeuPlana.material = materialChapeu;
            chapeuPlana.material.backFaceCulling = false;

            degrauPlana.material = materialFundo;

            if (materialSiloAtual === 'concreto' || materialSiloAtual === 'madeira') {
                chapeuPlana.visibility = 0;
            }
        }

        if (siloBase === 'em_v') {
            const chaoV = cena.getMeshByName('chaoV');
            const cilindroV = cena.getMeshByName(`cilindro${siloBase}`);
            const chapeuV = cena.getMeshByName(`chapeu${siloBase}`);
            const fundoV = cena.getMeshByName(`fundo${siloBase}`);
            const pilaresV = cena.getMeshByName(`pilares${siloBase}`);

            materialChao = new BABYLON.StandardMaterial('groundMaterialPulmao', cena);
            materialChao.diffuseTexture = texturaChao;
            chaoV.material = materialChao;

            cilindroV.material = materialCilindro;
            cilindroV.material.backFaceCulling = false;

            chapeuV.material = materialChapeu;
            chapeuV.material.backFaceCulling = false;

            fundoV.material = materialFundo;
            fundoV.material.backFaceCulling = false;

            pilaresV.material = materialFundo;

            if (materialSiloAtual === 'concreto' || materialSiloAtual === 'madeira') {
                chapeuV.visibility = 0;
            }
        }

        if (siloBase === SiloBaseEnum.SEMI_V) {
            const cilindroSemiV = cena.getMeshByName(`cilindro${siloBase}`);
            const chapeuSemiV = cena.getMeshByName(`chapeu${siloBase}`);
            const fundoSemiV = cena.getMeshByName(`fundo${siloBase}`);

            cilindroSemiV.material = materialCilindro;
            cilindroSemiV.material.backFaceCulling = false;

            chapeuSemiV.material = materialChapeu;
            chapeuSemiV.material.backFaceCulling = false;

            fundoSemiV.material = materialFundo;
            fundoSemiV.material.backFaceCulling = false;

            if (materialSiloAtual === 'concreto' || materialSiloAtual === 'madeira') {
                chapeuSemiV.visibility = 0;
            }
        }

        cena.render();
    }
}
