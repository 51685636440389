import { Injectable } from '@angular/core';
import { IApiTransportInterface } from 'app/core/interfaces';
import { ApiTransportService } from 'app/core/services/transport';
import { Observable } from 'rxjs';

export abstract class IEstruturaAmostragemController {
    abstract salvaAmostragem(dados: any): Observable<any>;
    abstract buscaAmostragemPorId(id: string): Observable<any>;
    abstract editaAmostragem(id: string, dados: any): Observable<any>;
}

@Injectable({
    providedIn: 'root',
})
export class EstruturaAmostragemController implements IEstruturaAmostragemController {
    private transport: IApiTransportInterface;

    constructor(public readonly apiTransportService: ApiTransportService) {
        this.transport = this.apiTransportService.execute('/geral/estrutura-amostragem');
    }
    public buscaAmostragemPorId(id: string): Observable<any> {
        return this.transport.http.get<any>(`${this.transport.path}/${id}`);
    }

    public salvaAmostragem(dados: any): Observable<any> {
        return this.transport.http.post<any>(`${this.transport.path}`, dados);
    }
    public editaAmostragem(id: string, dados: any): Observable<any> {
        return this.transport.http.patch<any>(`${this.transport.path}/${id}`, dados);
    }
}
