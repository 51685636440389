export enum EquipamentoTipoEnum {
    EHD20 = 'ehd20',
    WMS = 'wms',
    ESTACAO_METEOROLOGICA = 'estacao_meteorologica',
    PAR12 = 'par12',
    DRYER = 'dryer',
    CONTROL = 'control',
    MODBUS_MASTER = 'modbus_master',
    MODBUS_SLAVE = 'modbus_slave',
    FLUXO_GRAOS = 'fluxo_graos',
    DISPLAY = 'display',
}

export enum EquipamentoCategoriaEnum {
    TERMOMETRIA = 'termometria',
    ESTACAO_METEOROLOGICA = 'estacao_meteorologica',
    AUTOMACAO = 'automacao',
    LEITURA_AUXILIAR = 'leitura_auxiliar',
    SECADOR = 'secador',
    AERACAO = 'aeracao',
}

export enum EquipamentoCategoriaLabelEnum {
    termometria = 'Termometria',
    estacao_meteorologica = 'Estacão Meteorologica',
    automacao = 'Automacção',
    leitura_auxiliar = 'Leitura auxiliar',
    secador = 'Secador',
    aeracao = 'Aeração',
}
