<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
    <span class="relative">
        <img class="w-9 h-9 sm:w-7 sm:h-7 rounded-full mt-1 sm:mt-0" src="assets/images/avatars/blank.png" />
    </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
    <button mat-menu-item>
        <ng-container *ngIf="user?.name; else carregandoUsuario">
            <img class="w-7 h-7 rounded-full" src="assets/images/avatars/blank.png" />
            <span class="flex flex-col leading-none ml-3">
                <span class="mt-1.5 text-md font-medium">{{ user.name ? (user.name | titlecase) : "Nome" }}</span>
                <span class="mt-1.5 text-md font-medium">{{ user.email ? user.email : "E-mail" }}</span>
            </span>
        </ng-container>
    </button>

    <div mat-menu-item
    class="w-full mat-menu-item-customizado"
    [ngClass]="selectAberto ? 'accordion-container' : ''"
    >
        <div (click)="$event.stopPropagation()">
                <div class="flex w-full h-full items-center justify-end mt-1 mb-2 cursor-pointer" (click)="abrirFecharSelect()">
                    <mat-icon class="icon-size-4" [svgIcon]="'icon-idiomas-mundo'"></mat-icon>
                    <span>{{ "USER_COMPONENT.IDIOMA" | transloco }}</span>
                    <span class="ml-2"> {{idiomaSelecionado}}</span>
                    <mat-icon *ngIf="idiomaSelecionado === idiomaEnum.PORTUGUES" class="icon-size-4 ml-2" [svgIcon]="'icon-idiomas-brasil'"></mat-icon>
                    <mat-icon *ngIf="idiomaSelecionado === idiomaEnum.INGLES" class="icon-size-4 ml-2" [svgIcon]="'icon-idiomas-eua'"></mat-icon>
                    <mat-icon *ngIf="idiomaSelecionado === idiomaEnum.ESPANHOL" class="icon-size-4 ml-2" [svgIcon]="'icon-idiomas-espanhol'"></mat-icon>
                    <mat-icon>{{ selectAberto ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
                </div>
                <mat-divider class="mb-1 mt-[-12px]"></mat-divider>
                <div class="flex flex-col items-end mr-[35px]" *ngIf="selectAberto">
                    <div class="flex flex-row items-center cursor-pointer">
                    <p class="font-normal hover:text-[#019DE8]" (click)="alterarIdioma(idiomaSigla.PORTUGUES, idiomaEnum.PORTUGUES)">{{idiomaEnum.PORTUGUES}}</p>
                    <mat-icon class="icon-size-4 ml-2" [svgIcon]="'icon-idiomas-brasil'"></mat-icon>
                </div>
                <div class="flex flex-row items-center cursor-pointer mt-[-20px]">
                    <p class="font-normal hover:text-[#019DE8] " (click)="alterarIdioma(idiomaSigla.INGLES, idiomaEnum.INGLES)">{{idiomaEnum.INGLES}}</p>
                    <mat-icon class="icon-size-4 ml-2" [svgIcon]="'icon-idiomas-eua'"></mat-icon>
                </div>
                <div class="flex flex-row items-center cursor-pointer mt-[-20px]">
                    <p class="font-normal hover:text-[#019DE8] " (click)="alterarIdioma(idiomaSigla.ESPANHOL, idiomaEnum.ESPANHOL)">{{idiomaEnum.ESPANHOL}}</p>
                    <mat-icon class="icon-size-4 ml-2" [svgIcon]="'icon-idiomas-espanhol'"></mat-icon>
                </div>
                </div>
        </div>
    </div>

    <button mat-menu-item (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span>  {{ "USER_COMPONENT.SAIR" | transloco }}</span>
    </button>
</mat-menu>

<ng-template #carregandoUsuario>
    <span class="m-auto">
        {{ "USER_COMPONENT.CARREGANDO_USUARIO" | transloco }}
    </span>
</ng-template>
