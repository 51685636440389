import { Injectable } from '@angular/core';

import { EstadoComunicacaoEnum } from 'app/shared/enums';
import { TooltipClassesEstadosinterface } from 'app/shared/interfaces';

export abstract class IRetornaEstiloStatusComunicacao {
    abstract statusComunicacaoSilo(
        estado: string,
        traducaoComunicacao: string,
        traducaoFalhaComunicacao: string,
        traducaoComunicacaoParcial: string,
        classes?: TooltipClassesEstadosinterface
    ): string;
}
@Injectable({ providedIn: 'root' })
export class RetornaEstiloStatusComunicacao implements IRetornaEstiloStatusComunicacao {
    constructor() {}

    statusComunicacaoSilo(
        estado: string,
        traducaoComunicacao: string,
        traducaoFalhaComunicacao: string,
        traducaoComunicacaoParcial: string,
        classes?: TooltipClassesEstadosinterface
    ): string {
        const statusCommunicationColor = {
            GREEN: '#01FF70',
            RED: '#D65757E5',
            YELLOW: '#d4d411',
        };

        let status: any = {};

        if (estado === EstadoComunicacaoEnum.ALL) {
            status.segundaLinhaTooltip = traducaoComunicacao;
            status.classTooltip = classes?.classeTodos || 'tooltip-em-execucao';
            status.cor = statusCommunicationColor.GREEN;
        }

        if (estado === EstadoComunicacaoEnum.NONE) {
            status.segundaLinhaTooltip = traducaoFalhaComunicacao;
            status.classTooltip = classes?.classeNenhum || 'tooltip-sem-comunicacao';
            status.cor = statusCommunicationColor.RED;
        }

        if (estado === EstadoComunicacaoEnum.PARTIAL) {
            status.segundaLinhaTooltip = traducaoComunicacaoParcial;
            status.classTooltip = classes?.classeParcial || 'tooltip-comunicacao-parcial';
            status.cor = statusCommunicationColor.YELLOW;
        }

        return status;
    }
}
