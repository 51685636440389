import { Injectable } from '@angular/core';
import moment from 'moment';

export abstract class ITrataTimezoneRegraService {
    abstract removeTimezoneHRSLIGAR(formula: string): string;
    abstract adicionaTimezoneHRSLIGAR(formula: string): string;
}

@Injectable()
export class TrataTimezoneRegraService implements ITrataTimezoneRegraService {
    removeTimezoneHRSLIGAR(formula: string): string {
        const regex = /\b\d{1,2}:\d{2}\b/g;
        let formulaTimezone = '';
        if (formula.includes('HRSLIGAR')) {
            const horariosSemTimezone = formula.match(regex);
            formulaTimezone = formula
                .replace(
                    `${horariosSemTimezone[0]}`,
                    `${moment(horariosSemTimezone[0], 'HH:mm').utc(false).format('HH:mm')}`
                )
                .replace(
                    `${horariosSemTimezone[1]}`,
                    `${moment(horariosSemTimezone[1], 'HH:mm').utc(false).format('HH:mm')}`
                );
            return formulaTimezone;
        } else {
            return formula;
        }
    }

    adicionaTimezoneHRSLIGAR(formula: string): string {
        const regex = /\b\d{1,2}:\d{2}\b/g;
        let formulaTimezone = '';
        if (formula.includes('HRSLIGAR')) {
            const horariosSemTimezone = formula.match(regex);
            formulaTimezone = formula
                .replace(
                    `${horariosSemTimezone[0]}`,
                    `${moment(horariosSemTimezone[0], 'HH:mm').add(moment().utcOffset(), 'minutes').format('HH:mm')}`
                )
                .replace(
                    `${horariosSemTimezone[1]}`,
                    `${moment(horariosSemTimezone[1], 'HH:mm').add(moment().utcOffset(), 'minutes').format('HH:mm')}`
                );
            return formulaTimezone;
        } else {
            return formula;
        }
    }
}
