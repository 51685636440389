import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthModule } from 'app/core/auth/auth.module';
import { IconsModule } from 'app/shared/icons/icons.module';
import { AsideMenuModule } from './aside-menu/aside-menu.module';
import { TranslocoRootModule } from 'app/transloco-root.module';
import { LoadingBarModule } from './loading-bar/loading-bar.module';
import { LoadingModule } from './services/loading';
import { SplashScreenModule } from 'app/core/splash-screen';
import { ConfigModule } from 'app/core/config';
import { MediaWatcherModule } from 'app/core/media-watcher/media-watcher.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MATERIAL_SANITY_CHECKS } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

@NgModule({
    imports: [
        AuthModule, 
        IconsModule, 
        AsideMenuModule, 
        TranslocoRootModule,
        LoadingModule,
        LoadingBarModule,
        SplashScreenModule,
        ConfigModule,
        MediaWatcherModule,
        MatDialogModule
    ],
    providers: [
        {
            // Disable 'theme' sanity check
            provide : MATERIAL_SANITY_CHECKS,
            useValue: {
                doctype: true,
                theme  : false,
                version: true
            }
        },
        {
            // Use the 'fill' appearance on Angular Material form fields by default
            provide : MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'fill'
            }
        }
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
        }
    }
}
