import { Injectable } from '@angular/core';
import { IApiTransportInterface } from 'app/core/interfaces';
import { ApiTransportService } from 'app/core/services/transport';
import { Observable } from 'rxjs';
import { IEstruturaArmazenagemSelectInterface } from 'app/shared/interfaces';

export abstract class IEstruturaArmazenagemSensores3DController {
    abstract buscaDivisoesSensores3d(): Observable<IEstruturaArmazenagemSelectInterface[]>;
}

@Injectable()
export class EstruturaArmazenagemSensores3DController implements IEstruturaArmazenagemSensores3DController {
    private transport: IApiTransportInterface;

    constructor(public readonly apiTransportService: ApiTransportService) {
        this.transport = this.apiTransportService.execute('/armazenagem/estrutura-armazenagem-divisao');
    }

    buscaDivisoesSensores3d(): Observable<any> {
        return this.transport.http.get(
            `${this.transport.path}/sensor-volumetria-3d`
        );
    }
}