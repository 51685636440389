import { Injectable } from '@angular/core';
import { ApiTransportService } from 'app/core/services/transport';
import { Observable } from 'rxjs';

import { IApiTransportInterface } from 'app/core/interfaces/';
import { ListaRecursosInterface } from 'app/shared/interfaces';
import { SiloComVolumeModel, SiloModel } from 'app/shared/models';
import { IObjectToAnyService } from 'app/shared/services';
import { EstruturasArmazenagemInterface } from 'app/modules/armazenagem/visualizacao/painel-geral/interfaces';

export abstract class ISilosController {
    abstract buscaVarios(order?: string): Observable<ListaRecursosInterface<SiloModel>>;
    abstract listarEstruturas(order?: string): Observable<EstruturasArmazenagemInterface[]>;
    abstract buscaVariosComAeradores(order?: string): Observable<ListaRecursosInterface<SiloModel>>;
    abstract buscaSilosComPropriedades(order?: string): Observable<ListaRecursosInterface<any>>;    
    abstract buscaVariosComVolume(order?: string): Observable<ListaRecursosInterface<SiloComVolumeModel>>;
    abstract buscaSilosSensorCO2(): Observable<ListaRecursosInterface<SiloComVolumeModel>>;
}

@Injectable({
    providedIn: 'root',
})
export class SilosController implements ISilosController {
    private transport: IApiTransportInterface;

    constructor(
        public readonly apiTransportService: ApiTransportService,
        private readonly objectToAnyService: IObjectToAnyService
    ) {
        this.transport = this.apiTransportService.execute('/armazenagem');
    }

    listarEstruturas(): Observable<any> {        
        this.transport.options.params = this.objectToAnyService.toQueryString({
            order: 'ASC',            
        });
        return this.transport.http.get(`${this.transport.path}`, this.transport.options);
    }

    buscaVarios(): Observable<any> {
        const arquivado = { 'filter.arquivado': 'false' };
        this.transport.options.params = this.objectToAnyService.toQueryString({
            order: 'ASC',
            ...arquivado,
        });
        return this.transport.http.get(`${this.transport.path}/silo`, this.transport.options);
    }

    buscaVariosComAeradores(): Observable<any> {
        const arquivado = { 'filter.arquivado': 'false' };
        this.transport.options.params = this.objectToAnyService.toQueryString({
            order: 'ASC',
            ...arquivado,
        });
        return this.transport.http.get(`${this.transport.path}/silo/aeradores`, this.transport.options);
    }

    buscaVariosComVolume(): Observable<any> {
        return this.transport.http.get(`${this.transport.path}/silo/sensor-volume`);
    }

    buscaSilosComPropriedades(): Observable<any> {
        const arquivado = { 'filter.arquivado': 'false' };
        this.transport.options.params = this.objectToAnyService.toQueryString({
            order: 'ASC',
            ...arquivado,
        });
        return this.transport.http.get(`${this.transport.path}/silo/propriedade`, this.transport.options);
    }

    buscaSilosSensorCO2(): Observable<any> {
        return this.transport.http.get(`${this.transport.path}/silo/sensor-co2`);
    }
}
