import * as BABYLON from '@babylonjs/core';
import { Injectable } from '@angular/core';
import { PenduloSilo3DInterface, Silo3DPropriedadesInterface } from 'app/shared/interfaces';
import { CaminhoRaizImagensSilo3DEnum } from 'app/shared/enums';
export abstract class ICriaEscadaSilo3DService {
    abstract execute(
        siloBase: string,
        penduloData: PenduloSilo3DInterface[],
        siloData: Silo3DPropriedadesInterface,
        cena: BABYLON.Scene
    ): void;
}

@Injectable({ providedIn: 'root' })
export class CriaEscadaSilo3DService implements ICriaEscadaSilo3DService {
    async execute(
        siloBase: string,
        penduloData: PenduloSilo3DInterface[],
        siloData: Silo3DPropriedadesInterface,
        cena: BABYLON.Scene
    ): Promise<void> {
        const escadaMaterial = new BABYLON.StandardMaterial('escadaMaterial', cena);
        const coneRadius = siloData.diametroChapeu / 2;
        const coneHypotenuse = Math.sqrt(siloData.alturaChapeu * siloData.alturaChapeu + coneRadius * coneRadius);
        const result = await BABYLON.SceneLoader.ImportMeshAsync(
            null,
            CaminhoRaizImagensSilo3DEnum.PASTA_RAIZ,
            'Ladder.glb',
            cena
        );
        const escada = result.meshes[0];
        const coneAngle = Math.asin(coneRadius / coneHypotenuse);
        escada.material = escadaMaterial;
        const parentLadder = new BABYLON.TransformNode('parentLadder', cena);
        const angulo = Math.atan2(penduloData[0].posZ, penduloData[0].posX) + BABYLON.Tools.ToRadians(15);
        const x = coneRadius * Math.cos(angulo);
        const z = coneRadius * Math.sin(angulo);
        let altura;
        if (siloBase === 'plana') {
            altura = siloData.alturaSilo + siloData.alturaPlataforma + 0.1;
        } else {
            altura = siloData.alturaSilo + siloData.alturaFundo + siloData.alturaPlataforma;
        }

        escadaMaterial.alpha = 0;

        result.meshes.forEach((mesh) => {
            mesh.material = escadaMaterial;
            escadaMaterial.diffuseColor = new BABYLON.Color3(1, 1, 1);
            escadaMaterial.diffuseTexture = null;
            escadaMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
            mesh.parent = parentLadder;
        });

        parentLadder.rotation.z = coneAngle - 0.05;
        parentLadder.scaling.x = coneHypotenuse / 18;
        parentLadder.scaling.y = coneHypotenuse / 18;
        parentLadder.scaling.z = coneHypotenuse / 18;

        parentLadder.position.y = altura;
        parentLadder.position.x = x;
        parentLadder.position.z = z;
        parentLadder.rotation.y = -angulo - BABYLON.Tools.ToRadians(5);
    }
}
