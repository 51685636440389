import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';

import { AutenticacaoUsuarioObservable } from 'app/shared/observables';
import { ApiTransportService } from 'app/core/services/transport';
import { UsuarioLogadoInterface } from 'app/shared/interfaces';
import { IApiTransportInterface } from 'app/core/interfaces';

export abstract class IAutenticacaoUsuarioController {
    abstract buscarInformacoesUsuarioLogado(): Observable<UsuarioLogadoInterface>;
}

@Injectable({
    providedIn: 'root',
})
export class AutenticacaoUsuarioController implements IAutenticacaoUsuarioController {
    private transport: IApiTransportInterface;

    constructor(
        private autenticacaoUsuarioObservable: AutenticacaoUsuarioObservable,

        readonly apiTransportService: ApiTransportService
    ) {
        this.transport = this.apiTransportService.execute('/geral');
    }

    buscarInformacoesUsuarioLogado(): Observable<UsuarioLogadoInterface> {
        return this.transport.http.get<UsuarioLogadoInterface>(`${this.transport.path}/usuario/unidade`).pipe(
            map((response) => {
                const usuario = JSON.parse(localStorage.getItem('usuarioLogado'));
                if (usuario) {
                    usuario.nome = response.nome;
                    usuario.sobrenome = response.sobrenome;
                    usuario.email = response.email;
                    localStorage.setItem('usuarioLogado', JSON.stringify(usuario));
                }
                this.autenticacaoUsuarioObservable.setInformacoesAutenticacaoUsuario = response;
                return response;
            })
        );
    }
}
