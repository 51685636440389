import { Injectable } from '@angular/core';
import { DiasSemanaEnum } from 'app/modules/armazenagem/cadastro/regras/cadastro/enums';
import { TranslocoService } from '@ngneat/transloco';
import { TranslocoHttpLoader } from 'app/transloco-root.module';
export abstract class IRetornaDiasSemanaExibicaoService {
    abstract execute(formula: string): string;
}

@Injectable()
export class RetornaDiasSemanaExibicaoService implements IRetornaDiasSemanaExibicaoService {
    constructor(
        private transloco: TranslocoService,
        private translocoHttpLoader: TranslocoHttpLoader
    ){
        this.translocoHttpLoader.getTranslation(this.transloco.getActiveLang()).subscribe((translation) => {
            this.traduzPagina.traducaoSegunda = translation.SEGUNDA_FEIRA;
            this.traduzPagina.traducaoTerça = translation.TERCA_FEIRA;
            this.traduzPagina.traducaoQuarta = translation.QUARTA_FEIRA;
            this.traduzPagina.traducaoQuinta = translation.QUINTA_FEIRA;
            this.traduzPagina.traducaoSexta = translation.SEXTA_FEIRA;
            this.traduzPagina.traducaoSábado = translation.SABADO;
            this.traduzPagina.traducaoDomingo = translation.DOMINGO;
        });
    }

    traduzPagina = {
        traducaoSegunda: '',
        traducaoTerça : '',
        traducaoQuarta : '',
        traducaoQuinta : '',
        traducaoSexta : '',
        traducaoSábado : '',
        traducaoDomingo : ''
    }

    public execute(formula: string): string {
        if (formula.includes(DiasSemanaEnum.SEGUNDA_FEIRA)) return this.traduzPagina.traducaoSegunda;
        if (formula.includes(DiasSemanaEnum.TERCA_FEIRA)) return this.traduzPagina.traducaoTerça;
        if (formula.includes(DiasSemanaEnum.QUARTA_FEIRA)) return this.traduzPagina.traducaoQuarta;
        if (formula.includes(DiasSemanaEnum.QUINTA_FEIRA)) return this.traduzPagina.traducaoQuinta;
        if (formula.includes(DiasSemanaEnum.SEXTA_FEIRA)) return this.traduzPagina.traducaoSexta;
        if (formula.includes(DiasSemanaEnum.SABADO)) return this.traduzPagina.traducaoSábado;
        if (formula.includes(DiasSemanaEnum.DOMINGO)) return this.traduzPagina.traducaoDomingo;
    }
}
