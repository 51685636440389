import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';

import { ISetaTituloPaginaService } from 'app/shared/services';
import { FuncionalidadeChaveTraducaoEnum } from 'app/shared/enums';
import { PermissaoPerfilFuncionalidadeObservable } from 'app/shared/observables';
import { FuncionalidadePerfilInterface } from 'app/shared/interfaces';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-sem-permissao-acesso',
    templateUrl: './sem-permissao-acesso.component.html',
    styleUrls: ['./sem-permissao-acesso.component.scss'],
})
export class SemPermissaoAcessoComponent implements OnInit, OnDestroy {
    constructor(
        private router: Router,
        private setaTitulo: ISetaTituloPaginaService,
        private translocoService: TranslocoService,
        private sanitizer: DomSanitizer,
        private permissaoFuncionalidadeObservable: PermissaoPerfilFuncionalidadeObservable
    ) {}

    fraseEntreEmContato: SafeHtml;
    mensagemWhatsApp: string;

    private unsubscribeAll: Subject<any> = new Subject<any>();

    ngOnInit() {
        this.verificaFuncionalidade();
    }

    verificaFuncionalidade(): void {
        this.permissaoFuncionalidadeObservable.permissaoFuncionalidade$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((perfilFuncionalidade) => {
                if (perfilFuncionalidade?.funcionalidade) {
                    this.traduzTextosDaPagina();
                    this.setaTituloPagina(perfilFuncionalidade?.funcionalidade);
                } else {
                    this.router.navigate(['/']);
                }
            });
    }

    traduzTextosDaPagina(): void {
        setTimeout(() => {
            this.fraseEntreEmContato = this.sanitizer.bypassSecurityTrustHtml(
                this.translocoService.translate(
                    'SEM_PERMISSAO_ACESSO_COMPONENT.FAVOR_ENTRAR_EM_CONTATO_COM_A_EQUIPE_COMERCIAL'
                )
            );
            this.mensagemWhatsApp = this.translocoService.translate('SEM_PERMISSAO_ACESSO_COMPONENT.MENSAGEM_WHATSAPP');
        }, 1000);
    }

    setaTituloPagina(funcionalidade: FuncionalidadePerfilInterface): void {
        const caminhoCompleto = this.translocoService.translate(FuncionalidadeChaveTraducaoEnum[funcionalidade.chave]);
        const caminhoArray = caminhoCompleto.trim().split('>');

        this.setaTitulo.setaTituloPaginaService({
            modulo: caminhoArray[0],
            titulo: caminhoArray[1],
            categoria: caminhoArray[2],
        });
    }

    irParaWhatsApp(contato: any): string {
        return `https://wa.me/${contato}?text=${this.mensagemWhatsApp}`;
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
