export enum SensorTipoSecadorListaEnum {
    TODOS = 'Todos',
    TEMPERATURA_DIGITAL = 'Temperatura Digital',
    TEMPERATURA_TERMORESISTENCIA = 'Temperatura Termoresistencia',
    UMIDADE_GRAO = 'Umidade Grão',
    UMIDADE_RELATIVA = 'Umidade Relativa',
    PRESSAO = 'Pressão',
    CARGA_GRAOS = 'Carga grãos',
    TEMPERATURA_TEMOPAR = 'TemperaturaTermopar',
}

export enum SensorTipoSecadorEnum {
    NENHUM = 'nenhum',
    TEMPERATURA_DIGITAL = 'temperatura_digital',
    TEMPERATURA_TERMORESISTENCIA = 'temperatura_termoresistencia',
    UMIDADE_GRAO = 'umidade_grao',
    UMIDADE_RELATIVA = 'umidade_relativa',
    PRESSAO = 'pressao',
    CARGA_GRAOS = 'carga_graos',
    NIVEL = 'nivel',
    FLUXO_GRAO = 'fluxo_grao',
    TEMPERATURA_TEMOPAR = 'temperatura_termopar',
    TEMPERATURA_GRAO = 'temperatura_grao',
    NIVEL_MINIMO = 'nivel_minimo',
    NIVEL_MAXIMO = 'nivel_maximo',
    NIVEL_INTERMEDIARIO = 'nivel_intermediario',
}

export enum SensorTipoSecadorSelectEnum {
    todos = 'TODOS',
    nenhum = 'NENHUM',
    temperatura_digital = 'SECADOR_VISAO_SCREEN.TEMPERATURA_DIGITAL',
    umidade_grao = 'SECADOR_VISAO_SCREEN.UMIDADE_GRAO',
    umidade_relativa = 'SECADOR_VISAO_SCREEN.UMIDADE_RELATIVA',
    pressao = 'SECADOR_VISAO_SCREEN.PRESSAO',
    nivel = 'NIVEL',
    fluxo_grao = 'SECADOR_VISAO_SCREEN.FLUXO_GRAO',
    temperatura_termopar = 'SECADOR_VISAO_SCREEN.TEMPERATURA_TERMOPAR',
    temperatura_grao = 'SECADOR_VISAO_SCREEN.TEMPERATURA_GRAO',
    nivel_minimo = 'SECADOR_VISAO_SCREEN.NIVEL_MINIMO',
    nivel_maximo = 'SECADOR_VISAO_SCREEN.NIVEL_MAXIMO',
    nivel_intermediario = 'SECADOR_VISAO_SCREEN.NIVEL_INTERMEDIARIO',
}

export enum TipoLeituraEnum {
    TEMPERATURA = 'temperatura',
    UMIDADE = 'umidade',
    PRESSAO = 'pressao',
}
