export interface PenduloSilo3DInterface {
    numeroPendulo: string; // Número do pendulo para ser mostrado no botão
    mediaTemperatura: number; // Media de todas temperaturas validas do pendulo
    minTemperatura: number; // Valor da menor temperatura de algum sensor
    maxTemperatura: number; // Valor da maior temperatura de algum sensor
    qtdsensores: number; // Qtd de sensores no pendulo
    tempSensores: string[]; // Valor do sensor em formato de string EX: "---" ou "25.4"
    tempSensoresStr: string[]; //String formatada para ser inserida na temperatura do sensor EX: "01: 25,5"
    tipoPendulo: string; // Digital ou Termopar
    nivelVolume: number; // Até qual sensor está o grão
    posX: number; // Eixo X
    posZ: number; // Eixo Z
    posY1?: number; // Altura do pendulo na parte mais baixa
    posY2?: number; // Altura do pendulo na parte mais alta
    central?: boolean; // Pendulo mais próximo do centro
    destaque?: boolean; // Temperatura ruim
    distanciaBase?: number;
    distanciaTelhado?: number;
    sensorTempEspacamento?: number;
    ativo: boolean; // Se as informações do pêndulo estão aparecendo ou não
}
