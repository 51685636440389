import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { CookieHelper } from 'app/shared/helpers';
import { InformacaoesPaginaObservable } from 'app/shared/observables';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-page-info',
    templateUrl: './page-info.component.html',
    styleUrls: ['./page-info.component.scss'],
})
export class PageInfoComponent implements AfterViewInit, OnDestroy {
    moduleTitle = '';
    pageTitleInfo = '';
    subMenuTitleInfo = '';
    categoriaTitleInfo = '';

    private unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private pageInfo: InformacaoesPaginaObservable,
        private changeDetectorRef: ChangeDetectorRef,
        private transloco: TranslocoService
    ) {}

    ngAfterViewInit(): void {
        this.carregaTraducao();
    }

    private carregaTraducao(): void {
        const idioma = CookieHelper.carregarCookie('idioma');
        this.transloco.load(idioma).subscribe(() => {
            this.adicionaInformacaoPagina();
        });
    }

    private adicionaInformacaoPagina(): void {
        this.pageInfo.moduloSelecionado$.pipe(takeUntil(this.unsubscribeAll)).subscribe((module: any) => {
            this.moduleTitle = this.transloco.translate(module);
            this.changeDetectorRef.markForCheck();
        });
        this.pageInfo.subMenuSelecionado$.pipe(takeUntil(this.unsubscribeAll)).subscribe((page: any) => {
            this.pageTitleInfo = this.transloco.translate(page);
            this.changeDetectorRef.markForCheck();
        });
        this.pageInfo.paginaSelecionada$.pipe(takeUntil(this.unsubscribeAll)).subscribe((sub: any) => {
            this.subMenuTitleInfo = this.transloco.translate(sub);
            this.changeDetectorRef.markForCheck();
        });
        this.pageInfo.categoriaSelecionada$.pipe(takeUntil(this.unsubscribeAll)).subscribe((categoria: any) => {
            this.categoriaTitleInfo = this.transloco.translate(categoria);
            this.changeDetectorRef.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
