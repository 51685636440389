export enum Motivo {
    GraosAbaixo = '% de umidade dos grãos muito baixo',
    ElevadoUmidade = 'Elevado % de umidade dos grãos',
    ElevadoAvariados = 'Elevado % de avariados totais',
    ElevadoImpurezas = 'Elevado % de impurezas',
    UmidadeAdequada = '% de umidade dos grãos adequada',
    AvariadosPadrao = '% de avariados totais padrão',
    ImpurezasAdequada = '% de impurezas adequado'
}

export enum TipoSiloEnum {
    FalhaComunicacao = 'FALHA_COMUNICACAO',
    AguardandoLeitura = 'AGUARDANDO_LEITURA',
    Comunicando = 'COMUNICANDO',
    ComunicandoParcial = 'COMUNICANDO_PARCIAL'
}

export enum GraficoExpedicaoAmostragensEnum {
    TEMPERATURA_MIN = 'Temperatura mínima (°C)',
    TEMPERATURA_MEDIA = 'Temperatura média (°C)',
    TEMPERATURA_MAX = 'Temperatura máxima (°C)',
    UMIDADE_RELATIVA = 'Umidade relativa intergranular (%)',
    UMIDADE_GRAO = 'Umidade do grão (%)',
}

export enum TipoEmblemaExpedicaoAmostragensEnum {
    TEMPERATURA = 'temperatura',
    UMIDADE = 'umidade'
}
