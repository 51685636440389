export const environment = {
    isMockEnabled: false,
    production: false,
    level: 'nuvem',
    api: {
        urlCeres: 'https://api.ceres.dev.procer.co/v1',
        urlGestao: 'https://api-gestao-unidade.ceres.dev.procer.co/v1',
    },
    whiteLabel: {
        logoLoginUrl: 'assets/images/logo/logo-procer-azul.svg',
        logoRedirecionamentoUrl: 'assets/images/logo/logo-procer.png',
        logoMenuFechadoUrl: 'assets/images/logo/logo-menu.png',
        logoMenuAbertoUrl: 'assets/images/logo/logo-navbar.png',
        imgMapaContatos: 'assets/img/mapa-contatos-procer.gif',
    },
};
