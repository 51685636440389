import { PaletaDeCoresInterface } from "app/shared/interfaces";

export const paletaCoresEntradaTemperaturaGraoConstant: { [key in string]: PaletaDeCoresInterface[] } = {
    arroz: [
        { min: '', max: '', fundo: '#EBEEF2', cor: '#16171B' },
        { min: 0, max: 39, fundo: '#313696', cor: '#FFFFFF' },
        { min: 40, max: 49, fundo: '#74aed2', cor: '#FFFFFF' },
        { min: 50, max: 65, fundo: '#3DAC79', cor: '#FFFFFF' },
        { min: 66, max: 75, fundo: '#E75135', cor: '#FFFFFF' },
        { min: 76, max: 200, fundo: '#D83027', cor: '#FFFFFF' },
    ],
    milho: [
        { min: '', max: '', fundo: '#EBEEF2', cor: '#16171B' },
        { min: 0, max: 79, fundo: '#313696', cor: '#FFFFFF' },
        { min: 80, max: 89, fundo: '#74aed2', cor: '#FFFFFF' },
        { min: 90, max: 109, fundo: '#3DAC79', cor: '#FFFFFF' },
        { min: 110, max: 119, fundo: '#E75135', cor: '#FFFFFF' },
        { min: 120, max: 300, fundo: '#D83027', cor: '#FFFFFF' },
    ],
    soja: [
        { min: '', max: '', fundo: '#EBEEF2', cor: '#16171B' },
        { min: 0, max: 69, fundo: '#313696', cor: '#FFFFFF' },
        { min: 70, max: 79, fundo: '#74aed2', cor: '#FFFFFF' },
        { min: 80, max: 100, fundo: '#3DAC79', cor: '#FFFFFF' },
        { min: 101, max: 110, fundo: '#E75135', cor: '#FFFFFF' },
        { min: 111, max: 300, fundo: '#D83027', cor: '#FFFFFF' },
    ],
    trigo: [
        { min: '', max: '', fundo: '#EBEEF2', cor: '#16171B' },
        { min: 0, max: 79, fundo: '#313696', cor: '#FFFFFF' },
        { min: 80, max: 89, fundo: '#74aed2', cor: '#FFFFFF' },
        { min: 90, max: 109, fundo: '#3DAC79', cor: '#FFFFFF' },
        { min: 110, max: 119, fundo: '#E75135', cor: '#FFFFFF' },
        { min: 120, max: 300, fundo: '#D83027', cor: '#FFFFFF' },
    ],
};
